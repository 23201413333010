import {Component, EventEmitter, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {UsersService} from '../../../_services/users.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {BrandsComponent} from '../brands.component';
import {PostsService} from '../../../_services/posts.service';
import {BrandsService} from '../../../_services/brands.service';
import {Subscription} from 'rxjs';
import {UserType} from '../../../_types/user.type';
import {PostType} from '../../../_types/post.type';
import {UploaderOptions, UploadFile, UploadInput, UploadOutput} from 'ngx-uploader';
import * as firebase from 'firebase/app';
import {environment} from '../../../../environments/environment';
import {NotificationType} from '../../../_types/notification.type';
import {FcmService} from '../../../_services/fcm.service';
import {NotificationsService} from '../../../_services/notifications.service';
import {MatDialog} from '@angular/material/dialog';
import {SharedPostDialogComponent} from '../../../_shared/shared-post-dialog/shared-post-dialog.component';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-brand-calendar',
  templateUrl: './brand-calendar.component.html',
  styleUrls: ['./brand-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BrandCalendarComponent implements OnInit, OnDestroy {

  today = new Date();
  month: number;
  year: number;
  nextMonth: number;
  nextYear: number;
  prevMonth: number;
  prevYear: number;

  searchPost: string;
  showRemove: {} = {};
  showPostNew: {} = {};
  filterStatus: string[] = [];
  filterPlatform: string[] = [];
  filterType: string[] = [];
  hideAutoComments: boolean;
  count = {
    hide: 0,
    social: 0,
    graphic: 0,
    client: 0,
    accept: 0,
    publish: 0
  };

  platforms = [
    {
      value: 'facebook',
      viewValue: 'Facebook',
      types: [
        {value: 'carousel', viewValue: 'Carousel'},
        {value: 'collection', viewValue: 'Collection'},
        {value: 'eventResponses', viewValue: 'Event responses'},
        {value: 'group', viewValue: 'Group'},
        {value: 'image', viewValue: 'Image'},
        {value: 'instantExperience', viewValue: 'Instant Experience'},
        {value: 'leadGenerationAds', viewValue: 'Lead generation ads'},
        {value: 'pageLikes', viewValue: 'Page likes'},
        {value: 'postEngagement', viewValue: 'Post Engagement'},
        {value: 'offers', viewValue: 'Offers'},
        {value: 'video', viewValue: 'Video'}
      ]
    },
    {
      value: 'twitter',
      viewValue: 'Twitter',
      types: [
        {value: 'generalTweets', viewValue: 'General Tweets'},
        {value: 'mentions', viewValue: 'Mentions'},
        {value: 'replies', viewValue: 'Replies'},
      ]
    },
    {
      value: 'youTube',
      viewValue: 'YouTube',
      types: [
        {value: 'image', viewValue: 'Image'},
        {value: 'playlist', viewValue: 'Playlist'},
        {value: 'polls', viewValue: 'Polls'},
        {value: 'text', viewValue: 'Text'},
        {value: 'video', viewValue: 'Video'}
      ]
    },
    {
      value: 'instagram',
      viewValue: 'Instagram',
      types: [
        {value: 'carousel', viewValue: 'Carousel'},
        {value: 'igtv', viewValue: 'IGTV'},
        {value: 'mappedGuided', viewValue: 'Mapped – Guided'},
        {value: 'photo', viewValue: 'Photo'},
        {value: 'sponsored', viewValue: 'Sponsored'},
        {value: 'story', viewValue: 'Story'},
        {value: 'video', viewValue: 'Video'},
      ]
    },
    {
      value: 'linkedIn', viewValue: 'LinkedIn',
      types: [
        {value: 'conversation', viewValue: 'Conversation'},
        {value: 'dynamic', viewValue: 'Dynamic'},
        {value: 'job', viewValue: 'Job'},
        {value: 'message', viewValue: 'Message'},
        {value: 'singleImage', viewValue: 'Single Image'},
        {value: 'text', viewValue: 'Text'},
        {value: 'video', viewValue: 'Video'},
      ]
    }
  ];
  types: any[] = [];

  days: Array<any> = [];
  user: UserType;
  brand: any;
  posts: any[] = [];
  postsComplete = false;
  users: UserType[] = [];
  usersComplete = false;

  options: UploaderOptions;
  files: UploadFile[] = [];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  postsView = 'list';
  firstDayMonthIndex = 0;
  lastDayMonthComplement = 0;

  private subscriptions: Array<Subscription> = [];
  private routeSubscription: Subscription;
  private postsSubscription: Subscription;

  constructor(public brandsComponent: BrandsComponent,
              private dialog: MatDialog,
              private postsService: PostsService,
              private brandsService: BrandsService,
              private usersService: UsersService,
              private notificationsService: NotificationsService,
              private fcmService: FcmService,
              private route: ActivatedRoute,
              private router: Router,
              private afs: AngularFirestore,
              private progress: NgProgress,
              private snackBar: MatSnackBar) {

    this.routeSubscription = this.route.params.subscribe(params => {

      // unsubscribe all subscriptions excluding route
      this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
      });

      // reset values
      this.postsComplete = false;

      // get month and year
      this.month = parseInt(params.month, 10);
      this.year = parseInt(params.year, 10);

      // navigate to calendar current month and year
      if ((!this.month) || (!this.year)) {
        this.router.navigate([`/brands/${params.uid}/calendar/${this.today.getFullYear()}/${this.today.getMonth() + 1}`]);
      }

      // set prev and next month
      this.nextMonth = this.month + 1;
      this.nextYear = this.year;
      if (this.nextMonth === 13) {
        this.nextMonth = 1;
        this.nextYear = this.year + 1;
      }

      this.prevMonth = this.month - 1;
      this.prevYear = this.year;
      if (this.prevMonth === 0) {
        this.prevMonth = 12;
        this.prevYear = this.year - 1;
      }

      // set nav
      this.brandsComponent.setNav(params.uid);

      // set filter
      if (params.status) {
        this.filterStatus = [params.status];
      }

      // set user
      this.user = this.route.snapshot.parent.parent.data.user;
      if (this.user.settings && this.user.settings.socialBrands_hideAutoComments) {
        this.hideAutoComments = this.user.settings.socialBrands_hideAutoComments;
      }
      if (this.user.settings && this.user.settings.socialBrands_calendarView) {
        this.postsView = this.user.settings.socialBrands_calendarView;
      }

      // get brand
      this.getBrand();

      // get users
      if (!this.usersComplete) {
        this.getUsers();
      }
    });
  }

  ngOnInit() {
  }

  onAdd(date: Date) {
    this.progress.ref().start();

    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    const post: PostType = {
      uid: this.afs.createId(),
      files: [],
      date: date,
      type: '',
      platform: '',
      content: '',
      imageCopy: '',
      imageComments: '',
      showInMonth: year + '' + month,
      brandRef: this.afs.doc(`companies/${this.user.companyRef.id}/brands/${this.brand.uid}`).ref,
      status: 'hide',
      dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
      dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
      isActive: true,
      messages: [{
        uid: this.afs.createId(),
        message: 'has created the post',
        dateCreate: new Date,
        userRef: this.afs.doc(`users/${this.user.uid}`).ref,
        files: [],
        type: 'auto'
      }]
    };

    this.postsService.insertPost(post, this.user)
      .then(() => {
        // inform users
        if (this.brand.followerRefs) {
          for (const informUser of Object.keys(this.brand.followerRefs)) {
            // get user to check if user can see hidden post
            this.usersService.onceUserActive(this.afs.doc(`users/${informUser}`).ref).then(infU => {
              if ((infU.data().uid !== this.user.uid) && ((infU.data()._privileges.canSeeHiddenPost) || (infU.data()._rolesName === 'admin'))) {
                // insert notification
                this.saveNotification(
                  `<p>has created in brand <a class="link" routerLink="brands/${post.brandRef.id}">${this.brand.name}</a> <a class="link" routerLink="brands/${this.brand.uid}/post/${post.uid}">post</a></p>`,
                  informUser,
                  post.uid
                );
                // send push notification
                this.subscriptions.push(this.usersService.getUserByRef(this.afs.doc(`users/${informUser}`).ref).subscribe(user => {
                  this.fcmService.sendMessage(user, {
                    title: `New post has been created`,
                    body: `${this.user.name} has created the post in brand ${this.brand.name}`,
                    click_action: `${environment.url}/notifications`
                  });
                }));
              }
            });
          }
        }
        this.progress.ref().complete();
        this.showPostNew['' + date] = false;
        this.snackBar.open('Post has been added', '', environment.snackbarSuccess);
      })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  onChangeUserSettingsAutoComments() {
    this.progress.ref().start();
    this.hideAutoComments = !this.hideAutoComments;
    if (!this.user.settings) {
      this.user.settings = {};
    }
    this.user.settings['socialBrands_hideAutoComments'] = this.hideAutoComments;
    this.usersService.updateUser({
      settings: this.user.settings
    }, this.user.uid)
      .then(() => {
        this.progress.ref().complete();
        this.snackBar.open('User settings has been updated', '', environment.snackbarSuccess);
        this.getPosts(); // refresh show/hide autocomments
      })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  onChangeUserSettingsView() {
    this.progress.ref().start();
    if (!this.user.settings) {
      this.user.settings = {};
    }
    this.user.settings['socialBrands_calendarView'] = this.postsView;
    this.usersService.updateUser({
      settings: this.user.settings
    }, this.user.uid)
      .then(() => {
        this.progress.ref().complete();
        this.snackBar.open('User settings has been updated', '', environment.snackbarSuccess);
      })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  onChoosePlatform() {
    this.filterType = [];
    this.types = [];

    this.platforms.forEach(platform => {
      if (this.filterPlatform.includes(platform.value)) {
        // check unique
        let exists = false;
        platform.types.forEach(typeP => {
          this.types.forEach(typeT => {
            if (typeT.value === typeP.value) {
              exists = true;
            }
          });
          if (!exists) {
            this.types.push(typeP);
          }
        });
      }
    });
  }

  onPrevFile(post: PostType) {
    if (post.imagesVideosIndex === 0) {
      post.imagesVideosIndex = (post.imagesVideosCount - 1);
    } else {
      post.imagesVideosIndex--;
    }
  }

  onNextFile(post: PostType) {
    if (post.imagesVideosIndex === (post.imagesVideosCount - 1)) {
      post.imagesVideosIndex = 0;
    } else {
      post.imagesVideosIndex++;
    }
  }

  onOpenDialog(post: PostType) {
    this.dialog.open(SharedPostDialogComponent, {
      autoFocus: false,
      data: {
        post: post,
        user: this.user,
        users: this.users,
        brand: post.brand
      }
    });
  }

  onDropPost(event: CdkDragDrop<string[]>) {

    const post: PostType = event.item.data;
    const drag: any = event.previousContainer.data;
    const drop: any = event.container.data;
    if (drag.date !== drop.date) {

      // remove/add element from/to days array
      for (let i = 0; i < this.days.length; i++) {
        if (this.days[i].date.getTime() === drag.date.getTime()) {
          for (let j = 0; j < this.days[i].posts.length; j++) {
            if (this.days[i].posts[j].uid === post.uid) {
              // remove post
              this.days[i].posts.splice(j, 1);
            }
          }
        }
        if (this.days[i].date.getTime() === drop.date.getTime()) {
          for (let j = 0; j < this.days[i].posts.length; j++) {
            if (this.days[i].posts[j].uid === post.uid) {
              // add post
              this.days[i].posts.push(post);
            }
          }
        }
      }

      // update in db
      const fields = {};

      const changeMessage = 'has changed date: ' + drag.date.getFullYear() + '-'
        + ('0' + (drag.date.getMonth() + 1)).slice(-2) + '-' + ('0' + drag.date.getDate()).slice(-2) + ' → '
        + drop.date.getFullYear() + '-' + ('0' + (drop.date.getMonth() + 1)).slice(-2) + '-'
        + ('0' + drop.date.getDate()).slice(-2);
      const notification = '<p>has changed <a class="link" routerLink="brands/'
        + post.brandRef.id + '">' + this.brand.name + '</a> <a class="link" routerLink="brands/'
        + post.brandRef.id + '/post/' + post.uid + '">post</a><code class="code">date: '
        + drag.date.getFullYear() + '-'
        + ('0' + (drag.date.getMonth() + 1)).slice(-2) + '-' + ('0' + drag.date.getDate()).slice(-2) + ' → '
        + drop.date.getFullYear() + '-' + ('0' + (drop.date.getMonth() + 1)).slice(-2) + '-'
        + ('0' + drop.date.getDate()).slice(-2) + '</code></p>';
      fields['date'] = drop.date;

      // add to update message field
      post.messages.push({
        uid: this.afs.createId(),
        message: changeMessage,
        dateCreate: new Date,
        userRef: this.afs.doc(`users/${this.user.uid}`).ref,
        files: [],
        type: 'auto'
      });
      fields['messages'] = post.messages;

      // update post
      this.postsService.updatePost(fields, this.user, post.uid)
        .then(() => {
          this.progress.ref().complete();
          this.snackBar.open('Post has been updated', '', environment.snackbarSuccess);
          // inform follower users
          if (this.brand.followerRefs) {
            for (const informUser of Object.keys(this.brand.followerRefs)) {
              if (post.status !== 'hide') { // if post does not have status hide
                if (informUser !== this.user.uid) {
                  this.saveNotification(
                    notification,
                    informUser,
                    post.uid
                  );
                }
              } else { // if post does have status hide
                // get user to check if user can see hidden post
                this.usersService.onceUserActive(this.afs.doc(`users/${informUser}`).ref).then(infU => {
                  if ((infU.data().uid !== this.user.uid) && ((infU.data()._privileges.canSeeHiddenPost) || (infU.data()._rolesName === 'admin'))) {
                    this.saveNotification(
                      notification,
                      informUser,
                      post.uid
                    );
                  }
                });
              }
            }
          }
        })
        .catch(err => {
          this.progress.ref().complete();
          this.snackBar.open(err.message, '', environment.snackbarWarn);
        });
    }
  }

  private getBrand() {
    // get brand once
    this.brandsService.getBrandOnce(this.user, this.brandsComponent.uid).then(brand => {
      this.brand = brand.data();
      this.brandsComponent.title = this.brand.name;

      // get posts
      this.getPosts();
    });
  }

  private saveNotification(message: string, userId: string, postUid: string) {
    const notification: NotificationType = {
      uid: this.afs.createId(),
      message: message,
      dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
      dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
      userAuthorRef: this.afs.doc(`users/${this.user.uid}`).ref,
      userInformedRef: this.afs.doc(`users/${userId}`).ref,
      elementUid: postUid,
      elementType: 'brandPost',
      view: 'info',
      isUnread: true
    };
    const subscriptionNotification = {};

    subscriptionNotification[userId] = this.notificationsService
      .getNotificationsToMerge(`companies/${this.user.companyRef.id}/notifications/`, notification)
      .subscribe(notifications => {
        if (notifications.length) {
          this.notificationsService.updateNotification({
            message: notifications[0].message + message,
            dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            userInformedRef: this.afs.doc(`users/${userId}`).ref,
          }, `companies/${this.user.companyRef.id}/notifications/${notifications[0].uid}`).then();
        } else {
          this.notificationsService
            .insertNotification(notification, `companies/${this.user.companyRef.id}/notifications/${notification.uid}`)
            .then(() => {
            })
            .catch(err => {
              this.snackBar.open(err.message, '', environment.snackbarWarn);
            });
        }
        subscriptionNotification[userId].unsubscribe();
      });
  }

  private getPosts() {
    const month = ('0' + (this.month)).slice(-2);

    if (this.postsSubscription) {
      this.postsSubscription.unsubscribe();
    }
    this.postsSubscription = this.postsService.getPostsByBrandMonth(this.user, this.year + '' + month, this.afs.doc(`companies/${this.user.companyRef.id}/brands/${this.brandsComponent.uid}`).ref).subscribe(posts => {
      this.resetCount();
      this.posts = posts;
      this.posts.forEach(post => {
        // count
        this.count[post.status]++;
        this.count[post.platform]++;
        // brand
        post.brand = this.brand;
      });
      this.postsComplete = true;
      this.setCalendar();
    });
  }

  private resetCount() {
    // reset count status
    for (const property of Object.entries(this.count)) {
      this.count[property[0]] = 0;
    }
    // reset count platforms
    for (const property of Object.values(this.platforms)) {
      this.count[property.value] = 0;
    }
  }

  private getUsers() {
    this.usersService.onceUsers(this.user.companyRef).then(users => {
      users.forEach(userSnapshot => {
        const user: UserType = {
          uid: userSnapshot.data().uid,
          email: userSnapshot.data().email,
          name: userSnapshot.data().name,
          companyRef: userSnapshot.data().companyRef,
          isEmailVerified: userSnapshot.data().isEmailVerified,
          dateUpdate: userSnapshot.data().dateUpdate,
          userUpdateRef: userSnapshot.data().userUpdateRef,
          isActive: userSnapshot.data().isActive,
          isEmailNotification: userSnapshot.data().isEmailNotification,
          roleRef: userSnapshot.data().roleRef,
          _rolesName: userSnapshot.data()._rolesName,
          photoURL: userSnapshot.data().photoURL,
          changelog: {
            page: '',
            bottomSheet: '',
          }
        };
        this.users.push(user);
      });
      this.usersComplete = true;
      this.setCalendar();
    });

    // this.subscriptions.push(this.usersService.getUsers(this.user.companyRef).subscribe(users => {
    //   this.users = users;
    //   this.usersComplete = true;
    //   this.setCalendar();
    // }));
  }

  private setCalendar() {
    if (this.postsComplete && this.usersComplete) {
      this.days = [];
      const names = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const daysInMonth = new Date(this.year, this.month, 0).getDate();
      let posts = [];

      for (let i = 0; i < daysInMonth; i++) {
        posts = [];

        // find post in day
        this.posts.forEach(post => {
          if ((post.date.seconds * 1000) === new Date(this.year, (this.month - 1), (i + 1)).getTime()) {
            post.datepicker = new Date(post.date.seconds * 1000);

            // attributes to calendar view
            post.imagesVideosCount = 0;
            post.imagesVideosIndex = 0;
            if (post.platform) {
              this.platforms.forEach(platform => {
                if (post.platform === platform.value) {
                  post.platformView = platform.viewValue;
                  if (post.type) {
                    platform.types.forEach(type => {
                      if (post.type === type.value) {
                        post.typeView = type.viewValue;
                      }
                    });
                  }
                }
              });
            }
            post.files.forEach(file => {
              if (file.isActive && ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'video/mp4'].includes(file.type)) {
                post.imagesVideosCount++;
              }
            });

            posts.push(post);
          }
        });

        // sort according to date create
        posts.sort((a, b) => {
          if ((a.dateCreate) && (b.dateCreate)) {
            return a.dateCreate.seconds - b.dateCreate.seconds;
          }
          return 0;
        });

        // add posts to array
        this.days.push({
          date: new Date(this.year, (this.month - 1), (i + 1)),
          day: names[new Date(this.year, (this.month - 1), (i + 1)).getDay()],
          dayIndex: new Date(this.year, (this.month - 1), (i + 1)).getDay(),
          posts: posts
        });

      }

      if (this.days.length) {
        if (this.days[0].dayIndex === 0) { // Sunday
          this.firstDayMonthIndex = 6;
        } else {
          this.firstDayMonthIndex = this.days[0].dayIndex - 1;
        }
        this.lastDayMonthComplement = 7 - ((this.firstDayMonthIndex + this.days.length) % 7);
        if (this.lastDayMonthComplement === 7) {
          this.lastDayMonthComplement = 0;
        }
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.postsSubscription) {
      this.postsSubscription.unsubscribe();
    }
  }

}
