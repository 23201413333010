<section class="page-section">
    <form (ngSubmit)="onSave(f)" #f="ngForm" autocomplete="off" *ngIf="chat">

        <div>
            <mat-form-field>
                <input matInput
                       type="text"
                       name="name"
                       #name="ngModel"
                       [(ngModel)]="chat.name"
                       required
                       placeholder="Name">
                <mat-error *ngIf="name.hasError('required')">
                    Name is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field>
                <input matInput
                       type="text"
                       name="shortDesc"
                       #shortDesc="ngModel"
                       [(ngModel)]="chat.shortDesc"
                       placeholder="Short description">
            </mat-form-field>
        </div>

        <button type="submit"
                mat-raised-button color="primary"
                [disabled]="f.form.invalid">Save
        </button>
        <a (click)="showRemove = !showRemove" class="link link--btn" *ngIf="!showRemove">Delete</a>
        <span *ngIf="showRemove" class="link--btn">Are you sure? <a (click)="showRemove = false" class="link">No</a>&nbsp;<a
                (click)="onRemove()" class="link">Yes</a></span>
    </form>
</section>