import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {UsersService} from '../../_services/users.service';
import {NgForm} from '@angular/forms';


@Component({
    selector: 'app-email',
    templateUrl: './email.component.html',
    styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit, OnDestroy {

    alert = {
        message: '',
        class: ''
    };


    mode: string;
    actionCode: string;

    restoredEmail: string;
    newEmail: string;

    resetPasswordBtnShow: boolean;

    private subscriptions: Array<Subscription> = [];

    constructor(private activatedRoute: ActivatedRoute,
                private afAuth: AngularFireAuth,
                private usersService: UsersService,
                private router: Router) {
    }

    ngOnInit() {
        this.subscriptions.push(this.activatedRoute.queryParams.subscribe((params: Params) => {

            this.actionCode = params['oobCode'];
            this.mode = params['mode'];

            switch (this.mode) {

                case 'verifyEmail': {
                    // @ts-ignore
                    this.afAuth.auth.applyActionCode(this.actionCode)
                        .then(() => {
                            this.alert.message = 'E-mail has been verified. You can log in.';
                            this.alert.class = 'success';
                        })
                        .catch((error) => {
                            this.alert.message = error.message;
                            this.alert.class = 'warn';

                        });
                    break;
                }

                // case 'resetPassword': {
                //     break;
                // }

                case 'recoverEmail': {
                    // @ts-ignore
                    this.afAuth.auth.checkActionCode(this.actionCode)
                        .then(info => {
                            this.restoredEmail = info['data']['email'];
                            this.newEmail = info['data']['fromEmail'];
                            // @ts-ignore
                            return this.afAuth.auth.applyActionCode(this.actionCode);
                        })
                        .then(() => {
                            // set recover email in db
                            this.usersService.getUserByEmail(this.newEmail).subscribe(users => {
                                const user = users[0];
                                if (user) {
                                    user.email = this.restoredEmail;
                                    this.resetPasswordBtnShow = true;
                                    this.usersService.setUser(user)
                                        .then(() => {
                                            this.alert.message = 'E-mail has been recovered.';
                                            this.alert.class = 'success';
                                        })
                                        .catch(error => {
                                            this.alert.message = error.message;
                                            this.alert.class = 'warn';
                                        });
                                }
                            });
                        })
                        .catch((error) => {
                            this.alert.message = error.message;
                            this.alert.class = 'warn';
                            this.resetPasswordBtnShow = false;
                        });
                    break;
                }

                case 'gotoLogin': {
                    this.alert.message = 'Password has been changed.';
                    this.alert.class = 'success';
                    break;
                }
            }
        }));
    }

    onSave(form: NgForm) {
        if (form.valid) {
            // @ts-ignore
            this.afAuth.auth.confirmPasswordReset(this.actionCode, form.value.passwordNew).then(resp => {
                this.router.navigate(['/email'], {queryParams: {mode: 'gotoLogin'}});
            }).catch(error => {
                this.alert.message = error.message;
                this.alert.class = 'warn';
            });
        }
    }

    onSendResetEmail() {
        this.usersService.sendPasswordResetEmail(this.restoredEmail)
            .then(() => {
                this.alert.message = 'E-mail with instruction has been sent.';
                this.alert.class = 'success';
            })
            .catch(error => {
                this.alert.message = error.message;
                this.alert.class = 'warn';
            });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
