<mat-card class="mat-card--dashboard">
    <mat-card-header>
        <mat-card-title>Last chats messages</mat-card-title>
        <mat-slide-toggle
                [checked]="hiddenChatsVisibility"
                (change)="hiddenChatsVisibility = !hiddenChatsVisibility;">
            Show hidden chats
        </mat-slide-toggle>
    </mat-card-header>
    <mat-card-content>
        <mat-list>
            <ng-container *ngFor="let chat of chats">
                <mat-list-item class="mat-list-item--chat" *ngIf="!hiddenChats[chat.uid] || hiddenChatsVisibility" [ngClass]="{'hidden': hiddenChats[chat.uid]}">

                    <h3 matLine class="mat-line--chat"><a routerLink="/chats/{{ chat.uid }}">{{ chat.name }}</a></h3>
                    <mat-list matLine *ngIf="chat.lastMessages">

                        <ng-container *ngFor="let message of chat.lastMessages">
                            <app-shared-message *ngIf="message" [message]="message"></app-shared-message>
                        </ng-container>

                    </mat-list>

                    <button matTooltip="Hide chat" matTooltipPosition="above" mat-icon-button (click)="hideChat(chat.uid)" *ngIf="!hiddenChats[chat.uid]">
                        <i class="material-icons">visibility_off</i>
                    </button>
                    <button matTooltip="Show chat" matTooltipPosition="above" mat-icon-button (click)="showChat(chat.uid)" *ngIf="hiddenChats[chat.uid]">
                        <i class="material-icons">visibility</i>
                    </button>
                </mat-list-item>
            </ng-container>
        </mat-list>
    </mat-card-content>
</mat-card>
