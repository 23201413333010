<!--<mat-sidenav-container class="sidenav-container">-->
<!--    <app-aside></app-aside>-->
<!--    <mat-sidenav-content><router-outlet></router-outlet></mat-sidenav-content>-->
<!--</mat-sidenav-container>-->

<mat-sidenav-container class="sidenav-container">
    <mat-sidenav mode="side" opened="true" [disableClose]="true">
        <app-aside-nav></app-aside-nav>
    </mat-sidenav>

    <mat-sidenav mode="side" opened="true" position="end" [disableClose]="true">
        <app-aside-sidebar></app-aside-sidebar>
    </mat-sidenav>

    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
