<section class="page-section">
    <div class="row">
        <div class="col">
            <mat-list class="mat-list-messages mat-list-messages--received" id="messages--received" *ngIf="user">

                <ng-container *ngFor="let message of messages">
                    <app-shared-message *ngIf="message" [message]="message"></app-shared-message>
                </ng-container>

            </mat-list>

            <mat-list class="mat-list-messages mat-list-messages--write" *ngIf="user">
                <mat-list-item *ngIf="user">
                    <div matListAvatar
                         *ngIf="user.photoURL"
                         [ngStyle]="{'background-image': 'url(' + user.photoURL + ')'}"></div>
                    <div matListAvatar
                         *ngIf="!user.photoURL">
                        {{ user.name | avatar }}
                    </div>
                    <div matLine>
                        <div class="mat-line--name">{{user.name}}</div>
                        <div>
                            <form (ngSubmit)="onSend(f)" #f="ngForm">
                                <quill-editor
                                        name="messageText"
                                        #message="ngModel"
                                        ngModel
                                        placeholder="Write a message..."
                                        required>
                                    <div quill-editor-toolbar>
                                            <span class="ql-formats">
                                                <button class="ql-bold" [title]="'Bold'"></button>
                                                <button class="ql-italic" [title]="'Italic'"></button>
                                                <button class="ql-underline" [title]="'Underline'"></button>
                                                <button class="ql-strike" [title]="'Strike'"></button>
                                            </span>
                                        <span class="ql-formats">
                                                <button class="ql-list" value="ordered"
                                                        [title]="'List ordered'"></button>
                                                <button class="ql-list" value="bullet" [title]="'List bullet'"></button>
                                            </span>
                                        <span class="ql-formats">
                                                <button class="ql-blockquote" [title]="'Blockquote'"></button>
                                                <button class="ql-code-block" [title]="'Code-block'"></button>
                                            <button class="ql-clean" [title]="'Clean format'"></button>
                                            </span>
                                    </div>
                                </quill-editor>


                                <div class="drop-container"
                                     ngFileDrop
                                     [options]="options"
                                     (uploadOutput)="onUploadOutput($event)"
                                     [uploadInput]="uploadInput"
                                     [ngClass]="{ 'is-drop-over': dragOver }">
                                    <input type="file"
                                           ngFileSelect
                                           [options]="options"
                                           (uploadOutput)="onUploadOutput($event)"
                                           [uploadInput]="uploadInput"
                                           multiple>
                                    <span>Select or drop file(s) here</span>
                                </div>

                                <ul *ngIf="messageFiles" class="list-files">
                                    <li *ngFor="let file of messageFiles; let i = index">
                                        <a href="{{ file.url }}" class="link" target="_blank">{{file.name}}</a>
                                        <small>{{ file.size | fileSize }} ({{ file.type }})</small>
                                        <i class="material-icons" (click)="onDeleteFile(i)">clear</i>
                                    </li>
                                </ul>

                                <button type="submit"
                                        class="submit"
                                        mat-raised-button color="primary"
                                        [disabled]="f.form.invalid">Send
                                </button>
                            </form>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>

        </div>
    </div>
</section>
