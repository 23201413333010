import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {UserType} from '../_types/user.type';
import {InvoiceType} from '../_types/invoice.type';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  constructor(private afs: AngularFirestore) {
  }

  // get invoices
  getInvoices(user: UserType): Observable<InvoiceType[]> {
    return this.afs.collection<InvoiceType>(`companies/${user.companyRef.id}/invoices`, ref => ref
      .where('isActive', '==', true)
      .orderBy('deadline', 'desc'))
      .valueChanges();
  }
}
