<mat-card class="mat-card--user" *ngFor="let user of users$ | async">
    <mat-card-header>
        <div mat-card-avatar
             *ngIf="user.photoURL"
             [ngStyle]="{'background-image': 'url(' + user.photoURL + ')'}"></div>
        <img mat-card-avatar
             *ngIf="!user.photoURL"
             class="profile__icon"
             src="../../assets/default_profile_400x400.png">
        <mat-card-title>{{ user.name }}</mat-card-title>
        <mat-card-subtitle *ngIf="user.role | async as role;">{{ role.name }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
        <mat-slide-toggle [checked]="user.chatAccess" (click)="onChangeAccess(user.uid)">Access change
        </mat-slide-toggle>
    </mat-card-actions>
</mat-card>

