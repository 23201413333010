import {Component, OnDestroy, OnInit} from '@angular/core';
import {UsersComponent} from '../users.component';
import {UserType} from '../../../_types/user.type';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {UsersExternalService} from '../../../_services/users-external.service';
import {UsersService} from '../../../_services/users.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {

  user: UserType | undefined;
  users: UserType[] = [];

  searchUser: string | undefined;

  private subscriptions: Array<Subscription> = [];

  constructor(private usersExternalService: UsersExternalService,
              private usersService: UsersService,
              private usersComponent: UsersComponent,
              private route: ActivatedRoute) {

    // set nav
    this.usersComponent.setNavExternal();
  }

  ngOnInit(): void {
    // set title
    this.usersComponent.title = 'Users';

    // set user
    // @ts-ignore
    this.user = this.route.snapshot.parent.parent.data.user;

    // get users
    if (this.user?._rolesName === 'admin' || this.user?._privileges.canGetUser) {
      this.getUsers();
    } else {
      if (this.user) {
        this.users.push(this.user);
      }
    }
  }

  private getUsers(): void{
    this.subscriptions.push(this.usersService.getUsersActive(this.user?.companyRef).subscribe(users => {
      this.users = users;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
