import {Component, OnInit} from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {Router} from '@angular/router';

@Component({
    selector: 'app-shared-bottomsheet-version',
    templateUrl: './shared-bottomsheet-version.component.html',
    styleUrls: ['./shared-bottomsheet-version.component.scss']
})
export class SharedBottomsheetVersionComponent implements OnInit {

    constructor(private _bottomSheetRef: MatBottomSheetRef<SharedBottomsheetVersionComponent>,
                private router: Router) {
    }

    ngOnInit() {
    }

    openLink() {
        // this._bottomSheetRef.dismiss();
        // this.router.navigate(['/changelog']).then();
        window.location.replace('/changelog');
    }

    onRefresh() {
        location.reload();
    }
}
