import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {CompaniesService} from './companies.service';
import {UserType} from '../_types/user.type';
import {RolesService} from './roles.service';
import {AngularFireAuth} from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import {of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {PrivilegesService} from './privileges.service';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private companiesService: CompaniesService,
              private rolesService: RolesService,
              private privilegesService: PrivilegesService,
              private afs: AngularFirestore,
              private afAuth: AngularFireAuth) {
  }

  // get user
  getUser(): any {
    return this.afAuth.authState.pipe(switchMap(user => {
      if (user) {
        return this.afs.doc<UserType>(`users/${user.uid}`)
          .valueChanges();

        // return this.afs.doc<UserType>(`users/${user.uid}`).valueChanges().pipe(map(user2 => {
        //     return this.rolesService.getRole(user2, user2.roleRef.id).pipe(map(role => {
        //         user2.role = role;
        //         return user2;
        //     }));
        // }));

        // return this.afs.doc<UserType>(`users/${user.uid}`).valueChanges().pipe(map(user2 => {
        //     user2.role$ = this.rolesService.getRole(user2, user2.roleRef.id);
        //     return user2;
        // }));
      } else {
        return of();
      }
    }));
  }

  // once user active
  onceUserActive(userRef: any): any {
    const db = firebase.firestore();
    return db.doc(userRef.path).get();
  }

  // get user by ref
  getUserByRef(docRef: any): Observable<UserType | undefined> {
    return this.afs.doc<UserType>(docRef.path)
      .valueChanges();
  }

  // get users
  getUsers(companyRef: any): Observable<UserType[]> {
    return this.afs.collection<UserType>(`users`, ref => ref
      .where('companyRef', '==', companyRef)
      .orderBy('name'))
      .valueChanges();
  }

  // once users
  onceUsers(companyRef: any): any {
    const db = firebase.firestore();
    return db.collection('users')
      .where(`companyRef`, '==', companyRef)
      .orderBy('name')
      .get();
  }

  // get users active
  getUsersActive(companyRef: any): Observable<UserType[]> {
    return this.afs.collection<UserType>(`users`, ref => ref
      .where('companyRef', '==', companyRef)
      .where('isActive', '==', true)
      .orderBy('name'))
      .valueChanges();
  }

  // once users active
  onceUsersActive(companyRef: any): any {
    const db = firebase.firestore();
    return db.collection('users')
      .where(`companyRef`, '==', companyRef)
      .where(`isActive`, '==', true)
      .orderBy('name')
      .get();
  }

  // get filtered users
  getFilteredUsers(user: UserType, filter: { name: string, val: any }): Observable<UserType[]> {
    return this.afs.collection<UserType>(`users`, ref => ref
      .where('companyRef', '==', user.companyRef)
      .where(filter.name, '==', filter.val))
      .valueChanges();
  }

  // get user by email
  getUserByEmail(email: string): Observable<UserType[]> {
    return this.afs.collection<UserType>(`users`, ref => ref
      .where('email', '==', email))
      .valueChanges();
  }

  // set user
  setUser(user: UserType): Promise<void> {
    return this.afs.doc<UserType>(`users/${user.uid}`).set(user);
  }

  // update user
  updateUser(fields: {}, userUid: string): Promise<void> {
    return this.afs.doc<UserType>(`users/${userUid}`).update(fields);
  }

  // update password
  async updatePassword(newPassword: string): Promise<void> {
    const user = await this.afAuth.currentUser;
    // @ts-ignore
    return user.updatePassword(newPassword);
  }

  // update email
  async updateEmail(newEmail: string): Promise<void> {
    const user = await this.afAuth.currentUser;
    // @ts-ignore
    return user.updateEmail(newEmail);
  }

  // send password reset email
  async sendPasswordResetEmail(emailAddress: string): Promise<void> {
    const auth = (await this.afAuth.app).auth();
    return auth.sendPasswordResetEmail(emailAddress);
  }

  // upload picture
  uploadPicture = (file: any, path: string) => {
    const storageRef = firebase.storage().ref();
    return storageRef.child(path).put(file);
  }
}
