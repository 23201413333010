import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClientsService} from '../../../_services/clients.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {UserType} from '../../../_types/user.type';
import {ClientType} from '../../../_types/client.type';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import * as firebase from 'firebase/app';
import {AngularFirestore} from '@angular/fire/firestore';
import {RoleType} from '../../../_types/role.type';
import {MessageType} from '../../../_types/message.type';
import {MessagesService} from '../../../_services/messages.service';
import {BrandType} from '../../../_types/brand.type';
import {BrandsComponent} from '../brands.component';
import {BrandsService} from '../../../_services/brands.service';
import {NgForm} from '@angular/forms';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-brand-details',
  templateUrl: './brand-details.component.html',
  styleUrls: ['./brand-details.component.scss']
})
export class BrandDetailsComponent implements OnInit, OnDestroy {

  clients: ClientType[] = [];
  roles: RoleType[];
  user: UserType;
  brand: BrandType;
  brandClone: BrandType;
  client: any;
  clientClone: any;
  showRemove = false;

  selectedFiles: FileList;

  private subscriptions: Array<Subscription> = [];

  constructor(private clientsService: ClientsService,
              private messagesService: MessagesService,
              private brandsComponent: BrandsComponent,
              private brandsService: BrandsService,
              private route: ActivatedRoute,
              private router: Router,
              private afs: AngularFirestore,
              private progress: NgProgress,
              private snackBar: MatSnackBar) {

    // set params
    this.subscriptions.push(this.route.params.subscribe(params => {
      this.brandsComponent.setNav(params.uid);

      // set user
      this.user = this.route.snapshot.parent.parent.data.user;

      // get clients
      if (this.user._privileges.canGetClient || this.user._rolesName === 'admin') {
        this.getClients();
      }

      // get brand
      this.getBrand();
    }));

  }

  private getClients() {
    this.subscriptions.push(this.clientsService.getClients(this.user).subscribe(clients => {
      this.clients = clients;
      this.matchClientsToBrand();
    }));
  }

  private getBrand() {
    this.subscriptions.push(this.brandsService.getBrand(this.user, this.brandsComponent.uid).subscribe(brand => {
      this.brand = brand;
      this.brandClone = Object.assign({}, this.brand);
      this.brandsComponent.title = this.brand.name;
      this.matchClientsToBrand();
    }));
  }

  chooseFile(event) {
    this.selectedFiles = event.target.files;
  }

  onDeletePhoto() {
    this.user.photoURL = '';
  }

  private matchClientsToBrand() {
    if (this.clients.length && this.brand) {
      for (const client of this.clients) {
        if (client.uid === this.brand.clientRef.id) {
          this.client = client;
          this.clientClone = this.client;
        }
      }
    }
  }

  private dateFormat(date: Date) {
    if (date) {
      const mm = date.getMonth() + 1;
      const dd = date.getDate();

      return [date.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd
      ].join('-');
    } else {
      return '';
    }
  }

  ngOnInit() {
  }

  autocompleteDisplay(client?: ClientType): string | undefined {
    return client ? client.name : undefined;
  }

  autocompleteBlur() {
    if (typeof this.client === 'string') {
      this.clients.forEach(client => {
        if (client.name === this.client) {
          this.client = client;
        }
      });
    }
  }

  autocompleteSelected(selected) {
    if (typeof this.client === 'string') {
      this.clients.forEach(client => {
        if (client === selected) {
          this.client = client;
        }
      });
    }
  }

  onSave(form: NgForm) {
    if (form.valid) {
      this.progress.ref().start();
      this.user.name = form.value.name;
      this.user.isEmailNotification = form.value.isEmailNotification;
      if (this.selectedFiles) {
        // upload file
        const file = this.selectedFiles[0];
        const fileNameArray = file.name.split('.');
        const name = this.afs.createId() + '.' + fileNameArray[fileNameArray.length - 1];
        const path = `/${this.user.companyRef.id}/brands/${name}`;
        this.brandsService.uploadImage(file, path).then(snapshot => {
          // update brand
          snapshot.ref.getDownloadURL().then(downloadURL => {
            this.brand.imageURL = downloadURL;
            this.onUpdate();

          });
        });
        this.selectedFiles = null;
      } else {
        // update brand
        this.onUpdate();
      }
    }
  }

  onDeleteImage() {
    this.brand.imageURL = '';
  }

  onUpdate() {
    // check differences
    let message = '';
    if (this.brandClone.name !== this.brand.name) {
      message += `has changed name: ${this.brandClone.name} → ${this.brand.name}`;
    }
    if (this.clientClone.uid !== this.client.uid) {
      if (message) {
        message += '<br>';
      }
      message += `has changed client: ${this.clientClone.name} → ${this.client.name}`;
    }
    if (this.brandClone.shortDesc !== this.brand.shortDesc) {
      if (message) {
        message += '<br>';
      }
      message += `has changed short description: ${this.brandClone.shortDesc} → ${this.brand.shortDesc}`;
    }
    if (this.brandClone.notes !== this.brand.notes) {
      if (message) {
        message += '<br>';
      }
      message += `has changed notes: ${this.brandClone.notes} → ${this.brand.notes}`;
    }

    // update brand
    this.brandsService.updateBrand({
      name: this.brand.name,
      shortDesc: this.brand.shortDesc,
      clientRef: this.afs.doc(`companies/${this.user.companyRef.id}/clients/${this.client.uid}`).ref,
      notes: this.brand.notes,
      imageURL: this.brand.imageURL
    }, this.user, this.brand.uid)
      .then(() => {
        // insert changes
        const change: MessageType = {
          uid: this.afs.createId(),
          message: message,
          dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
          userRef: this.afs.doc(`users/${this.user.uid}`).ref,
          files: [],
          type: 'auto'
        };
        this.messagesService.insertMessage(change, `companies/${this.user.companyRef.id}/brands/${this.brand.uid}/changes/${change.uid}`)
          .then(() => {
            this.snackBar.open('Social brand has been updated', '', environment.snackbarSuccess);
            this.progress.ref().complete();
            // update clones
            this.brandClone = Object.assign({}, this.brand);
            this.clientClone = Object.assign({}, this.client);
          })
          .catch(err => {
            this.snackBar.open(err.message, '', environment.snackbarWarn);
          });
      }).catch(err => {
      this.snackBar.open(err.message, '', environment.snackbarWarn);
      this.progress.ref().complete();
    });
  }

  onRemove() {
    this.progress.ref().start();
    this.brandsService.updateBrand({
      isActive: false
    }, this.user, this.brand.uid).then(() => {
      // insert changes
      const change: MessageType = {
        uid: this.afs.createId(),
        message: 'has removed brand',
        dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
        userRef: this.afs.doc(`users/${this.user.uid}`).ref,
        files: [],
        type: 'auto'
      };
      this.messagesService.insertMessage(change, `companies/${this.user.companyRef.id}/brands/${this.brand.uid}/changes/${change.uid}`)
        .then(() => {
          this.progress.ref().complete();
          this.snackBar.open('Social brand has been removed', '', environment.snackbarSuccess);
        })
        .catch(err => {
          this.snackBar.open(err.message, '', environment.snackbarWarn);
        });


    })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });

  }

  onUndoRemove() {
    this.progress.ref().start();
    this.brandsService.updateBrand({
      isActive: true
    }, this.user, this.brand.uid).then(() => {
      // insert changes
      const change: MessageType = {
        uid: this.afs.createId(),
        message: 'has withdrew the removal of the social brand',
        dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
        userRef: this.afs.doc(`users/${this.user.uid}`).ref,
        files: [],
        type: 'auto'
      };
      this.messagesService.insertMessage(change, `companies/${this.user.companyRef.id}/brands/${this.brand.uid}/changes/${change.uid}`)
        .then(() => {
          this.progress.ref().complete();
          this.snackBar.open('Withdrew the removal of the social brand', '', environment.snackbarSuccess);
        })
        .catch(err => {
          this.snackBar.open(err.message, '', environment.snackbarWarn);
        });
    })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });

  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
