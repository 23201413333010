<section class="aside-subnav" [ngClass]="{'aside-subnav--full-page': showFullPage}">
  <ng-container *ngIf="roles$ | async as roles; else loading">
    <ng-container *ngIf="roles.length">
      <div class="search">
        <mat-form-field>
          <input matInput placeholder="Search" type="search" [(ngModel)]="searchRole">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>

      <mat-nav-list [ngClass]="{'mat-nav-list--button': user._rolesName === 'admin' || user._privileges.canCreateRole}">
        <div *ngFor="let role of roles | filter:searchRole:['name']">
          <!-- role is NOT admin -->
          <a mat-list-item
             *ngIf="role.name !== 'admin'"
             routerLinkActive="active"
             routerLink="/roles/{{ role.uid }}">
            {{ role.name }}
          </a>
          <!-- /role is NOT admin -->

          <!-- role is admin -->
          <mat-list-item class="disabled" *ngIf="role.name === 'admin'" disableRipple>
            {{ role.name }}
          </mat-list-item>
          <!-- /role is admin -->

        </div>
      </mat-nav-list>

    </ng-container>
  </ng-container>

  <ng-template #loading>
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </ng-template>

  <div class="container-btm"
       *ngIf="user._rolesName === 'admin' || user._privileges.canCreateRole">
    <a routerLink="/roles/role-add" mat-stroked-button color="primary">Add role</a>
  </div>
</section>

<main class="help-margin-left-180px">
  <div class="container">
    <div class="row">
      <div class="col">
        <header class="page-header">
          <h1 [innerHTML]="title" *ngIf="title"></h1>
          <nav mat-tab-nav-bar>
            <a mat-tab-link
               *ngFor="let link of navLinks"
               [routerLink]="link.path"
               routerLinkActive #rla="routerLinkActive"
               [active]="rla.isActive">
              {{link.label}}
            </a>
          </nav>
        </header>

        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</main>
