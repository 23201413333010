import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserType} from './_types/user.type';
import {ProjectType} from './_types/project.type';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'app';

  user: UserType | undefined;
  users: UserType[] | undefined;
  projects: ProjectType[] | undefined;

  constructor(private translate: TranslateService) {

    // set translation
    translate.setDefaultLang('en');
    translate.use('en');

    // get user
    // this.usersService.user$.subscribe(user => {
    //     this.user = user;
    //
    //     //get projects
    //     this.projectsService.getProjects(user).subscribe(projects => {
    //         this.projects = projects;
    //     })
    //
    //     //get users
    //
    // })

    // update current URL
    // this.subscriptions.push(this.usersService.user$.subscribe(user => {
    //     if (user) {
    //         router.events.forEach((event) => {
    //             if(event instanceof NavigationStart) {
    //                 this.usersService.updateUrl(event.url, `users/${user.uid}`)
    //             }
    //
    //             // NavigationEnd
    //             // NavigationCancel
    //             // NavigationError
    //             // RoutesRecognized
    //         });
    //
    //     }
    // }))
  }


}
