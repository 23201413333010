<main>
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="logo"><img src="../../../assets/soteam.png" alt="Soteam"></h1>

                <mat-card class="card-register" *ngIf="show == 'register'">
                    <mat-card-header>
                        <mat-card-title>Sign Up</mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                        <form name="form" (ngSubmit)="onRegister(f)" #f="ngForm">

                            <mat-form-field class="help-width-100p">
                                <input matInput
                                       type="email"
                                       name="email"
                                       #email="ngModel"
                                       email
                                       ngModel
                                       autocomplete="username email"
                                       required
                                       placeholder="Your company e-mail">
                                <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
                                    Please enter a valid company e-mail address
                                </mat-error>
                                <mat-error *ngIf="email.hasError('required')">
                                    Company e-mail is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="help-width-100p">
                                <input matInput
                                       type="password"
                                       name="password"
                                       #password="ngModel"
                                       ngModel
                                       required
                                       minlength="6"
                                       placeholder="Password"
                                       autocomplete="new-password"
                                       validateEqual="passwordConfirmation"
                                       reverse="true">
                                <mat-error *ngIf="password.invalid && (password.dirty || password.touched)">
                                    <span *ngIf="password.hasError('required')">Password is required. </span>
                                    <span *ngIf="password.hasError('minlength')">Password must be at least 6 characters long. </span>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="help-width-100p">
                                <input matInput
                                       type="password"
                                       name="passwordConfirmation"
                                       #passwordConfirmation="ngModel"
                                       ngModel
                                       required
                                       minlength="6"
                                       autocomplete="new-password"
                                       placeholder="Password confirmation"
                                       validateEqual="password">
                                <mat-error *ngIf="passwordConfirmation.value !== password.value">
                                    <span>Passwords do not match. </span>
                                </mat-error>
                            </mat-form-field>
                            <div class="help-margin-bottom-20px">
                                <mat-checkbox required
                                              name="rules"
                                              #rules="ngModel"
                                              ngModel><span>I accept <a href="assets/pdf/rules.pdf" target="_blank" class="link">Terms of Service</a> (<a href="assets/pdf/regulamin.pdf" target="_blank" class="link">PL version</a>) and <a href="assets/pdf/privacy.pdf" target="_blank" class="link">Privacy Policy</a> (<a href="assets/pdf/polityka.pdf" target="_blank" class="link">PL version</a>) including free trial period until {{ trialDateEnd | date: 'yyyy-MM-dd'}}.</span>
                                </mat-checkbox>
                            </div>


                            <button type="submit"
                                    mat-raised-button color="primary"
                                    [disabled]="f.form.invalid">Sign Up
                            </button>
                        </form>
                    </mat-card-content>
                </mat-card>

                <mat-card class="card-invitation" *ngIf="show == 'invitation'">
                    <mat-card-header>
                        <mat-card-title>Invitation</mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                        <form name="form" (ngSubmit)="onChoose(f)" #f="ngForm">

                            <p>We've recognized that you have invitation to join other company. What would you like to do?</p>

                            <mat-radio-group aria-label="Select an option"
                                             required
                                             ngModel
                                             name="invitation"
                                             #invitation="ngModel">
                                <mat-radio-button [value]="true"><strong class="accept">Accept invitation</strong> from {{ userExternal.invitingName }} ({{ userExternal.invitingEmail }})</mat-radio-button>
                                <mat-radio-button [value]="false"><strong class="reject">Reject invitation</strong> and continue</mat-radio-button>
                            </mat-radio-group>

                            <p class="warn"><strong>You won't be able to change this option.</strong></p>

                            <div class="help-text-align-center">
                                <button type="submit"
                                        mat-raised-button color="primary"
                                        [disabled]="f.form.invalid">Confirm
                                </button>
                            </div>

                        </form>
                    </mat-card-content>
                </mat-card>

                <mat-card class="card-complete" *ngIf="show == 'complete'">
                    <mat-card-header>
                        <mat-card-title>Registration is almost completed</mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                        <p>Please check the message with the verification link which has been sent to the e-mail you provided.</p>
                    </mat-card-content>
                </mat-card>

                <p>Have an account? <a routerLink="/login" class="btn-log-in" mat-stroked-button color="primary">Log In</a></p>
                <p><a href="https://so.team" class="link" target="_blank">About Soteam</a></p>
            </div>
        </div>
    </div>
</main>
