import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {UsersService} from '../../../_services/users.service';
import {UserType} from '../../../_types/user.type';
import {ModulesService} from '../../../_services/modules.service';
import {ModulesComponent} from '../modules.component';
import {ModuleType} from '../../../_types/module.type';
import {AccessToModulesService} from '../../../_services/access-to-modules.service';
import {AccessToModule} from '../../../_types/accessToModule.type';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-module-changes',
  templateUrl: './module-changes.component.html',
  styleUrls: ['./module-changes.component.scss']
})
export class ModuleChangesComponent implements OnInit, OnDestroy {

  user: UserType;
  users: UserType[];
  usersComplete = false;
  changes: AccessToModule[] = [];
  changesComplete = false;
  module: ModuleType;

  private subscriptions: Array<Subscription> = [];

  constructor(private modulesComponent: ModulesComponent,
              private accessToModulesService: AccessToModulesService,
              private route: ActivatedRoute,
              private afs: AngularFirestore,
              private usersService: UsersService,
              private modulesService: ModulesService) {
    // set params
    this.subscriptions.push(this.route.params.subscribe(params => {
      this.modulesComponent.setNav(params.uid);

      // set user
      this.user = this.route.snapshot.parent.parent.data.user;

      // get project changes
      this.getChanges();

      // get module
      this.getModule();

      // get users
      this.getUsers();

    }));
  }

  ngOnInit() {
  }

  private getModule() {
    this.subscriptions.push(this.modulesService.getModule(this.modulesComponent.uid).subscribe(module => {
      this.module = module;
      this.modulesComponent.title = this.module.name;
    }));
  }

  private getChanges() {
    this.subscriptions.push(this.accessToModulesService.getAccessesForModule(`companies/${this.user.companyRef.id}/accessToModules`, this.afs.doc(`modules/${this.modulesComponent.uid}/`).ref).subscribe(changes => {
      this.changes = changes;
      this.changesComplete = true;
      this.matchChangesToUsers();
    }));
  }

  private getUsers() {
    this.subscriptions.push(this.usersService.getUsers(this.user.companyRef).subscribe(users => {
      this.users = users;
      this.usersComplete = true;
      this.matchChangesToUsers();
    }));
  }

  private matchChangesToUsers() {
    if (this.changesComplete && this.usersComplete) {
      this.changes.forEach(change => {
        this.users.forEach(user => {
          if (change.userRef.id === user.uid) {
            change.author = user;
          }
        });
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
