import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CoreRoutingModule} from './core-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {QuillModule} from 'ngx-quill';

import {NgxUploaderModule} from 'ngx-uploader';
import {CoreComponent} from './core.component';
import {AsideComponent} from './aside/aside.component';
import {AsideNavComponent} from './aside/aside-nav/aside-nav.component';
import {AsideSidebarComponent} from './aside/aside-sidebar/aside-sidebar.component';
import {ChatsComponent} from './chats/chats.component';
import {ChatAddComponent} from './chats/chat-add/chat-add.component';
import {ChatDetailsComponent} from './chats/chat-details/chat-details.component';
import {ChatMessagesComponent} from './chats/chat-messages/chat-messages.component';
import {ChatTeamComponent} from './chats/chat-team/chat-team.component';
import {ClientsComponent} from './clients/clients.component';
import {ClientAddComponent} from './clients/client-add/client-add.component';
import {ClientDetailsComponent} from './clients/client-details/client-details.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {WidgetChatsComponent} from './dashboard/widget-chats/widget-chats.component';
import {WidgetProgressComponent} from './dashboard/widget-progress/widget-progress.component';
import {ProfileComponent} from './profile/profile.component';
import {ProfileEmailComponent} from './profile/profile-email/profile-email.component';
import {ProfilePasswordComponent} from './profile/profile-password/profile-password.component';
import {ProfileSettingsComponent} from './profile/profile-settings/profile-settings.component';
import {ProjectsComponent} from './projects/projects.component';
import {ProjectAddComponent} from './projects/project-add/project-add.component';
import {ProjectAssetsComponent} from './projects/project-assets/project-assets.component';
import {ProjectDetailsComponent} from './projects/project-details/project-details.component';
import {ProjectScheduleComponent} from './projects/project-schedule/project-schedule.component';
import {ProjectTeamComponent} from './projects/project-team/project-team.component';
import {ProjectWikiComponent} from './projects/project-wiki/project-wiki.component';
import {RolesComponent} from './roles/roles.component';
import {SettingsComponent} from './settings/settings.component';
import {RoleAddComponent} from './roles/role-add/role-add.component';
import {RoleDetailsComponent} from './roles/role-details/role-details.component';
import {RolePrivilegesComponent} from './roles/role-privileges/role-privileges.component';
import {SettingsDetailsComponent} from './settings/settings-details/settings-details.component';
import {SettingsDomainsComponent} from './settings/settings-domains/settings-domains.component';
import {BrandsComponent} from './brands/brands.component';
import {UsersComponent} from './users/users.component';
import {UserCalendarComponent} from './users/user-calendar/user-calendar.component';
import {UserChatComponent} from './users/user-chat/user-chat.component';
import {UserDetailsComponent} from './users/user-details/user-details.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {SharedTaskComponent} from '../_shared/shared-task/shared-task.component';
import {ProjectTaskComponent} from './projects/project-task/project-task.component';

import {registerLocaleData} from '@angular/common';
import localePL from '@angular/common/locales/pl';
import {SharedMessageComponent} from '../_shared/shared-message/shared-message.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {SharedTaskPageComponent} from '../_shared/shared-task-page/shared-task-page.component';
import {SharedTaskDialogComponent} from '../_shared/shared-task-dialog/shared-task-dialog.component';
import {ForbiddenTypeDirective} from '../_directives/forbidden-type.directive';
import {ProjectChangesComponent} from './projects/project-changes/project-changes.component';
import {ProjectStatsComponent} from './projects/project-stats/project-stats.component';
import {BrandAddComponent} from './brands/brand-add/brand-add.component';
import {BrandChangesComponent} from './brands/brand-changes/brand-changes.component';
import {BrandDetailsComponent} from './brands/brand-details/brand-details.component';
import {BrandCalendarComponent} from './brands/brand-calendar/brand-calendar.component';
import {BrandTeamComponent} from './brands/brand-team/brand-team.component';
import {ProjectStatusComponent} from './projects/project-status/project-status.component';
import {ModulesComponent} from './modules/modules.component';
import {ModuleUsersComponent} from './modules/module-users/module-users.component';
import {ModuleChangesComponent} from './modules/module-changes/module-changes.component';
import {BrandFollowersComponent} from './brands/brand-followers/brand-followers.component';
import {BrandPostComponent} from './brands/brand-post/brand-post.component';
import {SharedPostComponent} from '../_shared/shared-post/shared-post.component';
import {UserListComponent} from './users/user-list/user-list.component';
import {UserInvitationsComponent} from './users/user-invitations/user-invitations.component';
import {UserInviteComponent} from './users/user-invite/user-invite.component';
import {ChangelogComponent} from './changelog/changelog.component';
import {SharedBottomsheetVersionComponent} from '../_shared/shared-bottomsheet-version/shared-bottomsheet-version.component';
import {WidgetBrandsComponent} from './dashboard/widget-brands/widget-brands.component';
import {FeedbackComponent} from './feedback/feedback.component';

import {ChartsModule} from 'ng2-charts';
import {SettingsInvoicesComponent} from './settings/settings-invoices/settings-invoices.component';
import {SettingsPricesComponent} from './settings/settings-prices/settings-prices.component';
import {SharedPostDialogComponent} from '../_shared/shared-post-dialog/shared-post-dialog.component';
import { ServicesComponent } from './services/services.component';
import { ServiceAddComponent } from './services/service-add/service-add.component';
import { ServiceListComponent } from './services/service-list/service-list.component';
import { ServiceChangesComponent } from './services/service-changes/service-changes.component';
import { ServiceDetailsComponent } from './services/service-details/service-details.component';
import { ServiceFollowersComponent } from './services/service-followers/service-followers.component';
import { ServiceTicketComponent } from './services/service-ticket/service-ticket.component';
import { ServiceTeamComponent } from './services/service-team/service-team.component';
import {MatTableModule} from '@angular/material/table';
import {SharedModule} from '../shared.module';
import { SharedTicketDialogComponent } from '../_shared/shared-ticket-dialog/shared-ticket-dialog.component';
import { SharedTicketPageComponent } from '../_shared/shared-ticket-page/shared-ticket-page.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDividerModule} from '@angular/material/divider';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDialogModule} from '@angular/material/dialog';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';

registerLocaleData(localePL);

@NgModule({
  declarations: [
    CoreComponent,
    AsideComponent,
    AsideNavComponent,
    AsideSidebarComponent,
    ChatsComponent,
    ChatAddComponent,
    ChatDetailsComponent,
    ChatMessagesComponent,
    ChatTeamComponent,
    ClientsComponent,
    ClientAddComponent,
    ClientDetailsComponent,
    DashboardComponent,
    WidgetChatsComponent,
    WidgetProgressComponent,
    ProfileComponent,
    ProfileEmailComponent,
    ProfilePasswordComponent,
    ProfileSettingsComponent,
    ProjectsComponent,
    ProjectAddComponent,
    ProjectAssetsComponent,
    ProjectDetailsComponent,
    ProjectScheduleComponent,
    ProjectTeamComponent,
    ProjectWikiComponent,
    ProjectTaskComponent,
    ProjectChangesComponent,
    ProjectStatsComponent,
    RolesComponent,
    SettingsComponent,
    RoleAddComponent,
    RoleDetailsComponent,
    RolePrivilegesComponent,
    SettingsDetailsComponent,
    SettingsDomainsComponent,
    BrandsComponent,
    UsersComponent,
    UserCalendarComponent,
    UserChatComponent,
    UserDetailsComponent,
    NotificationsComponent,
    BrandAddComponent,
    BrandChangesComponent,
    BrandDetailsComponent,
    BrandCalendarComponent,
    BrandTeamComponent,
    ModulesComponent,

    ForbiddenTypeDirective,

    SharedTaskComponent,
    SharedTaskPageComponent,
    SharedTaskDialogComponent,
    SharedPostComponent,
    SharedPostDialogComponent,
    SharedBottomsheetVersionComponent,
    SharedTicketDialogComponent,
    SharedTicketPageComponent,
    // SharedMessageComponent,

    ProjectStatusComponent,
    ModuleUsersComponent,
    ModuleChangesComponent,
    BrandFollowersComponent,
    BrandPostComponent,
    UserListComponent,
    UserInvitationsComponent,
    UserInviteComponent,
    ChangelogComponent,
    WidgetBrandsComponent,
    FeedbackComponent,
    SettingsInvoicesComponent,
    SettingsPricesComponent,
    ServicesComponent,
    ServiceAddComponent,
    ServiceListComponent,
    ServiceChangesComponent,
    ServiceDetailsComponent,
    ServiceFollowersComponent,
    ServiceTicketComponent,
    ServiceTeamComponent
  ],
    imports: [
        SharedModule,

        BrowserModule,
        CoreRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        QuillModule,

        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatCardModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatMenuModule,
        MatListModule,
        MatDividerModule,
        MatBadgeModule,
        MatTabsModule,
        MatIconModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatExpansionModule,
        MatDialogModule,
        MatBottomSheetModule,
        MatTableModule,

        DragDropModule,

        NgxUploaderModule,

        ChartsModule,
        MatTabsModule
    ],
  entryComponents: [
    SharedTaskDialogComponent,
    SharedPostDialogComponent,
    SharedTicketDialogComponent,
    SharedBottomsheetVersionComponent
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'pl-PL'},
  ],
  bootstrap: [CoreComponent]
})
export class CoreModule {
}
