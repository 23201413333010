import {Component, OnDestroy, OnInit} from '@angular/core';
import {TaskType} from '../../../_types/task.type';
import {Subscription} from 'rxjs/internal/Subscription';
import {ActivatedRoute} from '@angular/router';
import {TasksService} from '../../../_services/tasks.service';
import {UserType} from '../../../_types/user.type';
import {ProjectsComponent} from '../projects.component';
import {ProjectsService} from '../../../_services/projects.service';

@Component({
    selector: 'app-project-task',
    templateUrl: './project-task.component.html',
    styleUrls: ['./project-task.component.scss']
})
export class ProjectTaskComponent implements OnInit, OnDestroy {

    taskDetails: TaskType | any = {
        uid: null,
        userTodoRef: null,
        userAuthorRef: null,
        name: null,
        description: null,
        projectRef: null,
        dateStart: new Date(),
        dateEnd: new Date(),
        showInMonths: null,
        showInDays: null,
        showInWeeks: null,
        type: 'feature',
        priority: 'normal',
        estimation: 1,
        dateUpdate: null,
        dateCreate: null,
        userUpdateRef: null,
        status: 'todo',
        isActive: true,
        files: []
    };
    user: UserType | any;

    private subscriptions: Array<Subscription> = [];

    constructor(private route: ActivatedRoute,
                private tasksService: TasksService,
                private projectsService: ProjectsService,
                private projectsComponent: ProjectsComponent) {
    }

    ngOnInit(): void {
        // set user
        // @ts-ignore
      this.user = this.route.snapshot.parent.parent.data.user;

      this.subscriptions.push(this.route.params.subscribe(params => {
            // get task
          this.subscriptions.push(this.tasksService.getTask(`companies/${this.user.companyRef.id}/tasks/${params.uidTask}`)
              .subscribe((task: any) => {
                  // get project
                  this.subscriptions.push(this.projectsService.getProject(this.user, task.projectRef.id).subscribe(project => {
                      task.project = project;
                      this.taskDetails = task;
                      this.projectsComponent.title = this.taskDetails.name;
                  }));
              }));
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
