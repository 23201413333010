import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserType} from '../../../_types/user.type';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {BrandsService} from '../../../_services/brands.service';
import {BrandType} from '../../../_types/brand.type';
import {PostsService} from '../../../_services/posts.service';
import {PostType} from '../../../_types/post.type';
import {NgProgress} from 'ngx-progressbar';

@Component({
  selector: 'app-widget-brands',
  templateUrl: './widget-brands.component.html',
  styleUrls: ['./widget-brands.component.scss']
})
export class WidgetBrandsComponent implements OnInit, OnDestroy {

  user: UserType;
  month: number;
  year: number;
  today: Date;

  brands: BrandType[];
  brandsComplete = false;

  posts: PostType[];
  postsComplete = false;

  private subscriptions: Array<Subscription> = [];
  private postsSubscription: Subscription;

  constructor(private brandsService: BrandsService,
              private postsService: PostsService,
              private route: ActivatedRoute,
              private progress: NgProgress) {
  }

  ngOnInit() {
    // set date
    this.today = new Date();
    this.setDate();

    // set user
    this.user = this.route.snapshot.parent.data.user;

    // get brands
    this.getBrands();

    // get posts
    this.getPosts();
  }

  prevMonth() {
    this.postsComplete = false;

    this.today = new Date(this.today.setMonth(this.today.getMonth() - 1));
    this.setDate();
    this.getPosts();
  }

  nextMonth() {
    this.postsComplete = false;

    this.today = new Date(this.today.setMonth(this.today.getMonth() + 1));
    this.setDate();
    this.getPosts();
  }

  private setDate() {
    this.month = this.today.getMonth() + 1;
    this.year = this.today.getFullYear();
  }

  private getBrands() {
    this.subscriptions.push(this.brandsService.getBrands(this.user).subscribe(brands => {
      this.brands = brands;
      this.brandsComplete = true;
      this.matchPostsToBrands();
    }));
  }

  private getPosts() {
    // unsubscribe if change date
    if (this.postsSubscription) {
      this.postsSubscription.unsubscribe();
    }

    // set year and month
    let yearMonth = this.year + '' + this.month;
    if (this.month < 10) {
      yearMonth = this.year + '0' + this.month;
    }

    // get posts
    this.postsSubscription = this.postsService.getPostsByMonth(this.user, yearMonth).subscribe(posts => {
      this.posts = posts;
      this.postsComplete = true;
      this.matchPostsToBrands();
    });
  }

  private matchPostsToBrands() {
    if (this.brandsComplete && this.postsComplete) {
      this.brands.forEach(brand => {
        brand.countStatus = {
          hide: 0,
          social: 0,
          graphic: 0,
          client: 0,
          accept: 0,
          publish: 0
        };
        this.posts.forEach(post => {
          if (brand.uid === post.brandRef.id) {
            brand.countStatus[post.status]++;
          }
        });
      });
    }
  }

  ngOnDestroy() {
    this.postsSubscription.unsubscribe();
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
