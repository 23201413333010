import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UserType} from '../../_types/user.type';
import {PostType} from '../../_types/post.type';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {PostsService} from '../../_services/posts.service';
import {BrandType} from '../../_types/brand.type';

@Component({
  selector: 'app-shared-post-dialog',
  templateUrl: './shared-post-dialog.component.html',
  styleUrls: ['./shared-post-dialog.component.scss']
})
export class SharedPostDialogComponent implements OnInit, OnDestroy {

  post: PostType;
  user: UserType;
  users: UserType[];
  postUid: string;
  postEdit: PostType;
  brand: BrandType;

  private subscriptions: Array<Subscription> = [];

  constructor(@Inject(MAT_DIALOG_DATA) public postDialog: any,
              private postsService: PostsService) {

    this.postUid = postDialog.post.uid;
    this.user = postDialog.user;
    this.users = postDialog.users;
    this.brand = postDialog.brand;

  }

  ngOnInit() {
    this.subscriptions.push(this.postsService.getPost(`companies/${this.user.companyRef.id}/posts/${this.postUid}`)
      .subscribe(post => {
        this.post = post;
        this.post.brand = this.brand;
      }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
