import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../_services/users.service';
import {UserType} from '../../_types/user.type';
import {ModulesService} from '../../_services/modules.service';
import {ModuleType} from '../../_types/module.type';
import {animate, animateChild, query, stagger, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent implements OnInit, OnDestroy {

  title: string;
  user: UserType;
  modules: ModuleType[];
  users: UserType[];
  uid: string;
  navLinks: any[];

  searchModule: string;

  modulesComplete = false;
  usersComplete = false;

  showFullPage = false;

  private subscriptions: Array<Subscription> = [];

  constructor(private router: Router,
              private usersService: UsersService,
              private modulesService: ModulesService,
              private route: ActivatedRoute) {

    // set menu and title
    this.subscriptions.push(this.router.events.subscribe(() => {
      this.showFullPage = false;
      switch (this.router.url) {
        case '/modules': {
          this.title = '';
          this.navLinks = [];
          this.showFullPage = true;
          break;
        }
      }
    }));

    // set user
    this.user = this.route.snapshot.parent.data.user;

    // get modules
    this.getModules();

    // get users
    this.getUsers();

  }

  ngOnInit() {
  }

  private getModules() {
    this.subscriptions.push(this.modulesService.getModules().subscribe(modules => {
      this.modules = modules;
      this.modulesComplete = true;
      this.countUsers();
    }));
  }

  private getUsers() {
    this.subscriptions.push(this.usersService.getUsersActive(this.user.companyRef).subscribe(users => {
      this.users = users;
      this.usersComplete = true;
      this.countUsers();
    }));
  }

  private countUsers() {
    if (this.modulesComplete && this.usersComplete) {
      this.modules.forEach(module => {
        module._usersNumber = 0;
        this.users.forEach(user => {
          if (user._modules && user._modules[module.code]) {
            module._usersNumber++;
          }
        });
      });
    }
  }

  setNav(uid) {
    this.uid = uid;
    this.navLinks = [
      {
        path: uid + '/users',
        label: 'Users'
      },
      {
        path: uid + '/changes',
        label: 'Changes'
      }
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
