import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {PostType} from '../_types/post.type';
import {UserType} from '../_types/user.type';
import * as firebase from 'firebase/app';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class PostsService {

  constructor(private afs: AngularFirestore) {
  }

  // get post
  getPost(path: string): Observable<PostType | undefined> {
    return this.afs.doc<PostType>(path).valueChanges();

    // return this.afs.doc<TaskType>(path).valueChanges().pipe(map(task => {
    //     task.project$ = this.afs.doc(task.projectRef.path).valueChanges();
    //     return task;
    // }));
  }

  // get posts by brand and month
  getPostsByBrandMonth(user: UserType, yearMonth: string, brandRef: any): Observable<PostType[]> {
    return this.afs.collection<PostType>(`companies/${user.companyRef.id}/posts`, ref => ref
      .where(`showInMonth`, '==', yearMonth)
      .where(`isActive`, '==', true)
      .where(`brandRef`, '==', brandRef)).valueChanges();
  }

  // get posts by brand and month once
  getPostsByBrandMonthOnce(user: UserType, yearMonth: any, brandRef: any): any {
    const db = firebase.firestore();
    return db.collection(`companies/${user.companyRef.id}/posts`)
      .where(`showInMonth`, '==', yearMonth)
      .where(`isActive`, '==', true)
      .where(`brandRef`, '==', brandRef)
      .get();
  }

  // get posts by month
  getPostsByMonth(user: UserType, yearMonth: string): Observable<PostType[]> {
    return this.afs.collection<PostType>(`companies/${user.companyRef.id}/posts`, ref => ref
      .where(`showInMonth`, '==', yearMonth)
      .where(`isActive`, '==', true)).valueChanges();
  }

  // insert post
  insertPost(post: PostType, user: UserType): Promise<void> {
    return this.afs.doc<PostType>(`companies/${user.companyRef.id}/posts/${post.uid}`).set(post);
  }

  // update post
  updatePost(fields: {}, user: UserType, postUid: string): Promise<void> {
    return this.afs.doc<PostType>(`companies/${user.companyRef.id}/posts/${postUid}`).update(fields);
  }

  // upload file
  uploadFile(file: any, path: string, fileName: string): any {
    const metadata = {
      contentDisposition: 'attachment; filename=' + fileName,
    };
    return firebase.storage().ref().child(path).put(file, metadata);
  }

  // insert post message
  // insertPostMessage(message: MessageType, user: UserType, postUid: string) {
  //   return this.afs.doc<PostType>(`companies/${user.companyRef.id}/posts/${postUid}`).update({
  //     messages: firebase.firestore.FieldValue.arrayUni
  //   });
  // }
}
