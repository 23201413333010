<section class="page-section">
  <mat-card class="mat-card--estimations">
    <mat-card-header>
      <mat-card-title>Predicted estimations vs allocated tasks</mat-card-title>
      <mat-card-subtitle>Compare estimation hours with allocated task hours</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ul *ngIf="roles" class="list-stats">
        <li *ngFor="let role of roles" class="list-stats-element">
          <h3 class="role">{{ role.name }}</h3>
          <div class="desc-line">
            <div class="desc">Predicted estimation<br>{{ role.estimations }} hours</div>
            <div class="line line-estimations">
              <div *ngIf="role.estimations"
                   class="part part-estimations"
                   [ngStyle]="{'width': role.estimations*100/roleEstimationsMax + '%'}"
                   matTooltip="{{ role.estimations }}h"
                   matTooltipPosition="above">
                <small>{{ role.estimations }}h Prediction</small>
              </div>
              <div *ngIf="!role.estimations"
                   class="info">
                <small>To see this chart fill the estimation in the project details</small>
              </div>
            </div>
          </div>
          <div class="desc-line">
            <div class="desc">Allocated tasks<br>{{ role.estimationsRealTotal }} hours</div>
            <div class="line line-estimations-real">
              <ng-container *ngIf="role.estimationsRealTotal">
                <div class="part part-done-feature"
                     *ngIf="role.estimationsReal.doneFeature"
                     [ngStyle]="{'width': role.estimationsReal.doneFeature*100/roleEstimationsMax + '%'}"
                     matTooltip="{{ role.estimationsReal.doneFeature }}h Done/Feature"
                     matTooltipPosition="above">
                  <small>{{ role.estimationsReal.doneFeature }}h Done/Feature</small>
                </div>
                <div class="part part-done-bug"
                     *ngIf="role.estimationsReal.doneBug"
                     [ngStyle]="{'width': role.estimationsReal.doneBug*100/roleEstimationsMax + '%'}"
                     matTooltip="{{ role.estimationsReal.doneBug }}h Done/Bug"
                     matTooltipPosition="above">
                  <small>{{ role.estimationsReal.doneBug }}h Done/Bug</small>
                </div>
                <div class="part part-done-check"
                     *ngIf="role.estimationsReal.doneCheck"
                     [ngStyle]="{'width': role.estimationsReal.doneCheck*100/roleEstimationsMax + '%'}"
                     matTooltip="{{ role.estimationsReal.doneCheck }}h Done/Check"
                     matTooltipPosition="above">
                  <small>{{ role.estimationsReal.doneCheck }}h Done/Check</small>
                </div>
                <div class="part part-toCheck-feature"
                     *ngIf="role.estimationsReal.toCheckFeature"
                     [ngStyle]="{'width': role.estimationsReal.toCheckFeature*100/roleEstimationsMax + '%'}"
                     matTooltip="{{ role.estimationsReal.toCheckFeature }}h To check/Feature"
                     matTooltipPosition="above">
                  <small>{{ role.estimationsReal.toCheckFeature }}h To check/Feature</small>
                </div>
                <div class="part part-toCheck-bug"
                     *ngIf="role.estimationsReal.toCheckBug"
                     [ngStyle]="{'width': role.estimationsReal.toCheckBug*100/roleEstimationsMax + '%'}"
                     matTooltip="{{ role.estimationsReal.toCheckBug }}h To check/Bug"
                     matTooltipPosition="above">
                  <small>{{ role.estimationsReal.toCheckBug }}h To check/Bug</small>
                </div>
                <div class="part part-toCheck-check"
                     *ngIf="role.estimationsReal.toCheckCheck"
                     [ngStyle]="{'width': role.estimationsReal.toCheckCheck*100/roleEstimationsMax + '%'}"
                     matTooltip="{{ role.estimationsReal.toCheckCheck }}h To check/Check"
                     matTooltipPosition="above">
                  <small>{{ role.estimationsReal.toCheckCheck }}h To check/Check</small>
                </div>
                <div class="part part-inProgress-feature"
                     *ngIf="role.estimationsReal.inProgressFeature"
                     [ngStyle]="{'width': role.estimationsReal.inProgressFeature*100/roleEstimationsMax + '%'}"
                     matTooltip="{{ role.estimationsReal.inProgressFeature }}h In progress/Feature"
                     matTooltipPosition="above">
                  <small>{{ role.estimationsReal.inProgressFeature }}h In progress/Feature</small>
                </div>
                <div class="part part-inProgress-bug"
                     *ngIf="role.estimationsReal.inProgressBug"
                     [ngStyle]="{'width': role.estimationsReal.inProgressBug*100/roleEstimationsMax + '%'}"
                     matTooltip="{{ role.estimationsReal.inProgressBug }}h In progress/Bug"
                     matTooltipPosition="above">
                  <small>{{ role.estimationsReal.inProgressBug }}h In progress/Bug</small>
                </div>
                <div class="part part-inProgress-check"
                     *ngIf="role.estimationsReal.inProgressCheck"
                     [ngStyle]="{'width': role.estimationsReal.inProgressCheck*100/roleEstimationsMax + '%'}"
                     matTooltip="{{ role.estimationsReal.inProgressCheck }}h In progress/Check"
                     matTooltipPosition="above">
                  <small>{{ role.estimationsReal.inProgressCheck }}h In progress/Check</small>
                </div>
                <div class="part part-todo-feature"
                     *ngIf="role.estimationsReal.todoFeature"
                     [ngStyle]="{'width': role.estimationsReal.todoFeature*100/roleEstimationsMax + '%'}"
                     matTooltip="{{ role.estimationsReal.todoFeature }}h Todo/Feature"
                     matTooltipPosition="above">
                  <small>{{ role.estimationsReal.todoFeature }}h Todo/Feature</small>
                </div>
                <div class="part part-todo-bug"
                     *ngIf="role.estimationsReal.todoBug"
                     [ngStyle]="{'width': role.estimationsReal.todoBug*100/roleEstimationsMax + '%'}"
                     matTooltip="{{ role.estimationsReal.todoBug }}h Todo/Bug"
                     matTooltipPosition="above">
                  <small>{{ role.estimationsReal.todoBug }}h Todo/Bug</small>
                </div>
                <div class="part part-todo-check"
                     *ngIf="role.estimationsReal.todoCheck"
                     [ngStyle]="{'width': role.estimationsReal.todoCheck*100/roleEstimationsMax + '%'}"
                     matTooltip="{{ role.estimationsReal.todoCheck }}h Todo/Check"
                     matTooltipPosition="above">
                  <small>{{ role.estimationsReal.todoCheck }}h Todo/Check</small>
                </div>
              </ng-container>
              <ng-container *ngIf="!role.estimationsRealTotal">
                <div class="info">
                  <small>To see chart allocate tasks</small>
                </div>
              </ng-container>
            </div>
          </div>
        </li>
      </ul>
    </mat-card-content>
  </mat-card>
</section>
