import {Pipe} from '@angular/core';

@Pipe({
    name: 'fileSize'
})
export class FileSizePipe {

    constructor() {}

    transform(fileSizeInBytes: number): any {
        const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        let i = -1;
        do {
            fileSizeInBytes = fileSizeInBytes / 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    }
}
