<section class="aside-subnav" [ngClass]="{'aside-subnav--full-page': showFullPage}">
  <ng-container *ngIf="services; else loading">
    <ng-container *ngIf="services.length">
      <div class="search">
        <mat-form-field>
          <input matInput placeholder="Search" type="search" [(ngModel)]="searchService">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>

      <mat-nav-list
        [ngClass]="{'mat-nav-list--button': user._rolesName === 'admin' || user._privileges.canCreateService}">
        <div *ngFor="let service of services | filter:searchService:['name']">

          <a mat-list-item
             *ngIf="service.access || user._rolesName === 'admin' || user._privileges.canCreateService"
             routerLinkActive="active"
             routerLink="/services/{{ service.uid }}">
            <div matLine>
              {{ service.name }}
            </div>
            <div matLine *ngIf="service.shortDesc">
              <small>{{ service.shortDesc }}</small>
            </div>
            <div matLine *ngIf="service.project">
              <small>{{ service.project.name }}</small>
            </div>
          </a>

        </div>
      </mat-nav-list>

    </ng-container>
  </ng-container>

  <ng-template #loading>
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </ng-template>

<!--  <div class="container-btm"-->
<!--       *ngIf="user._rolesName === 'admin' || user._privileges.canCreateBrand">-->
<!--    <a routerLink="/services/service-add" mat-stroked-button color="primary">Add service desk</a>-->
<!--  </div>-->
</section>

<main class="help-margin-left-180px">
  <div class="container">
    <div class="row">
      <div class="col">
        <header class="page-header">
          <h1 [innerHTML]="title" *ngIf="title"></h1>
          <nav mat-tab-nav-bar>
            <a mat-tab-link
               *ngFor="let link of navLinks"
               [routerLink]="link.path"
               routerLinkActive #rla="routerLinkActive"
               [active]="rla.isActive">
              {{link.label}}
            </a>
          </nav>
        </header>

        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</main>
