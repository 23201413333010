import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {UsersService} from '../_services/users.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProjectGetGuard implements CanActivate {

    constructor(private afAuth: AngularFireAuth,
                private afs: AngularFirestore,
                private usersService: UsersService,
                private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        return this.usersService.getUser().pipe(map((user: any) => {
            if (user._privileges.canGetProject || user._rolesName === `admin`) {
                return true;
            } else {
                this.router.navigate(['/error', {info: 'Permission denied'}]).then();
                return false;
            }
        }));

    }
}
