<section class="page-section">
    <mat-card class="mat-card--changes">
        <mat-card-content>
            <div class="row">
                <div class="col">
                    <mat-list class="mat-list-messages">

                        <ng-container *ngFor="let message of changes">
                            <app-shared-message *ngIf="message" [message]="message"></app-shared-message>
                        </ng-container>

                        <mat-list-item class="mat-list-item--write-message" *ngIf="user">
                            <div matListAvatar
                                 *ngIf="user.photoURL"
                                 [ngStyle]="{'background-image': 'url(' + user.photoURL + ')'}"></div>
                            <div matListAvatar
                                 *ngIf="!user.photoURL">
                                {{ user.name | avatar }}
                            </div>
                            <div matLine>
                                <div class="mat-line--name">{{user.name}}</div>
                                <div>
                                    <form #f="ngForm"
                                          (ngSubmit)="onSend(f)"
                                          (keydown.meta.enter)="onSend(f)"
                                          (keydown.control.enter)="onSend(f)">
                                        <quill-editor
                                                name="messageText"
                                                #message="ngModel"
                                                ngModel
                                                placeholder="Write a message..."
                                                required>
                                            <div quill-editor-toolbar>
                                        <span class="ql-formats">
                                            <button class="ql-bold" [title]="'Bold'"></button>
                                            <button class="ql-italic" [title]="'Italic'"></button>
                                            <button class="ql-underline" [title]="'Underline'"></button>
                                            <button class="ql-strike" [title]="'Strike'"></button>
                                        </span>
                                                <span class="ql-formats">
                                            <button class="ql-list" value="ordered" [title]="'List ordered'"></button>
                                            <button class="ql-list" value="bullet" [title]="'List bullet'"></button>
                                        </span>
                                                <span class="ql-formats">
                                                    <button class="ql-link" [title]="'Link'"></button>
                                            <button class="ql-blockquote" [title]="'Blockquote'"></button>
                                            <button class="ql-code-block" [title]="'Code-block'"></button>
                                                    <button class="ql-clean" [title]="'Clean format'"></button>
                                        </span>
                                            </div>
                                        </quill-editor>


                                        <div class="drop-container"
                                             ngFileDrop
                                             [options]="options"
                                             (uploadOutput)="onUploadMessage($event)"
                                             [uploadInput]="uploadInput"
                                             [ngClass]="{ 'is-drop-over': dragOver }">
                                            <input type="file"
                                                   ngFileSelect
                                                   [options]="options"
                                                   (uploadOutput)="onUploadMessage($event)"
                                                   [uploadInput]="uploadInput"
                                                   multiple>
                                            <span>Select or drop file(s) here</span>
                                        </div>

                                        <ul *ngIf="messageFiles" class="list-files">
                                            <li *ngFor="let file of messageFiles; let i = index">
                                                <a href="{{ file.url }}" class="link" target="_blank">{{file.name}}</a>
                                                <small>{{ file.size | fileSize }} ({{ file.type }})</small>
                                                <i class="material-icons" (click)="onDeleteMessageFile(i)">clear</i>
                                            </li>
                                        </ul>

                                        <button type="submit"
                                                class="submit"
                                                mat-raised-button color="primary"
                                                [disabled]="f.form.invalid">Send
                                        </button>
                                        <small class="cmdCtrlEnter">or press CMD/CTRL + Enter</small>
                                    </form>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</section>
