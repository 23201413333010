import {Component, OnInit} from '@angular/core';
import {UserType} from '../../_types/user.type';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    navLinks: any[];
    user: UserType;

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit() {

        // set user
        this.user = this.route.snapshot.parent.data.user;

        // define nav
        this.navLinks = [
            {
                path: 'settings',
                label: 'Settings'
            },
            {
                path: 'email',
                label: 'E-mail'
            },
            {
                path: 'password',
                label: 'Password'
            }
        ]
    }

}
