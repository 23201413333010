import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  navLinks: any[] | any;

  constructor(private router: Router) {

  }

  ngOnInit(): void {
    this.navLinks = [
      {
        path: 'details',
        label: 'Details'
      },
      {
        path: 'prices',
        label: 'Prices'
      },
      {
        path: 'invoices',
        label: 'Invoices'
      }
    ];

    if (this.router.url === '/settings') {
      this.router.navigate(['/settings/details']);
    }
  }

}
