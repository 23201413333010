<section class="page-section">

    <form (ngSubmit)="onSave(f)" #f="ngForm" autocomplete="off" *ngIf="client">

        <div class="row">
            <div class="col-4">
                <mat-card>
                    <mat-card-content
                            *ngIf="user._privileges.canUpdateClient || user._rolesName === 'admin'; else info">
                        <mat-form-field>
                            <input matInput
                                   type="text"
                                   name="name"
                                   #name="ngModel"
                                   [(ngModel)]="client.name"
                                   required
                                   placeholder="Name">
                            <mat-error *ngIf="name.hasError('required')">
                                Name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>

                        <button type="submit"
                                mat-raised-button color="primary"
                                [disabled]="f.form.invalid">Save
                        </button>
                        <a (click)="showRemove = !showRemove" class="link link--btn" *ngIf="!showRemove">Delete</a>
                        <span *ngIf="showRemove" class="link--btn">Are you sure? <a (click)="showRemove = false"
                                                                                    class="link">No</a>&nbsp;<a
                                (click)="onRemove()" class="link">Yes</a></span>
                    </mat-card-content>


                    <ng-template #info>
                        <mat-card-content>
                            <mat-form-field>
                                <input matInput
                                       type="text"
                                       name="name"
                                       #name="ngModel"
                                       [(ngModel)]="client.name"
                                       placeholder="Name"
                                       readonly>
                            </mat-form-field>
                        </mat-card-content>
                    </ng-template>

                </mat-card>
            </div>
        </div>


    </form>
</section>
