import {Component, OnInit} from '@angular/core';
import {ChatsComponent} from '../chats.component';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {UserType} from '../../../_types/user.type';
import {ChatType} from '../../../_types/chat.type';
import {UsersService} from '../../../_services/users.service';
import {ChatsService} from '../../../_services/chats.service';
import {map, switchMap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import * as firebase from 'firebase';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
    selector: 'app-chat-team',
    templateUrl: './chat-team.component.html',
    styleUrls: ['./chat-team.component.scss']
})
export class ChatTeamComponent implements OnInit {

    user: UserType;
    chat: ChatType;
    users$: Observable<UserType[]>;

    private subscriptions: Array<Subscription> = [];

    constructor(private chatsComponent: ChatsComponent,
                private chatsService: ChatsService,
                private route: ActivatedRoute,
                private usersService: UsersService,
                private progress: NgProgress,
                private snackBar: MatSnackBar,
                private afs: AngularFirestore ) {

        // set params
        this.subscriptions.push(this.route.params.subscribe(params => {
            this.chatsComponent.setNav(params.uid);

            // get user
            // this.subscriptions.push(this.usersService.user$.subscribe(user => {
            //     if (user) {
            //         this.user = user;
            //
            //         //get chat
            //         this.subscriptions.push(this.chatsService.getChat(`companies/${user.companyRef.id}/chats/${this.chatsComponent.uid}`).subscribe(chat => {
            //             this.chat = chat;
            //             this.chatsComponent.title = this.chat.name;
            //
            //             //get users and set access variable
            //             this.users$ = this.usersService.getUsers(user.companyRef).pipe(map(users => {
            //                 for (user of users) {
            //                     user.projectAccess = false;
            //                     for (let userRef of chat.userRefs) {
            //                         if (userRef.id === user.uid) {
            //                             user.chatAccess = true;
            //                         }
            //                     }
            //                 }
            //
            //                 return users;
            //             }));
            //         }));
            //
            //     }
            // }))
        }));
    }

    ngOnInit() {
    }

    onChangeAccess(userUid) {
        this.progress.ref().start();
        let removeIndex = -1;
        for (let i = 0; i < this.chat.userRefs.length; i++) {
            if (this.chat.userRefs[i].id === userUid) {
                removeIndex = i;
                break;
            }
        }
        if (removeIndex === -1) {
            this.chat.userRefs.push(this.afs.doc(`users/${userUid}`).ref);
            this.chatsService.insertChat(this.chat, `companies/${this.user.companyRef.id}/chats/${this.chat.uid}`)
                .then(() => {
                    this.snackBar.open('Access to the chat has been added', '', environment.snackbarSuccess);
                    this.progress.ref().complete();
                })
                .catch(err => {
                    this.snackBar.open(err.message, '', environment.snackbarWarn);
                    this.progress.ref().complete();
                });
        }
        else {
            this.chat.userRefs.splice(removeIndex, 1);
            this.chatsService.insertChat(this.chat, `companies/${this.user.companyRef.id}/chats/${this.chat.uid}`)
                .then(() => {
                    this.snackBar.open('Access to the chat has been removed', '', environment.snackbarSuccess);
                    this.progress.ref().complete();
                })
                .catch(err => {
                    this.snackBar.open(err.message, '', environment.snackbarWarn);
                    this.progress.ref().complete();
                });
        }
        this.progress.ref().complete();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
