import {Component, EventEmitter, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {ProjectsComponent} from '../projects.component';
import {ActivatedRoute} from '@angular/router';
import {UserType} from '../../../_types/user.type';
import {ProjectsService} from '../../../_services/projects.service';
import {ProjectType} from '../../../_types/project.type';
import {MessageType} from '../../../_types/message.type';
import {UsersService} from '../../../_services/users.service';
import {NgForm} from '@angular/forms';
import * as firebase from 'firebase/app';
import {environment} from '../../../../environments/environment';
import {UploaderOptions, UploadFile, UploadInput, UploadOutput} from 'ngx-uploader';
import {FileType} from '../../../_types/file.type';
import {MessagesService} from '../../../_services/messages.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-project-history',
    templateUrl: './project-changes.component.html',
    styleUrls: ['./project-changes.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProjectChangesComponent implements OnInit, OnDestroy {

    user: UserType;
    users: UserType[];
    usersComplete = false;
    project: ProjectType;
    changes: MessageType[];
    changesComplete = false;

    messageFiles: FileType[] = [];

    options: UploaderOptions;
    files: UploadFile[] = [];
    uploadInput: EventEmitter<UploadInput>;
    dragOver: boolean;

    private subscriptions: Array<Subscription> = [];

    constructor(private projectsComponent: ProjectsComponent,
                private messagesService: MessagesService,
                private route: ActivatedRoute,
                private usersService: UsersService,
                private projectsService: ProjectsService,
                private afs: AngularFirestore,
                private snackBar: MatSnackBar) {
        // set params
        this.subscriptions.push(this.route.params.subscribe(params => {
            this.projectsComponent.setNav(params.uid);

            // set user
            this.user = this.route.snapshot.parent.parent.data.user;

            // get project changes
            this.getProjectChanges();

            // get project
            this.getProject();

            // get users
            this.getUsers();

        }));
    }

    ngOnInit() {
    }

    onSend(form: NgForm) {
        let message: MessageType;
        message = {
            uid: this.afs.createId(),
            message: form.value.messageText,
            dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
            userRef: this.afs.doc(`users/${this.user.uid}`).ref,
            files: this.messageFiles,
            type: 'user'
        };
        this.messagesService.insertMessage(message, `companies/${this.user.companyRef.id}/projects/${this.project.uid}/changes/${message.uid}`)
            .then(() => {
                this.snackBar.open('Message has been added', '', environment.snackbarSuccess);
                this.messageFiles = [];
            })
            .catch(err => {
                this.snackBar.open(err.message, '', environment.snackbarWarn);
            });

        // reset form
        form.resetForm();
    }

    onUploadMessage(output: UploadOutput): void {
        if (output.type === 'allAddedToQueue') { // when all files added in queue
            this.startUploadMessage();
        } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
            this.files.push(output.file);
        } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
            console.log('uploading');
            // update current data in files array for uploading file
            const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
            this.files[index] = output.file;
        } else if (output.type === 'removed') {
            console.log('removed');
            // remove file from array when removed
            this.files = this.files.filter((file: UploadFile) => file !== output.file);
        } else if (output.type === 'dragOver') {
            this.dragOver = true;
        } else if (output.type === 'dragOut') {
            this.dragOver = false;
        } else if (output.type === 'drop') {
            this.dragOver = false;
        }
    }

    startUploadMessage(): void {
        for (const file of this.files) {
            // start progress bar
            // this.progress.ref().start();
            // show snackbar
            this.snackBar.open(`Uploading file(s)`, '', environment.snackbarInfo);
            // prepare file
            const fileNameArray = file.name.split('.');
            const name = this.afs.createId() + '.' + fileNameArray[fileNameArray.length - 1];
            const path = `/${this.user.companyRef.id}/projects/${this.project.uid}/${name}`;
            this.messagesService.uploadFile(file.nativeFile, path, file.name).then(snapshot => {
                // push file to message
                snapshot.ref.getDownloadURL().then(downloadURL => {
                    // push file to array
                    this.messageFiles.push({
                        name: file.name,
                        url: downloadURL,
                        type: file.type,
                        size: file.size,
                        isActive: true
                    });
                    // close progress bar
                    // this.progress.ref().complete();
                });
            });
        }
        // clear files
        this.files = [];
    }

    private getProject() {
        this.subscriptions.push(this.projectsService.getProject(this.user, this.projectsComponent.uid).subscribe(project => {
            this.project = project;
            this.projectsComponent.title = this.project.name;
        }));
    }

    private getProjectChanges() {
        this.subscriptions.push(this.projectsService.getProjectChanges(this.user, this.projectsComponent.uid).subscribe(changes => {
            this.changes = changes;
            this.changesComplete = true;
            this.matchUpdatesToUsers();
        }));
    }

    private getUsers() {
        this.subscriptions.push(this.usersService.getUsers(this.user.companyRef).subscribe(users => {
            this.users = users;
            this.usersComplete = true;
            this.matchUpdatesToUsers();
        }));
    }

    private matchUpdatesToUsers() {
        if (this.changesComplete && this.usersComplete) {
            this.changes.forEach(change => {
                this.users.forEach(user => {
                    if (change.userRef.id === user.uid) {
                        change.author = user;
                    }
                });
            });
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
