import {Component, Input, OnInit} from '@angular/core';
import {TaskType} from '../../_types/task.type';
import {UserType} from '../../_types/user.type';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-shared-task-page',
  templateUrl: './shared-task-page.component.html',
  styleUrls: ['./shared-task-page.component.scss']
})
export class SharedTaskPageComponent implements OnInit {

  @Input() task: TaskType;

  user: UserType;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    // set user
    // @ts-ignore
    this.user = this.route.snapshot.parent.parent.data.user;
  }

}
