import {Component, OnDestroy, OnInit} from '@angular/core';
import {InvoiceType} from '../../../_types/invoice.type';
import {InvoicesService} from '../../../_services/invoices.service';
import {Subscription} from 'rxjs';
import {UserType} from '../../../_types/user.type';
import {ActivatedRoute} from '@angular/router';
import {CompanyType} from '../../../_types/company.type';
import {CompaniesService} from '../../../_services/companies.service';

@Component({
  selector: 'app-settings-invoices',
  templateUrl: './settings-invoices.component.html',
  styleUrls: ['./settings-invoices.component.scss']
})
export class SettingsInvoicesComponent implements OnInit, OnDestroy {

  invoices: InvoiceType[] = [];
  user: UserType;
  company: CompanyType | any;

  private subscriptions: Array<Subscription> = [];

  constructor(private invoicesService: InvoicesService,
              private companiesService: CompaniesService,
              private route: ActivatedRoute) {

    // set user
    // @ts-ignore
    this.user = this.route.snapshot.parent.parent.data.user;

    // get invoices
    this.getInvoices();

    // get company
    this.getCompany();
  }

  private getInvoices(): void {
    this.subscriptions.push(this.invoicesService.getInvoices(this.user).subscribe(invoices => {
      this.invoices = invoices;
    }));
  }

  private getCompany(): void {
    this.subscriptions.push(this.companiesService.getCompany(this.user.companyRef.path).subscribe((company: any) => {
      this.company = company;
    }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
