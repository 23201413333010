import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ChatsComponent} from '../chats.component';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../../_services/users.service';
import {UserType} from '../../../_types/user.type';
import {ChatsService} from '../../../_services/chats.service';
import {ChatType} from '../../../_types/chat.type';
import {NgForm} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import * as firebase from 'firebase/app';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
    selector: 'app-chat-details',
    templateUrl: './chat-details.component.html',
    styleUrls: ['./chat-details.component.scss']
})
export class ChatDetailsComponent implements OnInit {

    user: UserType;
    chat: ChatType;

    private subscriptions: Array<Subscription> = [];

    constructor(private chatsComponent: ChatsComponent,
                private chatsService: ChatsService,
                private usersService: UsersService,
                private route: ActivatedRoute,
                private router: Router,
                private afs: AngularFirestore,
                private progress: NgProgress,
                private snackBar: MatSnackBar) {


        // set params
        // this.subscriptions.push(this.route.params.subscribe(params => {
        //     this.chatsComponent.setNav(params.uid);
        //
        //     //get user
        //     this.subscriptions.push(this.usersService.user$.subscribe(user => {
        //         if (user) {
        //             this.user = user;
        //
        //             //get chat
        //             this.subscriptions.push(this.chatsService.getChat(`companies/${user.companyRef.id}/chats/${this.chatsComponent.uid}`).subscribe(chat => {
        //                 this.chat = chat;
        //                 this.chatsComponent.title = this.chat.name;
        //             }));
        //
        //         }
        //     }))
        // }))
    }

    ngOnInit() {
    }

    onSave(form: NgForm) {

        this.progress.ref().start();
        this.chat.name = form.value.name;
        this.chat.shortDesc = form.value.shortDesc;
        this.chat.dateUpdate = firebase.firestore.FieldValue.serverTimestamp();
        this.chat.userUpdateRef = this.afs.doc(`users/${this.user.uid}`).ref;

        // check duplicate
        this.subscriptions.push(this.chatsService.isExists(`companies/${this.user.companyRef.id}/chats/`, name).subscribe(chats => {
            // @ts-ignore
            if (!chats.length || (chats[0].name === name)) {
                // update chat
                this.chatsService.insertChat(this.chat, `companies/${this.user.companyRef.id}/chats/${this.chat.uid}`)
                    .then(() => {
                        this.snackBar.open('Chat has been updated', '', environment.snackbarSuccess);
                        this.progress.ref().complete();
                    }).catch(err => {
                    this.snackBar.open(err.message, '', environment.snackbarWarn);
                    this.progress.ref().complete();
                });
            }
            else {
                this.snackBar.open('Chat already exists', '', environment.snackbarWarn);
                this.progress.ref().complete();
            }
            this.subscriptions[this.subscriptions.length - 1].unsubscribe();
        }));
    }

    onRemove() {
        this.progress.ref().start();
        this.chat.isActive = false;
        this.chat.dateUpdate = firebase.firestore.FieldValue.serverTimestamp();
        this.chat.userUpdateRef = this.afs.doc(`users/${this.user.uid}`).ref;

        // update chat
        this.chatsService.insertChat(this.chat, `companies/${this.user.companyRef.id}/chats/${this.chat.uid}`);
        this.router.navigate(['/'])
            .then(() => {
                this.progress.ref().complete();
                this.snackBar.open('Chat has been removed', '', environment.snackbarSuccess);
            })
            .catch(err => {
                this.progress.ref().complete();
                this.snackBar.open(err.message, '', environment.snackbarWarn);
            });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
