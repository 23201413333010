<section class="page-section page-section--settings-details">
  <form (ngSubmit)="onSave(f)" #f="ngForm">
    <div class="row">
      <div class="col-4">
        <mat-card>
          <mat-card-content *ngIf="company">

            <div class="col-header">Details</div>
            <mat-form-field>
              <input matInput
                     type="text"
                     name="name"
                     [(ngModel)]="company.name"
                     #name="ngModel"
                     required
                     placeholder="Company name">
              <mat-error *ngIf="name.hasError('required')">
                Name is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input matInput
                     type="text"
                     name="tin"
                     [(ngModel)]="company.tin"
                     #tin="ngModel"
                     required
                     placeholder="Tax identification number">
              <mat-error *ngIf="tin.hasError('required')">
                Tax identification number is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <div class="col-header">Address</div>
            <mat-form-field>
              <input matInput
                     type="text"
                     name="streetNumber"
                     [(ngModel)]="company.streetNumber"
                     #streetNumber="ngModel"
                     required
                     placeholder="Street and number">
              <mat-error *ngIf="streetNumber.hasError('required')">
                Street and number is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input matInput
                     type="text"
                     name="zipCode"
                     [(ngModel)]="company.zipCode"
                     #zipCode="ngModel"
                     required
                     placeholder="Zip code">
              <mat-error *ngIf="zipCode.hasError('required')">
                Zip code is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input matInput
                     type="text"
                     name="city"
                     [(ngModel)]="company.city"
                     #city="ngModel"
                     required
                     placeholder="City">
              <mat-error *ngIf="city.hasError('required')">
                City is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <div class="col-header">Currency</div>
            <mat-form-field class="help-width-100p">
              <mat-select name="type"
                          placeholder="Currency"
                          name="currency"
                          [(ngModel)]="company.currency"
                          #currency="ngModel">
                <mat-option value="PLN">PLN</mat-option>
                <mat-option value="$">$</mat-option>
              </mat-select>
            </mat-form-field>

            <div class="col-header">E-mail domains</div>
            <div class="col-content col-domains">
              <mat-chip-list>
                <mat-chip *ngFor="let domain of company.domains">@{{domain}}</mat-chip>
              </mat-chip-list>
            </div>


            <button type="submit"
                    mat-raised-button color="primary"
                    [disabled]="f.form.invalid">Save
            </button>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>
</section>
