<section class="page-section">
    <form (ngSubmit)="onSave(f)" #f="ngForm">
        <div class="row">
            <div class="col-4">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field>
                            <input matInput
                                   type="password"
                                   name="password"
                                   #password="ngModel"
                                   ngModel
                                   required
                                   minlength="6"
                                   placeholder="Password"
                                   validateEqual="passwordConfirmation"
                                   reverse="true">
                            <mat-error *ngIf="password.invalid && (password.dirty || password.touched)">
                                <span *ngIf="password.hasError('required')">Password is required. </span>
                                <span *ngIf="password.hasError('minlength')">Password must be at least 6 characters long. </span>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <input matInput
                                   type="password"
                                   name="passwordNew"
                                   #passwordNew="ngModel"
                                   ngModel
                                   required
                                   minlength="6"
                                   placeholder="New password"
                                   validateEqual="passwordNewConfirmation"
                                   reverse="true">
                            <mat-error *ngIf="passwordNew.invalid && (passwordNew.dirty || passwordNew.touched)">
                                <span *ngIf="passwordNew.hasError('required')">New password is required. </span>
                                <span *ngIf="passwordNew.hasError('minlength')">New password must be at least 6 characters long. </span>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <input matInput
                                   type="password"
                                   name="passwordNewConfirmation"
                                   #passwordNewConfirmation="ngModel"
                                   ngModel
                                   required
                                   minlength="6"
                                   placeholder="New password confirmation"
                                   validateEqual="password">
                            <mat-error *ngIf="passwordNewConfirmation.value !== passwordNew.value">
                                <span>Passwords do not match. </span>
                            </mat-error>
                        </mat-form-field>

                        <button type="submit"
                                mat-raised-button color="primary"
                                [disabled]="f.form.invalid">Save
                        </button>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </form>
</section>