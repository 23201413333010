import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {PriceType} from '../_types/price.type';
import {UserType} from '../_types/user.type';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class PricesService {

  constructor(private afs: AngularFirestore) {
  }

  // get prices
  getPrices(user: UserType): Observable<PriceType[]> {
    return this.afs.collection<PriceType>(`companies/${user.companyRef.id}/prices`, ref => ref)
      .valueChanges();
  }

  // get prices for module
  getPricesForModule(path: string, moduleRef: any): Observable<PriceType[]> {
    return this.afs.collection<PriceType>(path, ref => ref
      .where(`moduleRef`, '==', moduleRef)).valueChanges();
  }
}
