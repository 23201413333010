import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthService} from '../../../_services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs/internal/Subscription';
import {NotificationsService} from '../../../_services/notifications.service';
import {FcmService} from '../../../_services/fcm.service';
import {AppComponent} from '../../../app.component';
import {UserType} from '../../../_types/user.type';
import {ChangelogType} from '../../../_types/changelog.type';
import {ChangelogService} from '../../../_services/changelog.service';
import {UsersService} from '../../../_services/users.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {SharedBottomsheetVersionComponent} from '../../../_shared/shared-bottomsheet-version/shared-bottomsheet-version.component';
import {environment} from '../../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-aside-nav',
  templateUrl: './aside-nav.component.html',
  styleUrls: ['./aside-nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AsideNavComponent implements OnInit, OnDestroy {

  user: UserType;
  log: ChangelogType;

  notificationsUnreadedCount: any;

  private subscriptionUser: Subscription;
  private subscriptionNotifications: Subscription;
  private subscriptionChangelog: Subscription;

  constructor(private notificationsService: NotificationsService,
              private usersService: UsersService,
              private snackBar: MatSnackBar,
              private changelogService: ChangelogService,
              private appComponent: AppComponent,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              // tslint:disable-next-line:variable-name
              private _bottomSheet: MatBottomSheet,
              private fcm: FcmService,
              // tslint:disable-next-line:variable-name
              @Inject(DOCUMENT) private _document: HTMLDocument) {

    // set user
    this.subscriptionUser = this.usersService.getUser().subscribe(user => {
      this.user = user;


      // get notifications
      this.getNotifications();

      // get last changelog
      this.getChangelogLast();

      // fcm (push notifications)
      this.fcm.getPermission(this.user);
    });
  }

  ngOnInit(): void {

  }

  private updateUser(): void {
    let page = '';
    if (this.user.changelog) {
      page = this.user.changelog.page;
    }

    this.usersService.updateUser({
      changelog: {
        bottomSheet: this.log.version,
        page
      }
    }, this.user.uid)
      .then(() => {
      })
      .catch(err => {
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  private getChangelogLast(): void {
    this.subscriptionChangelog = this.changelogService.getChangelogLast().subscribe((logs: ChangelogType[]) => {
      this.log = logs[0];

      if (!this.user.changelog || (this.user.changelog.bottomSheet !== this.log.version)) {
        this._bottomSheet.open(SharedBottomsheetVersionComponent);

        // set user that saw bottom sheet
        this.updateUser();
      }
    });
  }

  private getNotifications(): void {
    this.subscriptionNotifications = this.notificationsService.getNotificationsUnreaded(this.user, this.user)
      .subscribe(notifications => {
        this.notificationsUnreadedCount = notifications.length;
        if (this.notificationsUnreadedCount > 99) {
          this.notificationsUnreadedCount = '99+';
        }
        if (this.notificationsUnreadedCount > 0) {
          // @ts-ignore
          this._document.getElementById('shortcutIcon').setAttribute('href', 'assets/favicon/favicon-notification.ico');
        } else {
          // @ts-ignore
          this._document.getElementById('shortcutIcon').setAttribute('href', 'assets/favicon/favicon.ico');
        }
      });
  }

  onSettings(): void {
    this.router.navigate(['/profile/settings']).then();
  }

  onLogOut(): void {
    this.authService.logout().then(() => {
      this.router.navigate(['/login']).then();
    });
  }

  ngOnDestroy(): void {
    // subscriptions should always work

    // necessary to log out
    if (this.subscriptionUser) {
      this.subscriptionUser.unsubscribe();
    }
    if (this.subscriptionNotifications) {
      this.subscriptionNotifications.unsubscribe();
    }
    if (this.subscriptionChangelog) {
      this.subscriptionChangelog.unsubscribe();
    }
  }

}
