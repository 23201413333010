<section class="page-section">
    <form (ngSubmit)="onSave(f)" #f="ngForm" autocomplete="off" *ngIf="roles">
        <div class="row">
            <div class="col-4">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field *ngIf="userCurrent.name">
                            <input matInput
                                   type="text"
                                   name="name"
                                   [(ngModel)]="userCurrent.name"
                                   #name="ngModel"
                                   required
                                   placeholder="Name">
                            <mat-error *ngIf="name.hasError('required')">
                                Name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="userCurrent.role">
                            <mat-select placeholder="Role"
                                        name="role"
                                        #role="ngModel"
                                        [(ngModel)]="userCurrent.role">
                                <mat-option *ngFor="let role of roles" [value]="role">
                                    {{role.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button type="submit"
                                mat-raised-button color="primary"
                                [disabled]="f.form.invalid">Save
                        </button>
                        <a (click)="showRemove = !showRemove" class="link link--btn" *ngIf="!showRemove">Delete</a>
                        <span *ngIf="showRemove" class="link--btn">Are you sure? <a (click)="showRemove = false"
                                                                                    class="link">No</a>&nbsp;<a
                                (click)="onRemove()" class="link">Yes</a></span>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </form>
</section>
