import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

import {AuthComponent} from './auth.component';
import {LoginComponent} from './login/login.component';
import {PasswordForgotComponent} from './password-forgot/password-forgot.component';
import {RegisterComponent} from './register/register.component';
import {EmailComponent} from './email/email.component';

const routes: Routes = [
    {
        path: '', component: AuthComponent, children: [
            {path: 'login', component: LoginComponent},
            {path: 'password-forgot', component: PasswordForgotComponent},
            {path: 'register', component: RegisterComponent},
            {path: 'email', component: EmailComponent}
        ]
    },

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        CommonModule
    ],
    exports: [RouterModule],
    declarations: []
})
export class AuthRoutingModule {
}

