import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectsComponent} from '../projects.component';
import {Subscription} from 'rxjs';
import {UserType} from '../../../_types/user.type';
import {ProjectType} from '../../../_types/project.type';
import {ProjectsService} from '../../../_services/projects.service';
import {TaskType} from '../../../_types/task.type';
import {TasksService} from '../../../_services/tasks.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {SharedTaskDialogComponent} from '../../../_shared/shared-task-dialog/shared-task-dialog.component';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {formatDate} from '@angular/common';
import * as firebase from 'firebase/app';
import {environment} from '../../../../environments/environment';
import {MessageType} from '../../../_types/message.type';
import {NotificationType} from '../../../_types/notification.type';
import {MessagesService} from '../../../_services/messages.service';
import {NotificationsService} from '../../../_services/notifications.service';
import {NgForm} from '@angular/forms';
import {UsersService} from '../../../_services/users.service';
import {FcmService} from '../../../_services/fcm.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgProgress} from 'ngx-progressbar';

@Component({
  selector: 'app-project-schedule',
  templateUrl: './project-schedule.component.html',
  styleUrls: ['./project-schedule.component.scss']
})
export class ProjectScheduleComponent implements OnInit, OnDestroy {

  today = new Date();
  month: number;
  year: number;
  nextMonth: number;
  nextYear: number;
  prevMonth: number;
  prevYear: number;
  daysInMonth: number;
  searchTask: string;
  enterTaskUid = '';

  user: UserType;
  userTodo: any;
  project: ProjectType;
  task: TaskType = {
    uid: null,
    userTodoRef: null,
    userAuthorRef: null,
    name: null,
    description: null,
    projectRef: null,
    dateStart: new Date(),
    dateEnd: new Date(),
    showInMonths: null,
    showInDays: null,
    showInWeeks: null,
    type: 'feature',
    priority: 'normal',
    estimation: 1,
    dateUpdate: null,
    dateCreate: null,
    userUpdateRef: null,
    status: 'todo',
    isActive: true,
    files: []
  };

  tasks: TaskType[];
  tasksComplete = false;
  tasksNoDate: TaskType[];
  tasksNoDateComplete = false;
  tasksNoDateHours = 0;
  users: UserType[] = [];
  usersComplete = false;
  projects: ProjectType[];
  projectsComplete = false;
  days: Array<any> = [];
  maxTodoHours: boolean[] = [];
  maxOrderedHours: boolean[] = [];

  groups: any[] = [];

  private subscriptions: Array<Subscription> = [];
  private routeParamsSubscription: Subscription;

  constructor(public projectsComponent: ProjectsComponent,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private tasksService: TasksService,
              private messagesService: MessagesService,
              private usersService: UsersService,
              private fcmService: FcmService,
              private notificationsService: NotificationsService,
              private router: Router,
              private afs: AngularFirestore,
              private projectsService: ProjectsService,
              private progress: NgProgress,
              private snackBar: MatSnackBar) {

    // get params
    this.routeParamsSubscription = this.route.params.subscribe(params => {
      // reset values
      this.days = [];
      this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
      });
      this.subscriptions = [];
      this.task.dateStart.setHours(0, 0, 0, 0);
      this.task.dateEnd.setHours(0, 0, 0, 0);

      this.projectsComponent.setNav(params.uid);

      // set month and year
      this.month = parseInt(params.month, 10);
      this.year = parseInt(params.year, 10);
      this.today.setHours(0, 0, 0, 0);
      this.daysInMonth = new Date(this.year, this.month, 0).getDate();

      // navigate to schedule current month and year
      if ((!this.month) || (!this.year)) {
        this.router.navigate([`/projects/${params.uid}/schedule/${this.today.getFullYear()}/${this.today.getMonth() + 1}`]);
      }

      // set prev and next month
      this.nextMonth = this.month + 1;
      this.nextYear = this.year;
      if (this.nextMonth === 13) {
        this.nextMonth = 1;
        this.nextYear = this.year + 1;
      }

      this.prevMonth = this.month - 1;
      this.prevYear = this.year;
      if (this.prevMonth === 0) {
        this.prevMonth = 12;
        this.prevYear = this.year - 1;
      }

      // set user
      this.user = this.route.snapshot.parent.parent.data.user;

      // get project
      this.subscriptions.push(this.projectsService.getProject(this.user, this.projectsComponent.uid).subscribe(project => {
        this.project = project;
        this.projectsComponent.title = this.project.name;

        // get tasks
        this.tasksComplete = false;
        this.tasksNoDateComplete = false;
        this.getTasks();

        // get users
        this.usersComplete = false;
        this.getUsers();
      }));
    });
  }

  ngOnInit() {
  }

  private getUsers() {
    this.subscriptions.push(this.usersService.getUsers(this.user.companyRef).subscribe(users => {
      this.users = users;
      this.usersComplete = true;
      this.matchTasksToUsers();
    }));
  }

  private getTasks() {
    let yearMonth = this.year + '0' + this.month;
    if (this.month > 9) {
      yearMonth = this.year + '' + this.month;
    }

    // get tasks with date
    this.subscriptions.push(this.tasksService.getTasksProjectByMonth(`companies/${this.user.companyRef.id}/tasks`, yearMonth, this.afs.doc(`companies/${this.user.companyRef.id}/projects/${this.project.uid}`).ref).subscribe(tasks => {
      this.tasks = tasks;
      this.tasksComplete = true;
      this.matchTasksToUsers();
    }));

    // get tasks without date
    this.subscriptions.push(this.tasksService.getTasksProjectNoDate(`companies/${this.user.companyRef.id}/tasks`, this.afs.doc(`companies/${this.user.companyRef.id}/projects/${this.project.uid}`).ref).subscribe(tasks => {
      this.tasksNoDate = tasks;
      this.tasksNoDateComplete = true;
      this.tasksNoDateHours = 0;
      this.tasksNoDate.forEach(task => {
        this.tasksNoDateHours += task.estimation;
      });
      this.matchTasksToUsers();
    }));
  }

  private matchTasksToUsers() {
    if (this.tasksComplete && this.tasksNoDateComplete && this.usersComplete) {
      this.users.forEach(user => {
        // match tasks with date
        this.tasks.forEach(task => {
          if (user.uid === task.userTodoRef.id) {
            task.userTodo = user;
          }
        });

        // match tasks without date
        this.tasksNoDate.forEach(task => {
          if (user.uid === task.userTodoRef.id) {
            task.userTodo = user;
          }
        });
      });
      this.setCalendar();
      // this.setCalendarGroups();
    }
  }

  private setCalendar() {
    const helpMaxOrderedHours = 0;
    let helpStrDate, helpTask, helpMax = 0, helpMaxTodoHours = 0;
    this.maxTodoHours = [];
    this.maxOrderedHours = [];

    for (let i = 0; i < this.daysInMonth; i++) {
      const helpDate: Date = new Date(this.year, (this.month - 1), (i + 1));
      const helpDay: number = helpDate.getDay();

      this.days[i] = {
        date: helpDate,
        isToday: new Date(helpDate).getTime() === new Date(this.today).getTime() ? true : false,
        isWeekendStart: (helpDay === 6) ? true : false,
        isWeekendEnd: (helpDay === 0) ? true : false,
        tasks: [],
        hours: 0
      };

      // set tasks to-do
      helpMax = 0;
      for (let j = 0; j < this.tasks.length; j++) {
        helpStrDate = this.year;
        if (this.month < 10) {
          helpStrDate += '0' + this.month;
        } else {
          helpStrDate += '' + this.month;
        }

        if ((i + 1) < 10) {
          helpStrDate += '0' + (i + 1);
        } else {
          helpStrDate += (i + 1);
        }

        if (typeof (this.tasks[j].showInDays[helpStrDate]) !== 'undefined') {
          helpTask = Object.assign({}, this.tasks[j]);
          helpTask.estimationDay = helpTask.estimation / Object.keys(helpTask.showInDays).length;
          helpTask.taskStart = false;
          if (Object.keys(this.tasks[j].showInDays)[0] === helpStrDate) {
            helpTask.taskStart = true;
          }
          helpTask.taskEnd = false;
          if (Object.keys(this.tasks[j].showInDays)[Object.keys(helpTask.showInDays).length - 1] === helpStrDate) {
            helpTask.taskEnd = true;
          }
          this.days[i].tasks.push(helpTask);
          this.days[i].hoursTodo += helpTask.estimationDay;
          helpMax += helpTask.estimationDay;
          helpMaxTodoHours = Math.max(helpMaxTodoHours, helpMax);
        }
      }
      this.days[i].hoursTodo = Math.round(this.days[i].hoursTodo * 10) / 10;
    }

    for (let j = 1; j < helpMaxTodoHours; j++) {
      this.maxTodoHours.push(true);
    }

    for (let j = 1; j < helpMaxOrderedHours; j++) {
      this.maxOrderedHours.push(true);
    }
  }

  private setCalendarGroups() {
    // define groups
    this.groups = [];
    let exists: boolean;
    this.tasks.forEach(task => {
      exists = false;
      this.groups.forEach(group => {
        if (task.group === group.name) {
          exists = true;
        }
      });

      if (!exists) {
        this.groups.push({
          name: task.name
        });
      }
    });
  }

  private updateProject(project: ProjectType, userTodo: UserType) {
    // set access to project
    project.userRefs[userTodo.uid] = this.afs.doc(`users/${userTodo.uid}`).ref;

    // update project
    this.projectsService.updateProject({
      userRefs: project.userRefs
    }, this.user, project.uid).then();
  }

  private onUpdateTask(task, dragDate, dropDate) {
    // start progress bar
    this.progress.ref().start();
    // copy task
    const taskCopy = Object.assign({}, task);
    taskCopy.dateStart = Object.assign({}, task.dateStart);
    taskCopy.dateEnd = Object.assign({}, task.dateEnd);
    // define val
    let show: {
      days: {},
      months: {},
      weeks: {}
    };
    let message: string;
    let notification: string;

    // manually remove task from the current day and add task to the target day - avoid blinking
    this.removeAndAddTask(task, dragDate, dropDate);

    if (task.dateStart.seconds === task.dateEnd.seconds) {  // if task takes 1 day
      // change dateStart and dateEnd
      task.dateStart.seconds = dropDate.getTime() / 1000;
      task.dateEnd.seconds = dropDate.getTime() / 1000;
      message = `has changed date start: ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}<br>and date end: ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}`;
      notification = `</p>has changed project task <a class="link" routerLink="projects/${task.projectRef.id}/task/${task.uid}">${task.name}</a> <code class="code">date start: ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code><code class="code">date end: ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code></p>`;
    } else {    // if task takes more than one day
      if ((task.dateStart.seconds * 1000) === dragDate.getTime()) { // if drag'n'drop the first day of the task
        task.dateStart.seconds = dropDate.getTime() / 1000;
        if (task.dateStart.seconds <= task.dateEnd.seconds) {
          // prepare message and notification
          message = `has changed date start ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}`;
          notification = `</p>has changed project task <a class="link" routerLink="projects/${task.projectRef.id}/task/${task.uid}">${task.name}</a> <code class="code">date start: ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code></p>`;
        } else {
          task.dateStart.seconds = task.dateEnd.seconds;
          task.dateEnd.seconds = dropDate.getTime() / 1000;
          // prepare message and notification
          message = `has changed date start: ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}<br>and date end: ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}`;
          notification = `</p>has changed project task <a class="link" routerLink="projects/${task.projectRef.id}/task/${task.uid}">${task.name}</a> <code class="code">date start: ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code><code class="code">date end: ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code></p>`;
        }
      } else if ((task.dateEnd.seconds * 1000) === dragDate.getTime()) {  // if drag'n'drop the last day of the task
        task.dateEnd.seconds = dropDate.getTime() / 1000;
        if (task.dateEnd.seconds >= task.dateStart.seconds) {
          message = `has changed date end ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}`;
          notification = `</p>has changed project task <a class="link" routerLink="projects/${task.projectRef.id}/task/${task.uid}">${task.name}</a> <code class="code">date end: ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code></p>`;
        } else {
          task.dateEnd.seconds = task.dateStart.seconds;
          task.dateStart.seconds = dropDate.getTime() / 1000;
          // prepare message and notification
          message = `has changed date start: ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}<br>and date end: ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}`;
          notification = `</p>has changed project task <a class="link" routerLink="projects/${task.projectRef.id}/task/${task.uid}">${task.name}</a> <code class="code">date start: ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code><code class="code">date end: ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code></p>`;
        }
      } else {    // if drag'n'drop the middle day of the task
        // check if we change date backward or forward
        const diffBusinessDays = this.calcBusinessDays(dragDate, dropDate);
        if (diffBusinessDays > 0) {
          // check and remove weekend days
          task.dateStart.seconds = this.addBusinessDays(task.dateStart.seconds * 1000, diffBusinessDays) / 1000;
          task.dateEnd.seconds = this.addBusinessDays(task.dateEnd.seconds * 1000, diffBusinessDays) / 1000;
        } else {
          // minus business days
          task.dateStart.seconds = this.minusBusinessDays(task.dateStart.seconds * 1000, diffBusinessDays) / 1000;
          task.dateEnd.seconds = this.minusBusinessDays(task.dateEnd.seconds * 1000, diffBusinessDays) / 1000;
        }
        // prepare message and notification
        message = `has changed date start: ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}<br>and date end: ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}`;
        notification = `</p>has changed project task <a class="link" routerLink="projects/${task.projectRef.id}/task/${task.uid}">${task.name}</a> <code class="code">date start: ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code><code class="code">date end: ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code></p>`;
      }
    }
    show = this.calcShowInDaysMonthsWeeks(task.dateStart.seconds * 1000, task.dateEnd.seconds * 1000);

    this.tasksService.updateTask({
      dateStart: new Date(task.dateStart.seconds * 1000),
      dateEnd: new Date(task.dateEnd.seconds * 1000),
      showInDays: show.days,
      showInMonths: show.months,
      showInWeeks: show.weeks,
      dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
      userUpdateRef: this.afs.doc(`users/${this.user.uid}`).ref
    }, `companies/${this.user.companyRef.id}/tasks/${task.uid}`)
      .then(() => {
        // insert message
        this.insertMessage(
          message,
          task.uid
        );
        // calculate users which should be informed
        const informed = this.calcInformed(task);
        for (const informUser of Object.keys(informed)) {
          this.saveNotification(
            notification,
            informUser,
            task.uid
          );
        }
        // show snackbar
        this.snackBar.open('Task has been updated', '', environment.snackbarSuccess);
        // close progress bar
        this.progress.ref().complete();
      })
      .catch(err => {
        // show snackbar
        this.snackBar.open(err.message, '', environment.snackbarWarn);
        // close progress bar
        this.progress.ref().complete();
      });
  }

  // manually remove and add task - avoid blinking caused by delay for time needed to read data from firestore
  private removeAndAddTask(task, dragDate, dropDate) {
    this.days.forEach(day => {
      // add task
      if (day.date.getTime() === dropDate.getTime()) {
        day.tasks.push(task);
      }
      // remove task
      if (day.date.getTime() === dragDate.getTime()) {
        for (let i = 0; i < day.tasks.length; i++) {
          if (day.tasks[i].uid === task.uid) {
            day.tasks.splice(i, 1);
          }
        }
      }
    });
  }

  private calcInformed(task) {
    const informed = {};
    if (task.userAuthorRef.id !== this.user.uid) {
      informed[task.userAuthorRef.id] = true;
    }
    if (task.userTodoRef.id !== this.user.uid) {
      informed[task.userTodoRef.id] = true;
    }
    return informed;
  }

  private addBusinessDays(timestamp, days) {
    const date = new Date(timestamp);
    while (days > 0) {
      date.setDate(date.getDate() + 1);
      if (date.getDay() !== 6 && date.getDay() !== 0) {
        days--;
      }
    }
    return date.getTime();
  }

  private minusBusinessDays(timestamp, days) {
    const date = new Date(timestamp);
    while (days < 0) {
      date.setDate(date.getDate() - 1);
      if (date.getDay() !== 6 && date.getDay() !== 0) {
        days++;
      }
    }
    return date.getTime();
  }

  private calcBusinessDays = function (dDate1, dDate2) {
    let iWeeks, iDateDiff, iAdjust = 0, abs = true;
    if (dDate2 < dDate1) {
      const temp = dDate1;
      dDate1 = dDate2;
      dDate2 = temp;
      abs = false;
    }
    let iWeekday1 = dDate1.getDay(); // day of week
    let iWeekday2 = dDate2.getDay();
    iWeekday1 = (iWeekday1 === 0) ? 7 : iWeekday1; // change Sunday from 0 to 7
    iWeekday2 = (iWeekday2 === 0) ? 7 : iWeekday2;
    if ((iWeekday1 > 5) && (iWeekday2 > 5)) {
      iAdjust = 1;
    } // adjustment if both days on weekend
    iWeekday1 = (iWeekday1 > 5) ? 5 : iWeekday1; // only count weekdays
    iWeekday2 = (iWeekday2 > 5) ? 5 : iWeekday2;

    // calculate difference in weeks (1000mS * 60sec * 60min * 24hrs * 7 days = 604800000)
    iWeeks = Math.floor((dDate2.getTime() - dDate1.getTime()) / 604800000);

    if (iWeekday1 <= iWeekday2) {
      iDateDiff = (iWeeks * 5) + (iWeekday2 - iWeekday1);
    } else {
      iDateDiff = ((iWeeks + 1) * 5) - (iWeekday1 - iWeekday2);
    }

    iDateDiff -= iAdjust; // take into account both days on weekend

    if (!abs) {
      return -Math.abs(iDateDiff);
    } else {
      return (iDateDiff);
    }
  };

  private calcShowInDaysMonthsWeeks(dateStartTimestamp, dateEndTimestamp) {
    const dateStart = new Date(dateStartTimestamp);
    dateStart.setHours(0, 0, 0, 0);
    const dateEnd = new Date(dateEndTimestamp);
    dateEnd.setHours(0, 0, 0, 0);
    const show = {
      days: {},
      months: {},
      weeks: {}
    };
    let firstJanuary: Date;
    for (const incDate = new Date(dateStart);
         incDate <= new Date(dateEnd);
         incDate.setDate(incDate.getDate() + 1)) {
      // show in months
      show.months[incDate.getFullYear() + '' + ('0' + (incDate.getMonth() + 1)).slice(-2)] = true;

      // check weekend days
      if ((incDate.getDay() !== 6) && (incDate.getDay() !== 0)) {
        // show in days
        show.days[incDate.getFullYear() + '' + ('0' + (incDate.getMonth() + 1)).slice(-2) + '' + ('0' + incDate.getDate()).slice(-2)] = true;
      }

      // show in weeks
      firstJanuary = new Date(incDate.getFullYear(), 0, 1);
      show.weeks[incDate.getFullYear() + '' + Math.ceil((((incDate.getTime() - firstJanuary.getTime()) / 86400000) + firstJanuary.getDay() + 1) / 7)] = true;
    }
    return show;
  }

  private insertMessage(messageMessage: string, taskUid: string, type: string = 'auto') {
    let message: MessageType;
    message = {
      uid: this.afs.createId(),
      message: messageMessage,
      dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
      userRef: this.afs.doc(`users/${this.user.uid}`).ref,
      files: [],
      type: type
    };
    this.messagesService.insertMessage(message, `companies/${this.user.companyRef.id}/tasks/${taskUid}/messages/${message.uid}`)
      .then(() => {
      })
      .catch(err => {
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  private saveNotification(message: string, userId: string, taskUid: string) {
    const notification: NotificationType = {
      uid: this.afs.createId(),
      message: message,
      dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
      dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
      userAuthorRef: this.afs.doc(`users/${this.user.uid}`).ref,
      userInformedRef: this.afs.doc(`users/${userId}`).ref,
      elementUid: taskUid,
      elementType: 'projectTask',
      view: 'info',
      isUnread: true
    };
    const subscriptionNotification = {};

    subscriptionNotification[userId] = this.notificationsService
      .getNotificationsToMerge(`companies/${this.user.companyRef.id}/notifications/`, notification)
      .subscribe(notifications => {
        if (notifications.length) {
          this.notificationsService.updateNotification({
            message: notifications[0].message + message,
            dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            userInformedRef: this.afs.doc(`users/${userId}`).ref,
          }, `companies/${this.user.companyRef.id}/notifications/${notifications[0].uid}`).then();
        } else {
          this.notificationsService
            .insertNotification(notification, `companies/${this.user.companyRef.id}/notifications/${notification.uid}`)
            .then(() => {
            })
            .catch(err => {
              this.snackBar.open(err.message, '', environment.snackbarWarn);
            });
        }
        subscriptionNotification[userId].unsubscribe();
      });
  }

  onDateRemove() {
    this.task.dateEnd = '';
    this.task.dateStart = '';
  }

  dropTask(event: CdkDragDrop<string[]>) {
    const task: TaskType = event.item.data;
    const drag: any = event.previousContainer.data;
    const drop: any = event.container.data;
    if (drag.date !== drop.date) {
      this.onUpdateTask(task, drag.date, drop.date);
    }
  }

  autocompleteDisplay(userTodo?: UserType): string | undefined {
    return userTodo ? userTodo.name : undefined;
  }

  autocompleteBlur() {
    if (typeof this.userTodo === 'string') {
      this.users.forEach(user => {
        if (user.name === this.userTodo) {
          this.userTodo = user;
        }
      });
    }
  }

  autocompleteSelected(selected) {
    if (typeof this.project === 'string') {
      this.projects.forEach(project => {
        if (project === selected) {
          this.project = project;
        }
      });
    }
  }

  filterWeekend = (d: Date): boolean => {
    let day;
    if (d) {
      day = d.getDay();
    }
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  };

  dateStartChange() {
    if (this.task.dateEnd.getTime() < this.task.dateStart.getTime()) {
      this.task.dateEnd = this.task.dateStart;
    }
  }

  dateEndChange() {
    if (this.task.dateEnd.getTime() < this.task.dateStart.getTime()) {
      this.task.dateStart = this.task.dateEnd;
    }
  }

  onOpenDialog(task) {
    this.dialog.open(SharedTaskDialogComponent, {
      height: '100vh',
      autoFocus: false,
      data: {
        task: task,
        user: this.user
      }
    });
  }

  onEnterTask(task) {
    this.enterTaskUid = task.uid;
  }

  onLeaveTask() {
    this.enterTaskUid = '';
  }

  onSaveTask(form: NgForm) {
    if (form.valid) {
      this.progress.ref().start();

      let show: {
        days: {},
        months: {},
        weeks: {}
      };
      show = this.calcShowInDaysMonthsWeeks(new Date(this.task.dateStart).getTime(), new Date(this.task.dateEnd).getTime());

      // insert task
      const task: TaskType = {
        uid: this.afs.createId(),
        userTodoRef: this.afs.doc(`users/${this.userTodo.uid}`).ref,
        userAuthorRef: this.afs.doc(`users/${this.user.uid}`).ref,
        name: form.value.name,
        description: '',
        projectRef: this.afs.doc(`companies/${this.user.companyRef.id}/projects/${this.project.uid}`).ref,
        dateStart: this.task.dateStart,
        dateEnd: this.task.dateEnd,
        showInMonths: show.months,
        showInDays: show.days,
        showInWeeks: show.weeks,
        type: this.task.type,
        priority: 'normal',
        estimation: this.task.estimation,
        dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
        dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
        userUpdateRef: this.afs.doc(`users/${this.user.uid}`).ref,
        status: 'todo',
        isActive: true,
        files: []
      };
      this.tasksService.insertTask(task, `companies/${this.user.companyRef.id}/tasks/${task.uid}`)
        .then(() => {

          // insert message
          this.insertMessage(
            `has created the task`,
            task.uid
          );

          // open dialog with task details
          task.dateStart = {
            seconds: new Date(task.dateStart).getTime() / 1000
          };
          task.dateEnd = {
            seconds: new Date(task.dateEnd).getTime() / 1000
          };

          // get project to show in dialog
          this.subscriptions.push(this.projectsService.getProject(this.user, task.projectRef.id).subscribe(project => {
            task.project = project;
          }));
          this.onOpenDialog(task);

          // inform users
          const informed = this.calcInformed(task);
          for (const informUser of Object.keys(informed)) {
            // insert notification
            this.saveNotification(
              `<p>has created project task <a class="link" routerLink="projects/${this.project.uid}/task/${task.uid}">${task.name}</a></p>`,
              informUser,
              task.uid
            );
            // send push notification
            this.subscriptions.push(this.usersService.getUserByRef(this.afs.doc(`users/${informUser}`).ref).subscribe(user => {
              this.fcmService.sendMessage(user, {
                title: `New task has been created`,
                body: `${this.user.name} has created the task ${task.name}`,
                click_action: `${environment.url}/notifications`
              });
            }));
          }

          // update project: estimation real, users in team
          this.updateProject(this.project, this.userTodo);

          // show snackbar and complete progress
          this.snackBar.open('Task has been created', '', environment.snackbarSuccess);
          this.progress.ref().complete();
        })
        .catch(err => {
          this.snackBar.open(err.message, '', environment.snackbarWarn);
          this.progress.ref().complete();
        });
      form.resetForm({
        userTodo: form.value.userTodo,
        dateStart: form.value.dateStart,
        dateEnd: form.value.dateEnd,
        estimation: form.value.estimation,
        type: form.value.type
      });

    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.routeParamsSubscription.unsubscribe();
  }

}
