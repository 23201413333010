import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ProjectsComponent} from '../projects.component';
import {ActivatedRoute} from '@angular/router';
import {UserType} from '../../../_types/user.type';
import {ProjectType} from '../../../_types/project.type';
import {ProjectsService} from '../../../_services/projects.service';
import {RoleType} from '../../../_types/role.type';
import {RolesService} from '../../../_services/roles.service';
import {TasksService} from '../../../_services/tasks.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {UsersService} from '../../../_services/users.service';
import {TaskType} from '../../../_types/task.type';
import {environment} from '../../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-project-stats',
  templateUrl: './project-stats.component.html',
  styleUrls: ['./project-stats.component.scss']
})
export class ProjectStatsComponent implements OnInit, OnDestroy {

  user: UserType;
  project: ProjectType;
  projectComplete = false;
  roles: RoleType[];
  rolesComplete = false;
  roleEstimationsMax = 0;
  users: UserType[];
  tasks: TaskType[];

  usersComplete = false;
  tasksComplete = false;

  private subscriptions: Array<Subscription> = [];

  constructor(private projectsComponent: ProjectsComponent,
              private route: ActivatedRoute,
              private projectsService: ProjectsService,
              private usersService: UsersService,
              private tasksService: TasksService,
              private afs: AngularFirestore,
              private rolesService: RolesService) {

    // set params
    this.subscriptions.push(this.route.params.subscribe(params => {
      this.projectsComponent.setNav(params.uid);

      // set user
      this.user = this.route.snapshot.parent.parent.data.user;

      // get tasks to update stats
      this.getTasks();

      // get users to update stats
      this.getUsers();

      // get roles
      this.getRoles();
    }));
  }

  ngOnInit() {
  }

  private getProject() {
    this.projectsService.getProjectOnce(this.user, this.projectsComponent.uid).then(project => {
      this.project = project.data() as ProjectType;
      this.projectsComponent.title = this.project.name;
      this.projectComplete = true;
      this.setRolesEstimations();
    });
  }

  private getUsers() {
    this.users = [];
    this.usersService.onceUsers(this.user.companyRef).then(users => {
      users.forEach(userSnapshot => {
        const user = userSnapshot.data() as UserType;
        this.users.push(user);
      });
      this.usersComplete = true;
      this.updateProject();
    });
  }

  private getTasks() {
    this.tasks = [];
    this.tasksService.onceTasksProject(`companies/${this.user.companyRef.id}/tasks`, this.afs.doc(`companies/${this.user.companyRef.id}/projects/${this.projectsComponent.uid}`).ref).then(tasks => {
      tasks.forEach(taskSnapshot => {
        const task = taskSnapshot.data() as TaskType;
        this.tasks.push(task);
      });
      this.tasksComplete = true;
      this.updateProject();
    });
  }

  private getRoles() {
    this.roles = [];
    this.rolesService.getRolesOnce(this.user).then(roles => {
      roles.forEach(roleSnapshot => {
        const role = roleSnapshot.data() as RoleType;
        this.roles.push(role);
      });
      this.rolesComplete = true;
      this.setRolesEstimations();
    });
  }

  private setRolesEstimations() {
    if (this.projectComplete && this.rolesComplete) {
      this.roleEstimationsMax = 0;
      this.roles.forEach(role => {
        role.estimations = 0;
        if (this.project.estimations[role.uid]) {
          role.estimations = this.project.estimations[role.uid];
        }
        role.estimationsReal = {
          todoFeature: 0,
          todoBug: 0,
          todoCheck: 0,
          inProgressFeature: 0,
          inProgressBug: 0,
          inProgressCheck: 0,
          toCheckFeature: 0,
          toCheckBug: 0,
          toCheckCheck: 0,
          doneFeature: 0,
          doneBug: 0,
          doneCheck: 0
        };
        if (this.project.estimationsReal && this.project.estimationsReal[role.uid]) {
          role.estimationsReal = this.project.estimationsReal[role.uid];
        }
        role.estimationsRealTotal = 0;
        if (typeof role.estimationsReal === 'object') {
          for (const est of Object.keys(role.estimationsReal)) {
            role.estimationsRealTotal += role.estimationsReal[est];
          }
        }
        this.roleEstimationsMax = Math.max(this.roleEstimationsMax, role.estimations, role.estimationsRealTotal);
      });
    }
  }

  private updateProject() {
    if (this.usersComplete && this.tasksComplete) {

      // update project
      const estimationsReal = {};

      this.tasks.forEach(task => {
        this.users.forEach(user => {
          if (user.uid === task.userTodoRef.id) {
            if (!estimationsReal[user.roleRef.id]) {
              estimationsReal[user.roleRef.id] = {};
            }
            if (!estimationsReal[user.roleRef.id][task.status + this.uppercaseFirstLetter(task.type)]) {
              estimationsReal[user.roleRef.id][task.status + this.uppercaseFirstLetter(task.type)] = task.estimation;
            } else {
              estimationsReal[user.roleRef.id][task.status + this.uppercaseFirstLetter(task.type)] += task.estimation;
            }
          }
        });
      });

      // update project
      this.projectsService.updateProject({
        estimationsReal: estimationsReal
      }, this.user, this.projectsComponent.uid).then(() => {
        // get project with updated data
        this.getProject();
      });
    }
  }

  private uppercaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
