import {Component, OnInit} from '@angular/core';
import {UsersService} from '../../_services/users.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgForm} from '@angular/forms';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-password-forgot',
    templateUrl: './password-forgot.component.html',
    styleUrls: ['./password-forgot.component.scss']
})
export class PasswordForgotComponent implements OnInit {

    constructor(private usersService: UsersService,
                private snackBar: MatSnackBar) {
    }

    ngOnInit() {
    }

    onSend(form: NgForm) {
        if (form.valid) {
            this.usersService.sendPasswordResetEmail(form.value.email).then(() => {
                // Email sent
                this.snackBar.open('E-mail with instruction has been sent', '', environment.snackbarSuccess);
                form.resetForm();

            }).catch(err => {
                // An error happened.
                this.snackBar.open(err.message, '', environment.snackbarWarn);
            });
        }
    }

}
