import {Component, OnDestroy, OnInit} from '@angular/core';
import {UsersService} from '../../_services/users.service';
import {UserType} from '../../_types/user.type';
import {ActivatedRoute} from '@angular/router';
import {RoleType} from '../../_types/role.type';
import {RolesService} from '../../_services/roles.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  users: UserType[];
  usersComplete = false;
  roles: RoleType[];
  user: UserType;

  private subscriptions: Array<Subscription> = [];

  constructor(private usersService: UsersService,
              private rolesService: RolesService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.user = this.route.snapshot.parent.data.user;

    // get users
    if (this.user._privileges.canGetUser || this.user._rolesName === 'admin') {
      this.getUsers();
    }
  }

  private getUsers() {
    this.subscriptions.push(this.usersService.getUsers(this.user.companyRef).subscribe(users => {
      this.users = users;
      this.usersComplete = true;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
