<section class="page-section">
    <form (ngSubmit)="onSave(f)" #f="ngForm" autocomplete="off">
        <mat-card>
            <mat-card-content>
                <div class="row">
                    <div class="col-4">
                        <div class="col-header">Details</div>
                        <mat-form-field>
                            <input matInput
                                   type="text"
                                   name="name"
                                   #name="ngModel"
                                   ngModel
                                   required
                                   placeholder="Name">
                            <mat-error *ngIf="name.hasError('required')">
                                Name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <input matInput
                                   type="text"
                                   placeholder="Client"
                                   name="client"
                                   [(ngModel)]="client"
                                   appForbiddenType="string"
                                   (blur)="autocompleteBlur()"
                                   required
                                   [matAutocomplete]="autoClients">
                            <mat-autocomplete
                                    autoActiveFirstOption
                                    #autoClients="matAutocomplete"
                                    [displayWith]="autocompleteDisplay"
                                    (optionSelected)="autocompleteSelected($event.option.value)">
                                <mat-option *ngFor="let client of clients | filter:client:['name']" [value]="client">
                                    {{client.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error>
                                Client is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <input matInput
                                   type="text"
                                   name="shortDesc"
                                   #shortDesc="ngModel"
                                   ngModel
                                   placeholder="Short description">
                        </mat-form-field>

                        <mat-form-field>
                            <textarea matInput
                                      name="notes"
                                      #notes="ngModel"
                                      ngModel
                                      matTextareaAutosize
                                      placeholder="Notes"></textarea>
                        </mat-form-field>
                    </div>

                    <div class="col-4" *ngIf="roles">
                        <div class="col-header">Estimations</div>
                        <mat-form-field *ngFor="let role of roles">
                            <input matInput
                                   type="number"
                                   name="estimations{{[role.uid]}}"
                                   #estimations{{[role.uid]}}="ngModel"
                                   [(ngModel)]="estimations[role.uid]"
                                   min="0"
                                   placeholder="{{ role.name }} estimation (h)">
                        </mat-form-field>
                    </div>

                    <div class="col-4">
                        <div class="col-header">Deadline</div>
                        <mat-form-field>
                            <input name="deadline"
                                   matInput
                                   [matDatepicker]="picker"
                                   ngModel
                                   placeholder="Choose a date"
                                   #deadline="ngModel"
                                   readonly>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>

                <button type="submit"
                        mat-raised-button
                        color="primary"
                        [disabled]="f.form.invalid">Save
                </button>
            </mat-card-content>
        </mat-card>
    </form>
</section>
