// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBWjJeq_DxwlpMEm1oKUYGXbd7cYNmOCZ0',
    authDomain: 'dev-soteam.firebaseapp.com',
    databaseURL: 'https://dev-soteam.firebaseio.com',
    projectId: 'dev-soteam',
    storageBucket: 'dev-soteam.appspot.com',
    messagingSenderId: '445507391315',
    appId: '1:445507391315:web:d58578d7b5bbac8693dba6'
  },
  url: 'https://dev.so.team',
  serverKey: 'AAAAZ7pPT1M:APA91bFBQEeMLu5dFCOTAAQIpNccELQ4OvTb3BPsB3i2iht_hVwr_UIBa8XyDjsiwCyi-4b7AXiZmh2QhTcF0a5D_eAb9Owh4fW4HWK-79ozx_bEOFPPP09FrIJERrazOj0xdN04ivGf',
  pushKey: 'BM0nn-koPkbTBa8iWwisVlewdiBfsE4PhVjncTaOOqHiH-r1qSQTU2C3zPBoG0c31WzlVDpMX5Dv8csKc2AUP0o',
  snackbarSuccess: {
    panelClass: 'success',
    verticalPosition: 'top' as 'top' | 'bottom',
    duration: 3000
  },
  snackbarWarn: {
    panelClass: 'warn',
    verticalPosition: 'top' as 'top' | 'bottom',
    duration: 3000
  },
  snackbarInfo: {
    panelClass: 'info',
    verticalPosition: 'top' as 'top' | 'bottom',
    duration: 3000
  }
};

// export const environment = {
//   production: true,
//   firebase: {
//     apiKey: 'AIzaSyDz1RUOwLy8TAACJnHVwp4dUoVlB0CDRoU',
//     authDomain: 'app3-teamtrack-io.firebaseapp.com',
//     databaseURL: 'https://app3-teamtrack-io.firebaseio.com',
//     projectId: 'app3-teamtrack-io',
//     storageBucket: 'app3-teamtrack-io.appspot.com',
//     messagingSenderId: '69100308992'
//   },
//   url: 'https://app.so.team',
// tslint:disable-next-line:max-line-length
//   serverKey: 'AAAAEBazCgA:APA91bHms0tA_J0uGfyngh9lkw9VWKElj_MOmGiEOet2WjQI-qc9fnf7QeERmLPffpgjLmF49jhqLzaD_03KaAS3p6PNAN6TwBY0eH1vWBiHEaa64IERPmeUIYG5-x5ca8rHFqGkuoDM',
//   pushKey: 'BE0zfUTmJP11Xt43ZOPPFii8Csy7sDCfVPbH3bT0hq5KaZwCI-aQrT0pVwsEoB14Ha2vQQLidwJ2t_qwhHXVVXk',
//   snackbarSuccess: {
//     panelClass: 'success',
//     verticalPosition: <'top' | 'bottom'> 'top',
//     duration: 3000
//   },
//   snackbarWarn: {
//     panelClass: 'warn',
//     verticalPosition: <'top' | 'bottom'> 'top',
//     duration: 3000
//   },
//   snackbarInfo: {
//     panelClass: 'info',
//     verticalPosition: <'top' | 'bottom'> 'top',
//     duration: 3000
//   }
// };


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
