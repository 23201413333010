<main>
  <div class="container">
    <div class="row">
      <div class="col">
        <header class="page-header">
          <h1>Notifications</h1>
          <div class="page-header-settings">
            <div class="element" *ngIf="notifications.length && isUnreadExists">
              <a (click)="showRead = !showRead" class="link" *ngIf="!showRead">Read all</a>
              <span *ngIf="showRead" class="element">Are you sure? <a (click)="showRead = false"
                                                                      class="link">No</a>&nbsp;<a
                (click)="onReadAll()" class="link">Yes</a></span>
            </div>
            <div class="element" *ngIf="notifications.length">
              <a (click)="showDelete = !showDelete" class="link" *ngIf="!showDelete">Delete all</a>
              <span *ngIf="showDelete">Are you sure? <a (click)="showDelete = false" class="link">No</a>&nbsp;<a
                (click)="onRemoveAll()" class="link">Yes</a></span>
            </div>

          </div>
        </header>
        <section class="page-section">
          <mat-list *ngIf="notifications.length && notificationsComplete && usersComplete" role="list" class="mat-list--notifications">
            <mat-list-item *ngFor="let notification of notifications">

              <!-- date nad time -->
              <div class="dateTime"
                   [ngClass]="{'dateTime--info': (notification.view == 'info'), 'dateTime--warn': (notification.view == 'warn'), 'dateTime--success': (notification.view == 'sucess')}">
                {{ notification.dateCreate.toDate() | date: 'yyyy-MM-dd HH:mm'}}
              </div>

              <!-- IF author is soteam user -->
              <a matLine class="mat-line--author" *ngIf="notification.author && notification.author.uid"
                 routerLink="/users/{{ notification.author.uid }}">
                <div class="avatar-name">
                  <div matListAvatar
                       *ngIf="notification.author.photoURL"
                       [ngStyle]="{'background-image': 'url(' + notification.author.photoURL + ')'}"></div>
                  <div matListAvatar
                       routerLink="/users/{{ notification.author.uid }}"
                       *ngIf="!notification.author.photoURL">
                    {{ notification.author.name | avatar }}
                  </div>
                  <div>
                    <div class="mat-line--name">
                      <div>{{ notification.author.name }} <small>{{ notification.author._rolesName }}</small></div>
                    </div>
                  </div>
                </div>
              </a>

              <!-- OR author is just string in DB -->
              <div matLine class="mat-line--author" *ngIf="notification.author && !notification.author.uid">
                <div class="avatar-name">
                  <div matListAvatar>
                    {{ notification.author | avatar }}
                  </div>
                  <div>
                    <div class="mat-line--name">
                      <div>{{ notification.author }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- message -->
              <div matLine class="mat-line--message">
                <div class="message" [innerHTML]="notification.message | safeHtml"
                     (click)="onLink($event)"></div>
              </div>

              <!-- buttons -->
              <div class="buttons">
                <button *ngIf="notification.isUnread"
                        mat-icon-button
                        (click)="onRead(notification)">
                  <mat-icon>mail_outline</mat-icon>
                </button>
                <button *ngIf="!notification.isUnread"
                        mat-icon-button
                        (click)="onUnread(notification)">
                  <mat-icon>markunread</mat-icon>
                </button>
                <button mat-icon-button
                        (click)="onRemove(notification)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>

            </mat-list-item>
          </mat-list>
          <div *ngIf="!notifications.length">You don't have any notifications</div>
        </section>
      </div>
    </div>
  </div>
</main>
