import {Component, EventEmitter, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UsersComponent} from '../users.component';
import {Subscription} from 'rxjs/internal/Subscription';
import {UsersService} from '../../../_services/users.service';
import {UserType} from '../../../_types/user.type';
import {AngularFirestore} from '@angular/fire/firestore';
import {MessageType} from '../../../_types/message.type';
import {FileType} from '../../../_types/file.type';
import {environment} from '../../../../environments/environment';
import {ChatsService} from '../../../_services/chats.service';
import {UploadOutput, UploadInput, UploadFile, UploaderOptions} from 'ngx-uploader';
import {NotificationsService} from '../../../_services/notifications.service';
import {FcmService} from '../../../_services/fcm.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-user-chat',
    templateUrl: './user-chat.component.html',
    styleUrls: ['./user-chat.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserChatComponent implements OnInit, OnDestroy {

    userCurrent: UserType | any;
    user: UserType | any;

    messages: MessageType[] = [];
    users: UserType[] = [];
    messageText: string | any;
    messageFiles: FileType[] = [];

    options: UploaderOptions | any;
    files: UploadFile[] = [];
    uploadInput: EventEmitter<UploadInput> | any;
    dragOver: boolean | any;

    chatUid: string | any;

    private subscriptions: Array<Subscription> = [];

    constructor(private usersComponent: UsersComponent,
                private usersService: UsersService,
                private chatsService: ChatsService,
                private afs: AngularFirestore,
                private snackBar: MatSnackBar,
                private route: ActivatedRoute) {

    }

    ngOnInit(): void {
        // get params
        this.subscriptions.push(this.route.params.subscribe(params => {
            // get current user
            this.subscriptions.push(this.usersService.getUserByRef(this.afs.doc(`users/${params.uid}`).ref).subscribe(userCurrent => {
                this.userCurrent = userCurrent as UserType;
                this.usersComponent.title = this.userCurrent.name;

                // set user
                // @ts-ignore
                this.user = this.route.snapshot.parent.parent.data.user;

                // get params
                this.subscriptions.push(this.route.params.subscribe(params2 => {
                    this.usersComponent.setNavInternal(params2.uid);
                }));

                // set chatUid
                this.setChatUid();

                // get messages
                this.getMessages();

                // get users
                this.getUsers();
            }));
        }));
    }

    setChatUid(): void {
        if (this.user.uid < this.userCurrent.uid) {
            this.chatUid = `${this.user.uid}-${this.userCurrent.uid}`;
        } else {
            this.chatUid = `${this.userCurrent.uid}-${this.user.uid}`;
        }
    }

    getMessages(): void {
        this.subscriptions.push(
          this.chatsService.getMessages(`companies/${this.user.companyRef.id}/chats/${this.chatUid}/messages`
          ).subscribe(messages => {
            this.messages = messages;
            this.matchMessagesToUsers();
        }));
    }

    getUsers(): void {
        this.subscriptions.push(this.usersService.getUsers(this.user.companyRef).subscribe(users => {
            this.users = users;
            this.matchMessagesToUsers();
        }));
    }

    // onSend(form: NgForm) {
        // //save notification & send push message
        // let informedIds = this.calculateInformed();
        // for (let id in informedIds) {
        //     //check current URL
        //     if (this.userCurrent.currentUrl !== `/users/${this.user.uid}/chat`) {
        //         //save notification
        //         this.saveNotification({
        //             message:`<p>has send you the direct message
        //             <a class="link" routerLink="users/${this.user.uid}/chat">${form.value.messageText}</a></p>`,
        //             informedId: id
        //         })
        //
        //         //send push
        //         this.sendPush({
        //             message: {
        //                 title: `New direct message`,
        //                 body: `${this.user.name} has send you the message ${form.value.messageText}`,
        //                 click_action: `${environment.url}/notifications`
        //             },
        //             informedId: id
        //         });
        //     }
        // }
        //
        // let message: MessageType;
        // message = {
        //     uid: this.afs.createId(),
        //     message: form.value.messageText,
        //     dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
        //     userRef: this.afs.doc(`users/${this.user.uid}`).ref,
        //     files: this.messageFiles,
        //     type: 'user'
        // }
        // this.chatsService.insertMessage(message, `companies/${this.user.companyRef.id}/chats/${this.chatUid}/messages/${message.uid}`)
        //     .then(() => {
        //         this.messageFiles = [];
        //     })
        //     .catch(err => {
        //         this.snackBar.open(err.message, '', environment.snackbarWarn);
        //     })
        //
        // //reset form
        // form.resetForm();
    // }

    onDeleteFile(index: number): void {
        this.messageFiles.splice(index, 1);
    }

    onUploadOutput(output: UploadOutput): void {

        if (output.type === 'allAddedToQueue') { // when all files added in queue
            this.startUpload();
        } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
            this.files.push(output.file);
        } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
            console.log('uploading');

            // update current data in files array for uploading file
            const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
            this.files[index] = output.file;
        } else if (output.type === 'removed') {
            console.log('removed');
            // remove file from array when removed
            this.files = this.files.filter((file: UploadFile) => file !== output.file);
        } else if (output.type === 'dragOver') {
            this.dragOver = true;
        } else if (output.type === 'dragOut') {
            this.dragOver = false;
        } else if (output.type === 'drop') {
            this.dragOver = false;
        }
    }

    startUpload(): void {
        for (const file of this.files) {
            // start progress bar
            // this.progress.ref().start();
            // show snackbar
            this.snackBar.open(`Uploading file(s)`, '', environment.snackbarInfo);
            // prepare file
            const fileNameArray = file.name.split('.');
            const name = this.afs.createId() + '.' + fileNameArray[fileNameArray.length - 1];
            const path = `/${this.user.companyRef.id}/chats/${this.chatUid}/${name}`;
            this.chatsService.uploadFile(file.nativeFile, path).then((snapshot: any) => {
                // push file to message
                snapshot.ref.getDownloadURL().then((downloadURL: any) => {
                    // push file to array
                    this.messageFiles.push({
                        name: file.name,
                        url: downloadURL,
                        type: file.type,
                        size: file.size,
                        isActive: true
                    });
                    // close progress bar
                    // this.progress.ref().complete();
                });
            });
        }
        // clear files
        this.files = [];
    }

    cancelUpload(id: string): void {
        this.uploadInput.emit({type: 'cancel', id});
    }

    removeFile(id: string): void {
        this.uploadInput.emit({type: 'remove', id});
    }

    removeAllFiles(): void {
        this.uploadInput.emit({type: 'removeAll'});
    }

    private matchMessagesToUsers(): void {
        if (this.messages.length && this.users.length) {
            this.messages.forEach(message => {
                this.users.forEach(user => {
                    if (user.uid === message.userRef.id) {
                        message.author = user;
                    }
                });
            });
            this.scrollContainerToBtm();
        }
    }

    private scrollContainerToBtm(): void {
        const cnt = document.getElementById('messages--received');

        // @ts-ignore
        cnt.scrollTop = cnt.scrollHeight;
    }

    private calculateInformed(): any {
        const informed = {};
        // @ts-ignore
        informed[this.userCurrent.uid] = true;
        return informed;
    }

    // private saveNotification(messageInformed) {
    //     let notification: NotificationType = {
    //         uid: this.afs.createId(),
    //         message: messageInformed.message,
    //         dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
    //         userAuthorRef: this.afs.doc(`users/${this.user.uid}`).ref,
    //         userInformedRef: this.afs.doc(`users/${messageInformed.informedId}`).ref,
    //         elementUid: this.chatUid,
    //         elementType: 'directMessage',
    //         view: 'info',
    //         isUnread: true
    //     }
    //     let subscriptionNotification = {};
    //
    //     subscriptionNotification[messageInformed.informedId] =
    //     this.notificationsService.getNotificationsToMerge(`companies/${this.user.companyRef.id}/notifications/`, notification)
    //     .subscribe(notifications => {
    //         if (notifications.length) {
    //             let messageAditional = notification.message;
    //             notification = notifications[0];
    //             notification.message += messageAditional;
    //         }
    //         this.notificationsService
    //         .insertNotification(notification, `companies/${this.user.companyRef.id}/notifications/${notification.uid}`)
    //             .then(() => {
    //             })
    //             .catch(err => {
    //                 this.snackBar.open(err.message, '', environment.snackbarWarn);
    //             })
    //         subscriptionNotification[messageInformed.informedId].unsubscribe();
    //     })
    // }

    // private sendPush(messageInformed) {
    //     this.subscriptions.push(this.usersService.getUserByRef(this.afs.doc(`users/${messageInformed.informedId}`).ref)
    //     subscribe(user => {
    //         this.fcm.sendMessage(user, messageInformed.message);
    //     }))
    // }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
