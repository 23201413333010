<section class="page-section">
    <div class="help-text-align-center help-margin-bottom-30px">
        <button (click)="loadPrev()" mat-stroked-button color="primary" *ngIf="tasksPage > 0">Load previous</button>
    </div>
    <mat-list *ngIf="tasks.length" role="list" class="mat-list--tasks">
        <mat-list-item *ngFor="let task of tasks"
                       (click)="onOpenDialog(task)">
            <div class="date">
                {{ task.dateStart }}<br>{{ task.dateEnd }}
            </div>

            <div mat-line>
                <div class="line">
                    <div class="estimationTask">
                        <div class="estimation"
                             [ngClass]="{'estimation--lowest': (task.priority == 'lowest'), 'estimation--low': (task.priority == 'low'), 'estimation--normal': (task.priority == 'normal'), 'estimation--high': (task.priority == 'high'), 'estimation--highest': (task.priority == 'highest'), 'estimation--done': (task.status == 'done'), 'estimation--inprogress': (task.status == 'inProgress'), 'estimation--tocheck': (task.status == 'toCheck'), 'estimation--bug': (task.type == 'bug'), 'estimation--check': (task.type == 'check')}"
                             [ngStyle]="{'height.px': task.estimation * 50, 'lineHeight.px': task.estimation * 50}">{{ task.estimation | number:'1.1-1' }}
                            h
                        </div>
                        <div class="task">
                            <div class="task-name">{{ task.name }}</div>
                            <div class="task-badges">
                                <small>{{ task.statusHumanize }}</small>
                                <small>{{ task.typeHumanize }}</small>
                                <small>{{ task.priorityHumanize }}</small>
                            </div>
                        </div>
                    </div>
                    <div class="authorTodo">
                        <div class="user">
                            <div class="avatar"
                                 *ngIf="task.userAuthor.photoURL"
                                 [ngStyle]="{'background-image': 'url(' + task.userAuthor.photoURL + ')'}"></div>
                            <div class="avatar"
                                 *ngIf="!task.userAuthor.photoURL">
                                {{ task.userAuthor.name | avatar }}
                            </div>
                            <div>
                                <div class="name">
                                    <div>{{ task.userAuthor.name }}<br>
                                        <small>{{ task.userAuthor._rolesName }}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="user"><i class="material-icons">arrow_right_alt</i></div>
                        <div class="user">
                            <div class="avatar"
                                 *ngIf="task.userTodo.photoURL"
                                 [ngStyle]="{'background-image': 'url(' + task.userTodo.photoURL + ')'}"></div>
                            <div class="avatar"
                                 *ngIf="!task.userTodo.photoURL">
                                {{ task.userTodo.name | avatar }}
                            </div>
                            <div>
                                <div class="name">
                                    <div>{{ task.userTodo.name }}<br>
                                        <small>{{ task.userTodo._rolesName }}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-list-item>
    </mat-list>
    <div class="help-text-align-center">
        <button (click)="loadNext()" mat-stroked-button color="primary" *ngIf="btnNext">Load next</button>
    </div>
    <div *ngIf="!tasks.length">Project doesn't have any tasks</div>
</section>
