<section class="page-section">
<!--  <div class="row" *ngIf="(numberOfUsers === numberOfUsersMax) && ((pricePLNForModule > 0) || (priceUSDForModule > 0))">-->
<!--    <div class="col">-->
<!--      <div class="section&#45;&#45;maxUsers">-->
<!--        If you add a new user you will pay more.-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <mat-card class="mat-card&#45;&#45;chart">-->
<!--    <mat-card-header>-->
<!--      <mat-card-title>Number of users</mat-card-title>-->
<!--      <mat-card-subtitle>User access in {{date | date: 'MMMM yyyy'}}</mat-card-subtitle>-->
<!--    </mat-card-header>-->
<!--    <mat-card-content>-->
<!--      <div *ngIf="drawChart">-->
<!--        <canvas baseChart-->
<!--                [datasets]="chart.dataset"-->
<!--                [labels]="chart.labels"-->
<!--                [options]="chart.options"-->
<!--                [chartType]="chart.type">-->
<!--        </canvas>-->
<!--        <div class="row pricing">-->
<!--          <div class="col" *ngIf="numberOfUsersMax">Max. number of users:<br><strong>{{ numberOfUsersMax }}</strong>-->
<!--          </div>-->
<!--          <div class="col" *ngIf="company">Price per user:<br>-->
<!--            <strong *ngIf="company.currency === 'PLN'">{{ pricePLNForModule | number: '1.2-2'}} PLN</strong>-->
<!--            <strong *ngIf="company.currency === 'USD'">${{ priceUSDForModule | number: '1.2-2'}}</strong>-->
<!--          </div>-->
<!--          <div class="col" *ngIf="company && numberOfUsersMax">Total in month:<br>-->
<!--            <strong *ngIf="company.currency === 'PLN'">{{ numberOfUsersMax * pricePLNForModule | number: '1.2-2' }}-->
<!--              PLN</strong>-->
<!--            <strong-->
<!--              *ngIf="company.currency === 'USD'">${{ numberOfUsersMax * priceUSDForModule | number: '1.2-2' }}</strong>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div *ngIf="!drawChart">-->
<!--        <div class="chart-info">In next month you will see chart with number of users</div>-->
<!--      </div>-->
<!--    </mat-card-content>-->
<!--  </mat-card>-->


  <div class="page-section-header">
    <mat-form-field>
      <input matInput placeholder="Search" type="search" [(ngModel)]="searchUser">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <ng-container *ngFor="let user of users | filter:searchUser:['name','_rolesName']">
    <mat-card class="mat-card--user" *ngIf="user.isActive">
      <a routerLink="/users/{{ user.uid }}" class="mat-card-link">
        <mat-card-header>
          <div mat-card-avatar
               *ngIf="user.photoURL"
               [ngStyle]="{'background-image': 'url(' + user.photoURL + ')'}"></div>
          <div mat-card-avatar
               *ngIf="!user.photoURL">{{ user.name | avatar }}
          </div>
          <mat-card-title>{{ user.name }}<br>
            <small>{{ user._rolesName }}</small>
          </mat-card-title>
        </mat-card-header>
      </a>
      <mat-card-actions
        *ngIf="userCurrent._privileges.canUpdateUser || (userCurrent._rolesName === 'admin'); else info">
        <mat-slide-toggle [checked]="user.moduleAccess" (change)="onChangeAccess(user)">Access
        </mat-slide-toggle>
      </mat-card-actions>
      <ng-template #info>
        <mat-slide-toggle *ngIf="user.moduleAccess" [checked]="user.moduleAccess" disabled>Access
        </mat-slide-toggle>
        <mat-slide-toggle *ngIf="!user.moduleAccess" [checked]="user.moduleAccess" disabled>Block
        </mat-slide-toggle>
      </ng-template>
    </mat-card>
  </ng-container>
</section>

