<section class="page-section page-section--settings-prices">
  <div class="row">
    <div class="col-4" *ngFor="let module of modules">
      <mat-card>
        <mat-card-title>{{ module.name }}</mat-card-title>
        <mat-card-content>

          <mat-list *ngIf="module.price">
            <mat-list-item *ngFor="let price of module.price.prices">
              <div matLine class="date"
                   [ngClass]="{'date--is-now': price.isNow}">
                {{ price.dateStart.toDate() | date: 'yyyy-MM-dd' }} - {{ price.dateEnd.toDate() | date: 'yyyy-MM-dd' }}
              </div>
              <div matLine class="price"
                   *ngIf="company"
                   [ngClass]="{'price--is-now': price.isNow}">
                <span *ngIf="company.currency === 'PLN'">{{ price.valuePLN | number: '0.2' }}
                  zł <small>per user / month</small></span>
                <span *ngIf="company.currency === 'USD'">${{ price.valueUSD | number: '0.2' }}
                  <small>per user / month</small></span>
              </div>
            </mat-list-item>
          </mat-list>

        </mat-card-content>
      </mat-card>
    </div>
  </div>
</section>
