import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {

    results: string[] = [];

    transform(items: any[], search: string, attributes: string[], compareType: string = 'inexact'): any[] {

        if (!items) {
            return [];
        }

        if (!search) {
            return items;
        }

        if (typeof (search) === 'object') {
            search = search[attributes[0]];
        }

        search = search.toLowerCase();

        this.results = [];
        for (const item of items) {
            let exists = false;
            for (const attr of attributes) {
                if (compareType === 'exact') {
                    if (item[attr].toLowerCase() === search) {
                        exists = true;
                    }
                } else if (compareType === 'inexact') {
                    if (item[attr].toLowerCase().search(search) > -1) {
                        exists = true;
                    }
                } else if (compareType === 'number') {
                    if ((search === '!0') && (item[attr] !== 0)) {
                        exists = true;
                    }

                    if (item[attr] === +search) {
                        exists = true;
                    }
                }
            }
            if (exists) {
                this.results.push(item);
            }
        }

        return this.results;
    }

}
