import {Component, OnDestroy, OnInit} from '@angular/core';
import {TaskType} from '../../../_types/task.type';
import {TasksService} from '../../../_services/tasks.service';
import {Subscription} from 'rxjs';
import {ProjectType} from '../../../_types/project.type';
import {ProjectsService} from '../../../_services/projects.service';
import {UserType} from '../../../_types/user.type';
import {ActivatedRoute} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {ProjectsComponent} from '../projects.component';
import {UsersService} from '../../../_services/users.service';
import {SharedTaskDialogComponent} from '../../../_shared/shared-task-dialog/shared-task-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-project-status',
    templateUrl: './project-status.component.html',
    styleUrls: ['./project-status.component.scss']
})
export class ProjectStatusComponent implements OnInit, OnDestroy {

    days: {}[] = [];
    user: UserType;
    users: UserType[];
    project: ProjectType;
    tasks: TaskType[] = [];
    tasksComplete = false;
    usersComplete = false;

    // pagination variables
    tasksLimit = 100;
    taskNext: any;
    btnNext = true;
    tasksPayload: any[] = [];
    tasksPage = 0;

    private subscriptions: Array<Subscription> = [];
    private subscriptionTasks: Subscription;

    constructor(private projectsComponent: ProjectsComponent,
                private tasksService: TasksService,
                private projectsService: ProjectsService,
                private usersService: UsersService,
                private route: ActivatedRoute,
                private afs: AngularFirestore,
                private translate: TranslateService,
                private dialog: MatDialog) {

        translate.setDefaultLang('en');
        translate.use('en');

        this.subscriptions.push(this.route.params.subscribe(params => {
            this.projectsComponent.setNav(params.uid);

            // set user
            this.user = this.route.snapshot.parent.parent.data.user;

            // get project
            this.getProject();
        }));
    }

    ngOnInit(): void {
    }

    onOpenDialog(task): void {
        this.dialog.open(SharedTaskDialogComponent, {
            height: '100vh',
            autoFocus: false,
            data: {
                task: task,
                user: this.user
            }
        });
    }

    loadPrev(): void {
        this.subscriptionTasks.unsubscribe();
        this.getTasks('prev');
    }

    loadNext(): void {
        this.subscriptionTasks.unsubscribe();
        this.getTasks( 'next');
    }

    private getProject(): void {
        this.subscriptions.push(this.projectsService.getProject(this.user, this.projectsComponent.uid).subscribe(project => {
            this.project = project;
            this.projectsComponent.title = this.project.name;

            // get tasks
            this.getTasks('');

            // get users
            this.getUsers();
        }));
    }

    private getTasks(prevNext: string): void {
        let taskQuery = '';
        let startQuery = '';
        let helpTask: any;
        if (prevNext === 'prev') {
            this.tasksPage--;
            taskQuery = this.tasksPayload[this.tasksPage * this.tasksLimit];
            startQuery = 'startAt';
        } else if (prevNext === 'next') {
            taskQuery = this.taskNext;
            startQuery = 'startAfter';
            this.tasksPage++;
        }
        let index = this.tasksPage * this.tasksLimit;

        this.subscriptionTasks = this.tasksService.getTasksByProject(`companies/${this.user.companyRef.id}/tasks`, this.afs.doc(`companies/${this.user.companyRef.id}/projects/${this.project.uid}`).ref, taskQuery, startQuery, this.tasksLimit).subscribe((tasks: any) => {
            this.tasks = [];
            if (tasks[tasks.length - 1]) {
                this.taskNext = tasks[tasks.length - 1].payload.doc;
                tasks.forEach((task: any) => {
                    helpTask = Object.assign({}, task.payload.doc.data());
                    helpTask.dateStart = new Date(helpTask.dateStart.seconds * 1000).toISOString().slice(0, 10).replace(/-/g, '-');
                    helpTask.dateEnd = new Date(helpTask.dateEnd.seconds * 1000).toISOString().slice(0, 10).replace(/-/g, '-');
                    helpTask.project = this.project;
                    this.subscriptions.push(this.translate.get('taskStatus.' + helpTask.status).subscribe((res: string) => {
                        helpTask.statusHumanize = res;
                    }));
                    this.subscriptions.push(this.translate.get('taskType.' + helpTask.type).subscribe((res: string) => {
                        helpTask.typeHumanize = res;
                    }));
                    this.subscriptions.push(this.translate.get('taskPriority.' + helpTask.priority).subscribe((res: string) => {
                        helpTask.priorityHumanize = res;
                    }));
                    this.tasks.push(helpTask);
                    this.tasksPayload[index] = task.payload.doc;
                    index++;
                });
                this.tasksComplete = true;
                this.matchTasksToUsers();
            } else {
                this.tasksPage--;
            }

            if (tasks.length < this.tasksLimit) {
                this.btnNext = false;
            } else {
                this.btnNext = true;
            }
        });


    }

    private getUsers(): void {
        this.subscriptions.push(this.usersService.getUsers(this.user.companyRef).subscribe(users => {
            this.users = users;
            this.usersComplete = true;
            this.matchTasksToUsers();
        }));
    }

    private matchTasksToUsers(): void {
        if (this.tasksComplete && this.usersComplete) {
            this.tasks.forEach(task => {
                this.users.forEach(user => {
                    if (task.userTodoRef.id === user.uid) {
                        task.userTodo = user;
                    }
                    if (task.userAuthorRef.id === user.uid) {
                        task.userAuthor = user;
                    }
                });
            });
            this.setTimeline();
        }
    }

    private setTimeline(): void {
        let helpTask;
        const daysTemp: {} = {};
        this.days = [];
        this.tasks.forEach(task => {
            const dateStart = new Date(task.dateStart.seconds * 1000);
            const dateEnd = new Date(task.dateEnd.seconds * 1000);
            const dateTemp = new Date(task.dateStart.seconds * 1000);

            while (dateTemp.getTime() <= dateEnd.getTime()) {
                helpTask = Object.assign({}, task);

                if (!daysTemp[dateTemp.getTime()]) {
                    daysTemp[dateTemp.getTime()] = {
                        date: new Date(dateTemp),
                        tasks: []
                    };
                }
                helpTask.taskStart = false;
                helpTask.taskEnd = false;
                helpTask.project = this.project;

                if (dateTemp.getTime() === dateStart.getTime()) {
                    helpTask.taskStart = true;
                }
                if (dateTemp.getTime() === dateEnd.getTime()) {
                    helpTask.taskEnd = true;
                }
                helpTask.estimationDay = helpTask.estimation / Object.keys(helpTask.showInDays).length;
                daysTemp[dateTemp.getTime()].tasks.push(helpTask);
                dateTemp.setDate(dateTemp.getDate() + 1); // add one day
            }

        });
        this.days = Object.values(daysTemp);
        this.days.sort((a: { date }, b: { date }) => {
            return b.date.getTime() - a.date.getTime();
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
        this.subscriptionTasks.unsubscribe();
    }

}
