import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'avatar'
})
export class AvatarPipe implements PipeTransform {

    constructor() {}

    transform(name: string): string {
        if (name) {
            name = name.trim();
            const arr = name.split(' ');
            if (arr.length === 1) {
                return arr[0][0];
            } else {
                return arr[0][0] + arr[arr.length - 1][0];
            }
        } else {
            return `•◡•`;
        }
    }
}
