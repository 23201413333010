import {Component, Inject, OnInit} from '@angular/core';
import {UserType} from '../../_types/user.type';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TicketType} from '../../_types/ticket.type';

@Component({
  selector: 'app-shared-ticket-dialog',
  templateUrl: './shared-ticket-dialog.component.html',
  styleUrls: ['./shared-ticket-dialog.component.scss']
})
export class SharedTicketDialogComponent implements OnInit {

  ticketUid: string;
  serviceUid: string;
  user: UserType;

  constructor(@Inject(MAT_DIALOG_DATA) public ticketDialog: any) {
    this.ticketUid = ticketDialog.ticketUid;
    this.serviceUid = ticketDialog.serviceUid;
    this.user = ticketDialog.user;
  }

  ngOnInit() {
  }

}
