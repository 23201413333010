import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserType} from '../../../_types/user.type';
import {RoleType} from '../../../_types/role.type';
import {BrandType} from '../../../_types/brand.type';
import {ActivatedRoute} from '@angular/router';
import {UsersService} from '../../../_services/users.service';
import {RolesService} from '../../../_services/roles.service';
import {MessagesService} from '../../../_services/messages.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {Subscription} from 'rxjs';
import {MessageType} from '../../../_types/message.type';
import * as firebase from 'firebase/app';
import {environment} from '../../../../environments/environment';
import {BrandsComponent} from '../brands.component';
import {BrandsService} from '../../../_services/brands.service';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-brand-team',
    templateUrl: './brand-team.component.html',
    styleUrls: ['./brand-team.component.scss']
})
export class BrandTeamComponent implements OnInit, OnDestroy {

    userCurrent: UserType;
    brand: BrandType;
    users: UserType[] = [];
    usersComplete = false;
    roles: RoleType[] = [];

    searchUser: string;

    private subscriptions: Array<Subscription> = [];

    constructor(private route: ActivatedRoute,
                private usersService: UsersService,
                private rolesService: RolesService,
                private brandsComponent: BrandsComponent,
                private messagesService: MessagesService,
                private brandsService: BrandsService,
                private afs: AngularFirestore,
                private progress: NgProgress,
                private snackBar: MatSnackBar) {

        // set params
        this.subscriptions.push(this.route.params.subscribe(params => {
            this.brandsComponent.setNav(params.uid);

            // set user
            this.userCurrent = this.route.snapshot.parent.parent.data.user;

            // get brand
            this.subscriptions.push(this.brandsService.getBrand(this.userCurrent, this.brandsComponent.uid).subscribe(brand => {
                this.brand = brand;

                this.brandsComponent.title = this.brand.name;

                // get users and set access variable
                this.getUsers();

            }));

        }));
    }

    ngOnInit() {
    }

    private getUsers() {
        this.subscriptions.push(this.usersService.getUsers(this.userCurrent.companyRef).subscribe(users => {
            users.forEach(user => {
                user.brandAccess = !!this.brand.userRefs[user.uid];
            });
            this.users = users;
            this.usersComplete = true;
        }));
    }

    onChangeAccess(userUid) {
        this.progress.ref().start();

        let message: string;
        let change: MessageType;
        let userName = '';
        this.users.forEach(user => {
            if (user.uid === userUid) {
                userName = user.name;
            }
        });

        if (this.brand.userRefs[userUid]) {
            delete this.brand.userRefs[userUid];
            message = 'Access to the brand has been removed';
            change = {
                uid: this.afs.createId(),
                message: `has removed from the team ${userName} `,
                dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
                userRef: this.afs.doc(`users/${this.userCurrent.uid}`).ref,
                files: [],
                type: 'auto'
            };
        } else {
            this.brand.userRefs[userUid] = this.afs.doc(`users/${userUid}`).ref;
            message = 'Access to the brand has been added';
            change = {
                uid: this.afs.createId(),
                message: `has added to the team ${userName}`,
                dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
                userRef: this.afs.doc(`users/${this.userCurrent.uid}`).ref,
                files: [],
                type: 'auto'
            };
        }

        this.brandsService.updateBrand({
            userRefs: this.brand.userRefs
        }, this.userCurrent, this.brand.uid)
            .then(() => {
                this.messagesService.insertMessage(change, `companies/${this.userCurrent.companyRef.id}/brands/${this.brand.uid}/changes/${change.uid}`)
                    .then(() => {
                        this.snackBar.open(message, '', environment.snackbarSuccess);
                        this.progress.ref().complete();
                    })
                    .catch(err => {
                        this.snackBar.open(err.message, '', environment.snackbarWarn);
                        this.progress.ref().complete();
                    });
            })
            .catch(err => {
                this.snackBar.open(err.message, '', environment.snackbarWarn);
                this.progress.ref().complete();
            });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
