<section class="page-section page-section--project-wiki">
    <quill-editor [(ngModel)]="editorContent"
                  *ngIf="user._privileges.canUpdateProject || (user._rolesName === 'admin'); else info">
        <div quill-editor-toolbar>
        <span class="ql-formats">
            <button class="ql-save" [title]="'Save'" (click)="onSave()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/>
                </svg>
            </button>
        </span>
            <span class="ql-formats">
            <select class="ql-header" [title]="'Header'">
                <option selected></option>
                <option value="1"></option>
                <option value="2"></option>
                <option value="3"></option>
                <option value="4"></option>
                <option value="5"></option>
                <option value="6"></option>
            </select>
        </span>
            <span class="ql-formats">
            <button class="ql-bold" [title]="'Bold'"></button>
            <button class="ql-italic" [title]="'Italic'"></button>
            <button class="ql-underline" [title]="'Underline'"></button>
                <button class="ql-strike" [title]="'Strike'"></button>
        </span>
            <span class="ql-formats">
            <button class="ql-list" value="ordered" [title]="'List ordered'"></button>
            <button class="ql-list" value="bullet" [title]="'List bullet'"></button>
        </span>
            <span class="ql-formats">
            <select class="ql-align" [title]="'Aligment'">
                <option selected></option>
                <option value="center"></option>
                <option value="right"></option>
                <option value="justify"></option>
            </select>
        </span>
            <span class="ql-formats">
                <button class="ql-link" [title]="'Link'"></button>
            <button class="ql-blockquote" [title]="'Blockquote'"></button>
            <button class="ql-code-block" [title]="'Code-block'"></button>
                <button class="ql-clean" [title]="'Clean format'"></button>
        </span>
        </div>
    </quill-editor>

    <ng-template #info>
        <div class="ql-editor-content" [innerHTML]="editorContent"></div>
    </ng-template>
</section>
