import {Component, Input, OnInit, EventEmitter, ViewEncapsulation, OnChanges, OnDestroy} from '@angular/core';
import {TaskType} from '../../_types/task.type';
import {UserType} from '../../_types/user.type';
import {Subscription} from 'rxjs/internal/Subscription';
import {UsersService} from '../../_services/users.service';
import {ProjectType} from '../../_types/project.type';
import {ProjectsService} from '../../_services/projects.service';
import {TasksService} from '../../_services/tasks.service';
import * as firebase from 'firebase/app';
import {environment} from '../../../environments/environment';
import {AngularFirestore} from '@angular/fire/firestore';
import {MessageType} from '../../_types/message.type';
import {MessagesService} from '../../_services/messages.service';
import {TranslateService} from '@ngx-translate/core';
import {NgForm} from '@angular/forms';
import {UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions} from 'ngx-uploader';
import {FileType} from '../../_types/file.type';
import {formatDate} from '@angular/common';
import {NotificationType} from '../../_types/notification.type';
import {NotificationsService} from '../../_services/notifications.service';
import {FcmService} from '../../_services/fcm.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RoleType} from '../../_types/role.type';
import {RolesService} from '../../_services/roles.service';
import {NgProgress, NgProgressComponent} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-shared-task',
  templateUrl: './shared-task.component.html',
  styleUrls: ['./shared-task.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SharedTaskComponent implements OnInit, OnDestroy, OnChanges {

  @Input() taskInp: TaskType;
  @Input() userInp: UserType;
  @Input() viewInp: string;

  task: TaskType;
  taskComplete = false;
  project: ProjectType;
  projectComplete = false;
  taskCopy: TaskType;
  showRemove = false;
  users: UserType[] = [];
  usersComplete = false;
  roles: RoleType[] = [];
  messages: MessageType[] = [];
  messagesComplete = false;
  messageText: string;
  messageFiles: FileType[] = [];
  author: any;
  authorCopy: any;
  todo: any;
  todoCopy: any;

  group: any;

  dateStartDatepicker: any;
  dateEndDatepicker: any;

  options: UploaderOptions;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  taskFiles: FileType[] = [];
  taskFileRemove: FileType;
  taskFileShowRemove: boolean[] = [];

  status = 'inProgress';
  hideAutoComments = false;

  expandImage = false;

  private subscriptions: Array<Subscription> = [];

  constructor(private usersService: UsersService,
              private rolesService: RolesService,
              private projectsService: ProjectsService,
              private messagesService: MessagesService,
              private notificationsService: NotificationsService,
              private tasksService: TasksService,
              private afs: AngularFirestore,
              private progress: NgProgress,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private fcm: FcmService,
              private router: Router) {
  }

  ngOnChanges() {
    // set hide/show autocomments
    if (this.userInp.settings && this.userInp.settings.tasks_hideAutoComments) {
      this.hideAutoComments = this.userInp.settings.tasks_hideAutoComments;
    }

    // get task
    this.getTask();

    // get project
    this.getProject();

    // upload flies do description
    this.options = {concurrency: 1, maxUploads: 10};
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
  }

  private getTask() {
    this.subscriptions.push(this.tasksService
      .getTask(`companies/${this.userInp.companyRef.id}/tasks/${this.taskInp.uid}`)
      .subscribe(task => {
        this.task = task;
        this.group = task.group;
        this.taskComplete = true;

        // copy task
        this.taskCopy = Object.assign({}, this.task);

        // set datepicker values and copy dates do taskCopy
        this.dateStartDatepicker = '';
        this.dateEndDatepicker = '';
        this.taskCopy.dateStart = {};
        this.taskCopy.dateEnd = {};
        if (this.task.dateStart) {
          this.dateStartDatepicker = new Date(this.task.dateStart.seconds * 1000);
          this.taskCopy.dateStart = Object.assign({}, this.task.dateStart);
        }
        if (this.task.dateEnd) {
          this.dateEndDatepicker = new Date(this.task.dateEnd.seconds * 1000);
          this.taskCopy.dateEnd = Object.assign({}, this.task.dateEnd);
        }

        // match author and to-do
        this.matchAuthorTodoUser();
      }));
  }

  private getProject() {
    this.subscriptions.push(this.projectsService.getProject(this.userInp, this.taskInp.projectRef.id).subscribe(project => {
      this.project = project;
      this.projectComplete = true;

      // get messages
      this.getMessages();

      // get users
      this.getUsers();
    }));
  }

  private getMessages() {
    if (this.project.userRefs[this.userInp.uid]) {
      this.subscriptions.push(this.messagesService
        .getMessages(`companies/${this.userInp.companyRef.id}/tasks/${this.taskInp.uid}/messages`)
        .subscribe(messages => {
          this.messages = messages;
          this.messagesComplete = true;

          // match messages to users
          this.matchMessagesToUsers();
        }));
    }
  }

  private getUsers() {
    if (this.project.userRefs[this.userInp.uid]) {
      this.subscriptions.push(this.usersService.getUsers(this.userInp.companyRef).subscribe(users => {
        this.users = users;
        this.usersComplete = true;

        // match author and to-do
        this.matchAuthorTodoUser();

        // match messages to users
        this.matchMessagesToUsers();

      }));
    }
  }

  private matchAuthorTodoUser() {
    if (this.usersComplete && this.taskComplete) {
      this.users.forEach(user => {
        if (user.uid === this.task.userAuthorRef.id) {
          this.author = user;
          this.authorCopy = Object.assign({}, this.author);
        }
        if (user.uid === this.task.userTodoRef.id) {
          this.todo = user;
          this.todoCopy = Object.assign({}, this.todo);
        }
      });
    }
  }

  private matchMessagesToUsers() {
    if (this.messagesComplete && this.usersComplete) {
      this.messages.forEach(message => {
        this.users.forEach(user => {
          if (message.userRef.id === user.uid) {
            message.author = user;
          }
        });
      });
    }
  }

  private onUpdateField(field) {
    // start progress bar
    this.progress.ref().start();
    // save message
    this.saveMessage(field.messageMessage);
    // save notification & send push message
    const informedIds = this.calculateInformed();
    for (const id of Object.keys(informedIds)) {
      // save notification
      this.saveNotification({
        message: field.notificationMessage,
        informedId: id
      });
      // send push
      this.sendPush({
        message: field.pushMessage,
        informedId: id
      });
    }
    // update task
    this.tasksService.updateTask(field.update, `companies/${this.userInp.companyRef.id}/tasks/${this.task.uid}`)
      .then(() => {
        // update taskCopy
        this.taskCopy = Object.assign({}, this.task);
        // open snackbar
        this.snackBar.open('Task has been updated', '', environment.snackbarSuccess);
        // update project estimation real
        if ((typeof field.update.userAuthorRef !== 'undefined') || (typeof field.update.userTodoRef !== 'undefined')) {
          this.updateProject();
        }
        // close progress bar
        this.progress.ref().complete();
      }).catch(err => {
        // show snackbar
        this.snackBar.open(err.message, '', environment.snackbarWarn);
        // close progress bar
        this.progress.ref().complete();
      }
    );
  }

  private updateProject() {
    // set access to project
    this.project.userRefs[this.task.userAuthorRef.id] = this.task.userAuthorRef;
    this.project.userRefs[this.task.userTodoRef.id] = this.task.userTodoRef;

    // update project
    this.projectsService.updateProject({
      userRefs: this.project.userRefs
    }, this.userInp, this.task.projectRef.id).then(() => {
      // console.log('update');
    });
  }

  private saveMessage(messageMessage: string, type: string = 'auto') {
    let message: MessageType;
    message = {
      uid: this.afs.createId(),
      message: messageMessage,
      dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
      userRef: this.afs.doc(`users/${this.userInp.uid}`).ref,
      files: this.messageFiles,
      type: type
    };
    this.messagesService.insertMessage(message, `companies/${this.userInp.companyRef.id}/tasks/${this.task.uid}/messages/${message.uid}`)
      .then(() => {
        this.messageFiles = [];
      })
      .catch(err => {
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  private saveNotification(messageInformed) {
    const notification: NotificationType = {
      uid: this.afs.createId(),
      message: messageInformed.message,
      dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
      dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
      userAuthorRef: this.afs.doc(`users/${this.userInp.uid}`).ref,
      userInformedRef: this.afs.doc(`users/${messageInformed.informedId}`).ref,
      elementUid: this.task.uid,
      elementType: 'projectTask',
      view: 'info',
      isUnread: true
    };
    const subscriptionNotification = {};

    subscriptionNotification[messageInformed.informedId] = this.notificationsService
      .getNotificationsToMerge(`companies/${this.userInp.companyRef.id}/notifications/`, notification)
      .subscribe(notifications => {
        if (notifications.length) {
          this.notificationsService.updateNotification({
            message: notifications[0].message + messageInformed.message,
            dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            userInformedRef: this.afs.doc(`users/${messageInformed.informedId}`).ref,
          }, `companies/${this.userInp.companyRef.id}/notifications/${notifications[0].uid}`).then();
        } else {
          this.notificationsService
            .insertNotification(notification, `companies/${this.userInp.companyRef.id}/notifications/${notification.uid}`)
            .then(() => {
            })
            .catch(err => {
              this.snackBar.open(err.message, '', environment.snackbarWarn);
            });
        }
        subscriptionNotification[messageInformed.informedId].unsubscribe();
      });
  }

  private sendPush(messageInformed) {
    this.subscriptions.push(this.usersService.getUserByRef(this.afs.doc(`users/${messageInformed.informedId}`).ref).subscribe(user => {
      this.fcm.sendMessage(user, messageInformed.message);
    }));
  }

  private calculateInformed() {
    const informed = {};
    if (this.taskCopy.userAuthorRef.id !== this.userInp.uid) {
      informed[this.taskCopy.userAuthorRef.id] = true;
    }
    if (this.task.userAuthorRef.id !== this.userInp.uid) {
      informed[this.task.userAuthorRef.id] = true;
    }
    if (this.taskCopy.userTodoRef.id !== this.userInp.uid) {
      informed[this.taskCopy.userTodoRef.id] = true;
    }
    if (this.task.userTodoRef.id !== this.userInp.uid) {
      informed[this.task.userTodoRef.id] = true;
    }
    return informed;
  }

  private calculateShowIn(dateStart, dateEnd) {
    dateStart.setHours(0, 0, 0, 0);
    dateEnd.setHours(0, 0, 0, 0);
    const showIn = {
      months: {},
      weeks: {},
      days: {},
    };
    let firstJanuary;
    for (const incDate = dateStart; incDate <= dateEnd; incDate.setDate(incDate.getDate() + 1)) {
      // show in months
      showIn.months[incDate.getFullYear() + '' + ('0' + (incDate.getMonth() + 1)).slice(-2)] = true;

      // check weekend days
      if ((incDate.getDay() !== 6) && (incDate.getDay() !== 0)) {
        // show in days
        showIn.days[incDate.getFullYear() + '' + ('0' + (incDate.getMonth() + 1)).slice(-2) + '' + ('0' + incDate.getDate()).slice(-2)] = true;
      }

      // show in weeks
      firstJanuary = new Date(incDate.getFullYear(), 0, 1);
      showIn.weeks[incDate.getFullYear() + '' + Math.ceil((((incDate.getTime() - firstJanuary.getTime()) / 86400000) + firstJanuary.getDay() + 1) / 7)] = true;
    }
    return showIn;
  }

  onChangeUserSettings() {
    this.progress.ref().start();
    this.hideAutoComments = !this.hideAutoComments;
    if (!this.userInp.settings) {
      this.userInp.settings = {};
    }
    this.userInp.settings['tasks_hideAutoComments'] = this.hideAutoComments;
    this.usersService.updateUser({
      settings: this.userInp.settings
    }, this.userInp.uid)
      .then(() => {
        this.progress.ref().complete();
        this.snackBar.open('User settings has been updated', '', environment.snackbarSuccess);
      })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  onGoToProject() {
    this.router.navigate([`/projects/${this.project.uid}/details`]).then();
  }

  onGoToTask() {
    this.router.navigate([`/projects/${this.taskInp.projectRef.id}/task/${this.task.uid}`]).then();
  }

  onCopyLink() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = `${environment.url}/projects/${this.taskInp.projectRef.id}/task/${this.task.uid}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackBar.open('Link to the task has been copied', '', environment.snackbarInfo);
  }

  onUpdate(field) {
    let messageMessage: string;
    let notificationMessage: string;
    let pushMessage: {};

    switch (field) {
      case 'status':
        // prepare message
        let tempStatusBefore = '';
        let tempStatusAfter = '';
        this.subscriptions.push(this.translate.get('taskStatus.' + this.taskCopy.status).subscribe(value => {
          tempStatusBefore = value;
        }));
        this.subscriptions.push(this.translate.get('taskStatus.' + this.task.status).subscribe(value => {
          tempStatusAfter = value;
        }));
        messageMessage = `has changed status: ${tempStatusBefore} → ${tempStatusAfter}`;
        notificationMessage = `<p>has changed project task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a> <code class="code">status: ${tempStatusBefore} → ${tempStatusAfter}</code></p>`;
        pushMessage = {
          title: `Change of project task status`,
          body: `${this.userInp.name} has changed project task ${this.task.name} status: ${tempStatusBefore} → ${tempStatusAfter}`,
          click_action: `${environment.url}/notifications`
        };

        // update field, insert message, insert notification
        this.onUpdateField({
          update: {
            status: this.task.status,
            dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            userUpdateRef: this.afs.doc(`users/${this.userInp.uid}`).ref
          },
          messageMessage: messageMessage,
          notificationMessage: notificationMessage,
          pushMessage: pushMessage
        });
        break;
      case 'name':
        if (this.taskCopy.name !== this.task.name) {
          messageMessage = `has changed name: ${this.taskCopy.name} → ${this.task.name}`;
          notificationMessage = `<p>has changed project task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a> <code class="code">name: ${this.taskCopy.name} → ${this.task.name}</code></p>`;
          pushMessage = {
            title: `Change of project task name`,
            body: `${this.userInp.name} has changed project task ${this.task.name} name: ${this.taskCopy.name} → ${this.task.name}`,
            click_action: `${environment.url}/notifications`
          };
          // update field, insert message, insert notification
          this.onUpdateField({
            update: {
              name: this.task.name,
              dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
              userUpdateRef: this.afs.doc(`users/${this.userInp.uid}`).ref
            },
            messageMessage: messageMessage,
            notificationMessage: notificationMessage,
            pushMessage: pushMessage
          });
        }
        break;
      case 'type':
        let tempTypeBefore = '';
        let tempTypeAfter = '';
        this.subscriptions.push(this.translate.get('taskType.' + this.taskCopy.type).subscribe(value => {
          tempTypeBefore = value;
        }));
        this.subscriptions.push(this.translate.get('taskType.' + this.task.type).subscribe(value => {
          tempTypeAfter = value;
        }));
        messageMessage = `has changed type: ${tempTypeBefore} → ${tempTypeAfter}`;
        notificationMessage = `<p>has changed project task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a> <code class="code">type: ${tempTypeBefore} → ${tempTypeAfter}</code></p>`;
        pushMessage = {
          title: `Change of project task type`,
          body: `${this.userInp.name} has changed project task ${this.task.name} type: ${tempTypeBefore} → ${tempTypeAfter}`,
          click_action: `${environment.url}/notifications`
        };
        // update field, insert message, insert notification
        this.onUpdateField({
          update: {
            type: this.task.type,
            dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            userUpdateRef: this.afs.doc(`users/${this.userInp.uid}`).ref
          },
          messageMessage: messageMessage,
          notificationMessage: notificationMessage,
          pushMessage: pushMessage
        });
        break;
      case 'priority':
        let tempPriorityBefore = '';
        let tempPriorityAfter = '';
        this.subscriptions.push(this.translate.get('taskPriority.' + this.taskCopy.priority).subscribe(value => {
          tempPriorityBefore = value;
        }));
        this.subscriptions.push(this.translate.get('taskPriority.' + this.task.priority).subscribe(value => {
          tempPriorityAfter = value;
        }));
        messageMessage = `has changed priority: ${tempPriorityBefore} → ${tempPriorityAfter}`;
        notificationMessage = `<p>has changed project task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a> <code class="code">priority: ${tempPriorityBefore} → ${tempPriorityAfter}</code></p>`;
        pushMessage = {
          title: `Change of project task priority`,
          body: `${this.userInp.name} has changed project task ${this.task.name} priority: ${tempPriorityBefore} → ${tempPriorityAfter}`,
          click_action: `${environment.url}/notifications`
        };
        // update field, insert message, insert notification
        this.onUpdateField({
          update: {
            priority: this.task.priority,
            dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            userUpdateRef: this.afs.doc(`users/${this.userInp.uid}`).ref
          },
          messageMessage: messageMessage,
          notificationMessage: notificationMessage,
          pushMessage: pushMessage
        });
        break;
      case 'userAuthorRef':
        // get new author
        this.subscriptions.push(this.usersService.getUserByRef(this.task.userAuthorRef).subscribe(user => {
          this.author = user;
          messageMessage = `has changed author: ${this.authorCopy.name} → ${this.author.name}`;
          notificationMessage = `<p>has changed project task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a> <code class="code">author: ${this.authorCopy.name} → ${this.author.name}</code></p>`;
          pushMessage = {
            title: `Change of project task author`,
            body: `${this.userInp.name} has changed project task ${this.task.name} author: ${this.authorCopy.name} → ${this.author.name}`,
            click_action: `${environment.url}/notifications`
          };
          // update field, insert message, insert notification
          this.onUpdateField({
            update: {
              userAuthorRef: this.task.userAuthorRef,
              dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
              userUpdateRef: this.afs.doc(`users/${this.userInp.uid}`).ref,
            },
            messageMessage: messageMessage,
            notificationMessage: notificationMessage,
            pushMessage: pushMessage
          });
          // update authorCopy
          this.authorCopy = Object.assign({}, this.author);
        }));
        break;
      case 'userTodoRef':
        // get new to do
        this.subscriptions.push(this.usersService.getUserByRef(this.task.userTodoRef).subscribe(user => {
          this.todo = user;
          messageMessage = `has changed todo: ${this.todoCopy.name} → ${this.todo.name}`;
          notificationMessage = `<p>has changed project task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a> <code class="code">todo: ${this.todoCopy.name} → ${this.todo.name}</code></p>`;
          pushMessage = {
            title: `Change of project task todo`,
            body: `${this.userInp.name} has changed project task ${this.task.name} todo: ${this.todoCopy.name} → ${this.todo.name}`,
            click_action: `${environment.url}/notifications`
          };
          // update field, insert message, insert notification
          this.onUpdateField({
            update: {
              userTodoRef: this.task.userTodoRef,
              dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
              userUpdateRef: this.afs.doc(`users/${this.userInp.uid}`).ref,
            },
            messageMessage: messageMessage,
            notificationMessage: notificationMessage,
            pushMessage: pushMessage
          });
          // update todoCopy
          this.todoCopy = Object.assign({}, this.todo);
        }));
        break;
      case 'group':
        if (this.taskCopy.group !== this.task.group) {
          if (!this.taskCopy.group) {
            this.taskCopy.group = '';
          }
          messageMessage = `has changed task group: ${this.taskCopy.group} → ${this.task.group}`;
          notificationMessage = `<p>has changed project task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a> <code class="code">task group: ${this.taskCopy.group} → ${this.task.group}</code></p>`;
          pushMessage = {
            title: `Change of project task group`,
            body: `${this.userInp.name} has changed project task ${this.task.name} group: ${this.taskCopy.group} → ${this.task.group}`,
            click_action: `${environment.url}/notifications`
          };

          // update task
          this.onUpdateField({
            update: {
              group: this.task.group,
              dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
              userUpdateRef: this.afs.doc(`users/${this.userInp.uid}`).ref
            },
            messageMessage: messageMessage,
            notificationMessage: notificationMessage,
            pushMessage: pushMessage
          });

          // update project
          let exists = false;
          if (this.project.groups) {
            this.project.groups.forEach(group => {
              if (group.name === this.task.group) {
                exists = true;
              }
            });
          }

          if (!exists) {
            if (this.project.groups) {
              this.project.groups.push({
                name: this.task.group
              });
            } else {
              this.project.groups = [{
                name: this.task.group
              }];
            }

            // update project
            this.projectsService.updateProject({
              groups: this.project.groups
            }, this.userInp, this.task.projectRef.id).then(() => {
              // console.log('update');
            });
          }
        }
        break;
      case 'estimation':
        if (this.taskCopy.estimation !== this.task.estimation) {
          if (this.task.estimation > 999) {
            this.task.estimation = 999;
          }
          messageMessage = `has changed estimation: ${this.taskCopy.estimation} → ${this.task.estimation}`;
          notificationMessage = `<p>has changed project task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a> <code class="code">estimation: ${this.taskCopy.estimation} → ${this.task.estimation}</code></p>`;
          pushMessage = {
            title: `Change of project task estimation`,
            body: `${this.userInp.name} has changed project task ${this.task.name} estimation: ${this.taskCopy.estimation} → ${this.task.estimation}`,
            click_action: `${environment.url}/notifications`
          };
          this.onUpdateField({
            update: {
              estimation: this.task.estimation,
              dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
              userUpdateRef: this.afs.doc(`users/${this.userInp.uid}`).ref
            },
            messageMessage: messageMessage,
            notificationMessage: notificationMessage,
            pushMessage: pushMessage
          });
        }
        break;
      case 'dateEnd':
        if (this.dateEndDatepicker.getTime() !== (this.taskCopy.dateEnd.seconds * 1000)) {
          messageMessage = `has changed date end: ${formatDate(new Date(this.taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(this.dateEndDatepicker, 'yyyy-MM-dd', 'en-US')}`;
          notificationMessage = `<p>has changed project task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a> <code class="code">date end: ${formatDate(new Date(this.taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(this.dateEndDatepicker, 'yyyy-MM-dd', 'en-US')}</code></p>`;
          pushMessage = {
            title: `Change of project task date end`,
            body: `${this.userInp.name} has changed project task ${this.task.name} date end: ${formatDate(new Date(this.taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(this.dateEndDatepicker, 'yyyy-MM-dd', 'en-US')}`,
            click_action: `${environment.url}/notifications`
          };
          // rewrite showInDays, showInMonth, showInWeeks
          const showIn = this.calculateShowIn(new Date(this.task.dateStart.seconds * 1000), new Date(this.dateEndDatepicker.getTime()));
          this.onUpdateField({
            update: {
              dateEnd: this.dateEndDatepicker,
              showInDays: showIn.days,
              showInMonths: showIn.months,
              showInWeeks: showIn.weeks,
              dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
              userUpdateRef: this.afs.doc(`users/${this.userInp.uid}`).ref
            },
            messageMessage: messageMessage,
            notificationMessage: notificationMessage,
            pushMessage: pushMessage
          });
        }
        break;
      case 'dateStart':
        if (this.dateStartDatepicker.getTime() !== (this.taskCopy.dateStart.seconds * 1000)) {
          messageMessage = `has changed date start: ${formatDate(new Date(this.taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(this.dateStartDatepicker, 'yyyy-MM-dd', 'en-US')}`;
          notificationMessage = `<p>has changed project task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a> <code class="code">date start: ${formatDate(new Date(this.taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(this.dateStartDatepicker, 'yyyy-MM-dd', 'en-US')}</code></p>`;
          pushMessage = {
            title: `Change of project task date start`,
            body: `${this.userInp.name} has changed project task ${this.task.name} date start: ${formatDate(new Date(this.taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(this.dateStartDatepicker, 'yyyy-MM-dd', 'en-US')}`,
            click_action: `${environment.url}/notifications`
          };
          // rewrite showInDays, showInMonth, showInWeeks
          const showIn = this.calculateShowIn(new Date(this.dateStartDatepicker.getTime()), new Date(this.task.dateEnd.seconds * 1000));
          this.onUpdateField({
            update: {
              dateStart: this.dateStartDatepicker,
              showInDays: showIn.days,
              showInMonths: showIn.months,
              showInWeeks: showIn.weeks,
              dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
              userUpdateRef: this.afs.doc(`users/${this.userInp.uid}`).ref
            },
            messageMessage: messageMessage,
            notificationMessage: notificationMessage,
            pushMessage: pushMessage
          });
        }
        break;
      case 'dateStartEnd':
        let update = false;
        let showIn;
        if (!this.dateStartDatepicker && !this.dateEndDatepicker) { // if we removed dates
          messageMessage = `has changed date start: ${formatDate(new Date(this.taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → , date end: ${formatDate(new Date(this.taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → `;
          notificationMessage = `<p>has changed project task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a> <code class="code">date start: ${formatDate(new Date(this.taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → </code><code>date end: ${formatDate(new Date(this.taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → </code></p>`;
          pushMessage = {
            title: `Change of project task dates`,
            body: `${this.userInp.name} has changed project task ${this.task.name} date start: ${formatDate(new Date(this.taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → , date end: ${formatDate(new Date(this.taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → `,
            click_action: `${environment.url}/notifications`
          };
          showIn = {
            days: {} = {},
            months: {} = {},
            weeks: {} = {},
          };
          update = true;
        } else if ((this.dateStartDatepicker.getTime() !== (this.taskCopy.dateStart.seconds * 1000)) && (this.dateEndDatepicker.getTime() !== (this.taskCopy.dateEnd.seconds * 1000))) { // if we changed dates
          let taskCopyDateStart = '';
          let taskCopyDateEnd = '';
          if (Object.getOwnPropertyNames(this.taskCopy.dateStart).length) {
            taskCopyDateStart = formatDate(new Date(this.taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US');
          }
          if (Object.getOwnPropertyNames(this.taskCopy.dateEnd).length) {
            taskCopyDateEnd = formatDate(new Date(this.taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US');
          }

          messageMessage = `has changed date start: ${taskCopyDateStart} → ${formatDate(this.dateStartDatepicker, 'yyyy-MM-dd', 'en-US')}, date end: ${taskCopyDateEnd} → ${formatDate(this.dateEndDatepicker, 'yyyy-MM-dd', 'en-US')}`;
          notificationMessage = `<p>has changed project task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a> <code class="code">date start: ${taskCopyDateStart} → ${formatDate(this.dateStartDatepicker, 'yyyy-MM-dd', 'en-US')}</code><code>date end: ${taskCopyDateEnd} → ${formatDate(this.dateEndDatepicker, 'yyyy-MM-dd', 'en-US')}</code></p>`;
          pushMessage = {
            title: `Change of project task dates`,
            body: `${this.userInp.name} has changed project task ${this.task.name} date start: ${taskCopyDateStart} → ${formatDate(this.dateStartDatepicker, 'yyyy-MM-dd', 'en-US')}, date end: ${taskCopyDateEnd} → ${formatDate(this.dateEndDatepicker, 'yyyy-MM-dd', 'en-US')}`,
            click_action: `${environment.url}/notifications`
          };
          // rewrite showInDays, showInMonth, showInWeeks
          showIn = this.calculateShowIn(new Date(this.dateStartDatepicker.getTime()), new Date(this.dateEndDatepicker.getTime()));
          update = true;
        }

        if (update) {
          this.onUpdateField({
            update: {
              dateStart: this.dateStartDatepicker,
              dateEnd: this.dateEndDatepicker,
              showInDays: showIn.days,
              showInMonths: showIn.months,
              showInWeeks: showIn.weeks,
              dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
              userUpdateRef: this.afs.doc(`users/${this.userInp.uid}`).ref
            },
            messageMessage: messageMessage,
            notificationMessage: notificationMessage,
            pushMessage: pushMessage
          });
        }
        break;
      case 'isActive':
        messageMessage = 'has removed task';
        notificationMessage = `<p>has removed project task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a></p>`;
        pushMessage = {
          title: `Remove project task`,
          body: `${this.userInp.name} has removed project task ${this.task.name}`,
          click_action: `${environment.url}/notifications`
        };
        this.onUpdateField({
          update: {
            isActive: this.task.isActive,
            dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            userUpdateRef: this.afs.doc(`users/${this.userInp.uid}`).ref
          },
          messageMessage: messageMessage,
          notificationMessage: notificationMessage,
          pushMessage: pushMessage
        });
        break;
      case 'isRemoved':
        messageMessage = 'has withdrew the removal of the task';
        notificationMessage = `<p>has withdrew the removal of the task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a></p>`;
        pushMessage = {
          title: `Withdrew the removal of the task`,
          body: `${this.userInp.name} has withdrew the removal of the task ${this.task.name}`,
          click_action: `${environment.url}/notifications`
        };
        this.onUpdateField({
          update: {
            isActive: this.task.isActive,
            dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            userUpdateRef: this.afs.doc(`users/${this.userInp.uid}`).ref
          },
          messageMessage: messageMessage,
          notificationMessage: notificationMessage,
          pushMessage: pushMessage
        });
        break;
      case 'description':
        if (this.taskCopy.description !== this.task.description) {
          // messageMessage = `has changed description: ${this.taskCopy.description} → ${this.task.description}`;
          messageMessage = `has changed description`;
          // notificationMessage = `<p>has changed project task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a> <code class="code">description: ${this.taskCopy.description} → ${this.task.description}</code></p>`;
          notificationMessage = `<p>has changed description in the project task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a></p>`;
          // pushMessage = {
          //     title: `Change of project task description`,
          //     body: `${this.userInp.name} has changed project task ${this.task.name} description: ${this.taskCopy.description} → ${this.task.description}`,
          //     click_action: `${environment.url}/notifications`
          // };
          pushMessage = {
            title: `Change of project task description`,
            body: `${this.userInp.name} has changed description in the project task ${this.task.name}`,
            click_action: `${environment.url}/notifications`
          };
          // update field, insert message, insert notification
          this.onUpdateField({
            update: {
              description: this.task.description,
              dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
              userUpdateRef: this.afs.doc(`users/${this.userInp.uid}`).ref
            },
            messageMessage: messageMessage,
            notificationMessage: notificationMessage,
            pushMessage: pushMessage
          });
        }
        break;
      case 'filesAdd':
        let filesAdded = '';
        this.taskFiles.forEach(function (file, i) {
          if (i === 0) {
            filesAdded += `${file.name}`;
          } else {
            filesAdded += `, ${file.name}`;
          }
        });
        messageMessage = `has added file(s): ${filesAdded}`;
        notificationMessage = `<p>has changed project task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a> <code class="code">files added: ${filesAdded}</code></p>`;
        pushMessage = {
          title: `Add files to the task project`,
          body: `${this.userInp.name} has changed project task ${this.task.name}, files added: ${filesAdded}`,
          click_action: `${environment.url}/notifications`
        };
        // update field, insert message, insert notification
        this.task.files = this.task.files.concat(this.taskFiles);
        this.onUpdateField({
          update: {
            files: this.task.files,
            dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            userUpdateRef: this.afs.doc(`users/${this.userInp.uid}`).ref
          },
          messageMessage: messageMessage,
          notificationMessage: notificationMessage,
          pushMessage: pushMessage
        });
        break;
      case 'fileRemove':
        messageMessage = `has removed file: ${this.taskFileRemove.name}`;
        notificationMessage = `<p>has changed project task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a> <code class="code">removed file: ${this.taskFileRemove.name}</code></p>`;
        pushMessage = {
          title: `Remove files to the task project`,
          body: `${this.userInp.name} has changed project task ${this.task.name}, removed file: ${this.taskFileRemove.name}`,
          click_action: `${environment.url}/notifications`
        };
        // update field, insert message, insert notification
        // this.task.files = this.task.files.concat(this.taskFiles);
        this.onUpdateField({
          update: {
            files: this.task.files,
            dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            userUpdateRef: this.afs.doc(`users/${this.userInp.uid}`).ref
          },
          messageMessage: messageMessage,
          notificationMessage: notificationMessage,
          pushMessage: pushMessage
        });
        break;
      default:
        break;
    }
  }

  onSend(form: NgForm) {

    // save message
    this.saveMessage(form.value.messageText, 'user');
    this.messageFiles = [];

    const notificationMessage = `<p>has commented project task <a class="link" routerLink="projects/${this.task.projectRef.id}/task/${this.task.uid}">${this.task.name}</a> <code class="code">${form.value.messageText.replace(/<[^>]*>/g, '')}</code></p>`;
    const informedIds = this.calculateInformed();
    for (const id of Object.keys(informedIds)) {
      // save notification
      this.saveNotification({
        message: notificationMessage,
        informedId: id
      });

      // send push
      this.sendPush({
        message: `${this.userInp.name} has commented project task ${this.task.name}: ${form.value.messageText.replace(/<[^>]*>/g, '')} `,
        informedId: id
      });
    }

    // reset form
    form.resetForm();
  }

  onDeleteMessageFile(index) {
    this.messageFiles.splice(index, 1);
  }

  onDeleteTaskFile(index) {
    this.taskFileRemove = this.task.files[index];
    this.task.files[index].isActive = false;
    this.expandImage = false;
    this.onUpdate('fileRemove');
  }

  onRemove() {
    this.task.isActive = false;
    this.onUpdate('isActive');
  }

  onUndoRemove() {
    this.task.isActive = true;
    this.onUpdate('isRemoved');
  }

  onChangeType($event) {
    this.task.type = $event.value;
    this.onUpdate('type');
  }

  onChangeDescription($event) {
    if (($event.range === null) && ($event.oldRange !== null)) {
      this.onUpdate('description');
    }
  }

  onChangePriority($event) {
    this.task.priority = $event.value;
    this.onUpdate('priority');
  }

  onChangeStatus($event) {
    this.task.status = $event.value;
    this.onUpdate('status');
  }

  onChangeDateStart() {
    if (!this.dateEndDatepicker) {
      this.dateEndDatepicker = new Date(this.dateStartDatepicker.getTime());
      this.onUpdate('dateStartEnd');
    } else if (this.dateStartDatepicker.getTime() <= (this.task.dateEnd.seconds * 1000)) {
      this.onUpdate('dateStart');
    } else {
      this.dateStartDatepicker = new Date(this.dateStartDatepicker.getTime());
      this.dateEndDatepicker = new Date(this.dateStartDatepicker.getTime());
      this.onUpdate('dateStartEnd');
    }
  }

  onChangeDateEnd() {
    if (!this.dateStartDatepicker) {
      this.dateStartDatepicker = new Date(this.dateEndDatepicker.getTime());
      this.onUpdate('dateStartEnd');
    } else if (this.dateEndDatepicker.getTime() >= (this.task.dateStart.seconds * 1000)) {
      this.onUpdate('dateEnd');
    } else {
      this.dateStartDatepicker = new Date(this.dateEndDatepicker.getTime());
      this.dateEndDatepicker = new Date(this.dateEndDatepicker.getTime());
      this.onUpdate('dateStartEnd');
    }
  }

  onDateRemove() {
    this.dateStartDatepicker = '';
    this.dateEndDatepicker = '';
    this.onUpdate('dateStartEnd');
  }

  onUploadMessage(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') { // when all files added in queue
      this.startUploadMessage();
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
      this.files.push(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      console.log('uploading');
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      console.log('removed');
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
  }

  startUploadMessage(): void {
    for (const file of this.files) {
      // start progress bar
      // this.progress.ref().start();
      // show snackbar
      this.snackBar.open(`Uploading file(s)`, '', environment.snackbarInfo);
      // prepare file
      const fileNameArray = file.name.split('.');
      const name = this.afs.createId() + '.' + fileNameArray[fileNameArray.length - 1];
      const path = `/${this.userInp.companyRef.id}/projects/${this.task.projectRef.id}/${name}`;
      this.messagesService.uploadFile(file.nativeFile, path, file.name).then(snapshot => {
        // push file to message
        snapshot.ref.getDownloadURL().then(downloadURL => {
          // push file to array
          this.messageFiles.push({
            name: file.name,
            url: downloadURL,
            type: file.type,
            size: file.size,
            isActive: true
          });
          // close progress bar
          // this.progress.ref().complete();
        });
      });
    }
    // clear files
    this.files = [];
  }

  onUploadDescription(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') { // when all files added in queue
      this.onUpdate('description');
      this.startUploadDescription();
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
      // https://github.com/bleenco/ngx-uploader/issues/330
      this.files.push(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      console.log('uploading');
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      console.log('removed');
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
  }

  startUploadDescription(): void {
    let filesLength = this.files.length;
    this.taskFiles = [];
    // start progress bar
    this.progress.ref().start();
    // show snackbar
    this.snackBar.open(`Uploading file(s)`, '', environment.snackbarInfo);
    for (const file of this.files) {
      // prepare file
      const fileNameArray = file.name.split('.');
      const name = this.afs.createId() + '.' + fileNameArray[fileNameArray.length - 1];
      const path = `/${this.userInp.companyRef.id}/projects/${this.task.projectRef.id}/${name}`;

      // this.tasksService.uploadFile(file.nativeFile, path, file.name);
      this.tasksService.uploadFile(file.nativeFile, path, file.name).then(snapshot => {
        // push file to message
        snapshot.ref.getDownloadURL().then(downloadURL => {
          // push file to array
          this.taskFiles.push({
            name: file.name,
            url: downloadURL,
            type: file.type,
            size: file.size,
            isActive: true
          });
          filesLength--;
          if (filesLength === 0) {
            // close progress bar
            this.progress.ref().complete();
            this.onUpdate('filesAdd');
          }

        });
      });
    }
    // clear files
    this.files = [];
  }

  // cancelUpload(id: string): void {
  //     this.uploadInput.emit({type: 'cancel', id: id});
  // }
  //
  // removeFile(id: string): void {
  //     this.uploadInput.emit({type: 'remove', id: id});
  // }
  //
  // removeAllFiles(): void {
  //     this.uploadInput.emit({type: 'removeAll'});
  // }

  filterWeekend = (d: Date): boolean => {
    let day;
    if (d) {
      day = d.getDay();
    }
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  autocompleteDisplayTodo(user?: UserType): string | undefined {
    return user ? user.name : undefined;
  }

  autocompleteSelectedTodo() {
    if (this.todo.uid !== this.todoCopy.uid) {
      this.task.userTodoRef = this.afs.doc(`users/${this.todo.uid}`).ref;
      this.onUpdate('userTodoRef');
    }
  }

  autocompleteDisplayAuthor(user?: UserType): string | undefined {
    return user ? user.name : undefined;
  }

  autocompleteSelectedAuthor() {
    if (this.author.uid !== this.authorCopy.uid) {
      this.task.userAuthorRef = this.afs.doc(`users/${this.author.uid}`).ref;
      this.onUpdate('userAuthorRef');
    }
  }

  autocompleteSelectedGroup() {
    this.task.group = this.group;
    this.onUpdate('group');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
