<mat-card class="mat-card--dashboard">
  <mat-card-header>
    <mat-card-title>Social media</mat-card-title>
    <mat-card-subtitle>Check posts status</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="mat-card-settings">
      <div>
        <a mat-stroked-button (click)="prevMonth()">Prev month</a>
        <span class="dates">{{ year }}-{{ month | number:'2.0' }}</span>
        <a mat-stroked-button (click)="nextMonth()">Next month</a>
      </div>
    </div>

    <div class="spinner" *ngIf="!brandsComplete || !postsComplete">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>

    <table class="brands" *ngIf="brandsComplete && postsComplete">
      <ng-container *ngFor="let brand of brands">
        <tr *ngIf="brand.access || user._rolesName === 'admin' || user._privileges.canCreateBrand">
          <td class="brand">
            <a routerLink="/brands/{{ brand.uid }}" class="brand-link">
              <div class="brand-avatar"
                   *ngIf="brand.imageURL"
                   [ngStyle]="{'background-image': 'url(' + brand.imageURL + ')'}">
              </div>
              <div class="brand-avatar"
                   *ngIf="!brand.imageURL">{{ brand.name | avatar }}
              </div>
              <div class="brand-title">
                {{ brand.name }}<br>
                <small>{{ brand.shortDesc }}</small>
              </div>
            </a>
          </td>
          <td class="status">
            <mat-chip-list *ngIf="brand.countStatus">
              <mat-chip class="mat-chip--hide"
                        routerLink="/brands/{{ brand.uid }}/calendar/{{ year }}/{{ month }}/hide"
                        *ngIf="user._privileges.canSeeHiddenPost || user._rolesName === 'admin'"
                        [ngClass]="{'mat-chip--fill': brand.countStatus.hide}">Hide
                <small>{{ brand.countStatus.hide }}</small>
              </mat-chip>
            </mat-chip-list>
          </td>
          <td class="status">
            <mat-chip-list *ngIf="brand.countStatus">
              <mat-chip class="mat-chip--social"
                        routerLink="/brands/{{ brand.uid }}/calendar/{{ year }}/{{ month }}/social"
                        [ngClass]="{'mat-chip--fill': brand.countStatus.social}">Social
                <small>{{ brand.countStatus.social }}</small>
              </mat-chip>
            </mat-chip-list>
          </td>
          <td class="status">
            <mat-chip-list *ngIf="brand.countStatus">
              <mat-chip class="mat-chip--graphic"
                        routerLink="/brands/{{ brand.uid }}/calendar/{{ year }}/{{ month }}/graphic"
                        [ngClass]="{'mat-chip--fill': brand.countStatus.graphic}">Graphic
                <small>{{ brand.countStatus.graphic }}</small>
              </mat-chip>
            </mat-chip-list>
          </td>
          <td class="status">
            <mat-chip-list *ngIf="brand.countStatus">
              <mat-chip class="mat-chip--client"
                        routerLink="/brands/{{ brand.uid }}/calendar/{{ year }}/{{ month }}/client"
                        [ngClass]="{'mat-chip--fill': brand.countStatus.client}">Client
                <small>{{ brand.countStatus.client }}</small>
              </mat-chip>
            </mat-chip-list>
          </td>
          <td class="status">
            <mat-chip-list *ngIf="brand.countStatus">
              <mat-chip class="mat-chip--accept"
                        routerLink="/brands/{{ brand.uid }}/calendar/{{ year }}/{{ month }}/accept"
                        [ngClass]="{'mat-chip--fill': brand.countStatus.accept}">Accept
                <small>{{ brand.countStatus.accept }}</small>
              </mat-chip>
            </mat-chip-list>
          </td>
          <td class="status">
            <mat-chip-list *ngIf="brand.countStatus">
              <mat-chip class="mat-chip--publish"
                        routerLink="/brands/{{ brand.uid }}/calendar/{{ year }}/{{ month }}/publish"
                        [ngClass]="{'mat-chip--fill': brand.countStatus.publish}">Publish
                <small>{{ brand.countStatus.publish }}</small>
              </mat-chip>
            </mat-chip-list>
          </td>
        </tr>
      </ng-container>
    </table>
  </mat-card-content>
</mat-card>
