import {Inject, Injectable} from '@angular/core';
import {FirebaseApp} from '@angular/fire';
import '@firebase/messaging';
import * as firebase from 'firebase/app';
import {AngularFireAuth} from '@angular/fire/auth';
import {UsersService} from './users.service';
import {Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../environments/environment';
import { UserType } from '../_types/user.type';


@Injectable({
  providedIn: 'root'
})
export class FcmService {

  // @ts-ignore
  private messaging: firebase.messaging.Messaging;
  private messageSource = new Subject();

  // tslint:disable-next-line:variable-name
  constructor(@Inject(FirebaseApp) private _firebaseApp: firebase.app.App,
              private afAuth: AngularFireAuth,
              private usersService: UsersService,
              private http: HttpClient,
              private snackBar: MatSnackBar) {

    if (!('serviceWorker' in navigator)) {
      return;
    }

    if (!('PushManager' in window)) {
      return;
    }

    this.messaging = firebase.messaging(this._firebaseApp);
  }

  // get permission to send messages
  getPermission(user: UserType): void {
    if (this.messaging) {
      this.messaging.requestPermission()
        .then(() => {
          this.messaging.usePublicVapidKey(environment.pushKey);
          return this.messaging.getToken();
        })
        .then((token: string) => {
          this.saveToken(user, token);
          this.monitorRefresh(user);
          this.receiveMessage();
        })
        .catch(() => {
        });
    }
  }

  // send message
  sendMessage(user: UserType, pushMessage: any): void {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': `application/json`,
                Authorization: `key=${environment.serverKey}`
            })
        };
        if (user.fcmTokens) {
            for (const token of Object.keys(user.fcmTokens)) {
                const notification: any = {};
                notification.title = pushMessage.title;
                notification.body = pushMessage.body;
                notification.click_action = pushMessage.click_action;
                notification.icon = '/assets/favicon/android-icon-144x144.png';
                notification.badge = '/assets/favicon/android-icon-144x144.png';
                notification.vibrate = [200, 100, 200];
                notification.tag = 'soteam';

                const body = {
                    notification,
                    to: user.uid + '-' + token
                };

                this.http.post('https://fcm.googleapis.com/fcm/send', body, httpOptions).subscribe((next) => {
                    console.log('pushNotification has been sent');
                    // console.log(next);
                }, err => {
                    console.error(err);
                });
            }
        }
    }

    // listen for token refresh
    private monitorRefresh(user: UserType): void {
        this.messaging.onTokenRefresh(() => {
            this.messaging.getToken()
                .then((refreshedToken: string) => {
                    this.saveToken(user, refreshedToken);
                })
                .catch((err: any) => console.log(err, 'Unable to retrieve new token'));
        });
    }

    // save the permission token in firestore
    private saveToken(user: UserType, token: string): void {
        const currentTokens = user.fcmTokens || {};
        if (!currentTokens[token]) {
            const tokens = {...currentTokens, [user.uid + '-' + token]: true};
            this.usersService.updateUser({
                fcmTokens: tokens
            }, user.uid).then();
        }
    }

    // used to show message when app is open
    private receiveMessage(): void {
        if (this.messaging) {
            this.messaging.onMessage((payload: any) => {
                this.snackBar.open(`${payload.notification.title}: ${payload.notification.body}`, '', environment.snackbarInfo);
                this.messageSource.next(payload);
            });
        }
    }
}
