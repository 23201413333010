import {Component, EventEmitter, OnInit} from '@angular/core';
import {ServicesService} from '../../../_services/services.service';
import {ServicesComponent} from '../services.component';
import {ServiceType} from '../../../_types/service.type';
import {Subscription} from 'rxjs/internal/Subscription';
import {ActivatedRoute} from '@angular/router';
import {UserType} from '../../../_types/user.type';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UploaderOptions, UploadFile, UploadInput, UploadOutput} from 'ngx-uploader';
import {NgProgress} from 'ngx-progressbar';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.scss']
})
export class ServiceDetailsComponent implements OnInit {

  user: UserType | any;
  service: ServiceType | any;

  file: UploadFile | any;
  options: UploaderOptions | any;
  uploadInput: EventEmitter<UploadInput>  | any;

  dragOver: boolean | any;
  showRemove = false;

  private subscriptions: Array<Subscription> = [];

  constructor(public servicesComponent: ServicesComponent,
              private servicesService: ServicesService,
              private route: ActivatedRoute,
              private progress: NgProgress,
              private snackBar: MatSnackBar) {

    // set params
    this.subscriptions.push(this.route.params.subscribe(params => {
      this.servicesComponent.setNav(params.uid);

      // set user
      // @ts-ignore
      this.user = this.route.snapshot.parent.parent.data.user;

      // get service
      this.getService();
    }));
  }

  ngOnInit(): void {
  }

  private getService(): void {
    // get service once
    this.servicesService.getServiceOnce(this.servicesComponent.uid).then((service: any) => {
      this.service = service.data() as ServiceType;
      this.servicesComponent.title = this.service.name;
    });
  }

  onSave(): void {
    this.progress.ref().start();
  }

  onUndoRemove(): void {

  }

  onRemove(): void {

  }

  onRemoveFile(): void {

  }

  onUpload(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') { // when all files added in queue
      // this.startUpload();
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
      this.file = output.file;
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      // update current data in files array for uploading file
      // const index = this.file.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.file = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      // this.file = this.file.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
  }
}
