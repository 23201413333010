import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ChangelogType} from '../_types/changelog.type';

@Injectable({
    providedIn: 'root'
})
export class ChangelogService {

    constructor(private afs: AngularFirestore) {
    }

    // get changelog
    getChangelog(): any {
        return this.afs.collection<ChangelogType>(`changelog`, ref => ref
            .orderBy('datetime', 'desc'))
            .valueChanges();
    }

    // get last changelog
    getChangelogLast(): any {
        return this.afs.collection<ChangelogType>(`changelog`, ref => ref
            .orderBy('datetime', 'desc')
            .limit(1))
            .valueChanges();
    }
}
