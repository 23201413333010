<mat-list-item
  class="mat-list-item--message"
  [ngClass]="{ 'mat-list-item--auto': message.type == 'auto' }">
  <a matLine class="mat-line--author" *ngIf="message.author" routerLink="/users/{{ message.author.uid }}">
    <div class="avatar-name">
      <div matListAvatar
           *ngIf="message.author.photoURL"
           [ngStyle]="{'background-image': 'url(' + message.author.photoURL + ')'}"></div>
      <div matListAvatar
           *ngIf="!message.author.photoURL">
        {{ message.author.name | avatar }}
      </div>
      <div>
        <div class="mat-line--name">
          <div>{{ message.author.name }} <small>{{ message.author._rolesName }}</small></div>
        </div>
      </div>
    </div>
  </a>
  <div matLine class="mat-line--message">
    <div [outerHTML]="message.message | urlify"></div>

    <mat-accordion *ngIf="message.files.length">
      <ng-container *ngFor="let file of message.files; let i = index">
        <mat-expansion-panel *ngIf="file.isActive">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ file.name }}
            </mat-panel-title>
            <mat-panel-description>
              {{ file.size | fileSize }} ({{ file.type }})
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="mat-expansion-panel-wrapper" [ngClass]="{ 'mat-expansion-panel-wrapper__expand': expandImage }">
            <div class="options">
              <a class="link" download href="{{ file.url }}">Download</a>
              <div class="options--right">
                <span *ngIf="expandImage"
                      (click)="expandImage = !expandImage"
                      class="link-close"><span class="material-icons">clear</span></span>
              </div>
            </div>
            <div class="expansion-panel__image"
                 *ngIf="file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/gif'">
              <img src="{{ file.url }}" (click)="expandImage = !expandImage">
            </div>
          </div>

        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
    <div *ngIf="message.dateCreate" class="date">
      <small>{{ message.dateCreate.toDate() | date: 'yyyy-MM-dd HH:mm' }}</small></div>
  </div>
</mat-list-item>
