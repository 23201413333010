import {Component, OnInit} from '@angular/core';
import {TicketsService} from '../../_services/tickets.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ServiceType} from '../../_types/service.type';
import {ServicesService} from '../../_services/services.service';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {

  ticket: any;
  service: ServiceType | undefined;
  ticketUid: string | undefined;
  slugService: any;

  private subscriptions: Array<Subscription> = [];

  constructor(private route: ActivatedRoute,
              private ticketsServices: TicketsService,
              private servicesServices: ServicesService) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.route.params.subscribe(params => {
      this.ticketUid = params.uidTicket;
      this.slugService = params.slug;

      // get service
      this.getService();
    }));
  }

  private getService(): void {
    this.subscriptions.push(this.servicesServices.getService(this.slugService).subscribe(service => {
      this.service = service[0];
    }));
  }

}
