<section class="page-section">
  <mat-card class="mat-card--calendar">
    <mat-card-header>
      <div class="row">
        <div class="col header">
          <a mat-stroked-button
             routerLink="/projects/{{ projectsComponent.uid }}/schedule/{{ prevYear }}/{{ prevMonth }}">Prev
            month</a>
          <span class="date">{{ year }}-{{ month | number:'2.0' }}</span>
          <a mat-stroked-button
             routerLink="/projects/{{ projectsComponent.uid }}/schedule/{{ nextYear }}/{{ nextMonth }}">Next
            month</a>
          <mat-form-field>
            <input matInput placeholder="Search" type="search" [(ngModel)]="searchTask">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">

          <div class="month d-flex flex-row" cdkDropListGroup>
            <div *ngFor="let day of days"
                 class="day"
                 [ngClass]="{'isWeekendStart': day.isWeekendStart, 'isWeekendEnd': day.isWeekendEnd, 'isToday': day.isToday}">
              <div class="dayInfo">
                {{ day.date | date : 'd' }}<br>
                {{ day.date | date : 'E' }}
              </div>
              <div class="hours">
                <div class="hour"
                     *ngFor="let todoHour of maxTodoHours"></div>
              </div>
              <div class="tasks"
                   [ngClass]="{'tasks--drop': !day.tasks.length}"
                   *ngIf="user && (user._privileges.canGetTask || user._rolesName === 'admin') && !day.isWeekendStart && !day.isWeekendEnd"
                   cdkDropList
                   [cdkDropListData]="day"
                   (cdkDropListDropped)="dropTask($event)">
                <div *ngFor="let task of day.tasks | filter:searchTask:['name']"
                     cdkDrag
                     [cdkDragData]="task">
                  <div class="task"
                       *ngIf="task.userTodo"
                       matTooltip="{{ task.name }} - {{ task.userTodo.name }} ({{ task.priority }})"
                       matTooltipPosition="above"
                       [ngClass]="{'task--start': task.taskStart, 'task--end': task.taskEnd, 'task--enter': (task.uid == enterTaskUid), 'task--lowest': (task.priority == 'lowest'), 'task--low': (task.priority == 'low'), 'task--normal': (task.priority == 'normal'), 'task--high': (task.priority == 'high'), 'task--highest': (task.priority == 'highest'), 'task--done': (task.status == 'done'), 'task--inprogress': (task.status == 'inProgress'), 'task--tocheck': (task.status == 'toCheck'), 'task--feature': (task.type == 'feature'), 'task--bug': (task.type == 'bug'), 'task--check': (task.type == 'check')}"
                       [ngStyle]="{'height.px': task.estimationDay * 20}"
                       (click)="onOpenDialog(task)"
                       (mouseenter)="onEnterTask(task)"
                       (mouseleave)="onLeaveTask()">
                  </div>
                </div>
                <div class="hour" *ngIf="day.hours > 0"><small>{{ day.hours }} h</small></div>
              </div>
            </div>
          </div>

        </div>
      </div>

<!--      <div>-->
<!--        asd-->
<!--      </div>-->
    </mat-card-content>
  </mat-card>

  <mat-accordion class="mat-accordion--tasks">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Unscheduled tasks
        </mat-panel-title>
        <mat-panel-description *ngIf="tasksNoDate && tasksNoDateHours">
          ({{ tasksNoDate.length }} / {{ tasksNoDateHours }} h)
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="panel-content">
        <mat-nav-list>
          <mat-list-item *ngFor="let task of tasksNoDate">
            <div mat-line *ngIf="task.userTodo" (click)="onOpenDialog(task)">
              <span class="task"
                    [ngClass]="{'task--lowest': (task.priority == 'lowest'), 'task--low': (task.priority == 'low'), 'task--normal': (task.priority == 'normal'), 'task--high': (task.priority == 'high'), 'task--highest': (task.priority == 'highest'), 'task--done': (task.status == 'done'), 'task--inprogress': (task.status == 'inProgress'), 'task--tocheck': (task.status == 'toCheck'), 'task--bug': (task.type == 'bug'), 'task--check': (task.type == 'check')}"><span
                class="hours">{{ task.estimation }}h</span></span>{{ task.name }} - {{ task.userTodo.name }}
              ({{ task.priority }})
            </div>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <form (ngSubmit)="onSaveTask(f)"
        #f="ngForm"
        autocomplete="off"
        class="new-task">
    <div class="row">
      <!--name-->
      <div class="col-2">
        <mat-form-field class="help-width-100p">
          <input matInput
                 type="text"
                 name="name"
                 #name="ngModel"
                 ngModel
                 required
                 placeholder="Name">
          <mat-error *ngIf="name.hasError('required')">
            Name is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <!--user-->
      <div class="col-2">
        <mat-form-field class="help-width-100p">
          <input matInput
                 name="userTodo"
                 type="text"
                 [(ngModel)]="userTodo"
                 appForbiddenType="string"
                 (blur)="autocompleteBlur()"
                 required
                 placeholder="User todo"
                 [matAutocomplete]="autoUsers">
          <mat-autocomplete
            autoActiveFirstOption
            #autoUsers="matAutocomplete"
            [displayWith]="autocompleteDisplay"
            (optionSelected)="autocompleteSelected($event.option.value)">
            <mat-option *ngFor="let userTodo of users | filter:userTodo:['name']"
                        [value]="userTodo"
                        matTooltip="{{ userTodo.name }}"
                        matTooltipPosition="above">
              {{userTodo.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-error>
            User todo is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <!--date start-->
      <div class="col-2">
        <mat-form-field class="help-width-100p date-input">
          <input name="dateStart"
                 matInput
                 [matDatepickerFilter]="filterWeekend"
                 [matDatepicker]="pickerStart"
                 (dateInput)="dateStartChange()"
                 (dateChange)="dateStartChange()"
                 placeholder="Start date"
                 [(ngModel)]="task.dateStart"
                 #dateStart="ngModel"
                 readonly>
          <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
          <mat-datepicker #pickerStart></mat-datepicker>
        </mat-form-field>
        <span class="date-remove" (click)="onDateRemove()" *ngIf="task.dateStart"><i
          class="material-icons">close</i></span>
      </div>

      <!--date end-->
      <div class="col-2">
        <mat-form-field class="help-width-100p date-input">
          <input name="dateEnd"
                 matInput
                 [matDatepickerFilter]="filterWeekend"
                 [matDatepicker]="pickerEnd"
                 (dateInput)="dateEndChange()"
                 (dateChange)="dateEndChange()"
                 placeholder="End date"
                 [(ngModel)]="task.dateEnd"
                 #dateEnd="ngModel"
                 appCompareDates="task.dateStart"
                 readonly>
          <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
          <mat-datepicker #pickerEnd></mat-datepicker>
        </mat-form-field>
        <span class="date-remove" (click)="onDateRemove()" *ngIf="task.dateEnd"><i
          class="material-icons">close</i></span>
      </div>

      <!--type-->
      <div class="col-1">
        <mat-form-field class="help-width-100p">
          <mat-select name="type"
                      placeholder="Type"
                      [(ngModel)]="task.type"
                      #type="ngModel">
            <mat-option value="feature">Feature</mat-option>
            <mat-option value="bug">Bug</mat-option>
            <mat-option value="check">Check</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!--estimation-->
      <div class="col-1">
        <mat-form-field class="help-width-100p">
          <input matInput
                 type="number"
                 name="estimation"
                 min="0.5"
                 step="0.5"
                 [(ngModel)]="task.estimation"
                 #estimation="ngModel"
                 required
                 placeholder="Estimation">
          <mat-error *ngIf="estimation.hasError('required')">
            Estimation is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <!--submit-->
      <div class="col-2">
        <button type="submit"
                mat-raised-button color="primary"
                [disabled]="f.form.invalid">Save
        </button>
      </div>

    </div>
  </form>

</section>
