import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {ProjectsComponent} from '../projects.component';
import {ActivatedRoute} from '@angular/router';
import {UserType} from '../../../_types/user.type';
import {ProjectType} from '../../../_types/project.type';
import {ProjectsService} from '../../../_services/projects.service';
import {WikiType} from '../../../_types/wiki.type';
import {AngularFirestore} from '@angular/fire/firestore';
import {environment} from '../../../../environments/environment';
import * as firebase from 'firebase/app';
import {MessageType} from '../../../_types/message.type';
import {MessagesService} from '../../../_services/messages.service';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-project-wiki',
    templateUrl: './project-wiki.component.html',
    styleUrls: ['./project-wiki.component.scss']
})
export class ProjectWikiComponent implements OnInit, OnDestroy {

    user: UserType | any;
    project: ProjectType | any;
    versionTimeDif = 3600; // 3600 seconds = 1h

    editorContent: string | any;
    autosaveTimeout: any;

    private subscriptions: Array<Subscription> = [];

    constructor(private projectsComponent: ProjectsComponent,
                private projectsService: ProjectsService,
                private messagesService: MessagesService,
                private afs: AngularFirestore,
                private route: ActivatedRoute,
                private progress: NgProgress,
                private snackBar: MatSnackBar) {


        // set params
        this.subscriptions.push(this.route.params.subscribe(params => {
            this.projectsComponent.setNav(params.uid);

            // set user
            // @ts-ignore
            this.user = this.route.snapshot.parent.parent.data.user;

            // get project
            this.subscriptions.push(this.projectsService.getProject(this.user, this.projectsComponent.uid).subscribe(project => {
                this.project = project;

                this.projectsComponent.title = this.project.name;

                // get last project wiki
                this.editorContent = '';
                if (this.project.wiki.length > 0) {
                    this.editorContent = this.project.wiki[this.project.wiki.length - 1].content;
                }

            }));

        }));
    }

    ngOnInit(): void {
    }

    // onAutosave() {
    //     clearTimeout(this.autosaveTimeout);
    //     this.autosaveTimeout = setTimeout(() => this.onSave(), 10000);
    // }

    onSave(): void {
        this.progress.ref().start();
        // first wiki version
        if (this.project.wiki.length === 0) {
            const wiki: WikiType = {
                content: this.editorContent,
                dateVersion: Math.round(+new Date() / 1000),
                dateUpdate: Math.round(+new Date() / 1000),
                userUpdateRef: this.afs.doc(`users/${this.user.uid}`).ref,
                isActive: true
            };

            this.project.wiki.push(wiki);
            // update project
            this.updateProject();

        } else {
            const dateVersion = this.project.wiki[this.project.wiki.length - 1].dateVersion;
            const timestamp = Math.round(+new Date() / 1000);
            // update last version
            if ((timestamp - dateVersion) < this.versionTimeDif) {
                this.project.wiki[this.project.wiki.length - 1].dateUpdate = timestamp;
                this.project.wiki[this.project.wiki.length - 1].content = this.editorContent;
            } else {
                const wiki: WikiType = {
                    content: this.editorContent,
                    dateVersion: Math.round(+new Date() / 1000),
                    dateUpdate: Math.round(+new Date() / 1000),
                    userUpdateRef: this.afs.doc(`users/${this.user.uid}`).ref,
                    isActive: true
                };
                this.project.wiki.push(wiki);
            }
            // update project
            this.updateProject();
        }

    }

    private updateProject(): void {
        this.projectsService.updateProject({
            wiki: this.project.wiki
        }, this.user, this.project.uid)
            .then(() => {
                const change: MessageType = {
                    uid: this.afs.createId(),
                    message: `has changed the wiki`,
                    dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
                    userRef: this.afs.doc(`users/${this.user.uid}`).ref,
                    files: [],
                    type: 'auto'
                };
                this.messagesService.insertMessage(change, `companies/${this.user.companyRef.id}/projects/${this.project.uid}/changes/${change.uid}`)
                    .then(() => {
                        this.progress.ref().complete();
                        this.snackBar.open('Wiki has been updated', '', environment.snackbarSuccess);
                    })
                    .catch(err => {
                        this.snackBar.open(err.message, '', environment.snackbarWarn);
                    });
            }).catch(err => {
            this.progress.ref().complete();
            this.snackBar.open(err.message, '', environment.snackbarWarn);
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
