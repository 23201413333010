import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AuthComponent} from './auth.component';
import {AuthRoutingModule} from './auth-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import 'hammerjs';

import {LoginComponent} from './login/login.component';
import {EmailComponent} from './email/email.component';
import {PasswordForgotComponent} from './password-forgot/password-forgot.component';
import {RegisterComponent} from './register/register.component';

import { registerLocaleData } from '@angular/common';
import localePL from '@angular/common/locales/pl';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MAT_DATE_LOCALE} from '@angular/material/core';

registerLocaleData(localePL);


@NgModule({
    declarations: [
        AuthComponent,
        LoginComponent,
        EmailComponent,
        PasswordForgotComponent,
        RegisterComponent,
    ],
    imports: [
        BrowserModule,
        AuthRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,

        MatButtonModule,
        MatInputModule,
        MatCardModule,
        MatSnackBarModule,
        MatCheckboxModule,
        MatRadioModule
    ],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'pl-PL'},
    ],
    bootstrap: [AuthComponent]
})
export class AuthModule {
}
