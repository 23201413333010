import {Component, OnInit} from '@angular/core';
import {UsersService} from '../../../_services/users.service';
import {UserType} from '../../../_types/user.type';
import {environment} from '../../../../environments/environment';
import {NgForm} from '@angular/forms';
import {AngularFirestore} from '@angular/fire/firestore';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgProgress} from 'ngx-progressbar';

@Component({
    selector: 'app-profile-settings',
    templateUrl: './profile-settings.component.html',
    styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent implements OnInit {

    user: UserType;
    selectedFiles: FileList;


    constructor(private usersService: UsersService,
                private snackBar: MatSnackBar,
                private route: ActivatedRoute,
                public progress: NgProgress,
                private afs: AngularFirestore) {

    }

    ngOnInit() {
        // set user
        this.user = this.route.snapshot.parent.parent.data.user;
    }

    onSave(form: NgForm) {
        if (form.valid) {
            this.progress.ref().start();
            this.user.name = form.value.name;
            this.user.isEmailNotification = form.value.isEmailNotification;
            if (this.selectedFiles) {
                // upload file
                const file = this.selectedFiles[0];
                const fileNameArray = file.name.split('.');
                const name = this.afs.createId() + '.' + fileNameArray[fileNameArray.length - 1];
                const path = `/${this.user.companyRef.id}/users/${name}`;
                this.usersService.uploadPicture(file, path).then(snapshot => {
                    // update profile
                    snapshot.ref.getDownloadURL().then(downloadURL => {
                        this.user.photoURL = downloadURL;
                        this.onUpdate();

                    });
                });
                this.selectedFiles = null;
            } else {
                // update profile
                this.onUpdate();
            }
        }
    }

    chooseFile(event) {
        this.selectedFiles = event.target.files;
    }

    onDeletePhoto() {
        this.user.photoURL = '';
    }

    onUpdate() {
        this.usersService.updateUser({
            name: this.user.name,
            isEmailNotification: this.user.isEmailNotification,
            photoURL:  this.user.photoURL
        }, this.user.uid)
            .then(() => {
                this.progress.ref().complete();
                this.snackBar.open('Profile has been updated', '', environment.snackbarSuccess);
            })
            .catch(err => {
                this.progress.ref().complete();
                this.snackBar.open(err.message, '', environment.snackbarWarn);
            });
    }

}
