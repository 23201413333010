import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {UserType} from '../../../_types/user.type';
import {UsersExternalService} from '../../../_services/users-external.service';
import {UsersComponent} from '../users.component';
import {AngularFirestore} from '@angular/fire/firestore';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {UserExternalType} from '../../../_types/userExternal.type';
import * as firebase from 'firebase/app';
import {environment} from '../../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgProgress} from 'ngx-progressbar';

@Component({
    selector: 'app-user-invite',
    templateUrl: './user-invite.component.html',
    styleUrls: ['./user-invite.component.scss']
})
export class UserInviteComponent implements OnInit, OnDestroy {

    private subscriptionIsExists: Subscription | undefined;

    user: UserType;

    constructor(private usersExternalService: UsersExternalService,
                private usersComponent: UsersComponent,
                private afs: AngularFirestore,
                private progress: NgProgress,
                private snackBar: MatSnackBar,
                private router: Router,
                private route: ActivatedRoute) {

        // set title
        this.usersComponent.title = 'Users';

        // set user
        // @ts-ignore
        this.user = this.route.snapshot.parent.parent.data.user;

        // set nav
        this.usersComponent.setNavExternal();
    }

    ngOnInit(): void {
    }

    onSave(form: NgForm): void {

        this.progress.ref().start();

        // insert user external
        const userExternal: UserExternalType = {
            uid: this.afs.createId(),
            email: form.value.email.toLowerCase(),
            companyRef: this.afs.doc(`companies/${this.user.companyRef.id}`).ref,
            dateAdd: firebase.firestore.FieldValue.serverTimestamp(),
            userAddRef: this.afs.doc(`users/${this.user.uid}`).ref,
            isActive: true,
            invitingName: this.user.name,
            invitingEmail: this.user.email,
            status: 'pending'
        };

        // check duplicate
        this.subscriptionIsExists = this.usersExternalService.isExists(userExternal, this.user).subscribe(usersExternal => {
            if (usersExternal.length) {
                this.progress.ref().complete();
                this.snackBar.open('External user exists', '', environment.snackbarWarn);
            } else {
                // insert external user
                this.subscriptionIsExists?.unsubscribe();
                this.usersExternalService.insertUserExternal(userExternal)
                    .then(() => {
                        this.progress.ref().complete();
                        this.snackBar.open('To external user has been send a message', '', environment.snackbarSuccess);
                        this.router.navigate([`/users/user-invitations`]).then();
                    })
                    .catch(err => {
                        this.progress.ref().complete();
                        this.snackBar.open(err.message, '', environment.snackbarWarn);
                    });
            }
        });
    }

    ngOnDestroy() {
        if (this.subscriptionIsExists) {
            this.subscriptionIsExists.unsubscribe();
        }
    }

}
