import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UsersService} from '../../../_services/users.service';
import {NotificationsService} from '../../../_services/notifications.service';
import {FcmService} from '../../../_services/fcm.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ServicesComponent} from '../services.component';
import {TicketsService} from '../../../_services/tickets.service';
import {ServicesService} from '../../../_services/services.service';
import {Subscription} from 'rxjs';
import {UserType} from '../../../_types/user.type';
import {TicketType} from '../../../_types/ticket.type';
import {SharedTicketDialogComponent} from '../../../_shared/shared-ticket-dialog/shared-ticket-dialog.component';
import {ServiceType} from '../../../_types/service.type';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss']
})
export class ServiceListComponent implements OnInit, OnDestroy {

  today = new Date();
  month: number | any;
  year: number | any;
  nextMonth: number | any;
  nextYear: number | any;
  prevMonth: number | any;
  prevYear: number | any;

  searchTicket: string | any;
  user: UserType | any;
  service: ServiceType | any;
  tickets: TicketType[] = [];
  ticketsComplete = false;
  users: UserType[] | any;
  usersComplete = false;

  private routeSubscription: Subscription;
  private subscriptions: Array<Subscription> = [];

  constructor(public servicesComponent: ServicesComponent,
              private dialog: MatDialog,
              private ticketsService: TicketsService,
              private servicesService: ServicesService,
              private usersService: UsersService,
              private notificationsService: NotificationsService,
              private fcmService: FcmService,
              private route: ActivatedRoute,
              private router: Router) {

    this.routeSubscription = this.route.params.subscribe(params => {

      // unsubscribe all subscriptions excluding route
      this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
      });

      // reset values
      this.ticketsComplete = false;
      this.usersComplete = false;

      // get month and year
      this.month = parseInt(params.month, 10);
      this.year = parseInt(params.year, 10);

      // navigate to calendar current month and year
      if ((!this.month) || (!this.year)) {
        this.router.navigate([`/services/${params.uid}/list/${this.today.getFullYear()}/${this.today.getMonth() + 1}`]);
      }

      // set prev and next month
      this.nextMonth = this.month + 1;
      this.nextYear = this.year;
      if (this.nextMonth === 13) {
        this.nextMonth = 1;
        this.nextYear = this.year + 1;
      }

      this.prevMonth = this.month - 1;
      this.prevYear = this.year;
      if (this.prevMonth === 0) {
        this.prevMonth = 12;
        this.prevYear = this.year - 1;
      }

      // set nav
      this.servicesComponent.setNav(params.uid);

      // set user
      // @ts-ignore
      this.user = this.route.snapshot.parent.parent.data.user;

      // get service
      this.getService();

      // get users
      this.getUsers();
    });
  }

  ngOnInit(): void {
  }

  private getService(): void {
    // get service once
    this.servicesService.getServiceOnce(this.servicesComponent.uid).then((service: any) => {
      this.service = service.data() as ServiceType;
      this.servicesComponent.title = this.service.name;

      // get posts
      this.getTickets();
    });
  }

  private getTickets(): void {
    const month = ('0' + (this.month)).slice(-2);

    this.subscriptions.push(this.ticketsService.getTicketsByServiceMonth(this.service, this.year + '' + month).subscribe(tickets => {
      this.tickets = tickets;

      // sort according to date create
      this.tickets.sort((a: any, b: any) => {
        if ((a.dateCreate) && (b.dateCreate)) {
          return a.dateCreate.seconds - b.dateCreate.seconds;
        }
        return 0;
      });

      this.ticketsComplete = true;
      this.matchUsersToTickets();
    }));
  }

  private getUsers(): void {
    this.users = [];
    this.usersService.onceUsers(this.user.companyRef).then((users: any) => {
      users.forEach((userSnapshot: any) => {
        const user: UserType = {
          uid: userSnapshot.data().uid,
          email: userSnapshot.data().email,
          name: userSnapshot.data().name,
          companyRef: userSnapshot.data().companyRef,
          isEmailVerified: userSnapshot.data().isEmailVerified,
          dateUpdate: userSnapshot.data().dateUpdate,
          userUpdateRef: userSnapshot.data().userUpdateRef,
          isActive: userSnapshot.data().isActive,
          isEmailNotification: userSnapshot.data().isEmailNotification,
          roleRef: userSnapshot.data().roleRef,
          _rolesName: userSnapshot.data()._rolesName,
          photoURL: userSnapshot.data().photoURL,
          changelog: {
            page: '',
            bottomSheet: '',
          }
        };
        this.users.push(user);
      });
      this.usersComplete = true;
      this.matchUsersToTickets();
    });
  }

  private matchUsersToTickets(): void {
    if (this.ticketsComplete && this.usersComplete) {
      this.tickets.forEach(ticket => {
        this.users.forEach((user: any) => {
          if (ticket.userTodoRef.id === user.uid) {
            ticket.userTodo = user;
          }
        });
      });
    }
  }

  onOpenDialog(ticket: any): void {
    this.dialog.open(SharedTicketDialogComponent, {
      height: '100vh',
      autoFocus: false,
      data: {
        ticketUid: ticket.uid,
        serviceUid: this.service.uid,
        user: this.user
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

}
