import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserType} from '../../../_types/user.type';
import {ProjectType} from '../../../_types/project.type';
import {RoleType} from '../../../_types/role.type';
import {Subscription} from 'rxjs/internal/Subscription';
import {ActivatedRoute} from '@angular/router';
import {UsersService} from '../../../_services/users.service';
import {RolesService} from '../../../_services/roles.service';
import {MessagesService} from '../../../_services/messages.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ServicesService} from '../../../_services/services.service';
import {ServicesComponent} from '../services.component';
import {ServiceType} from '../../../_types/service.type';
import {MessageType} from '../../../_types/message.type';
import * as firebase from 'firebase/app';
import {environment} from '../../../../environments/environment';
import {NgProgress} from 'ngx-progressbar';

@Component({
  selector: 'app-service-team',
  templateUrl: './service-team.component.html',
  styleUrls: ['./service-team.component.scss']
})
export class ServiceTeamComponent implements OnInit, OnDestroy {

  userCurrent: UserType | any;
  service: any;
  users: UserType[] = [];
  usersComplete = false;
  roles: RoleType[] = [];

  searchUser: string | any;

  private subscriptions: Array<Subscription> = [];

  constructor(private route: ActivatedRoute,
              private usersService: UsersService,
              private rolesService: RolesService,
              private servicesComponent: ServicesComponent,
              private messagesService: MessagesService,
              private servicesService: ServicesService,
              private afs: AngularFirestore,
              private progress: NgProgress,
              private snackBar: MatSnackBar) {

    // set params
    this.subscriptions.push(this.route.params.subscribe(params => {
      this.servicesComponent.setNav(params.uid);

      // set user
      // @ts-ignore
      this.userCurrent = this.route.snapshot.parent.parent.data.user;

      // get service desk
      this.servicesService.getServiceOnce(this.servicesComponent.uid).then((service: any) => {
        this.service = service.data();

        this.servicesComponent.title = this.service.name;

        // get users and set access variable
        this.getUsers();

      });

    }));
  }

  ngOnInit(): void {
  }

  private getUsers(): void {
    this.subscriptions.push(this.usersService.getUsers(this.userCurrent.companyRef).subscribe(users => {
      users.forEach(user => {
        user.serviceAccess = !!this.service.userRefs[user.uid];
      });
      this.users = users;
      this.usersComplete = true;
    }));
  }

  onChangeAccess(userUid: string): void {
    this.progress.ref().start();

    let message: string;
    let change: MessageType;
    let userName = '';
    this.users.forEach(user => {
      if (user.uid === userUid) {
        userName = user.name;
      }
    });

    if (this.service.userRefs[userUid]) {
      delete this.service.userRefs[userUid];
      message = 'Access to the service desk has been removed';
      change = {
        uid: this.afs.createId(),
        message: `has removed from the team ${userName} `,
        dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
        userRef: this.afs.doc(`users/${this.userCurrent.uid}`).ref,
        files: [],
        type: 'auto'
      };
    } else {
      this.service.userRefs[userUid] = this.afs.doc(`users/${userUid}`).ref;
      message = 'Access to the service desk has been added';
      change = {
        uid: this.afs.createId(),
        message: `has added to the team ${userName}`,
        dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
        userRef: this.afs.doc(`users/${this.userCurrent.uid}`).ref,
        files: [],
        type: 'auto'
      };
    }

    this.servicesService.updateService({
      userRefs: this.service.userRefs
    }, this.service.uid)
      .then(() => {
        this.messagesService.insertMessage(change, `services/${this.service.uid}/changes/${change.uid}`)
          .then(() => {
            this.snackBar.open(message, '', environment.snackbarSuccess);
            this.progress.ref().complete();
          })
          .catch(err => {
            this.snackBar.open(err.message, '', environment.snackbarWarn);
          });
      })
      .catch(err => {
        this.snackBar.open(err.message, '', environment.snackbarWarn);
        this.progress.ref().complete();
      });
    this.progress.ref().complete();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
