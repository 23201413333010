<section class="page-section">
  <mat-card class="mat-card--calendar">
    <mat-card-header>
      <div class="header">
        <div class="date-search">
          <a mat-stroked-button
             routerLink="/users/{{ usersComponent.uid }}/calendar/{{ prevYear }}/{{ prevMonth }}">Prev
            month</a>
          <span class="date">{{ year }}-{{ month | number:'2.0' }}</span>
          <a mat-stroked-button
             routerLink="/users/{{ usersComponent.uid }}/calendar/{{ nextYear }}/{{ nextMonth }}">Next
            month</a>
          <mat-form-field>
            <input matInput placeholder="Search" type="search" [(ngModel)]="search">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>
        <div class="settings">
          <mat-slide-toggle [checked]="showTodo" (change)="onShowTodo()">Show todo</mat-slide-toggle>
          <mat-slide-toggle class="help-margin-left-10px" [checked]="showOrdered" (change)="onShowOrdered()">Show ordered</mat-slide-toggle>
        </div>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">

          <div class="month month--todo d-flex flex-row"
               cdkDropListGroup>

            <div *ngFor="let day of days"
                 class="day"
                 [ngClass]="{'is-weekend-start': day.isWeekendStart, 'is-weekend-end': day.isWeekendEnd, 'is-today': day.isToday}">
              <div class="day-info">
                {{ day.date | date : 'd' }}<br>
                {{ day.date | date : 'E' }}
              </div>

              <ng-container *ngIf="showTodo">
                <div class="hours">
                  <div class="hour"
                       *ngFor="let todoHour of maxTodoHours"></div>
                </div>
                <div class="tasks-tickets"
                     [ngClass]="{'tasks-tickets--drop': !day.tasksTodo.length && !day.tickets.length}"
                     *ngIf="user && !day.isWeekendStart && !day.isWeekendEnd"
                     cdkDropList
                     [cdkDropListData]="day"
                     (cdkDropListDropped)="dropTaskTicket($event)">
                  <div *ngFor="let task of day.tasksTodo | filter:search:['name', 'projectName']"
                       cdkDrag
                       [cdkDragData]="task">
                    <div *ngIf="task.project.access"
                         class="task"
                         matTooltip="{{ task.name }} - {{ task.project.name }} ({{ task.priority }})"
                         matTooltipPosition="above"
                         [ngClass]="{'task--start': task.taskStart, 'task--end': task.taskEnd, 'task--active': (task.uid == taskDetails.uid), 'task--enter': (task.uid == enterTaskUid), 'task--lowest': (task.priority == 'lowest'), 'task--low': (task.priority == 'low'), 'task--normal': (task.priority == 'normal'), 'task--high': (task.priority == 'high'), 'task--highest': (task.priority == 'highest'), 'task--done': (task.status == 'done'), 'task--inprogress': (task.status == 'inProgress'), 'task--tocheck': (task.status == 'toCheck'), 'task--feature': (task.type == 'feature'), 'task--bug': (task.type == 'bug'), 'task--check': (task.type == 'check')}"
                         [ngStyle]="{'height.px': task.estimationDay * 20}"
                         (click)="onOpenDialogTask(task)"
                         (mouseenter)="onEnterTask(task)"
                         (mouseleave)="onLeaveTask()">
                    </div>
                    <div *ngIf="!task.project.access"
                         class="task task--denied"
                         matTooltip="[permission denied] {{ task.name }} - {{ task.project.name }} ({{ task.priority }}) by {{ task.userAuthor.name }}"
                         matTooltipPosition="above"
                         [ngClass]="{'task--start': task.taskStart, 'task--end': task.taskEnd}"
                         [ngStyle]="{'height.px': task.estimationDay * 20}">
                    </div>
                  </div>
                  <div *ngFor="let ticket of day.tickets | filter:search:['name', 'serviceName']"
                       cdkDrag
                       [cdkDragData]="ticket">
                    <div *ngIf="ticket.service.access"
                         class="ticket"
                         matTooltip="{{ ticket.name }} - {{ ticket.service.name }} ({{ ticket.priority }})"
                         matTooltipPosition="above"
                         [ngClass]="{'ticket--start': ticket.ticketStart, 'ticket--end': ticket.ticketEnd, 'ticket--enter': (ticket.uid == enterTicketUid), 'ticket--lowest': (ticket.priority == 'lowest'), 'ticket--low': (ticket.priority == 'low'), 'ticket--normal': (ticket.priority == 'normal'), 'ticket--high': (ticket.priority == 'high'), 'ticket--highest': (ticket.priority == 'highest'), 'ticket--waitingforcustomer': (ticket.status == 'waitingForCustomer'), 'ticket--inprogress': (ticket.status == 'inProgress'), 'ticket--resolved': (ticket.status == 'resolved')}"
                         [ngStyle]="{'height.px': ticket.estimationDay * 20}"
                         (click)="onOpenDialogTicket(ticket)"
                         (mouseenter)="onEnterTicket(ticket)"
                         (mouseleave)="onLeaveTicket()">
                      <div class="icons"><span class="material-icons">support</span></div>
                    </div>
                    <div *ngIf="!ticket.service.access"
                         class="ticket ticket--denied"
                         matTooltip="[permission denied] {{ ticket.name }} - {{ ticket.service.name }} ({{ ticket.priority }})"
                         matTooltipPosition="above"
                         [ngClass]="{'ticket--start': ticket.ticketStart, 'ticket--end': ticket.ticketEnd}"
                         [ngStyle]="{'height.px': ticket.estimationDay * 20}">
                    </div>
                  </div>
                </div>
                <div class="hour" *ngIf="day.hoursTodo > 0">
                  <small>{{ day.hoursTodo }} h</small>
                </div>
              </ng-container>
            </div>
          </div>

          <div *ngIf="showOrdered"
               class="month month--ordered d-flex flex-row"
               cdkDropListGroup>
            <div *ngFor="let day of days"
                 class="day"
                 [ngClass]="{'is-weekend-start': day.isWeekendStart, 'is-weekend-end': day.isWeekendEnd, 'is-today': day.isToday}">
              <div class="hours">
                <div class="hour"
                     *ngFor="let orderedHour of maxOrderedHours"></div>
              </div>
              <div class="tasks-tickets"
                   [ngClass]="{'tasks-tickets--drop': !day.tasksOrdered.length}"
                   *ngIf="user && !day.isWeekendStart && !day.isWeekendEnd"
                   cdkDropList
                   [cdkDropListData]="day"
                   (cdkDropListDropped)="dropTaskTicket($event)">
                <div *ngFor="let task of day.tasksOrdered | filter:search:['name', 'projectName']"
                     cdkDrag
                     [cdkDragData]="task">
                  <div *ngIf="task.project.access"
                       class="task"
                       matTooltip="{{ task.name }} - {{ task.project.name }} ({{ task.priority }}) "
                       matTooltipPosition="above"
                       [ngClass]="{'task--start': task.taskStart, 'task--end': task.taskEnd, 'task--active': (task.uid == taskDetails.uid), 'task--enter': (task.uid == enterTaskUid), 'task--lowest': (task.priority == 'lowest'), 'task--low': (task.priority == 'low'), 'task--normal': (task.priority == 'normal'), 'task--high': (task.priority == 'high'), 'task--highest': (task.priority == 'highest'), 'task--done': (task.status == 'done'), 'task--inprogress': (task.status == 'inProgress'), 'task--tocheck': (task.status == 'toCheck'), 'task--feature': (task.type == 'feature'), 'task--bug': (task.type == 'bug'), 'task--check': (task.type == 'check')}"
                       [ngStyle]="{'height.px': task.estimationDay * 20}"
                       (click)="onOpenDialogTask(task)"
                       (mouseenter)="onEnterTask(task)"
                       (mouseleave)="onLeaveTask()">
                  </div>
                  <div *ngIf="!task.project.access"
                       class="task task--denied"
                       matTooltip="[permission denied] {{ task.name }} - {{ task.project.name }} ({{ task.priority }}) by {{ task.userAuthor.name }}"
                       matTooltipPosition="above"
                       [ngClass]="{'task--start': task.taskStart, 'task--end': task.taskEnd}"
                       [ngStyle]="{'height.px': task.estimationDay * 20}">
                  </div>
                </div>
              </div>
              <div class="hour" *ngIf="day.hoursOrdered > 0">
                <small>{{ day.hoursOrdered }} h</small>
              </div>
            </div>
          </div>

        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-accordion class="mat-accordion--tasks">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Unscheduled todo tasks
        </mat-panel-title>
        <mat-panel-description *ngIf="tasksTodoNoDate && tasksTodoNoDateHours">
          ({{ tasksTodoNoDate.length }} / {{ tasksTodoNoDateHours }} h)
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="panel-content">
        <div *ngIf="tasksTodoNoDate && !tasksTodoNoDate.length">No unscheduled todo tasks</div>
        <mat-nav-list *ngIf="tasksTodoNoDate && tasksTodoNoDate.length">
          <mat-list-item *ngFor="let task of tasksTodoNoDate">
            <div mat-line (click)="onOpenDialogTask(task)">
              <span class="task"
                    [ngClass]="{'task--lowest': (task.priority == 'lowest'), 'task--low': (task.priority == 'low'), 'task--normal': (task.priority == 'normal'), 'task--high': (task.priority == 'high'), 'task--highest': (task.priority == 'highest'), 'task--done': (task.status == 'done'), 'task--inprogress': (task.status == 'inProgress'), 'task--tocheck': (task.status == 'toCheck'), 'task--bug': (task.type == 'bug'), 'task--check': (task.type == 'check')}"><span
                class="hours">{{ task.estimation }}h</span></span>{{ task.name }} - {{ task.projectName }}
              ({{task.priority}})
            </div>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Unscheduled ordered tasks
        </mat-panel-title>
        <mat-panel-description *ngIf="tasksOrderedNoDate && tasksOrderedNoDateHours">
          ({{ tasksOrderedNoDate.length }} / {{ tasksOrderedNoDateHours }} h)
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="panel-content">
        <div *ngIf="tasksOrderedNoDate && !tasksOrderedNoDate.length">No unscheduled ordered tasks</div>
        <mat-nav-list *ngIf="tasksOrderedNoDate && tasksOrderedNoDate.length">
          <mat-list-item *ngFor="let task of tasksOrderedNoDate">
            <div mat-line *ngIf="task.projectName" (click)="onOpenDialogTask(task)">
              <span class="task"
                    [ngClass]="{'task--lowest': (task.priority == 'lowest'), 'task--low': (task.priority == 'low'), 'task--normal': (task.priority == 'normal'), 'task--high': (task.priority == 'high'), 'task--highest': (task.priority == 'highest'), 'task--done': (task.status == 'done'), 'task--inprogress': (task.status == 'inProgress'), 'task--tocheck': (task.status == 'toCheck'), 'task--bug': (task.type == 'bug'), 'task--check': (task.type == 'check')}"><span
                class="hours">{{ task.estimation }}h</span></span>{{ task.name }} - {{ task.projectName }}
              ({{task.priority}})
            </div>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <form (ngSubmit)="onSaveTask(f)"
        #f="ngForm"
        autocomplete="off"
        *ngIf="user && (user._privileges.canGetProject || user._rolesName === 'admin')"
        class="new-task">
    <div class="row">
      <div class="col">
        <h2>Add task</h2>
      </div>
    </div>
    <div class="row">
      <!--name-->
      <div class="col-2">
        <mat-form-field class="help-width-100p">
          <input matInput
                 type="text"
                 name="name"
                 #name="ngModel"
                 ngModel
                 required
                 placeholder="Name">
          <mat-error *ngIf="name.hasError('required')">
            Name is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <!--project-->
      <div class="col-2">
        <mat-form-field class="help-width-100p">
          <input matInput
                 name="project"
                 type="text"
                 [(ngModel)]="project"
                 appForbiddenType="string"
                 (blur)="autocompleteBlur()"
                 required
                 placeholder="Project"
                 [matAutocomplete]="autoProjects">
          <mat-autocomplete
            autoActiveFirstOption
            #autoProjects="matAutocomplete"
            [displayWith]="autocompleteDisplay"
            (optionSelected)="autocompleteSelected($event.option.value)">
            <mat-option *ngFor="let project of commonProjects | filter:project:['name']"
                        [value]="project"
                        matTooltip="{{ project.name }}"
                        matTooltipPosition="above">
              {{project.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-error>
            Project is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <!--date start-->
      <div class="col-2">
        <mat-form-field class="help-width-100p date-input">
          <input name="dateStart"
                 matInput
                 [matDatepickerFilter]="filterWeekend"
                 [matDatepicker]="pickerStart"
                 (dateInput)="dateStartChange()"
                 (dateChange)="dateStartChange()"
                 placeholder="Start date"
                 [(ngModel)]="task.dateStart"
                 #dateStart="ngModel"
                 readonly>
          <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
          <mat-datepicker #pickerStart></mat-datepicker>
        </mat-form-field>
        <span class="date-remove" (click)="onDateRemove()" *ngIf="task.dateStart"><i
          class="material-icons">close</i></span>
      </div>

      <!--date end-->
      <div class="col-2">
        <mat-form-field class="help-width-100p date-input">
          <input name="dateEnd"
                 matInput
                 [matDatepickerFilter]="filterWeekend"
                 [matDatepicker]="pickerEnd"
                 (dateInput)="dateEndChange()"
                 (dateChange)="dateEndChange()"
                 placeholder="End date"
                 [(ngModel)]="task.dateEnd"
                 #dateEnd="ngModel"
                 appCompareDates="task.dateStart"
                 readonly>
          <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
          <mat-datepicker #pickerEnd></mat-datepicker>
        </mat-form-field>
        <span class="date-remove" (click)="onDateRemove()" *ngIf="task.dateEnd"><i
          class="material-icons">close</i></span>
      </div>

      <!--type-->
      <div class="col-1">
        <mat-form-field class="help-width-100p">
          <mat-select name="type"
                      placeholder="Type"
                      [(ngModel)]="task.type"
                      #type="ngModel">
            <mat-option value="feature">Feature</mat-option>
            <mat-option value="bug">Bug</mat-option>
            <mat-option value="check">Check</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!--estimation-->
      <div class="col-1">
        <mat-form-field class="help-width-100p">
          <input matInput
                 type="number"
                 name="estimation"
                 min="0.5"
                 step="0.5"
                 [(ngModel)]="task.estimation"
                 #estimation="ngModel"
                 required
                 placeholder="Estimation">
          <mat-error *ngIf="estimation.hasError('required')">
            Estimation is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <!--submit-->
      <div class="col-2">
        <button type="submit"
                mat-raised-button color="primary"
                [disabled]="f.form.invalid">Save
        </button>
      </div>

    </div>
  </form>
</section>
