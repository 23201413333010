import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {UserType} from '../_types/user.type';
import {MessageType} from '../_types/message.type';
import {map} from 'rxjs/operators';
import {BrandType} from '../_types/brand.type';
import * as firebase from 'firebase/app';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class BrandsService {

  constructor(private afs: AngularFirestore) {
  }

  // check access to brand
  private checkAccess = (brand: BrandType, user: UserType) => {
    let access = false;
    if (brand.userRefs[user.uid]) {
      access = true;
    }
    return access;
  }

  // get brand
  getBrand(user: UserType, brandUid: string): Observable<BrandType | undefined> {
    return this.afs.doc<BrandType>(`companies/${user.companyRef.id}/brands/${brandUid}`).valueChanges();
  }

  // get brand once
  getBrandOnce(user: UserType, brandUid: string): any {
    const db = firebase.firestore();
    return db.doc(`companies/${user.companyRef.id}/brands/${brandUid}`).get();
  }

  // get brand changes
  getBrandChanges(user: UserType, brandUid: string): Observable<MessageType[]> {
    return this.afs.collection<MessageType>(`companies/${user.companyRef.id}/brands/${brandUid}/changes`, ref => ref
      .orderBy('dateCreate'))
      .valueChanges();
  }

  // get brands
  getBrands(user: UserType): Observable<BrandType[]> {
    return this.afs.collection<BrandType>(`companies/${user.companyRef.id}/brands`, ref => ref
      .where('isActive', '==', true)
      .orderBy('name'))
      .valueChanges()
      .pipe(map(brands => {
        // check access to brand
        for (const brand of brands) {
          brand.access = this.checkAccess(brand, user);
        }
        return brands;
      }));
  }

  // brands once
  // getBrandsOnce(user: UserType) {
  //   const db = firebase.firestore();
  //   return db.collection(`companies/${user.companyRef.id}/brands`)
  //     .where('isActive', '==', true)
  //     .orderBy('name')
  //     .get();
  // }


  // get brands access
  // getBrandsAccess(user: UserType) {
  //     return this.afs.collection<BrandType>(`companies/${user.companyRef.id}/brands`, ref => ref
  //         .where('isActive', '==', true)
  //         .where('userRefs.' + user.uid, '>', 0))
  //         .valueChanges();
  // }

  // check duplicates
  isExists(brand: BrandType, user: UserType): Observable<BrandType[]> {
    return this.afs.collection<BrandType>(`companies/${user.companyRef.id}/brands`, ref => ref
      .where('isActive', '==', true)
      .where('name', '==', brand.name))
      .valueChanges();
  }

  // insert brand
  insertBrand(brand: BrandType, user: UserType): Promise<void> {
    return this.afs.doc<BrandType>(`companies/${user.companyRef.id}/brands/${brand.uid}`).set(brand);
  }

  // update brand
  updateBrand(fields: {}, user: UserType, brandUid: string): Promise<void> {
    return this.afs.doc<BrandType>(`companies/${user.companyRef.id}/brands/${brandUid}`).update(fields);
  }

  // upload image
  uploadImage = (file: any, path: string) => {
    const storageRef = firebase.storage().ref();
    return storageRef.child(path).put(file);
  }
}
