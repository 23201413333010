import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UserType} from '../../../_types/user.type';
import {Subscription} from 'rxjs';
import {UsersService} from '../../../_services/users.service';
import {TasksService} from '../../../_services/tasks.service';
import {ProjectsService} from '../../../_services/projects.service';
import {ProjectType} from '../../../_types/project.type';
import {ActivatedRoute} from '@angular/router';
import {SharedTaskDialogComponent} from '../../../_shared/shared-task-dialog/shared-task-dialog.component';
import {environment} from '../../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {NgProgress} from 'ngx-progressbar';

@Component({
  selector: 'app-widget-progress',
  templateUrl: './widget-progress.component.html',
  styleUrls: ['./widget-progress.component.scss']
})
export class WidgetProgressComponent implements OnInit, OnDestroy {

  @Input() users: UserType[];

  userCurrent: UserType;
  monday: Date;
  friday: Date;
  headerWeek: { name: string, date: string, today: boolean }[] = [];
  hiddenUsers: {} = {};
  projects: ProjectType[] = [];
  projectsComplete = false;
  week: {} = {};
  weekComplete = false;

  enterTaskUid = '';

  searchTask: string;

  private subscriptions: Array<Subscription> = [];

  constructor(public progress: NgProgress,
              private dialog: MatDialog,
              public usersService: UsersService,
              private tasksService: TasksService,
              private projectsService: ProjectsService,
              private snackBar: MatSnackBar,
              private route: ActivatedRoute) {
  }

  ngOnInit() {

    // set monday
    this.monday = this.getMonday();

    // set friday
    this.friday = this.addDays(4);

    // set week header
    this.headerWeek = this.getHeaderWeek(new Date(this.monday));

    // set user
    this.userCurrent = this.route.snapshot.parent.data.user;

    // get settings
    if (!this.userCurrent.settings) {
      this.userCurrent.settings = {};
    }

    // get settings data
    if (!this.userCurrent.settingsData) {
      this.userCurrent.settingsData = {
        dashboard_hiddenUsers: {}
      };
    }

    // get tasks
    this.getTasks();

    // get projects
    this.getProjects();
  }

  nextWeek() {
    this.monday = this.addDays(7);
    this.friday = this.addDays(4);
    this.getTasks();
    this.headerWeek = this.getHeaderWeek(this.monday);
  }

  prevWeek() {
    this.monday = this.addDays(-7);
    this.friday = this.addDays(4);
    this.getTasks();
    this.headerWeek = this.getHeaderWeek(this.monday);
  }

  showUser(uid) {
    delete this.userCurrent.settingsData.dashboard_hiddenUsers[uid];
    this.usersService.updateUser({
      settingsData: this.userCurrent.settingsData
    }, this.userCurrent.uid)
      .then(() => {
        this.progress.ref().complete();
        this.snackBar.open('User settings data has been updated', '', environment.snackbarSuccess);
        this.matchTasksToUsersAndProjects();
      })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  hideUser(uid) {
    this.progress.ref().start();
    if (!this.userCurrent.settingsData.dashboard_hiddenUsers) {
      this.userCurrent.settingsData.dashboard_hiddenUsers = {};
    }
    this.userCurrent.settingsData.dashboard_hiddenUsers[uid] = true;
    this.usersService.updateUser({
      settingsData: this.userCurrent.settingsData
    }, this.userCurrent.uid)
      .then(() => {
        this.progress.ref().complete();
        this.snackBar.open('User settings data has been updated', '', environment.snackbarSuccess);
        this.matchTasksToUsersAndProjects();
      })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  onOpenDialog(task) {
    this.dialog.open(SharedTaskDialogComponent, {
      height: '100vh',
      autoFocus: false,
      data: {
        task: task,
        user: this.userCurrent
      }
    });
  }

  onEnterTask(task) {
    this.enterTaskUid = task.uid;
  }

  onLeaveTask() {
    this.enterTaskUid = '';
  }

  onChangeUserSettings(settingName) {
    this.progress.ref().start();
    this.userCurrent.settings['dashboard_weeklyProgress_' + settingName] = !this.userCurrent.settings['dashboard_weeklyProgress_' + settingName];
    this.usersService.updateUser({
      settings: this.userCurrent.settings
    }, this.userCurrent.uid)
      .then(() => {
        this.progress.ref().complete();
        this.snackBar.open('User settings has been updated', '', environment.snackbarSuccess);
        this.matchTasksToUsersAndProjects();
      })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  private getHeaderWeek(monday) {
    const headerWeek: { name: string, date: string, today: boolean }[] = [];
    const headerDay: Date = new Date(monday.getTime());
    const today = new Date();
    let isToday: boolean;

    today.setHours(0, 0, 0, 0);

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    while (headerDay <= this.friday) {
      isToday = false;
      if (headerDay.getTime() === today.getTime()) {
        isToday = true;
      }
      headerWeek.push({
        date: this.getYearMonthDay(headerDay, '-'),
        name: days[headerDay.getDay()],
        today: isToday
      });
      headerDay.setDate(headerDay.getDate() + 1);
    }
    return headerWeek;
  }

  private getMonday() {
    // get all tasks in the week
    const today: any = new Date();
    const day = today.getDay(),
      diff = today.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    const monday = new Date(today.setDate(diff));
    return new Date(monday.setHours(0, 0, 0, 0));
  }

  private addDays(days) {
    const date = new Date(this.monday);
    date.setDate(date.getDate() + days);
    return date;
  }

  private getTasks() {
    if ((this.userCurrent._privileges.canGetProject && this.userCurrent._privileges.canGetTask) || (this.userCurrent._rolesName === 'admin')) {
      const yearWeek = this.getYearWeek(this.monday);
      let yearMonthDay: string;
      const days = [];
      const mondayClone = new Date(this.monday.getTime());
      // prepare arrays with days
      while (mondayClone <= this.friday) {
        days.push(new Date(mondayClone.getTime()));
        mondayClone.setDate(mondayClone.getDate() + 1);
      }
      // get to do tasks (ordered tasks get from author)
      this.subscriptions.push(
        this.tasksService.getTasksTodoByWeekAnyUser(`companies/${this.userCurrent.companyRef.id}/tasks`, yearWeek).subscribe(tasks => {
          // for each day
          days.forEach(day => {
            yearMonthDay = this.getYearMonthDay(day);
            this.week[yearMonthDay] = [];
            // for each task
            tasks.forEach(task => {
              if (task.showInDays[yearMonthDay]) {
                this.week[yearMonthDay].push(task);
              }
            });
          });
          this.weekComplete = true;
          this.matchTasksToUsersAndProjects();
        }));
    }
  }

  private getProjects() {
    if (this.userCurrent._privileges.canGetProject || (this.userCurrent._rolesName === 'admin')) {
      this.subscriptions.push(this.projectsService.getProjectsAll(this.userCurrent).subscribe(projects => {
        this.projects = projects;
        this.projectsComplete = true;
        this.matchTasksToUsersAndProjects();
      }));
    }
  }

  private matchTasksToUsersAndProjects() {
    if (this.projectsComplete && this.weekComplete) {
      let taskDay: Date;
      let yearMonthDay: string;
      let yearMonthDayDash: string;
      const today = new Date();
      let isToday: boolean;
      let helpTask;
      let dateWithoutHours;

      today.setHours(0, 0, 0, 0);

      // foreach user
      for (let i = 0; i < this.users.length; i++) {
        this.users[i]['tasks'] = {};
        taskDay = new Date(this.monday);

        // foreach day
        while (taskDay <= this.friday) {
          yearMonthDay = this.getYearMonthDay(taskDay);
          yearMonthDayDash = this.getYearMonthDay(taskDay, '-');
          isToday = false;

          if (taskDay.getTime() === today.getTime()) {
            isToday = true;
          }

          this.users[i]['tasks'][yearMonthDayDash] = {
            todo: [],
            todoHours: 0,
            ordered: [],
            orderedHours: 0,
            today: isToday
          };

          // foreach task
          for (let j = 0; j < this.week[yearMonthDay].length; j++) {

            // foreach project
            for (let k = 0; k < this.projects.length; k++) {
              if (this.week[yearMonthDay][j].projectRef.id === this.projects[k].uid) {
                this.week[yearMonthDay][j].project = this.projects[k];
                this.week[yearMonthDay][j].projectName = this.projects[k].name; // only for FilterPipe
              }
            }

            // calculate estimation day
            this.week[yearMonthDay][j].estimationDay = this.week[yearMonthDay][j].estimation / Object.keys(this.week[yearMonthDay][j].showInDays).length;

            // set to-do tasks
            if (this.users[i].uid === this.week[yearMonthDay][j].userTodoRef.id) {
              this.users[i]['tasks'][yearMonthDayDash]['todoHours'] += Math.round(this.week[yearMonthDay][j].estimation / Object.keys(this.week[yearMonthDay][j].showInDays).length * 10) / 10;
              helpTask = Object.assign({}, this.week[yearMonthDay][j]);

              helpTask.taskStart = false;
              dateWithoutHours = new Date(helpTask.dateStart.seconds * 1000);
              dateWithoutHours.setHours(0, 0, 0, 0);
              if (dateWithoutHours.getTime() === taskDay.getTime()) {
                helpTask.taskStart = true;
              }

              helpTask.taskEnd = false;
              dateWithoutHours = new Date(helpTask.dateEnd.seconds * 1000);
              dateWithoutHours.setHours(0, 0, 0, 0);
              if (dateWithoutHours.getTime() === taskDay.getTime()) {
                helpTask.taskEnd = true;
              }
              this.users[i]['tasks'][yearMonthDayDash]['todo'].push(helpTask);
            }

            // sort according to date create
            this.users[i]['tasks'][yearMonthDayDash]['todo'].sort((a, b) => {
              if ((a.dateCreate) && (b.dateCreate)) {
                return a.dateCreate.seconds - b.dateCreate.seconds;
              }
              return 0;
            });

            // set ordered tasks
            if (this.users[i].uid === this.week[yearMonthDay][j].userAuthorRef.id) {
              this.users[i]['tasks'][yearMonthDayDash]['orderedHours'] += Math.round(this.week[yearMonthDay][j].estimation / Object.keys(this.week[yearMonthDay][j].showInDays).length * 10) / 10;
              helpTask = Object.assign({}, this.week[yearMonthDay][j]);
              helpTask.taskStart = false;
              dateWithoutHours = new Date(helpTask.dateStart.seconds * 1000);
              dateWithoutHours.setHours(0, 0, 0, 0);
              if (dateWithoutHours.getTime() === taskDay.getTime()) {
                helpTask.taskStart = true;
              }
              helpTask.taskEnd = false;
              dateWithoutHours = new Date(helpTask.dateEnd.seconds * 1000);
              dateWithoutHours.setHours(0, 0, 0, 0);
              if (dateWithoutHours.getTime() === taskDay.getTime()) {
                helpTask.taskEnd = true;
              }
              this.users[i]['tasks'][yearMonthDayDash]['ordered'].push(helpTask);
            }

            // sort according to date create
            this.users[i]['tasks'][yearMonthDayDash]['ordered'].sort((a, b) => {
              if ((a.dateCreate) && (b.dateCreate)) {
                return a.dateCreate.seconds - b.dateCreate.seconds;
              }
              return 0;
            });
          }

          taskDay.setDate(taskDay.getDate() + 1);
        }

      }

    }
  }

  private getYearMonthDay(d: Date, separator: string = '') {
    let dd: any = d.getDate();
    let mm: any = d.getMonth() + 1;
    const yyyy: any = d.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return yyyy + separator + mm + separator + dd;
  }

  private getYearWeek(d: Date) {
    let firstJanuary: Date;
    firstJanuary = new Date(d.getFullYear(), 0, 1);
    return d.getFullYear() + '' + Math.ceil((((d.getTime() - firstJanuary.getTime()) / 86400000) + firstJanuary.getDay() + 1) / 7);
  }


  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
