<ng-container *ngIf="user._rolesName === 'admin' || user._privileges.canGetPost">
  <div class="post" [ngClass]="{ 'post--dialog': view == 'dialog' }">

    <div class="hidden"
         *ngIf="(post.status === 'hide' && user._rolesName !== 'admin' && !user._privileges.canSeeHiddenPost && view === 'single')">
      Post has been hidden.
    </div>

    <div class="deleted" *ngIf="!post.isActive">
      Post has been removed.
      <button mat-stroked-button class="mat-white"
              (click)="onUndoDelete()">Undo remove
      </button>
    </div>

    <ng-container *ngIf="((post.status === 'hide' && user._privileges.canSeeHiddenPost) || user._rolesName === 'admin') || post.status !== 'hide'">

      <div class="settings">
        <button mat-button
                matTooltip="Copy link to post"
                (click)="onCopyLink()"
                matTooltipPosition="above"><i class="material-icons">link</i></button>
        <div>
        <span *ngIf="post.isActive && (user._privileges.canUpdatePost || user._rolesName === 'admin')"
              class="delete">
          <a (click)="showRemove = !showRemove" class="link"
             *ngIf="!showRemove">Delete</a>
          <span *ngIf="showRemove">Are you sure? <a (click)="showRemove = false"
                                                    class="link">No</a>&nbsp;<button
            mat-button
            (click)="onDelete()" color="primary"
            class="link">Yes</button></span>
        </span>
          <button
            *ngIf="(!parentComponent.postEdit || (parentComponent.postEdit.uid !== post.uid)) && (user._privileges.canUpdatePost || user._rolesName === 'admin')"
            class="button--edit mat-raised-button mat-primary" (click)="onEdit();">Edit
          </button>
          <button
            *ngIf="!saveInProgress && parentComponent.postEdit && (parentComponent.postEdit.uid === post.uid) && (user._privileges.canUpdatePost || user._rolesName === 'admin')"
            class="button--edit mat-raised-button mat-primary" (click)="onSave();">Save
          </button>
          <button
            *ngIf="saveInProgress && parentComponent.postEdit"
            class="button--edit mat-raised-button mat-primary" disabled>Saving...
          </button>
        </div>
      </div>

      <mat-list-item class="mat-list-item--post">
        <div mat-line>
          <div class="row row--data">

            <!-- details -->
            <div class="col-8 col--details">
              <h4>Details</h4>
              <div class="row">
                <div class="col-4 col--files">

                  <ul class="uploads" *ngFor="let file of files">
                    <li class="upload">{{file.name}} <span
                      *ngIf="file.progress.data.percentage">({{file.progress.data.percentage}}%)</span></li>
                  </ul>

                  <div
                    *ngIf="parentComponent.postEdit && (parentComponent.postEdit.uid === post.uid) && (user._privileges.canUpdatePost || user._rolesName === 'admin')"
                    class="drop-container"
                    ngFileDrop
                    [options]="options"
                    (uploadOutput)="onUpload($event)"
                    [uploadInput]="uploadInput"
                    [ngClass]="{ 'is-drop-over': dragOver }">
                    <input type="file"
                           ngFileSelect
                           [options]="options"
                           (uploadOutput)="onUpload($event)"
                           [uploadInput]="uploadInput"
                           multiple>
                    <span>Select or drop file(s)</span>
                  </div>

                  <div *ngIf="selectFileInfo && !parentComponent.postEdit"
                       class="select">
                    Please select a file
                  </div>

                  <ng-container *ngFor="let file of post.files; let i = index">
                    <div class="file"
                         *ngIf="file.isActive"
                         [ngClass]="{ 'file--expand': expandFile[i], 'file--selected': post.shouldSelectFile && file.isSelected }">
                      <div class="options">
                        <a class="link" download href="{{ file.url }}">Download</a>
                        <div class="options--right">
                          <div *ngIf="parentComponent.postEdit">
                            <a (click)="showRemoveFile[i] = !showRemoveFile[i]" class="link"
                               *ngIf="!showRemoveFile[i]">Delete</a>
                            <span *ngIf="showRemoveFile[i]"><a
                              (click)="showRemoveFile[i] = false" class="link">No</a>&nbsp;<a
                              (click)="file.isActive = false"
                              class="link">Yes</a></span>
                          </div>
                          <span class="link"
                                (click)="onSelectFile(post, i)"
                                *ngIf="!parentComponent.postEdit && post.shouldSelectFile && !file.isSelected">Select</span>
                          <span class="link"
                                (click)="onUnselectFile(post, i)"
                                *ngIf="!parentComponent.postEdit && post.shouldSelectFile && file.isSelected">Unselect</span>
                          <span *ngIf="expandFile[i]"
                                (click)="expandFile[i] = !expandFile[i]"
                                class="link-close"><span class="material-icons">clear</span></span>
                        </div>
                      </div>

                      <div class="item item--image"
                           *ngIf="file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/gif'">
                        <img class="image" src="{{ file.url }}" (click)="expandFile[i] = !expandFile[i]">
                      </div>

                      <div class="item item--video"
                           *ngIf="file.type === 'video/mp4'">
                        <video class="video"
                               *ngIf="!expandFile[i]"
                               (click)="expandFile[i] = !expandFile[i]">
                          <source src="{{ file.url }}">
                        </video>
                        <video class="video"
                               controls
                               *ngIf="expandFile[i]">
                          <source src="{{ file.url }}">
                        </video>
                        <span class="material-icons material-icons--play"
                              *ngIf="!expandFile[i]"
                              (click)="expandFile[i] = !expandFile[i]">play_arrow</span>
                      </div>

                      <div class="item item--other"
                           *ngIf="file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'image/gif' && file.type !== 'video/mp4'">
                        <div class="name">{{ file.name }}</div>
                        <div class="size"><small>{{ file.size | fileSize }}</small></div>
                      </div>

                    </div>
                  </ng-container>
                  <div class="help-margin-bottom-10px"
                       *ngIf="parentComponent.postEdit && (parentComponent.postEdit.uid === post.uid) && (user._privileges.canUpdatePost || user._rolesName === 'admin')">
                    <mat-checkbox
                      [(ngModel)]="parentComponent.postEdit.shouldSelectFile"
                      (change)="parentComponent.postEdit.shouldSelectFile != parentComponent.postEdit.shouldSelectFile">
                      should be selected
                    </mat-checkbox>
                  </div>
                </div>
                <div class="col-8 col--inputs">
                  <div class="row">
                    <div
                      [ngClass]="user._privileges.canUpdatePost || user._rolesName === 'admin' || post.time ? 'col-6' : 'col-12'">

                      <!-- date -->
                      <mat-form-field
                        *ngIf="parentComponent.postEdit && (parentComponent.postEdit.uid === post.uid) && (user._privileges.canUpdatePost || user._rolesName === 'admin'); else dateReadonly">
                        <input name="date"
                               matInput
                               [matDatepicker]="date"
                               placeholder="Date"
                               [(ngModel)]="parentComponent.postEdit.datepicker"
                               #date="ngModel"
                               required
                               readonly>
                        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                        <mat-datepicker #date></mat-datepicker>
                      </mat-form-field>

                      <ng-template #dateReadonly>
                        <mat-form-field class="mat-form-field--readonly">
                          <input matInput
                                 placeholder="Date"
                                 [matDatepicker]
                                 [(ngModel)]="post.datepicker"
                                 readonly>
                        </mat-form-field>
                      </ng-template>
                      <!-- /date -->

                    </div>

                    <div class="col-6 col--time"
                         *ngIf="user._privileges.canUpdatePost || user._rolesName === 'admin' || post.time">

                      <!-- time -->
                      <mat-form-field
                        *ngIf="parentComponent.postEdit && (parentComponent.postEdit.uid === post.uid) && (user._privileges.canUpdatePost || user._rolesName === 'admin'); else timeReadonly">
                        <input name="time"
                               type="time"
                               #time="ngModel"
                               [(ngModel)]="parentComponent.postEdit.time"
                               matInput
                               placeholder="Time">
                      </mat-form-field>

                      <ng-template #timeReadonly>
                        <mat-form-field class="mat-form-field--readonly">
                          <input matInput
                                 placeholder="Time"
                                 [(ngModel)]="post.time"
                                 readonly>
                        </mat-form-field>
                      </ng-template>
                      <!-- /time -->

                    </div>
                  </div>

                  <div class="row">
                    <div
                      [ngClass]="{ 'col-6': post.platform || (parentComponent.postEdit && parentComponent.postEdit.platform), 'col-12':!post.platform && (!parentComponent.postEdit || !parentComponent.postEdit.platform) }"
                      class="col-6">

                      <!-- platform -->
                      <mat-form-field
                        *ngIf="parentComponent.postEdit && (parentComponent.postEdit.uid === post.uid) && (user._privileges.canUpdatePost || user._rolesName === 'admin'); else platformReadonly">
                        <mat-label>Platform</mat-label>
                        <mat-select placeholder="Platform"
                                    name="platform"
                                    #platform="ngModel"
                                    (selectionChange)="onSelectPlatform(parentComponent.postEdit.platform)"
                                    [(ngModel)]="parentComponent.postEdit.platform">
                          <mat-option *ngFor="let platform of platforms"
                                      [value]="platform.value">
                            {{platform.viewValue}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <ng-template #platformReadonly>
                        <mat-form-field class="mat-form-field--readonly">
                          <input matInput
                                 placeholder="Platform"
                                 [(ngModel)]="post.platformView"
                                 readonly>
                        </mat-form-field>
                      </ng-template>
                      <!-- /platform -->

                    </div>
                    <div class="col-6"
                         *ngIf="post.platform || (parentComponent.postEdit && parentComponent.postEdit.platform)">

                      <!-- type -->
                      <mat-form-field
                        *ngIf="parentComponent.postEdit && (parentComponent.postEdit.uid === post.uid) && (user._privileges.canUpdatePost || user._rolesName === 'admin'); else typeReadonly">
                        <mat-label>Type</mat-label>
                        <mat-select placeholder="Type"
                                    name="type"
                                    #type="ngModel"
                                    [(ngModel)]="parentComponent.postEdit.type">
                          <mat-option *ngFor="let type of types"
                                      [value]="type.value">
                            {{type.viewValue}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <ng-template #typeReadonly>
                        <mat-form-field class="mat-form-field--readonly">
                          <input matInput
                                 placeholder="Type"
                                 [(ngModel)]="post.typeValue"
                                 readonly>
                        </mat-form-field>
                      </ng-template>
                      <!-- /type -->

                    </div>
                  </div>

                  <div class="row">
                    <div class="col">

                      <!-- content -->
                      <mat-form-field
                        *ngIf="parentComponent.postEdit && (parentComponent.postEdit.uid === post.uid) && (user._privileges.canUpdatePost || user._rolesName === 'admin'); else contentReadonly">
                    <textarea matInput
                              class="textarea"
                              name="content"
                              #content="ngModel"
                              [(ngModel)]="parentComponent.postEdit.content"
                              matTextareaAutosize
                              placeholder="Content"></textarea>
                      </mat-form-field>

                      <ng-template #contentReadonly>
                        <mat-form-field class="mat-form-field--readonly">
                      <textarea matInput
                                class="textarea"
                                [(ngModel)]="post.content"
                                matTextareaAutosize
                                placeholder="Content"
                                readonly></textarea>
                        </mat-form-field>
                      </ng-template>
                      <!-- /content -->

                    </div>
                  </div>

                  <div class="row">
                    <div class="col-6">

                      <!-- imageCopy -->
                      <mat-form-field
                        *ngIf="parentComponent.postEdit && (parentComponent.postEdit.uid === post.uid) && (user._privileges.canUpdatePost || user._rolesName === 'admin'); else imageCopyReadonly">
                    <textarea matInput
                              class="textarea"
                              name="imageCopy"
                              #imageCopy="ngModel"
                              [(ngModel)]="parentComponent.postEdit.imageCopy"
                              matTextareaAutosize
                              placeholder="Image copy"></textarea>
                      </mat-form-field>

                      <ng-template #imageCopyReadonly>
                        <mat-form-field class="mat-form-field--readonly">
                      <textarea matInput
                                class="textarea"
                                [(ngModel)]="post.imageCopy"
                                matTextareaAutosize
                                placeholder="Image copy"
                                readonly></textarea>
                        </mat-form-field>
                      </ng-template>
                      <!-- /imageCopy -->

                    </div>
                    <div class="col-6">

                      <!-- imageComments -->
                      <mat-form-field
                        *ngIf="parentComponent.postEdit && (parentComponent.postEdit.uid === post.uid) && (user._privileges.canUpdatePost || user._rolesName === 'admin'); else imageCommentsReadonly">
                    <textarea matInput
                              class="textarea"
                              name="imageComments"
                              #imageComments="ngModel"
                              [(ngModel)]="parentComponent.postEdit.imageComments"
                              matTextareaAutosize
                              placeholder="Image comments"></textarea>
                      </mat-form-field>

                      <ng-template #imageCommentsReadonly>
                        <mat-form-field class="mat-form-field--readonly">
                      <textarea matInput
                                class="textarea"
                                [(ngModel)]="post.imageComments"
                                matTextareaAutosize
                                placeholder="Image comments"
                                readonly></textarea>
                        </mat-form-field>
                      </ng-template>
                      <!-- /imageComments -->

                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!-- /details -->

            <!-- messages -->
            <div class="col-4 col--messages">
              <h4>Comments</h4>
              <form #f="ngForm"
                    (ngSubmit)="onSend(f)"
                    (keydown.enter)="onSend(f)">
                <div matLine>
                  <mat-form-field>
                <textarea matInput
                          name="messageText"
                          #message="ngModel"
                          ngModel
                          placeholder="Write a message and press ENTER..."
                          required></textarea>
                  </mat-form-field>
                </div>
              </form>

              <mat-list class="mat-list-messages">
                <ng-container *ngFor="let message of post.messages; let i = index">
                  <mat-list-item class="mat-list-item--message"
                                 *ngIf="!(hideAutoComments && post.messages[post.messages.length - 1 - i].type == 'auto')"
                                 [ngClass]="{ 'mat-list-item--auto': post.messages[post.messages.length - 1 - i].type == 'auto' }">
                    <div matLine>
                      <ng-container *ngFor="let user of users">
                        <div class="message-user"
                             *ngIf="user.uid === post.messages[post.messages.length - 1 - i].userRef.id">
                          <a
                            routerLink="/users/{{ user.uid }}">{{ user.name }}
                            <small>{{ user._rolesName }}</small>
                          </a></div>
                      </ng-container>
                      <div class="message-message">{{ post.messages[post.messages.length - 1 - i].message }}</div>
                      <div class="message-date">
                        <small>{{ post.messages[post.messages.length - 1 - i].dateCreate.seconds * 1000 | date: 'yyyy-MM-dd HH:mm' }}</small>
                      </div>
                    </div>
                  </mat-list-item>
                </ng-container>
              </mat-list>

            </div>
            <!-- /messages -->

          </div>


        </div>
      </mat-list-item>

      <!-- btns -->
      <div class="btns" ngClass="btns--{{post.status}}">
        <ng-container *ngIf="user._privileges.canSeeHiddenPost || user._rolesName === 'admin'">
          <ng-container
            *ngIf="(user._privileges.canUpdatePostStatusToHide && ((user._privileges.canUpdatePostStatusFromHide && post.status === 'hide') || (user._privileges.canUpdatePostStatusFromSocial && post.status === 'social') || (user._privileges.canUpdatePostStatusFromGraphic && post.status === 'graphic') || (user._privileges.canUpdatePostStatusFromClient && post.status === 'client') || (user._privileges.canUpdatePostStatusFromAccept && post.status === 'accept') || (user._privileges.canUpdatePostStatusFromPublish && post.status === 'publish'))) || user._rolesName === 'admin'; else hide">
            <button class="hide"
                    [ngClass]="{'active': post.status == 'hide'}"
                    mat-stroked-button
                    (click)="onUpdateStatus('hide')">Hide
            </button>
          </ng-container>
          <ng-template #hide>
            <button class="hide"
                    [ngClass]="{'active': post.status == 'hide'}"
                    mat-stroked-button
                    disabled>Hide
            </button>
          </ng-template>
        </ng-container>

        <ng-container
          *ngIf="(user._privileges.canUpdatePostStatusToSocial && ((user._privileges.canUpdatePostStatusFromHide && post.status === 'hide') || (user._privileges.canUpdatePostStatusFromSocial && post.status === 'social') || (user._privileges.canUpdatePostStatusFromGraphic && post.status === 'graphic') || (user._privileges.canUpdatePostStatusFromClient && post.status === 'client') || (user._privileges.canUpdatePostStatusFromAccept && post.status === 'accept') || (user._privileges.canUpdatePostStatusFromPublish && post.status === 'publish'))) || user._rolesName === 'admin'; else social">
          <button class="social"
                  [ngClass]="{'active': post.status == 'social'}"
                  mat-stroked-button
                  (click)="onUpdateStatus('social')">Social
          </button>
        </ng-container>
        <ng-template #social>
          <button class="social"
                  [ngClass]="{'active': post.status == 'social'}"
                  mat-stroked-button
                  disabled>Social
          </button>
        </ng-template>

        <ng-container
          *ngIf="(user._privileges.canUpdatePostStatusToGraphic && ((user._privileges.canUpdatePostStatusFromHide && post.status === 'hide') || (user._privileges.canUpdatePostStatusFromSocial && post.status === 'social') || (user._privileges.canUpdatePostStatusFromGraphic && post.status === 'graphic') || (user._privileges.canUpdatePostStatusFromClient && post.status === 'client') || (user._privileges.canUpdatePostStatusFromAccept && post.status === 'accept') || (user._privileges.canUpdatePostStatusFromPublish && post.status === 'publish'))) || user._rolesName === 'admin'; else graphic">
          <button class="graphic"
                  [ngClass]="{'active': post.status == 'graphic'}"
                  mat-stroked-button
                  (click)="onUpdateStatus('graphic')">Graphic
          </button>
        </ng-container>
        <ng-template #graphic>
          <button class="graphic"
                  [ngClass]="{'active': post.status == 'graphic'}"
                  mat-stroked-button
                  disabled>Graphic
          </button>
        </ng-template>

        <ng-container
          *ngIf="(user._privileges.canUpdatePostStatusToClient && ((user._privileges.canUpdatePostStatusFromHide && post.status === 'hide') || (user._privileges.canUpdatePostStatusFromSocial && post.status === 'social') || (user._privileges.canUpdatePostStatusFromGraphic && post.status === 'graphic') || (user._privileges.canUpdatePostStatusFromClient && post.status === 'client') || (user._privileges.canUpdatePostStatusFromAccept && post.status === 'accept') || (user._privileges.canUpdatePostStatusFromPublish && post.status === 'publish'))) || user._rolesName === 'admin'; else client">
          <button class="client"
                  [ngClass]="{'active': post.status == 'client'}"
                  mat-stroked-button
                  (click)="onUpdateStatus('client')">Client
          </button>
        </ng-container>
        <ng-template #client>
          <button class="client"
                  [ngClass]="{'active': post.status == 'client'}"
                  mat-stroked-button
                  disabled>Client
          </button>
        </ng-template>

        <ng-container
          *ngIf="(!selectFileInfo && user._privileges.canUpdatePostStatusToAccept && ((user._privileges.canUpdatePostStatusFromHide && post.status === 'hide') || (user._privileges.canUpdatePostStatusFromSocial && post.status === 'social') || (user._privileges.canUpdatePostStatusFromGraphic && post.status === 'graphic') || (user._privileges.canUpdatePostStatusFromClient && post.status === 'client') || (user._privileges.canUpdatePostStatusFromAccept && post.status === 'accept') || (user._privileges.canUpdatePostStatusFromPublish && post.status === 'publish'))) || user._rolesName === 'admin'; else accept">
          <button class="accept"
                  [ngClass]="{'active': post.status == 'accept'}"
                  mat-stroked-button
                  (click)="onUpdateStatus('accept')">Accept
          </button>
        </ng-container>
        <ng-template #accept>
          <button class="accept"
                  [ngClass]="{'active': post.status == 'accept'}"
                  mat-stroked-button
                  disabled>Accept
          </button>
        </ng-template>

        <ng-container
          *ngIf="(user._privileges.canUpdatePostStatusToPublish && ((user._privileges.canUpdatePostStatusFromHide && post.status === 'hide') || (user._privileges.canUpdatePostStatusFromSocial && post.status === 'social') || (user._privileges.canUpdatePostStatusFromGraphic && post.status === 'graphic') || (user._privileges.canUpdatePostStatusFromClient && post.status === 'client') || (user._privileges.canUpdatePostStatusFromAccept && post.status === 'accept') || (user._privileges.canUpdatePostStatusFromPublish && post.status === 'publish'))) || user._rolesName === 'admin'; else publish">
          <button class="publish"
                  [ngClass]="{'active': post.status == 'publish'}"
                  mat-stroked-button
                  (click)="onUpdateStatus('publish')">Publish
          </button>
        </ng-container>
        <ng-template #publish>
          <button class="publish"
                  [ngClass]="{'active': post.status == 'publish'}"
                  mat-stroked-button
                  disabled>Publish
          </button>
        </ng-template>
      </div>
      <!-- /btns -->
    </ng-container>
  </div>
</ng-container>
