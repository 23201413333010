import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {NotificationType} from '../_types/notification.type';
import {UserType} from '../_types/user.type';
import * as firebase from 'firebase/app';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private afs: AngularFirestore) {
  }

  // get notifications
  getNotifications(user: UserType): Observable<NotificationType[]> {
    return this.afs.collection<NotificationType>(`companies/${user.companyRef.id}/notifications`, ref => ref
      .where('userInformedRef', '==', this.afs.doc(`users/${user.uid}`).ref)
      .orderBy('dateCreate', 'desc')
      .limit(100))
      .valueChanges();
  }

  // get notifications all
  getNotificationsAll(user: UserType): Observable<NotificationType[]> {
    return this.afs.collection<NotificationType>(`companies/${user.companyRef.id}/notifications`, ref => ref
      .where('userInformedRef', '==', this.afs.doc(`users/${user.uid}`).ref))
      .valueChanges();
  }

  // get notification to merge
  getNotificationsToMerge(path: string, notification: NotificationType): Observable<NotificationType[]> {
    return this.afs.collection<NotificationType>(path, ref => ref
      .where('elementType', '==', notification.elementType)
      .where('elementUid', '==', notification.elementUid)
      .where('isUnread', '==', true)
      .where('userAuthorRef', '==', notification.userAuthorRef)
      .where('userInformedRef', '==', notification.userInformedRef)
      .where('view', '==', notification.view)
      .limit(100))
      .valueChanges();
  }

  // get elementUid notification
  // getNotificationsByElementUid(path: string, user: UserType, elementsUid: string[]) {
  //   return this.afs.collection<NotificationType>(path, ref => ref
  //     .where('elementUid', 'in', elementsUid)
  //     .where('isUnread', '==', true)
  //     .where('userInformedRef', '==', this.afs.doc(`users/${user.uid}`).ref)
  //     .limit(1))
  //     .valueChanges();
  // }

  onceNotificationsToMerge(path: string, notification: NotificationType): any {
    const db = firebase.firestore();
    return db.collection(path).where('elementType', '==', notification.elementType)
      .where('elementUid', '==', notification.elementUid)
      .where('isUnread', '==', true)
      .where('userAuthorRef', '==', notification.userAuthorRef)
      .where('userInformedRef', '==', notification.userInformedRef)
      .where('view', '==', notification.view)
      .limit(100).get();
  }

  // get unreaded notifications
  getNotificationsUnreaded(user: UserType, userInformed: UserType): Observable<NotificationType[]> {
    return this.afs.collection<NotificationType>(`companies/${user.companyRef.id}/notifications`, ref => ref
      .where('userInformedRef', '==', this.afs.doc(`users/${userInformed.uid}`).ref)
      .where('isUnread', '==', true)
      .limit(100))
      .valueChanges().pipe(map(notifications => {
        return notifications;
      }));
  }

  // insert notification
  insertNotification(notification: NotificationType, path: string): Promise<void> {
    return this.afs.doc<NotificationType>(path).set(notification);
  }

  // update notification
  updateNotification(fields: {}, path: string): Promise<void> {
    return this.afs.doc<NotificationType>(path).update(fields);
  }

  // delete notification
  deleteNotification(path: string): Promise<void> {
    return this.afs.doc<NotificationType>(path).delete();
  }

}
