<main>
    <div class="container">
        <div class="row">
            <div class="col">
                <header class="page-header">
                    <h1>{{ info }}</h1>
                </header>
            </div>
        </div>
    </div>
</main>
