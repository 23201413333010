import {Injectable} from '@angular/core';
import {ProjectType} from '../_types/project.type';
import {AngularFirestore} from '@angular/fire/firestore';
import {map, filter} from 'rxjs/operators';
import {UserType} from '../_types/user.type';
import * as firebase from 'firebase/app';
import {MessageType} from '../_types/message.type';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  constructor(private afs: AngularFirestore) {
  }

  // check access to project
  private checkAccess = (project: ProjectType, user: UserType) => {
    let access = false;
    if (project.userRefs[user.uid]) {
      access = true;
    }
    return access;
  }

  // get project
  getProject(user: UserType, projectUid: string): Observable<ProjectType | undefined> {
    return this.afs.doc<ProjectType>(`companies/${user.companyRef.id}/projects/${projectUid}`).valueChanges();
  }

  // get project once
  getProjectOnce(user: UserType, projectUid: string): any {
    const db = firebase.firestore();
    return db.doc(`companies/${user.companyRef.id}/projects/${projectUid}`).get();
  }

  // get project changes
  getProjectChanges(user: UserType, projectUid: string): Observable<MessageType[]> {
    return this.afs.collection<MessageType>(`companies/${user.companyRef.id}/projects/${projectUid}/changes`, ref => ref
      .orderBy('dateCreate'))
      .valueChanges();
  }

  // get projects
  getProjects(user: UserType): Observable<ProjectType[]> {
    return this.afs.collection<ProjectType>(`companies/${user.companyRef.id}/projects`, ref => ref
      .where('isActive', '==', true)
      .orderBy('name'))
      .valueChanges()
      .pipe(map(projects => {
        // check access to project
        for (const project of projects) {
          project.access = this.checkAccess(project, user);
        }
        return projects;
      }));
  }

  // get not active projects
  getProjectsNotActive(user: UserType): Observable<ProjectType[]> {
    return this.afs.collection<ProjectType>(`companies/${user.companyRef.id}/projects`, ref => ref
      .where('isActive', '==', false)
      .orderBy('name'))
      .valueChanges()
      .pipe(map(projects => {
        // check access to project
        for (const project of projects) {
          project.access = this.checkAccess(project, user);
        }
        return projects;
      }));
  }

  // get all projects
  getProjectsAll(user: UserType): Observable<ProjectType[]> {
    return this.afs.collection<ProjectType>(`companies/${user.companyRef.id}/projects`, ref => ref
      .orderBy('name'))
      .valueChanges()
      .pipe(map(projects => {
        // check access to project
        for (const project of projects) {
          project.access = this.checkAccess(project, user);
        }
        return projects;
      }));
  }

  // getFileteredProjects(user: UserType, filter: { name: string, val: any }) {
  //     return this.afs.collection<ProjectType>(`companies/${user.companyRef.id}/projects`, ref => ref
  //         .where(filter.name, '==', filter.val))
  //         .valueChanges()
  // }

  // check duplicates
  isExists(project: ProjectType, user: UserType): Observable<ProjectType[]> {
    return this.afs.collection<ProjectType>(`companies/${user.companyRef.id}/projects`, ref => ref
      .where('isActive', '==', true)
      .where('name', '==', project.name))
      .valueChanges();
  }

  // insert project
  insertProject(project: ProjectType, user: UserType): Promise<void> {
    return this.afs.doc<ProjectType>(`companies/${user.companyRef.id}/projects/${project.uid}`).set(project);
  }

  // update project
  updateProject(fields: {}, user: UserType, projectUid: string): Promise<void> {
    return this.afs.doc<ProjectType>(`companies/${user.companyRef.id}/projects/${projectUid}`).update(fields);
  }

  // upload file
  uploadFile(file: any, path: string, fileName: string): any {
    const metadata = {
      contentDisposition: 'attachment; filename=' + fileName,
    };
    return firebase.storage().ref().child(path).put(file, metadata);
  }


}
