<section class="page-section">
  <form (ngSubmit)="onSave()" #f="ngForm" autocomplete="off" *ngIf="service">
    <mat-card>
      <mat-card-content *ngIf="user._privileges.canUpdateService || user._rolesName === 'admin'; else info">

        <div class="row" *ngIf="service.isActive === false">
          <div class="col">
            <div class="section--undoRemove">
              This project has been archived.
              <button mat-stroked-button class="mat-white" (click)="onUndoRemove()" type="button">Undo archive</button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="col-header">Details</div>
            <mat-form-field>
              <input matInput
                     type="text"
                     name="name"
                     #name="ngModel"
                     [(ngModel)]="service.name"
                     required
                     placeholder="Name">
              <mat-error *ngIf="name.hasError('required')">
                Name is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <input matInput
                     type="text"
                     name="slug"
                     #slug="ngModel"
                     [(ngModel)]="service.slug"
                     required
                     placeholder="URL">
              <span matPrefix>https://app.so.team/service/</span>
              <mat-error *ngIf="slug.hasError('required')">
                URL is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <input matInput
                     type="text"
                     name="color"
                     #color="ngModel"
                     [(ngModel)]="service.color"
                     required
                     placeholder="Color">
              <mat-error *ngIf="color.hasError('required')">
                Color is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <input matInput
                     type="text"
                     name="shortDesc"
                     [(ngModel)]="service.shortDesc"
                     placeholder="Short description">
            </mat-form-field>

            <mat-form-field>
                <textarea matInput
                          name="notes"
                          [(ngModel)]="service.notes"
                          matTextareaAutosize
                          placeholder="Notes"></textarea>
            </mat-form-field>

            <label class="label">Logo</label>
            <ul class="uploads" *ngIf="file">
              <li class="upload">{{file.name}} <span
                *ngIf="file.progress.data.percentage">({{file.progress.data.percentage}}%)</span><span
                *ngIf="!file.progress.data.percentage" class="material-icons remove"
                (click)="onRemoveFile()">close</span></li>
            </ul>
            <div class="drop-container"
                 ngFileDrop
                 [options]="options"
                 (uploadOutput)="onUpload($event)"
                 [uploadInput]="uploadInput"
                 [ngClass]="{ 'is-drop-over': dragOver }">
              <input type="file"
                     ngFileSelect
                     [options]="options"
                     (uploadOutput)="onUpload($event)"
                     [uploadInput]="uploadInput"
                     multiple>
              <span>Select or drop file(s) here</span>
            </div>
          </div>
        </div>

        <button type="submit"
                mat-raised-button color="primary"
                [disabled]="f.form.invalid">Save
        </button>
        <span *ngIf="service.isActive">
            <a (click)="showRemove = !showRemove" class="link link--btn" *ngIf="!showRemove">Archive</a>
            <span *ngIf="showRemove" class="link--btn">Are you sure? <a (click)="showRemove = false"
                                                                        class="link">No</a>&nbsp;<a
              (click)="onRemove()" class="link">Yes</a></span>
        </span>
      </mat-card-content>

      <ng-template #info>
        <mat-card-content>
          <div class="row">
            <div class="col-4">
              <div class="col-header">Details</div>
              <mat-form-field>
                <input matInput
                       type="text"
                       name="name"
                       #name="ngModel"
                       [(ngModel)]="project.name"
                       readonly
                       placeholder="Name">
              </mat-form-field>

              <mat-form-field>
                <input matInput
                       type="text"
                       name="client"
                       [(ngModel)]="client"
                       readonly
                       placeholder="Client"
                       [matAutocomplete]="autoClients">
                <mat-autocomplete autoActiveFirstOption #autoClients="matAutocomplete"
                                  [displayWith]="autocompleteDisplay">
                  <mat-option *ngFor="let client of clients | filter:client:['name']" [value]="client">
                    {{client.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field>
                <input matInput
                       type="text"
                       name="shortDesc"
                       readonly
                       [(ngModel)]="project.shortDesc"
                       placeholder="Short description">
              </mat-form-field>

              <mat-form-field>
                            <textarea matInput
                                      name="notes"
                                      readonly
                                      [(ngModel)]="project.notes"
                                      matTextareaAutosize
                                      placeholder="Notes"></textarea>
              </mat-form-field>
            </div>

            <div class="col-4" *ngIf="roles">
              <div class="col-header">Estimations</div>
              <mat-form-field *ngFor="let role of roles">
                <input matInput
                       type="number"
                       name="estimation{{[role.uid]}}"
                       readonly
                       [(ngModel)]="project.estimations[role.uid]"
                       placeholder="{{ role.name }} estimation (h)">
              </mat-form-field>
            </div>

            <div class="col-4">
              <div class="col-header">Deadline</div>
              <mat-form-field>
                <input name="deadline"
                       matInput
                       [matDatepicker]="picker"
                       placeholder="Date"
                       [(ngModel)]="project.deadline"
                       #deadline="ngModel"
                       readonly>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </ng-template>
    </mat-card>
  </form>
</section>
