import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

import {AppComponent} from './app.component';
import {AuthComponent} from './auth/auth.component';
import {CoreComponent} from './core/core.component';
import {ServiceComponent} from './service/service.component';
import {TicketComponent} from './service/ticket/ticket.component';
import {ResolveUserService} from './_services/resolve-user.service';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {path: '', component: CoreComponent},
      {path: '', component: AuthComponent},
      {path: 'service', component: ServiceComponent},
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CommonModule
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {
}

