import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {UsersService} from '../../../_services/users.service';
import {AuthService} from '../../../_services/auth.service';
import {environment} from '../../../../environments/environment';
import {UserType} from '../../../_types/user.type';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgProgress} from 'ngx-progressbar';

@Component({
    selector: 'app-profile-password',
    templateUrl: './profile-password.component.html',
    styleUrls: ['./profile-password.component.scss']
})
export class ProfilePasswordComponent implements OnInit {

    user: UserType;

    constructor(private usersService: UsersService,
                private authService: AuthService,
                private route: ActivatedRoute,
                private snackBar: MatSnackBar,
                private progress: NgProgress) {
    }

    ngOnInit() {
        // set user
        this.user = this.route.snapshot.parent.parent.data.user;
    }

    onSave(form: NgForm) {
        if (form.valid) {
            this.progress.ref().start();
            this.authService.login({
                email: this.user.email,
                password: form.value.password
            })
                .then(() => {
                    // update password
                    this.usersService.updatePassword(form.value.passwordNew)
                        .then(() => {
                            this.snackBar.open('Password has been changed', '', environment.snackbarSuccess);
                            form.resetForm();
                            this.progress.ref().complete();
                        })
                        .catch(err => {
                            this.snackBar.open(err.message, '', environment.snackbarWarn);
                            this.progress.ref().complete();
                        });
                })
                .catch(err => {
                    this.snackBar.open(err.message, '', environment.snackbarWarn);
                    this.progress.ref().complete();
                });

        }
    }

}
