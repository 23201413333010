import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  info: any;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.info = this.route.snapshot.paramMap.get(`info`);
  }

}
