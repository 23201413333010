<section class="page-section page-section--brand-calendar">
  <div class="row">
    <div class="col col--header">
      <a mat-stroked-button
         routerLink="/brands/{{ brandsComponent.uid }}/calendar/{{ prevYear }}/{{ prevMonth }}">Prev
        month</a>
      <span class="dates">{{ year }}-{{ month | number:'2.0' }}</span>
      <a mat-stroked-button
         routerLink="/brands/{{ brandsComponent.uid }}/calendar/{{ nextYear }}/{{ nextMonth }}">Next
        month</a>
      <mat-form-field>
        <input matInput placeholder="Search" type="search" [(ngModel)]="searchPost">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col col--filters-settings">
      <div class="filters">
        <div class="filterStatus" *ngIf="filterStatus">
          <mat-button-toggle-group name="status"
                                   [(ngModel)]="filterStatus"
                                   multiple
                                   aria-label="Status">
            <mat-button-toggle class="mat-button-toggle--hide"
                               *ngIf="user._privileges.canSeeHiddenPost || user._rolesName === 'admin'"
                               value="hide">Hide
              <small>{{ count.hide }}</small>
            </mat-button-toggle>
            <mat-button-toggle class="mat-button-toggle--social"
                               value="social">Social
              <small>{{ count.social }}</small>
            </mat-button-toggle>
            <mat-button-toggle class="mat-button-toggle--graphic"
                               value="graphic">Graphic
              <small>{{ count.graphic }}</small>
            </mat-button-toggle>
            <mat-button-toggle class="mat-button-toggle--client"
                               value="client">Client
              <small>{{ count.client }}</small>
            </mat-button-toggle>
            <mat-button-toggle class="mat-button-toggle--accept"
                               value="accept">Accept
              <small>{{ count.accept }}</small>
            </mat-button-toggle>
            <mat-button-toggle class="mat-button-toggle--publish"
                               value="publish">Publish
              <small>{{ count.publish }}</small>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="filterPlatform" *ngIf="filterPlatform">
          <mat-button-toggle-group name="type"
                                   [(ngModel)]="filterPlatform"
                                   multiple
                                   aria-label="Type">
            <mat-button-toggle *ngFor="let platform of platforms"
                               (click)="onChoosePlatform()"
                               class="mat-button-toggle mat-button-toggle--{{ platform.value }}"
                               [value]="platform.value">{{ platform.viewValue }}
              <small>{{ count[platform.value] }}</small>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="filterType" *ngIf="types.length">
          <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select [(ngModel)]="filterType" multiple>
              <mat-option *ngFor="let type of types" [value]="type.value">{{type.viewValue}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="settings">
        <mat-slide-toggle [checked]="hideAutoComments"
                          class="settingsAutoComments"
                          (change)="onChangeUserSettingsAutoComments()">Hide auto comments
        </mat-slide-toggle>
        <div class="settingsView" *ngIf="postsView">
          View:
          <mat-button-toggle-group name="view"
                                   [(ngModel)]="postsView"
                                   (change)="onChangeUserSettingsView()"
                                   aria-label="View">
            <mat-button-toggle value="list"
                               class="mat-button-toggle">List
            </mat-button-toggle>
            <mat-button-toggle value="calendar"
                               class="mat-button-toggle">Calendar
            </mat-button-toggle>
          </mat-button-toggle-group>

          <!--          <span class="material-icons" (click)="getPosts()">refresh</span>-->
        </div>
      </div>
    </div>
  </div>

  <div class="spinner" *ngIf="!postsComplete || !usersComplete">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>

  <!-- posts -->
  <div *ngIf="postsComplete && usersComplete">

    <!-- list view -->
    <div *ngIf="postsView === 'list'" class="view-list">
      <mat-list *ngIf="days.length" role="list" class="mat-list--posts">
        <div class="day" *ngFor="let day of days">
          <div class="date">
            {{ day.date | date: 'yyyy-MM-dd'}}
            {{ day.day }}
          </div>
          <ng-container
            *ngFor="let post of day.posts | filter:searchPost:['title', 'content', 'imageCopy', 'imageComments']">
            <ng-container
              *ngIf="(!filterStatus.length || filterStatus.includes(post.status)) && (!filterPlatform.length || filterPlatform.includes(post.platform)) && (!filterType.length || filterType.includes(post.type))">

              <!-- post -->
              <app-shared-post [post]="post" [user]="user" [users]="users" [view]="'list'"></app-shared-post>
              <!-- end post -->

            </ng-container>
          </ng-container>

          <!-- add new post -->
          <button class="btn-add" (click)="onAdd(day.date)" mat-stroked-button
                  *ngIf="user._privileges.canCreatePost || user._rolesName === 'admin'">
            <span class="material-icons">add</span>
          </button>
          <!-- end add new post -->
        </div>
      </mat-list>
    </div>
    <!-- /list view -->

    <!-- calendar view -->
    <div *ngIf="postsView === 'calendar'"
         class="view-calendar">
      <ul class="list-days">
        <li class="day">Monday</li>
        <li class="day">Tuesday</li>
        <li class="day">Wednesday</li>
        <li class="day">Thursday</li>
        <li class="day">Friday</li>
        <li class="day">Saturday</li>
        <li class="day">Sunday</li>
      </ul>
      <ul class="list-posts" cdkDropListGroup>
        <li class="posts" *ngIf="firstDayMonthIndex > 0"></li>
        <li class="posts" *ngIf="firstDayMonthIndex > 1"></li>
        <li class="posts" *ngIf="firstDayMonthIndex > 2"></li>
        <li class="posts" *ngIf="firstDayMonthIndex > 3"></li>
        <li class="posts" *ngIf="firstDayMonthIndex > 4"></li>
        <li class="posts" *ngIf="firstDayMonthIndex > 5"></li>
        <li class="posts" *ngFor="let day of days; index as i;">
          <div class="day-number">{{ (i + 1) }}</div>
          <div cdkDropList
               [cdkDropListData]="day"
               (cdkDropListDropped)="onDropPost($event)">

            <div class="drop-space"
                 *ngIf="!day.posts.length && (user._privileges.canUpdatePost || user._rolesName === 'admin')"></div>

            <div *ngFor="let post of day.posts | filter:searchPost:['title', 'content', 'imageCopy', 'imageComments']">

              <ng-container
                *ngIf="((post.status === 'hide' && user._privileges.canSeeHiddenPost) || user._rolesName === 'admin') || post.status !== 'hide'">

                <ng-container
                  *ngIf="(!filterStatus.length || filterStatus.includes(post.status)) && (!filterPlatform.length || filterPlatform.includes(post.platform)) && (!filterType.length || filterType.includes(post.type))">

                  <!-- post (drag'n'drop) -->
                  <mat-card class="card--post card--move"
                            *ngIf="user._privileges.canUpdatePost || user._rolesName === 'admin'; else postReadonly"
                            cdkDrag
                            [cdkDragData]="post"
                            [ngClass]="{'card--hide': post.status === 'hide','card--social': post.status === 'social','card--graphic': post.status === 'graphic','card--client': post.status === 'client','card--accept': post.status === 'accept','card--publish': post.status === 'publish'}">
                    <mat-tab-group *ngIf="post.imagesVideosCount"
                                   [selectedIndex]="post.imagesVideosIndex">
                      <ng-container *ngFor="let file of post.files; let index = index">
                        <mat-tab *ngIf="file.isActive">
                          <div class="file image"
                               [ngClass]="{'file--selected': file.isSelected}"
                               (click)="onOpenDialog(post)"
                               *ngIf="file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif'"
                               [ngStyle]="{'background-image': 'url(' + file.url + ')'}"></div>
                          <div class="file video"
                               [ngClass]="{'file--selected': file.isSelected}"
                               (click)="onOpenDialog(post)"
                               *ngIf="file.type === 'video/mp4'">
                            <small class="material-icons">play_arrow</small>
                            <video>
                              <source src="{{ file.url }}" type="video/mp4">
                            </video>
                          </div>
                          <div class="file other"
                               [ngClass]="{'file--selected': file.isSelected}"
                               (click)="onOpenDialog(post)"
                               *ngIf="file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg' && file.type !== 'image/gif' && file.type !== 'video/mp4'">
                            <div class="file-name">
                              <small>{{file.name}}</small>
                            </div>
                          </div>
                        </mat-tab>
                      </ng-container>
                    </mat-tab-group>

                    <small class="platform">{{ post.platformView }}</small>
                    <small class="type">{{ post.typeView }}</small>

                    <div *ngIf="post.imagesVideosCount > 1"
                         class="files-pagination">
                      <small class="material-icons" (click)="onPrevFile(this.post)">navigate_before</small>
                      <small class="pages">{{ (post.imagesVideosIndex + 1) }}/{{ post.imagesVideosCount }}</small>
                      <small class="material-icons" (click)="onNextFile(this.post)">navigate_next</small>
                    </div>

                    <div *ngIf="!post.imagesVideosCount"
                         (click)="onOpenDialog(post)"
                         class="no-files">
                      <small>{{ post.imageCopy }}</small>
                    </div>
                  </mat-card>
                  <!-- end post (drag'n'drop) -->

                  <!-- post (click) -->
                  <ng-template #postReadonly>
                    <mat-card class="card--post"
                              [ngClass]="{'card--hide': post.status === 'hide','card--social': post.status === 'social','card--graphic': post.status === 'graphic','card--client': post.status === 'client','card--accept': post.status === 'accept','card--publish': post.status === 'publish'}">
                      <mat-tab-group *ngIf="post.imagesVideosCount"
                                     [selectedIndex]="post.imagesVideosIndex">
                        <ng-container *ngFor="let file of post.files; let index = index">
                          <mat-tab *ngIf="file.isActive">
                            <div class="file image"
                                 (click)="onOpenDialog(post)"
                                 *ngIf="file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif'"
                                 [ngStyle]="{'background-image': 'url(' + file.url + ')'}"></div>
                            <div class="file video"
                                 (click)="onOpenDialog(post)"
                                 *ngIf="file.type === 'video/mp4'">
                              <small class="material-icons">play_arrow</small>
                              <video>
                                <source src="{{ file.url }}" type="video/mp4">
                              </video>
                            </div>
                            <div class="file other"
                                 [ngClass]="{'file--selected': file.isSelected}"
                                 (click)="onOpenDialog(post)"
                                 *ngIf="file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg' && file.type !== 'image/gif' && file.type !== 'video/mp4'">
                              <div class="file-name">
                                <small>{{file.name}}</small>
                              </div>
                            </div>
                          </mat-tab>
                        </ng-container>
                      </mat-tab-group>

                      <small class="platform">{{ post.platformView }}</small>
                      <small class="type">{{ post.typeView }}</small>

                      <div *ngIf="post.imagesVideosCount > 1"
                           class="files-pagination">
                        <small class="material-icons" (click)="onPrevFile(this.post)">navigate_before</small>
                        <small class="pages">{{ (post.imagesVideosIndex + 1) }}/{{ post.imagesVideosCount }}</small>
                        <small class="material-icons" (click)="onNextFile(this.post)">navigate_next</small>
                      </div>

                      <div *ngIf="!post.imagesVideosCount"
                           (click)="onOpenDialog(post)"
                           class="no-files">
                        <small>{{ post.imageCopy }}</small>
                      </div>
                    </mat-card>
                  </ng-template>
                  <!-- end post (click) -->

                </ng-container>

              </ng-container>

            </div>
          </div>

          <button class="btn-add" (click)="onAdd(day.date)" mat-stroked-button
                  *ngIf="user._privileges.canCreatePost || user._rolesName === 'admin'">
            <span class="material-icons">add</span>
          </button>
        </li>
        <li class="posts" *ngIf="lastDayMonthComplement > 0"></li>
        <li class="posts" *ngIf="lastDayMonthComplement > 1"></li>
        <li class="posts" *ngIf="lastDayMonthComplement > 2"></li>
        <li class="posts" *ngIf="lastDayMonthComplement > 3"></li>
        <li class="posts" *ngIf="lastDayMonthComplement > 4"></li>
        <li class="posts" *ngIf="lastDayMonthComplement > 5"></li>
      </ul>
    </div>
    <!-- /calendar view -->

  </div>

</section>
