import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import * as firebase from 'firebase/app';
import {Subscription} from 'rxjs/internal/Subscription';
import {ProjectsService} from '../../../_services/projects.service';
import {ClientsService} from '../../../_services/clients.service';
import {ClientType} from '../../../_types/client.type';
import {UserType} from '../../../_types/user.type';
import {ProjectType} from '../../../_types/project.type';
import {AngularFirestore} from '@angular/fire/firestore';
import {ProjectsComponent} from '../projects.component';
import {ActivatedRoute, Router} from '@angular/router';
import {RolesService} from '../../../_services/roles.service';
import {RoleType} from '../../../_types/role.type';
import {MessageType} from '../../../_types/message.type';
import {MessagesService} from '../../../_services/messages.service';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-project-add',
  templateUrl: './project-add.component.html',
  styleUrls: ['./project-add.component.scss']
})
export class ProjectAddComponent implements OnInit, OnDestroy {

  clients: ClientType[];
  client: any;
  roles: RoleType[];
  user: UserType;

  estimations: {} = {};
  deadline: '';

  private subscriptions: Array<Subscription> = [];
  private subscriptionIsExists: Subscription;

  constructor(private clientsService: ClientsService,
              private projectsService: ProjectsService,
              private messagesService: MessagesService,
              private projectsComponent: ProjectsComponent,
              private rolesService: RolesService,
              private route: ActivatedRoute,
              private afs: AngularFirestore,
              private progress: NgProgress,
              private snackBar: MatSnackBar,
              private router: Router) {


    // set user
    this.user = this.route.snapshot.parent.parent.data.user;

    // get clients
    this.getClients();

    // get roles
    this.getRoles();

    // set title
    this.projectsComponent.title = 'Add project';

  }

  ngOnInit() {
  }

  private getClients() {
    this.subscriptions.push(this.clientsService.getClients(this.user).subscribe(clients => {
      this.clients = clients;
    }));
  }

  private getRoles() {
    this.subscriptions.push(this.rolesService.getRoles(this.user).subscribe(roles => {
      this.roles = roles;
      if (this.roles) {
        this.roles.forEach(role => {
          this.estimations[role.uid] = 0;
        });
      }
    }));
  }

  autocompleteDisplay(client?: ClientType): string | undefined {
    return client ? client.name : undefined;
  }

  autocompleteBlur() {
    if (typeof this.client === 'string') {
      this.clients.forEach(client => {
        if (client.name === this.client) {
          this.client = client;
        }
      });
    }
  }

  autocompleteSelected(selected) {
    if (typeof this.client === 'string') {
      this.clients.forEach(client => {
        if (client === selected) {
          this.client = client;
        }
      });
    }
  }

  onSave(form: NgForm) {

    this.progress.ref().start();

    // insert project
    const project: ProjectType = {
      uid: this.afs.createId(),
      clientRef: this.afs.doc(`companies/${this.user.companyRef.id}/clients/${form.value.client.uid}`).ref,
      name: form.value.name,
      shortDesc: form.value.shortDesc,
      notes: form.value.notes,
      deadline: form.value.deadline,
      estimations: this.estimations,
      userRefs: {
        [this.user.uid]: this.afs.doc(`users/${this.user.uid}`).ref
      },
      wiki: [],
      isActive: true,
      files: []
    };

    // check duplicate
    this.subscriptionIsExists = this.projectsService.isExists(project, this.user).subscribe(projects => {
      if (projects.length) {
        this.progress.ref().complete();
        this.snackBar.open('Project exists', '', environment.snackbarWarn);
      } else {
        // insert project
        this.subscriptionIsExists.unsubscribe();
        this.projectsService.insertProject(project, this.user)
          .then(() => {
            // insert changes
            const change: MessageType = {
              uid: this.afs.createId(),
              message: 'has created the project',
              dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
              userRef: this.afs.doc(`users/${this.user.uid}`).ref,
              files: [],
              type: 'auto'
            };
            this.messagesService.insertMessage(change, `companies/${this.user.companyRef.id}/projects/${project.uid}/changes/${change.uid}`)
              .then(() => {
                this.progress.ref().complete();
                this.snackBar.open('Project has been added', '', environment.snackbarSuccess);
                this.router.navigate([`/projects/${project.uid}/team`]).then();
              })
              .catch(err => {
                this.snackBar.open(err.message, '', environment.snackbarWarn);
              });
          })
          .catch(err => {
            this.progress.ref().complete();
            this.snackBar.open(err.message, '', environment.snackbarWarn);
          });
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    if (this.subscriptionIsExists) {
      this.subscriptionIsExists.unsubscribe();
    }
  }

}
