import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ServiceComponent} from './service.component';
import {TicketComponent} from './ticket/ticket.component';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {SharedModule} from '../shared.module';
import {NgxUploaderModule} from 'ngx-uploader';
import {ServiceRoutingModule} from './service-routing.module';

@NgModule({
  declarations: [
    ServiceComponent,
    TicketComponent,
  ],
  imports: [
    SharedModule,

    ServiceRoutingModule,

    CommonModule,
    MatListModule,
    MatCardModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,

    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatChipsModule,
    MatDatepickerModule,
    MatAutocompleteModule,

    NgxUploaderModule
  ],
  entryComponents: [
    // SharedTicketComponent,
  ],
  exports: [
    // SharedTicketComponent
  ],
  bootstrap: [ServiceComponent]
})
export class ServiceModule {
}
