<section class="page-section">
      <div class="row">
        <div class="col">
          <mat-list *ngIf="changes.length" role="list" class="mat-list--changes">
            <mat-list-item *ngFor="let change of changes">
              <div class="dateTime">
                {{ change.dateCreate.toDate() | date: 'yyyy-MM-dd HH:mm'}}
              </div>
              <a matLine class="mat-line--author" *ngIf="change.author" routerLink="/users/{{ change.author.uid }}">
                <div class="avatar-name">
                  <div matListAvatar
                       *ngIf="change.author.photoURL"
                       [ngStyle]="{'background-image': 'url(' + change.author.photoURL + ')'}"></div>
                  <div matListAvatar
                       routerLink="/users/{{ change.author.uid }}"
                       *ngIf="!change.author.photoURL">
                    {{ change.author.name | avatar }}
                  </div>
                  <div>
                    <div class="mat-line--name">
                      <div>{{ change.author.name }} <small>{{ change.author._rolesName }}</small></div>
                    </div>
                  </div>
                </div>
              </a>
              <div matLine class="mat-line--message">
                <div class="message">{{ change.message }}</div>
                <div class="info">number of users after change: {{ change.numberOfUsers }}</div>
              </div>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
</section>
