import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
    selector: '[appForbiddenType]',
    providers: [{provide: NG_VALIDATORS, useExisting: ForbiddenTypeDirective, multi: true}]
})
export class ForbiddenTypeDirective implements Validator {

    @Input('appForbiddenType') forbiddenType: string | undefined;

    constructor() {
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (typeof control.value === this.forbiddenType) {
            return {value: control.value};
        }
        return null;
    }

}
