import {Component, Inject, OnInit} from '@angular/core';
import {TaskType} from '../../_types/task.type';
import {UserType} from '../../_types/user.type';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-shared-task-dialog',
    templateUrl: './shared-task-dialog.component.html',
    styleUrls: ['./shared-task-dialog.component.scss']
})
export class SharedTaskDialogComponent implements OnInit {

    task: TaskType;
    user: UserType;

    constructor(@Inject(MAT_DIALOG_DATA) public taskDialog: any) {
        this.task = taskDialog.task;
        this.user = taskDialog.user;
    }

    ngOnInit() {
    }

}
