<section class="page-section page-section--post">
    <div class="settings">
        <mat-slide-toggle [checked]="hideAutoComments"
                          (change)="onChangeUserSettings()">hide auto comments
        </mat-slide-toggle>
    </div>
    <mat-list role="list" class="mat-list--posts">
        <app-shared-post *ngIf="post && user" [post]="post" [user]="user" [users]="users" [view]="'single'"></app-shared-post>
    </mat-list>
</section>
