import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AccessToModule} from '../_types/accessToModule.type';
import * as firebase from 'firebase/app';
import WhereFilterOp = firebase.firestore.WhereFilterOp;
import DocumentReference = firebase.firestore.DocumentReference;
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class AccessToModulesService {

  constructor(private afs: AngularFirestore) {
  }

  // insert message access to module
  insertAccess(accessMessage: AccessToModule, path: string): Promise<void> {
    return this.afs.doc<AccessToModule>(path).set(accessMessage);
  }

  // get message access to module
  getAccessesForModule(path: string, moduleRef: any): Observable<AccessToModule[]> {
    return this.afs.collection<AccessToModule>(path, ref => ref
      .where('moduleRef', '==', moduleRef)
      .orderBy('dateCreate', 'desc'))
      .valueChanges();
  }

  // get message access to module in month and year with limit
  getAccessesForModuleDateLimitSign(
    path: string,
    moduleRef: DocumentReference,
    date: Date = new Date(),
    limit: number = 999,
    sign: WhereFilterOp = '<='): Observable<AccessToModule[]> {
    return this.afs.collection<AccessToModule>(path, ref => ref
      .where('moduleRef', '==', moduleRef)
      .where('dateCreate', sign, date)
      .limit(limit))
      .valueChanges();
  }

  // get last message access to module
  getLastAccessForModule(path: string, moduleRef: any): Observable<AccessToModule[]>  {
    return this.afs.collection<AccessToModule>(path, ref => ref
      .where('moduleRef', '==', moduleRef)
      .orderBy('dateCreate', 'desc')
      .limit(1))
      .valueChanges();
  }
}
