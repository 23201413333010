<main>
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="logo"><img src="../../../assets/soteam.png" alt="Soteam"></h1>

                <mat-card *ngIf="mode === 'verifyEmail'">
                    <mat-card-header>
                        <mat-card-title>E-mail verification</mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                        <p [ngClass]="alert.class">{{ alert.message }}</p>

                        <div class="help-text-align-center">
                            <a routerLink="/login" mat-raised-button color="primary">Log In</a>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card *ngIf="mode === 'recoverEmail'">
                    <mat-card-header>
                        <mat-card-title>Recover e-mail</mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                        <p *ngIf="alert.message" [ngClass]="alert.class">{{ alert.message }}</p>
                        <div class="help-text-align-center">
                            <a routerLink="/login"
                               mat-raised-button
                               color="primary"
                               *ngIf="!resetPasswordBtnShow">Log In</a>
                        </div>
                        <div class="help-text-align-center">
                            <a mat-raised-button color="primary"
                               (click)="onSendResetEmail()"
                               *ngIf="resetPasswordBtnShow">Reset password</a>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card *ngIf="mode === 'resetPassword'">
                    <mat-card-header>
                        <mat-card-title>Set new password</mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                        <p *ngIf="alert.message" [ngClass]="alert.class">{{ alert.message }}</p>
                        <form (ngSubmit)="onSave(f)" #f="ngForm" class="text-left">
                            <mat-form-field class="help-width-100p">
                                <input matInput
                                       type="password"
                                       name="passwordNew"
                                       #passwordNew="ngModel"
                                       ngModel
                                       required
                                       minlength="6"
                                       placeholder="New password"
                                       validateEqual="passwordNewConfirmation"
                                       reverse="true">
                                <mat-error *ngIf="passwordNew.invalid && (passwordNew.dirty || passwordNew.touched)">
                                    <span *ngIf="passwordNew.hasError('required')">New password is required. </span>
                                    <span *ngIf="passwordNew.hasError('minlength')">New password must be at least 6 characters long. </span>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="help-width-100p">
                                <input matInput
                                       type="password"
                                       name="passwordNewConfirmation"
                                       #passwordNewConfirmation="ngModel"
                                       ngModel
                                       required
                                       minlength="6"
                                       placeholder="New password confirmation"
                                       validateEqual="password">
                                <mat-error *ngIf="passwordNewConfirmation.value !== passwordNew.value">
                                    <span>Passwords do not match. </span>
                                </mat-error>
                            </mat-form-field>
                            <button type="submit"
                                    mat-raised-button color="primary"
                                    [disabled]="f.form.invalid">Save
                            </button>
                        </form>
                    </mat-card-content>
                </mat-card>

                <mat-card *ngIf="mode === 'gotoLogin'">
                    <mat-card-header>
                        <mat-card-title>Set new password</mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                        <p *ngIf="alert.message" [ngClass]="alert.class">{{ alert.message }}</p>
                        <div class="help-text-align-center">
                            <a routerLink="/login" mat-raised-button color="primary">Log In</a>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</main>
