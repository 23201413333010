<section class="aside-subnav" [ngClass]="{'aside-subnav--full-page': showFullPage}">
  <ng-container *ngIf="clients$ | async as clients; else loading">
    <ng-container *ngIf="clients.length">
      <div class="search">
        <mat-form-field>
          <input matInput placeholder="Search" type="search" [(ngModel)]="searchClient">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>

      <mat-nav-list
        [ngClass]="{'mat-nav-list--button': user._rolesName === 'admin' || user._privileges.canCreateClient}">
        <div *ngFor="let client of clients | filter:searchClient:['name']">
          <a mat-list-item
             routerLinkActive="active"
             routerLink="/clients/{{ client.uid }}">
            {{ client.name }}
          </a>
        </div>
      </mat-nav-list>

    </ng-container>
  </ng-container>

  <ng-template #loading>
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </ng-template>

  <div class="container-btm"
       *ngIf="user._rolesName === 'admin' || user._privileges.canCreateClient">
    <a routerLink="/clients/client-add" mat-stroked-button color="primary">Add client</a>
  </div>
</section>

<main class="help-margin-left-180px">
  <div class="container">
    <div class="row">
      <div class="col">
        <header class="page-header">
          <h1 [innerHTML]="title" *ngIf="title"></h1>
          <nav mat-tab-nav-bar>
            <a mat-tab-link
               *ngFor="let link of navLinks"
               [routerLink]="link.path"
               routerLinkActive #rla="routerLinkActive"
               [active]="rla.isActive">
              {{ link.label }}
            </a>
          </nav>
        </header>

        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</main>
