import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

import {CoreComponent} from './core.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ClientsComponent} from './clients/clients.component';
import {ProjectsComponent} from './projects/projects.component';
import {SettingsComponent} from './settings/settings.component';
import {RolesComponent} from './roles/roles.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {ProfileComponent} from './profile/profile.component';
import {ProfileSettingsComponent} from './profile/profile-settings/profile-settings.component';
import {ProfileEmailComponent} from './profile/profile-email/profile-email.component';
import {ProfilePasswordComponent} from './profile/profile-password/profile-password.component';
import {UsersComponent} from './users/users.component';
import {UserCalendarComponent} from './users/user-calendar/user-calendar.component';
import {UserChatComponent} from './users/user-chat/user-chat.component';
import {UserDetailsComponent} from './users/user-details/user-details.component';
import {RoleAddComponent} from './roles/role-add/role-add.component';
import {RolePrivilegesComponent} from './roles/role-privileges/role-privileges.component';
import {RoleDetailsComponent} from './roles/role-details/role-details.component';
import {SettingsDetailsComponent} from './settings/settings-details/settings-details.component';
import {SettingsDomainsComponent} from './settings/settings-domains/settings-domains.component';
import {ClientAddComponent} from './clients/client-add/client-add.component';
import {ClientDetailsComponent} from './clients/client-details/client-details.component';
import {ProjectAddComponent} from './projects/project-add/project-add.component';
import {ProjectScheduleComponent} from './projects/project-schedule/project-schedule.component';
import {ProjectDetailsComponent} from './projects/project-details/project-details.component';
import {ProjectTeamComponent} from './projects/project-team/project-team.component';
import {ProjectWikiComponent} from './projects/project-wiki/project-wiki.component';
import {ProjectAssetsComponent} from './projects/project-assets/project-assets.component';
import {ProjectTaskComponent} from './projects/project-task/project-task.component';
import {ChatsComponent} from './chats/chats.component';
import {ChatAddComponent} from './chats/chat-add/chat-add.component';
import {ChatTeamComponent} from './chats/chat-team/chat-team.component';
import {ChatMessagesComponent} from './chats/chat-messages/chat-messages.component';
import {ChatDetailsComponent} from './chats/chat-details/chat-details.component';

import {ResolveUserService} from '../_services/resolve-user.service';
import {AuthGuard} from '../_guards/auth.guard';
import {ClientAddGuard} from '../_guards/client-add.guard';
import {ErrorComponent} from '../error/error.component';
import {ClientGetGuard} from '../_guards/client-get.guard';
import {UserUpdateGuard} from '../_guards/user-update.guard';
import {ProjectAddGuard} from '../_guards/project-add.guard';
import {ProjectGetGuard} from '../_guards/project-get.guard';
import {TaskGetGuard} from '../_guards/task-get.guard';
import {CompanyUpdateGuard} from '../_guards/company-update.guard';
import {RoleGetGuard} from '../_guards/role-get.guard';
import {RoleAddGuard} from '../_guards/role-add.guard';
import {ProjectChangesComponent} from './projects/project-changes/project-changes.component';
import {ProjectStatsComponent} from './projects/project-stats/project-stats.component';
import {BrandsComponent} from './brands/brands.component';
import {BrandGetGuard} from '../_guards/brand-get.guard';
import {BrandAddGuard} from '../_guards/brand-add.guard';
import {BrandAddComponent} from './brands/brand-add/brand-add.component';
import {BrandCalendarComponent} from './brands/brand-calendar/brand-calendar.component';
import {BrandDetailsComponent} from './brands/brand-details/brand-details.component';
import {BrandTeamComponent} from './brands/brand-team/brand-team.component';
import {BrandChangesComponent} from './brands/brand-changes/brand-changes.component';
import {ProjectStatusComponent} from './projects/project-status/project-status.component';
import {ModulesComponent} from './modules/modules.component';
import {ModuleUsersComponent} from './modules/module-users/module-users.component';
import {ModuleGetGuard} from '../_guards/module-get.guard';
import {ModuleChangesComponent} from './modules/module-changes/module-changes.component';
import {BrandFollowersComponent} from './brands/brand-followers/brand-followers.component';
import {BrandPostComponent} from './brands/brand-post/brand-post.component';
import {PostGetGuard} from '../_guards/post-get.guard';
import {ModuleProjectsGuard} from '../_guards/module-projects.guard';
import {ModuleSocialBrandsGuard} from '../_guards/module-social-brands.guard';
import {UserInviteGuard} from '../_guards/user-invite.guard';
import {UserListComponent} from './users/user-list/user-list.component';
import {UserInvitationsComponent} from './users/user-invitations/user-invitations.component';
import {UserInviteComponent} from './users/user-invite/user-invite.component';
import {ChangelogComponent} from './changelog/changelog.component';
import {SettingsPricesComponent} from './settings/settings-prices/settings-prices.component';
import {SettingsInvoicesComponent} from './settings/settings-invoices/settings-invoices.component';
import {ServicesComponent} from './services/services.component';
import {ServiceAddComponent} from './services/service-add/service-add.component';
import {ServiceListComponent} from './services/service-list/service-list.component';
import {ServiceDetailsComponent} from './services/service-details/service-details.component';
import {ServiceTeamComponent} from './services/service-team/service-team.component';
import {ServiceFollowersComponent} from './services/service-followers/service-followers.component';
import {ServiceChangesComponent} from './services/service-changes/service-changes.component';
import {ServiceTicketComponent} from './services/service-ticket/service-ticket.component';
import {ModuleServicesGuard} from '../_guards/module-services.guard';
import {ServiceAddGuard} from '../_guards/service-add.guard';
import {ServiceGetGuard} from '../_guards/service-get.guard';
import {TicketGetGuard} from '../_guards/ticket-get.guard';

const routes: Routes = [
  {
    path: '',
    component: CoreComponent,
    resolve: {user: ResolveUserService},
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },

      {path: 'notifications', component: NotificationsComponent},

      {
        path: 'clients',
        component: ClientsComponent,
        canActivate: [ClientGetGuard],
        children: [
          {path: 'client-add', component: ClientAddComponent, canActivate: [ClientAddGuard]},
          {path: ':uid', redirectTo: ':uid/details'},
          {path: ':uid/details', component: ClientDetailsComponent},
        ]
      },

      {
        path: 'projects',
        component: ProjectsComponent,
        canActivate: [ProjectGetGuard, ModuleProjectsGuard],
        children: [
          {path: 'project-add', component: ProjectAddComponent, canActivate: [ProjectAddGuard]},
          {path: ':uid', redirectTo: ':uid/details'},
          {path: ':uid/details', component: ProjectDetailsComponent},
          {path: ':uid/schedule', component: ProjectScheduleComponent},
          {path: ':uid/schedule/:year/:month', component: ProjectScheduleComponent},
          {path: ':uid/team', component: ProjectTeamComponent},
          {path: ':uid/wiki', component: ProjectWikiComponent},
          {path: ':uid/assets', component: ProjectAssetsComponent},
          {path: ':uid/changes', component: ProjectChangesComponent},
          {path: ':uid/stats', component: ProjectStatsComponent},
          {path: ':uid/status', component: ProjectStatusComponent},
          {path: ':uid/task/:uidTask', component: ProjectTaskComponent, canActivate: [TaskGetGuard]}
        ]
      },

      {
        path: 'brands',
        component: BrandsComponent,
        canActivate: [BrandGetGuard, ModuleSocialBrandsGuard],
        children: [
          {path: 'brand-add', component: BrandAddComponent, canActivate: [BrandAddGuard]},
          {path: ':uid', component: BrandCalendarComponent},
          {path: ':uid/calendar', component: BrandCalendarComponent},
          {path: ':uid/calendar/:year/:month', component: BrandCalendarComponent},
          {path: ':uid/calendar/:year/:month/:status', component: BrandCalendarComponent},
          {path: ':uid/details', component: BrandDetailsComponent},
          {path: ':uid/team', component: BrandTeamComponent},
          {path: ':uid/followers', component: BrandFollowersComponent},
          {path: ':uid/changes', component: BrandChangesComponent},
          {path: ':uid/post/:uidPost', component: BrandPostComponent, canActivate: [PostGetGuard]},
        ]
      },

      {
        path: 'services',
        component: ServicesComponent,
        canActivate: [ServiceGetGuard, ModuleServicesGuard],
        children: [
          {path: 'service-add', component: ServiceAddComponent, canActivate: [ServiceAddGuard]},
          {path: ':uid', component: ServiceListComponent},
          {path: ':uid/list', component: ServiceListComponent},
          {path: ':uid/list/:year/:month', component: ServiceListComponent},
          {path: ':uid/list/:year/:month/:status', component: ServiceListComponent},
          {path: ':uid/details', component: ServiceDetailsComponent},
          {path: ':uid/team', component: ServiceTeamComponent},
          {path: ':uid/followers', component: ServiceFollowersComponent},
          {path: ':uid/changes', component: ServiceChangesComponent},
          {path: ':uid/ticket/:uidTicket', component: ServiceTicketComponent, canActivate: [TicketGetGuard]},
        ]
      },

      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [CompanyUpdateGuard],
        children: [
          {path: 'details', component: SettingsDetailsComponent},
          {path: 'prices', component: SettingsPricesComponent},
          {path: 'invoices', component: SettingsInvoicesComponent}
          // {path: 'domains', component: SettingsDomainsComponent}
        ]
      },

      {
        path: 'roles',
        component: RolesComponent,
        canActivate: [RoleGetGuard],
        children: [
          {path: 'role-add', component: RoleAddComponent, canActivate: [RoleAddGuard]},
          {path: ':uid', redirectTo: ':uid/privileges'},
          {path: ':uid/privileges', component: RolePrivilegesComponent},
          {path: ':uid/details', component: RoleDetailsComponent},
        ]
      },

      {
        path: 'modules',
        component: ModulesComponent,
        canActivate: [ModuleGetGuard],
        children: [
          {path: ':uid', redirectTo: ':uid/users'},
          {path: ':uid/users', component: ModuleUsersComponent},
          {path: ':uid/changes', component: ModuleChangesComponent},
        ]
      },

      {
        path: 'profile', component: ProfileComponent, children: [
          {path: 'settings', component: ProfileSettingsComponent},
          {path: 'email', component: ProfileEmailComponent},
          {path: 'password', component: ProfilePasswordComponent}
        ]
      },

      {
        path: 'users', component: UsersComponent, children: [
          {path: 'user-list', component: UserListComponent},
          {path: 'user-invite', component: UserInviteComponent, canActivate: [UserInviteGuard]},
          {path: 'user-invitations', component: UserInvitationsComponent, canActivate: [UserInviteGuard]},
          {path: ':uid', component: UserCalendarComponent},
          {path: ':uid/calendar', component: UserCalendarComponent},
          {path: ':uid/calendar/:year/:month', component: UserCalendarComponent},
          {path: ':uid/chat', component: UserChatComponent},
          {path: ':uid/details', component: UserDetailsComponent, canActivate: [UserUpdateGuard]}
        ]
      },

      {
        path: 'changelog',
        component: ChangelogComponent
      },

      {
        path: 'chats', component: ChatsComponent, children: [
          {path: 'chat-add', component: ChatAddComponent},
          {path: ':uid', redirectTo: ':uid/messages'},
          {path: ':uid/messages', component: ChatMessagesComponent},
          {path: ':uid/details', component: ChatDetailsComponent},
          {path: ':uid/team', component: ChatTeamComponent},
        ]
      },
      {path: 'error', component: ErrorComponent},
      // {path: '**', redirectTo: '/error'}

    ]
  },


  // {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CommonModule
  ],
  exports: [RouterModule],
  declarations: []
})
export class CoreRoutingModule {
}

