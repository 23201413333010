import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {ActivatedRoute} from '@angular/router';
import {ProjectsComponent} from '../projects.component';
import {UsersService} from '../../../_services/users.service';
import {UserType} from '../../../_types/user.type';
import {ProjectsService} from '../../../_services/projects.service';
import {ProjectType} from '../../../_types/project.type';
import {AngularFirestore} from '@angular/fire/firestore';
import {environment} from '../../../../environments/environment';
import {RoleType} from '../../../_types/role.type';
import {RolesService} from '../../../_services/roles.service';
import {MessageType} from '../../../_types/message.type';
import * as firebase from 'firebase/app';
import {MessagesService} from '../../../_services/messages.service';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-project-team',
    templateUrl: './project-team.component.html',
    styleUrls: ['./project-team.component.scss']
})
export class ProjectTeamComponent implements OnInit, OnDestroy {

    userCurrent: UserType | any;
    project: ProjectType | any;
    users: UserType[] = [];
    usersComplete = false;
    roles: RoleType[] = [];

    searchUser: string | any;

    private subscriptions: Array<Subscription> = [];

    ngOnInit(): void {
    }

    constructor(private route: ActivatedRoute,
                private usersService: UsersService,
                private rolesService: RolesService,
                private projectsComponent: ProjectsComponent,
                private messagesService: MessagesService,
                private projectsService: ProjectsService,
                private afs: AngularFirestore,
                private progress: NgProgress,
                private snackBar: MatSnackBar) {

        // set params
        this.subscriptions.push(this.route.params.subscribe(params => {
            this.projectsComponent.setNav(params.uid);

            // set user
            // @ts-ignore
            this.userCurrent = this.route.snapshot.parent.parent.data.user;

            // get project
            this.subscriptions.push(this.projectsService.getProject(this.userCurrent, this.projectsComponent.uid).subscribe(project => {
                this.project = project;

                this.projectsComponent.title = this.project.name;

                // get users and set access variable
                this.getUsers();

            }));

        }));

    }

    private getUsers(): void {
        this.subscriptions.push(this.usersService.getUsers(this.userCurrent.companyRef).subscribe(users => {
            users.forEach(user => {
                user.projectAccess = !!this.project.userRefs[user.uid];
            });
            this.users = users;
            this.usersComplete = true;
        }));
    }

    onChangeAccess(userUid: string): void {
        this.progress.ref().start();

        let message: string;
        let change: MessageType;
        let userName = '';
        this.users.forEach(user => {
            if (user.uid === userUid) {
                userName = user.name;
            }
        });

        if (this.project.userRefs[userUid]) {
            delete this.project.userRefs[userUid];
            message = 'Access to the project has been removed';
            change = {
                uid: this.afs.createId(),
                message: `has removed from the team ${userName} `,
                dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
                userRef: this.afs.doc(`users/${this.userCurrent.uid}`).ref,
                files: [],
                type: 'auto'
            };
        } else {
            this.project.userRefs[userUid] = this.afs.doc(`users/${userUid}`).ref;
            message = 'Access to the project has been added';
            change = {
                uid: this.afs.createId(),
                message: `has added to the team ${userName}`,
                dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
                userRef: this.afs.doc(`users/${this.userCurrent.uid}`).ref,
                files: [],
                type: 'auto'
            };
        }

        this.projectsService.updateProject({
            userRefs: this.project.userRefs
        }, this.userCurrent, this.project.uid)
            .then(() => {
                this.messagesService.insertMessage(change, `companies/${this.userCurrent.companyRef.id}/projects/${this.project.uid}/changes/${change.uid}`)
                    .then(() => {
                        this.snackBar.open(message, '', environment.snackbarSuccess);
                        this.progress.ref().complete();
                    })
                    .catch(err => {
                        this.snackBar.open(err.message, '', environment.snackbarWarn);
                    });
            })
            .catch(err => {
                this.snackBar.open(err.message, '', environment.snackbarWarn);
                this.progress.ref().complete();
            });
        this.progress.ref().complete();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
