import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ServiceType} from '../_types/service.type';
import {ServiceCategoryType} from '../_types/serviceCategory.type';
import {ServiceSubcategoryType} from '../_types/serviceSubcategory.type';
import {UserType} from '../_types/user.type';
import {map} from 'rxjs/operators';
import * as firebase from 'firebase/app';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(private afs: AngularFirestore) {
  }

  // check access to service
  private checkAccess = (service: ServiceType, user: UserType) => {
    let access = false;
    if (service.userRefs[user.uid]) {
      access = true;
    }
    return access;
  }

  // get services
  getServices(user: UserType): Observable<ServiceType[]> {
    return this.afs.collection<ServiceType>(`services`, ref => ref
      .where('isActive', '==', true)
      .where(`companyRef`, '==', user.companyRef)
      .orderBy('name'))
      .valueChanges()
      .pipe(map(services => {
        // check access to project
        for (const service of services) {
          service.access = this.checkAccess(service, user);
        }
        return services;
      }));
  }

  // get service
  getService(slug: string): Observable<ServiceType[]> {
    return this.afs.collection<ServiceType>(`services`, ref => ref
      .where('isActive', '==', true)
      .where('slug', '==', slug))
      .valueChanges();
  }

  // get service once
  getServiceOnce(serviceUid: string): any {
    const db = firebase.firestore();
    return db.doc(`services/${serviceUid}`).get();
  }

  // get categories
  getCategories(service: ServiceType): Observable<ServiceCategoryType[]> {
    return this.afs.collection<ServiceCategoryType>(`services/${service.uid}/categories`, ref => ref
      .where('isActive', '==', true)
      .orderBy('name', 'asc'))
      .valueChanges();
  }

  // get categories once
  getCategoriesOnce(service: ServiceType): any {
    const db = firebase.firestore();
    return db.collection(`services/${service.uid}/categories`)
      .where('isActive', '==', true)
      .orderBy('name', 'asc')
      .get();
  }

  // get subcategories
  getSubcategories(service: ServiceType, category: ServiceCategoryType): Observable<ServiceSubcategoryType[]> {
    return this.afs.collection<ServiceSubcategoryType>(`services/${service.uid}/categories/${category.uid}/subcategories`, ref => ref
      .where('isActive', '==', true)
      .orderBy('name', 'asc'))
      .valueChanges();
  }

  // get subcategories once
  getSubcategoriesOnce(service: ServiceType, category: any): any {
    const db = firebase.firestore();
    return db.collection(`services/${service.uid}/categories/${category.uid}/subcategories`)
      .where('isActive', '==', true)
      .orderBy('name', 'asc')
      .get();
  }

  // update service
  updateService(fields: {}, serviceUid: string): Promise<void> {
    return this.afs.doc<ServiceType>(`services/${serviceUid}`).update(fields);
  }
}
