import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AvatarPipe} from './_pipes/avatar.pipe';
import {FilterPipe} from './_pipes/filter.pipe';
import {SafeHtmlPipe} from './_pipes/safeHtml.pipe';
import {UrlifyPipe} from './_pipes/urlify.pipe';
import {FileSizePipe} from './_pipes/fileSize.pipe';
import {SharedTicketComponent} from './_shared/shared-ticket/shared-ticket.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {QuillModule} from 'ngx-quill';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatListModule} from '@angular/material/list';
import {SharedMessageComponent} from './_shared/shared-message/shared-message.component';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {MatExpansionModule} from '@angular/material/expansion';
import {NgxUploaderModule} from 'ngx-uploader';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,

    FormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatListModule,
    MatExpansionModule,
    MatChipsModule,
    MatIconModule,

    QuillModule,
    NgxUploaderModule,
    MatTabsModule
  ],
  declarations: [
    FilterPipe,
    SafeHtmlPipe,
    AvatarPipe,
    UrlifyPipe,
    FileSizePipe,

    SharedTicketComponent,
    SharedMessageComponent,
  ],
  exports: [
    FilterPipe,
    SafeHtmlPipe,
    AvatarPipe,
    UrlifyPipe,
    FileSizePipe,

    SharedTicketComponent,
    SharedMessageComponent,
  ],
})
export class SharedModule { }
