import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {UserType} from '../_types/user.type';
import {switchMap, map, first} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {Observable} from 'rxjs';
import {RolesService} from './roles.service';

@Injectable({
  providedIn: 'root'
})
export class ResolveUserService implements Resolve<Observable<UserType>> {

  constructor(private rolesService: RolesService,
              private afs: AngularFirestore,
              private afAuth: AngularFireAuth) {
  }

  resolve(): Observable<any> {
    return this.afAuth.authState.pipe(switchMap((user: any) => {
      return this.afs.doc<UserType>(`users/${user.uid}`).valueChanges().pipe(first());
      // return this.afs.doc<UserType>(`users/${user.uid}`).valueChanges().pipe(map(user2 => {
      //     user2.role$ = this.rolesService.getRole(user2, user2.roleRef.id).pipe(first());
      //
      //     // this.rolesService.getRole(user, user.roleRef.id).subscribe(role => {
      //     //     user2.role = role;
      //     // });
      //     return user2;
      // }), first());
    })).pipe(first());
  }
}
