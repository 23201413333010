import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {MessageType} from '../_types/message.type';
import {map} from 'rxjs/operators';
import * as firebase from 'firebase/app';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})
export class MessagesService {

    constructor(private afs: AngularFirestore) {
    }

    // insert task message
    insertMessage(message: MessageType, path: string): Promise<void> {
        return this.afs.doc<MessageType>(path).set(message);
    }

    // get task messages
    getMessages(path: string): Observable<MessageType[]> {
        return this.afs.collection<MessageType>(path, ref => ref
            .orderBy('dateCreate', 'desc'))
            .valueChanges()
            .pipe(map(messages => {
            // for (let message of messages) {
            //     message.author = this.afs.doc(message.userRef.path).valueChanges();
            // }
            return messages;
        }));

    }

    // upload file
    uploadFile(file: any, path: string, fileName: string): any {
        const metadata = {
            contentDisposition: 'attachment; filename=' + fileName,
        };
        return firebase.storage().ref().child(path).put(file, metadata);
    }
}
