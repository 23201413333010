<section class="page-section page-section--service-list">
  <div class="row">
    <div class="col col--header">
      <a mat-stroked-button
         routerLink="/services/{{ servicesComponent.uid }}/list/{{ prevYear }}/{{ prevMonth }}">Prev
        month</a>
      <span class="date">{{ year }}-{{ month | number:'2.0' }}</span>
      <a mat-stroked-button
         routerLink="/services/{{ servicesComponent.uid }}/list/{{ nextYear }}/{{ nextMonth }}">Next
        month</a>
      <mat-form-field>
        <input matInput placeholder="Search" type="search" [(ngModel)]="searchTicket">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <mat-nav-list *ngIf="tickets.length; else noRecords">
    <mat-list-item *ngFor="let ticket of tickets | filter:searchTicket:['name']" (click)="onOpenDialog(ticket)">
      <div class="status status--{{ticket.status}}"><span class="material-icons">support</span></div>
      <div matLine class="name">{{ ticket.name }}</div>
      <div matLine class="content">
        <div>
          <small class="date">Date: {{ ticket.dateCreate.toDate() | date: 'yyyy-MM-dd HH:mm' }}</small>
          <small class="sla">SLA: {{ ticket.sla }}h</small>
        </div>
        <div>
          <small class="authors">Author(s): {{ ticket.notificationEmails[0]}} ({{ ticket.notificationEmails.length }})</small>
          <small class="todo" *ngIf="ticket.userTodo">Todo: {{ ticket.userTodo.name }}</small>
        </div>
      </div>
    </mat-list-item>
  </mat-nav-list>

  <ng-template #noRecords>
    <div>No tickets</div>
  </ng-template>

  <ng-template #loading>
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </ng-template>

</section>
