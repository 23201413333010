import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {TaskType} from '../../_types/task.type';
import {MessageType} from '../../_types/message.type';

@Component({
  selector: 'app-shared-message',
  templateUrl: './shared-message.component.html',
  styleUrls: ['./shared-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SharedMessageComponent implements OnInit {

  @Input() message: MessageType;

  expandImage = false;

  constructor() {
  }

  ngOnInit() {
  }

}
