import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChangelogService} from '../../_services/changelog.service';
import {Subscription} from 'rxjs';
import {ChangelogType} from '../../_types/changelog.type';
import {environment} from '../../../environments/environment';
import {UsersService} from '../../_services/users.service';
import {UserType} from '../../_types/user.type';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-changelog',
    templateUrl: './changelog.component.html',
    styleUrls: ['./changelog.component.scss']
})
export class ChangelogComponent implements OnInit, OnDestroy {

    user: UserType;
    logs: ChangelogType[] = [];

    private subscriptions: Array<Subscription> = [];

    constructor(private changelogService: ChangelogService,
                private snackBar: MatSnackBar,
                private usersService: UsersService,
                private route: ActivatedRoute) {

        // set user
        this.user = this.route.snapshot.parent.data.user;

        // get changelog
        this.getChangelog();

    }

    ngOnInit() {
    }

    private getChangelog() {
        this.subscriptions.push(this.changelogService.getChangelog().subscribe(logs => {
            this.logs = logs;
            // update user
            this.updateUser();
        }));
    }

    private updateUser() {
        let bottomSheet = '';
        if (this.user.changelog) {
            bottomSheet = this.user.changelog.bottomSheet;
        }

        this.usersService.updateUser({
            changelog: {
                bottomSheet: bottomSheet,
                page: this.logs[0].version
            }
        }, this.user.uid)
            .then(() => {
            })
            .catch(err => {
                this.snackBar.open(err.message, '', environment.snackbarWarn);
            });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
