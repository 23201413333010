import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AngularFireAuth} from '@angular/fire/auth';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss']
})
export class CoreComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private usr: ActivatedRoute,
              private router: Router,
              private afAuth: AngularFireAuth,
              private translate: TranslateService) {

    // set translation
    translate.setDefaultLang('en');
    translate.use('en');

    if (this.router.url === '/') {
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit() {
  }

}
