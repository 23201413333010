import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {UsersComponent} from '../users.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs/internal/Subscription';
import {TaskType} from '../../../_types/task.type';
import {ProjectType} from '../../../_types/project.type';
import {UserType} from '../../../_types/user.type';
import {MessageType} from '../../../_types/message.type';
import {UsersService} from '../../../_services/users.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {TasksService} from '../../../_services/tasks.service';
import {NgForm} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import * as firebase from 'firebase/app';
import {ProjectsService} from '../../../_services/projects.service';
import {MessagesService} from '../../../_services/messages.service';
import {NotificationType} from '../../../_types/notification.type';
import {NotificationsService} from '../../../_services/notifications.service';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {formatDate} from '@angular/common';
import {FcmService} from '../../../_services/fcm.service';
import {SharedTaskDialogComponent} from '../../../_shared/shared-task-dialog/shared-task-dialog.component';
import {TicketsService} from '../../../_services/tickets.service';
import {ServicesService} from '../../../_services/services.service';
import {ServiceType} from '../../../_types/service.type';
import {TicketType} from '../../../_types/ticket.type';
import {SharedTicketDialogComponent} from '../../../_shared/shared-ticket-dialog/shared-ticket-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgProgress} from 'ngx-progressbar';

@Component({
  selector: 'app-user-calendar',
  templateUrl: './user-calendar.component.html',
  styleUrls: ['./user-calendar.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class UserCalendarComponent implements OnInit, OnDestroy {

  today = new Date();
  month: number | any;
  year: number | any;
  nextMonth: number | any;
  nextYear: number | any;
  prevMonth: number | any;
  prevYear: number | any;
  daysInMonth: number | any;
  search: string | any;
  enterTaskUid = '';

  task: TaskType | any = {
    uid: null,
    userTodoRef: null,
    userAuthorRef: null,
    name: null,
    description: null,
    projectRef: null,
    dateStart: new Date(),
    dateEnd: new Date(),
    showInMonths: null,
    showInDays: null,
    showInWeeks: null,
    type: 'feature',
    priority: 'normal',
    estimation: 1,
    dateUpdate: null,
    dateCreate: null,
    userUpdateRef: null,
    status: 'todo',
    isActive: true,
    files: []
  };

  commonProjects: ProjectType[] | any;
  projects: ProjectType[] | any;
  projectsComplete = false;
  project: any;
  tasksTodo: TaskType[] | any;
  tasksTodoComplete = false;
  tasksOrdered: TaskType[] | any;
  tasksOrderedComplete = false;
  tasksTodoNoDate: TaskType[] | any;
  tasksTodoNoDateComplete = false;
  tasksTodoNoDateHours = 0;
  tasksOrderedNoDate: TaskType[] | any;
  tasksOrderedNoDateComplete = false;
  tasksComplete = false;
  tasksOrderedNoDateHours = 0;
  user: UserType | any;
  userCurrent: UserType | any;
  users: UserType[] | any;
  usersComplete = false;
  maxTodoHours: boolean[] = [];
  maxOrderedHours: boolean[] = [];

  services: ServiceType[] | any;
  tickets: TicketType[] | any = [];
  ticketsComplete = false;
  enterTicketUid = '';

  days: Array<any> = [];

  title: string | any;

  showTodo = true;
  showOrdered = false;

  taskDetails: TaskType | any = {
    uid: null,
    userTodoRef: null,
    userAuthorRef: null,
    name: null,
    description: null,
    projectRef: null,
    dateStart: new Date(),
    dateEnd: new Date(),
    showInMonths: null,
    showInDays: null,
    showInWeeks: null,
    type: 'feature',
    priority: 'normal',
    estimation: 1,
    dateUpdate: null,
    dateCreate: null,
    userUpdateRef: null,
    status: 'todo',
    isActive: true,
    files: []
  };

  options: any = {
    locale: {format: 'YYYY-MM-DD'},
    alwaysShowCalendars: false,
    autoApply: true,
    startDate: new Date(),
    endDate: new Date(),
  };

  private subscriptions: Array<Subscription> = [];
  private routeParamsSubscription: Subscription | any;

  constructor(public usersComponent: UsersComponent,
              private dialog: MatDialog,
              private usersService: UsersService,
              private tasksService: TasksService,
              private ticketsService: TicketsService,
              private projectsService: ProjectsService,
              private servicesService: ServicesService,
              private messagesService: MessagesService,
              private notificationsService: NotificationsService,
              private fcmService: FcmService,
              private afs: AngularFirestore,
              private route: ActivatedRoute,
              private router: Router,
              private progress: NgProgress,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    // reset hours
    this.task.dateStart.setHours(0, 0, 0, 0);
    this.task.dateEnd.setHours(0, 0, 0, 0);

    // get params
    this.routeParamsSubscription = this.route.params.subscribe(params => {
      this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
      });
      this.subscriptions = [];

      this.month = parseInt(params.month, 10);
      this.year = parseInt(params.year, 10);
      this.today.setHours(0, 0, 0, 0);

      // set user
      // @ts-ignore
      this.user = this.route.snapshot.parent.parent.data.user;

      // set hide/show todoTasks
      if (this.user.settings && (typeof(this.user.settings.calendar_showTodo) !== 'undefined')) {
        this.showTodo = this.user.settings.calendar_showTodo;
      }

      // set hide/show orderedTasks
      if (this.user.settings && (typeof(this.user.settings.calendar_showOrdered) !== 'undefined')) {
        this.showOrdered = this.user.settings.calendar_showOrdered;
      }

      // navigate to details if user doesn't have access to module projects
      if (!this.user._modules || !this.user._modules.projects) {
        this.router.navigate([`/users/${params.uid}/details`]);
      }

      // navigate to calendar current month and year
      if ((!this.month) || (!this.year)) {
        this.router.navigate([`/users/${params.uid}/calendar/${this.today.getFullYear()}/${this.today.getMonth() + 1}`]);
      }

      // set prev and next month
      this.nextMonth = this.month + 1;
      this.nextYear = this.year;
      if (this.nextMonth === 13) {
        this.nextMonth = 1;
        this.nextYear = this.year + 1;
      }

      this.prevMonth = this.month - 1;
      this.prevYear = this.year;
      if (this.prevMonth === 0) {
        this.prevMonth = 12;
        this.prevYear = this.year - 1;
      }

      this.daysInMonth = new Date(this.year, this.month, 0).getDate();

      // get current user
      this.subscriptions.push(this.usersService.getUserByRef(this.afs.doc(`users/${params.uid}`).ref).subscribe(userCurrent => {
        this.userCurrent = userCurrent as UserType;
        this.usersComponent.title = this.userCurrent.name;

        // set nav
        this.usersComponent.setNavInternal(params.uid);

        // get projects
        this.projectsComplete = false;
        this.getProjects();

        // get tasks
        this.tasksComplete = false;
        this.tasksTodoComplete = false;
        this.tasksOrderedComplete = false;
        this.tasksTodoNoDateComplete = false;
        this.tasksOrderedNoDateComplete = false;
        this.getTasks();

        // get users - necessary to countEstimationsReal and for access denied tasks
        this.usersComplete = false;
        this.getUsers();

        // get services and then tickets
        this.ticketsComplete = false;
        this.getServicesAndTickets();

      }));
    });


  }

  private getProjects(): void {
    this.subscriptions.push(this.projectsService.getProjects(this.user).subscribe(projects => {
      this.projects = projects;
      this.projectsComplete = true;
      this.matchTasksToProjects();

      // set my projects
      const tempProjects: Array<ProjectType> = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < projects.length; i++) {
        if (projects[i].userRefs[this.user.uid]) {
          tempProjects.push(projects[i]);
        }
      }
      this.commonProjects = tempProjects;
    }));
  }

  private getServicesAndTickets(): void {
    let yearMonth = this.year + '0' + this.month;
    if (this.month > 9) {
      yearMonth = this.year + '' + this.month;
    }

    this.subscriptions.push(
      this.servicesService.getServices(this.user).subscribe(services => {
        this.services = services;
        if (this.services.length <= 0) {
          this.ticketsComplete = true;
          this.setCalendar();
        }
        // get tickets
        this.services.forEach((service: { uid: any; }) => {
          this.subscriptions.push(
            this.ticketsService.getTicketsByMonth(`services/${service.uid}/tickets`, yearMonth, this.afs.doc(`users/${this.userCurrent.uid}`).ref).subscribe(tickets => {
              this.tickets = tickets;
              this.tickets.forEach((ticket: { service: { uid: any; }; }) => {
                ticket.service = service;
              });
              this.ticketsComplete = true;
              this.setCalendar();
            })
          );
        });
      })
    );
  }

  private getTasks(): void {
    let yearMonth = this.year + '0' + this.month;
    if (this.month > 9) {
      yearMonth = this.year + '' + this.month;
    }

    // get tasks to do
    this.subscriptions.push(this.tasksService.getTasksTodoByMonth(`companies/${this.user.companyRef.id}/tasks`, yearMonth, this.afs.doc(`users/${this.userCurrent.uid}`).ref).subscribe(tasks => {
      this.tasksTodo = tasks;
      this.tasksTodoComplete = true;
      this.matchTasksToProjects();
    }));

    // get tasks ordered
    this.subscriptions.push(this.tasksService.getTasksOrderedByMonth(`companies/${this.user.companyRef.id}/tasks`, yearMonth, this.afs.doc(`users/${this.userCurrent.uid}`).ref).subscribe(tasks => {
      this.tasksOrdered = tasks;
      this.tasksOrderedComplete = true;
      this.matchTasksToProjects();
    }));

    // get tasks to do without date
    this.subscriptions.push(this.tasksService.getTasksTodoNoDate(`companies/${this.user.companyRef.id}/tasks`, this.afs.doc(`users/${this.userCurrent.uid}`).ref).subscribe(tasks => {
      this.tasksTodoNoDate = tasks;
      this.tasksTodoNoDateComplete = true;
      this.tasksTodoNoDateHours = 0;
      this.tasksTodoNoDate.forEach((task: { estimation: number; }) => {
        this.tasksTodoNoDateHours += task.estimation;
      });
      this.matchTasksToProjects();
    }));

    // get tasks ordered without date
    this.subscriptions.push(this.tasksService.getTasksOrderedNoDate(`companies/${this.user.companyRef.id}/tasks`, this.afs.doc(`users/${this.userCurrent.uid}`).ref).subscribe(tasks => {
      this.tasksOrderedNoDate = tasks;
      this.tasksOrderedNoDateComplete = true;
      this.tasksOrderedNoDateHours = 0;
      this.tasksOrderedNoDate.forEach((task: { estimation: number; }) => {
        this.tasksOrderedNoDateHours += task.estimation;
      });
      this.matchTasksToProjects();
    }));
  }

  private getUsers(): void {
    this.users = [];
    this.usersService.onceUsers(this.user.companyRef).then((users: any[]) => {
      users.forEach((userSnapshot: any) => {
        const user: UserType = {
          uid: userSnapshot.data().uid,
          email: userSnapshot.data().email,
          name: userSnapshot.data().name,
          companyRef: userSnapshot.data().companyRef,
          isEmailVerified: userSnapshot.data().isEmailVerified,
          dateUpdate: userSnapshot.data().dateUpdate,
          userUpdateRef: userSnapshot.data().userUpdateRef,
          isActive: userSnapshot.data().isActive,
          isEmailNotification: userSnapshot.data().isEmailNotification,
          roleRef: userSnapshot.data().roleRef,
          _rolesName: userSnapshot.data()._rolesName,
          photoURL: userSnapshot.data().photoURL,
          changelog: {
            page: '',
            bottomSheet: '',
          }
        };
        this.users.push(user);
      });
      this.usersComplete = true;
      this.matchTasksToProjects();
    });
  }

  private matchTasksToProjects(): void {
    if (this.projectsComplete && this.tasksTodoComplete && this.tasksOrderedComplete && this.tasksTodoNoDateComplete
      && this.tasksOrderedNoDateComplete && this.usersComplete) {

      // match tasks to do
      this.assingTasksToProjectAndUsers(this.tasksTodo);

      // match tasks ordered
      this.assingTasksToProjectAndUsers(this.tasksOrdered);

      // match tasks to do without date
      this.assingTasksToProjectAndUsers(this.tasksTodoNoDate);

      // match tasks ordered without date
      this.assingTasksToProjectAndUsers(this.tasksOrderedNoDate);

      // goto set calendar
      this.tasksComplete = true;
      this.setCalendar();
    }
  }

  private assingTasksToProjectAndUsers(tasks: any[]): void {
    // define archival project
    const projectArchival: ProjectType = {
      uid: '',
      clientRef: '',
      name: 'Archival',
      shortDesc: '',
      notes: '',
      userRefs: '',
      wiki: [],
      isActive: false,
      files: [],
      access: false
    };

    // assign
    tasks.forEach(task => {
      task.project = projectArchival;
      task.projectName = projectArchival.name; // only for FilterPipe

      this.projects.forEach((project: any) => {
        if (project.uid === task.projectRef.id) {
          task.project = project;
          task.projectName = project.name; // only for FilterPipe
        }
      });
      this.users.forEach((user: any) => {
        if (user.uid === task.userAuthorRef.id) {
          task.userAuthor = user;
        }
      });
    });


  }

  private setCalendar(): void {
    if (this.tasksComplete && this.ticketsComplete) {
      // tslint:disable-next-line:one-variable-per-declaration
      let helpStrDate, helpTask, helpTicket, helpMax = 0, helpMaxTodoHours = 0, helpMaxOrderedHours = 0;
      this.maxTodoHours = [];
      this.maxOrderedHours = [];
      this.days = [];
      for (let i = 0; i < this.daysInMonth; i++) {
        const helpDate: Date = new Date(this.year, (this.month - 1), (i + 1));
        const helpDay: number = helpDate.getDay();

        this.days[i] = {
          date: helpDate,
          isToday: new Date(helpDate).getTime() === new Date(this.today).getTime() ? true : false,
          isWeekendStart: (helpDay === 6) ? true : false,
          isWeekendEnd: (helpDay === 0) ? true : false,
          tasksTodo: [],
          tasksOrdered: [],
          tickets: [],
          hoursTodo: 0,
          hoursOrdered: 0
        };

        // set tasks and tickets to-do
        // set tasks
        helpMax = 0;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.tasksTodo.length; j++) {
          helpStrDate = this.year;
          if (this.month < 10) {
            helpStrDate += '0' + this.month;
          } else {
            helpStrDate += '' + this.month;
          }

          if ((i + 1) < 10) {
            helpStrDate += '0' + (i + 1);
          } else {
            helpStrDate += (i + 1);
          }

          if (typeof (this.tasksTodo[j].showInDays[helpStrDate]) !== 'undefined') {
            helpTask = Object.assign({}, this.tasksTodo[j]);
            helpTask.day = this.calcBusinessDays(new Date(this.tasksTodo[j].dateStart.seconds * 1000), this.days[i].date);
            helpTask.estimationDay = helpTask.estimation / Object.keys(helpTask.showInDays).length;
            helpTask.taskStart = false;
            if (Object.keys(this.tasksTodo[j].showInDays)[0] === helpStrDate) {
              helpTask.taskStart = true;
            }
            helpTask.taskEnd = false;
            if (Object.keys(this.tasksTodo[j].showInDays)[Object.keys(helpTask.showInDays).length - 1] === helpStrDate) {
              helpTask.taskEnd = true;
            }
            this.days[i].tasksTodo.push(helpTask);
            this.days[i].hoursTodo += helpTask.estimationDay;
            helpMax += helpTask.estimationDay;
            helpMaxTodoHours = Math.max(helpMaxTodoHours, helpMax);
          }
        }
        // sort tasks according to date create
        this.days[i].tasksTodo.sort((a: any, b: any) => {
          if ((a.dateCreate) && (b.dateCreate)) {
            return a.dateCreate.seconds - b.dateCreate.seconds;
          }
          return null;
        });
        // set tickets
        helpMax = 0;
        for (let j = 0; j < this.tickets.length; j++) {
          helpStrDate = this.year;
          if (this.month < 10) {
            helpStrDate += '0' + this.month;
          } else {
            helpStrDate += '' + this.month;
          }

          if ((i + 1) < 10) {
            helpStrDate += '0' + (i + 1);
          } else {
            helpStrDate += (i + 1);
          }

          if (typeof (this.tickets[j].showInDays[helpStrDate]) !== 'undefined') {
            helpTicket = Object.assign({}, this.tickets[j]);
            helpTicket.estimationDay = helpTicket.estimation / Object.keys(helpTicket.showInDays).length;
            helpTicket.ticketStart = false;
            if (Object.keys(this.tickets[j].showInDays)[0] === helpStrDate) {
              helpTicket.ticketStart = true;
            }
            helpTicket.ticketEnd = false;
            if (Object.keys(this.tickets[j].showInDays)[Object.keys(helpTicket.showInDays).length - 1] === helpStrDate) {
              helpTicket.ticketEnd = true;
            }
            this.days[i].tickets.push(helpTicket);
            this.days[i].hoursTodo += helpTicket.estimationDay;
            helpMax += helpTicket.estimationDay;
            helpMaxTodoHours = Math.max(helpMaxTodoHours, helpMax);
          }
        }
        // sort tickets according to date create
        this.days[i].tickets.sort((a: any, b: any) => {
          if ((a.dateCreate) && (b.dateCreate)) {
            return a.dateCreate.seconds - b.dateCreate.seconds;
          }
          return null;
        });
        // set hoursTodo
        this.days[i].hoursTodo = Math.round(this.days[i].hoursTodo * 10) / 10;

        // set tasks ordered
        helpMax = 0;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.tasksOrdered.length; j++) {
          helpStrDate = this.year;
          if (this.month < 10) {
            helpStrDate += '0' + this.month;
          } else {
            helpStrDate += '' + this.month;
          }

          if ((i + 1) < 10) {
            helpStrDate += '0' + (i + 1);
          } else {
            helpStrDate += (i + 1);
          }

          if (typeof (this.tasksOrdered[j].showInDays[helpStrDate]) !== 'undefined') {
            helpTask = Object.assign({}, this.tasksOrdered[j]);
            helpTask.estimationDay = helpTask.estimation / Object.keys(helpTask.showInDays).length;
            helpTask.taskStart = false;
            if (Object.keys(this.tasksOrdered[j].showInDays)[0] === helpStrDate) {
              helpTask.taskStart = true;
            }
            helpTask.taskEnd = false;
            if (Object.keys(this.tasksOrdered[j].showInDays)[Object.keys(helpTask.showInDays).length - 1] === helpStrDate) {
              helpTask.taskEnd = true;
            }
            this.days[i].tasksOrdered.push(helpTask);
            this.days[i].hoursOrdered += helpTask.estimationDay;
            helpMax += helpTask.estimationDay;
            helpMaxOrderedHours = Math.max(helpMaxOrderedHours, helpMax);
          }
        }
        // sort according to date create
        this.days[i].tasksOrdered.sort((a: any, b: any) => {
          if ((a.dateCreate) && (b.dateCreate)) {
            return a.dateCreate.seconds - b.dateCreate.seconds;
          }
          return null;
        });
        this.days[i].hoursOrdered = Math.round(this.days[i].hoursOrdered * 10) / 10;
      }

      for (let j = 1; j < helpMaxTodoHours; j++) {
        this.maxTodoHours.push(true);
      }

      for (let j = 1; j < helpMaxOrderedHours; j++) {
        this.maxOrderedHours.push(true);
      }
    }
  }

  private updateProject(project: ProjectType, userTodo: UserType): void {
    // set access to project
    project.userRefs[userTodo.uid] = this.afs.doc(`users/${userTodo.uid}`).ref;

    // update project
    this.projectsService.updateProject({
      userRefs: project.userRefs
    }, this.user, project.uid).then();
  }

  private onUpdateTask(task: any, dragDate: any, dropDate: any): any {
    // start progress bar
    this.progress.ref().start();
    // copy task
    const taskCopy = Object.assign({}, task);
    taskCopy.dateStart = Object.assign({}, task.dateStart);
    taskCopy.dateEnd = Object.assign({}, task.dateEnd);
    // define val
    let show: {
      days: {},
      months: {},
      weeks: {}
    };
    let message: string;
    let notification: string;

    // manually remove task from the current day and add task to the target day - avoid blinking
    this.removeAndAddTask(task, dragDate, dropDate);

    if (task.dateStart.seconds === task.dateEnd.seconds) {  // if task takes 1 day
      // change dateStart and dateEnd
      task.dateStart.seconds = dropDate.getTime() / 1000;
      task.dateEnd.seconds = dropDate.getTime() / 1000;
      message = `has changed date start: ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}<br>and date end: ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}`;
      notification = `</p>has changed project task <a class="link" routerLink="projects/${task.projectRef.id}/task/${task.uid}">${task.name}</a> <code class="code">date start: ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code><code class="code">date end: ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code></p>`;
    } else {    // if task takes more than one day
      if ((task.dateStart.seconds * 1000) === dragDate.getTime()) { // if drag'n'drop the first day of the task
        task.dateStart.seconds = dropDate.getTime() / 1000;
        if (task.dateStart.seconds <= task.dateEnd.seconds) {
          // prepare message and notification
          message = `has changed date start ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}`;
          notification = `</p>has changed project task <a class="link" routerLink="projects/${task.projectRef.id}/task/${task.uid}">${task.name}</a> <code class="code">date start: ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code></p>`;
        } else {
          task.dateStart.seconds = task.dateEnd.seconds;
          task.dateEnd.seconds = dropDate.getTime() / 1000;
          // prepare message and notification
          message = `has changed date start: ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}<br>and date end: ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}`;
          notification = `</p>has changed project task <a class="link" routerLink="projects/${task.projectRef.id}/task/${task.uid}">${task.name}</a> <code class="code">date start: ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code><code class="code">date end: ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code></p>`;
        }
      } else if ((task.dateEnd.seconds * 1000) === dragDate.getTime()) {  // if drag'n'drop the last day of the task
        task.dateEnd.seconds = dropDate.getTime() / 1000;
        if (task.dateEnd.seconds >= task.dateStart.seconds) {
          // prepare message and notification
          message = `has changed date end ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}`;
          notification = `</p>has changed project task <a class="link" routerLink="projects/${task.projectRef.id}/task/${task.uid}">${task.name}</a> <code class="code">date end: ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code></p>`;
        } else {
          task.dateEnd.seconds = task.dateStart.seconds;
          task.dateStart.seconds = dropDate.getTime() / 1000;
          // prepare message and notification
          message = `has changed date start: ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}<br>and date end: ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}`;
          notification = `</p>has changed project task <a class="link" routerLink="projects/${task.projectRef.id}/task/${task.uid}">${task.name}</a> <code class="code">date start: ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code><code class="code">date end: ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code></p>`;
        }
      } else {    // if drag'n'drop the middle day of the task
        // check if we change date backward or forward
        const diffBusinessDays = this.calcBusinessDays(dragDate, dropDate);
        if (diffBusinessDays > 0) {
          // check and remove weekend days
          // @ts-ignore
          task.dateStart.seconds = this.addBusinessDays(task.dateStart.seconds * 1000, diffBusinessDays) / 1000;
          // @ts-ignore
          task.dateEnd.seconds = this.addBusinessDays(task.dateEnd.seconds * 1000, diffBusinessDays) / 1000;
        } else {
          // minus business days
          task.dateStart.seconds = this.minusBusinessDays(task.dateStart.seconds * 1000, diffBusinessDays) / 1000;
          task.dateEnd.seconds = this.minusBusinessDays(task.dateEnd.seconds * 1000, diffBusinessDays) / 1000;
        }
        // prepare message and notification
        message = `has changed date start: ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}<br>and date end: ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}`;
        notification = `</p>has changed project task <a class="link" routerLink="projects/${task.projectRef.id}/task/${task.uid}">${task.name}</a> <code class="code">date start: ${formatDate(new Date(taskCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code><code class="code">date end: ${formatDate(new Date(taskCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(task.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code></p>`;
      }
    }

    show = this.calcShowInDaysMonthsWeeks(task.dateStart.seconds * 1000, task.dateEnd.seconds * 1000);

    this.tasksService.updateTask({
      dateStart: new Date(task.dateStart.seconds * 1000),
      dateEnd: new Date(task.dateEnd.seconds * 1000),
      showInDays: show.days,
      showInMonths: show.months,
      showInWeeks: show.weeks,
      dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
      userUpdateRef: this.afs.doc(`users/${this.user.uid}`).ref
    }, `companies/${this.user.companyRef.id}/tasks/${task.uid}`)
      .then(() => {
        // insert message
        this.insertMessageTask(
          message,
          task
        );
        // calculate users which should be informed
        const informed = this.calcInformedTask(task);
        for (const informUser of Object.keys(informed)) {
          this.saveNotificationTaskTicket(
            notification,
            informUser,
            task.uid,
            'projectTask'
          );

        }
        // show snackbar
        this.snackBar.open('Task has been updated', '', environment.snackbarSuccess);
        // close progress bar
        this.progress.ref().complete();
      })
      .catch(err => {
        // show snackbar
        this.snackBar.open(err.message, '', environment.snackbarWarn);
        // close progress bar
        this.progress.ref().complete();
      });
  }

  private onUpdateTicket(ticket: any, dragDate: any, dropDate: any): void {
    // start progress bar
    this.progress.ref().start();
    // copy ticket
    const ticketCopy = Object.assign({}, ticket);
    ticketCopy.dateStart = Object.assign({}, ticket.dateStart);
    ticketCopy.dateEnd = Object.assign({}, ticket.dateEnd);
    // define val
    let show: {
      days: {},
      months: {},
      weeks: {}
    };
    let message: string;
    let notification: string;

    // manually remove task from the current day and add task to the target day - avoid blinking
    this.removeAndAddTicket(ticket, dragDate, dropDate);

    if (ticket.dateStart.seconds === ticket.dateEnd.seconds) {  // if ticket takes 1 day
      // change dateStart and dateEnd
      ticket.dateStart.seconds = dropDate.getTime() / 1000;
      ticket.dateEnd.seconds = dropDate.getTime() / 1000;
      message = `has changed date start: ${formatDate(new Date(ticketCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}<br>and date end: ${formatDate(new Date(ticketCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}`;
      notification = `</p>has changed service desk ticket <a class="link" routerLink="services/${ticket.serviceRef.id}/ticket/${ticket.uid}">${ticket.name}</a> <code class="code">date start: ${formatDate(new Date(ticketCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code><code class="code">date end: ${formatDate(new Date(ticketCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code></p>`;
    } else {    // if ticket takes more than one day
      if ((ticket.dateStart.seconds * 1000) === dragDate.getTime()) { // if drag'n'drop the first day of the ticket
        ticket.dateStart.seconds = dropDate.getTime() / 1000;
        if (ticket.dateStart.seconds <= ticket.dateEnd.seconds) {
          // prepare message and notification
          message = `has changed date start ${formatDate(new Date(ticketCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}`;
          notification = `</p>has changed service desk ticket <a class="link" routerLink="services/${ticket.serviceRef.id}/ticket/${ticket.uid}">${ticket.name}</a> <code class="code">date start: ${formatDate(new Date(ticketCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code></p>`;
        } else {
          ticket.dateStart.seconds = ticket.dateEnd.seconds;
          ticket.dateEnd.seconds = dropDate.getTime() / 1000;
          // prepare message and notification
          message = `has changed date start: ${formatDate(new Date(ticketCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}<br>and date end: ${formatDate(new Date(ticketCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}`;
          notification = `</p>has changed service desk ticket <a class="link" routerLink="services/${ticket.serviceRef.id}/ticket/${ticket.uid}">${ticket.name}</a> <code class="code">date start: ${formatDate(new Date(ticketCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code><code class="code">date end: ${formatDate(new Date(ticketCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code></p>`;
        }
      } else if ((ticket.dateEnd.seconds * 1000) === dragDate.getTime()) {  // if drag'n'drop the last day of the ticket
        ticket.dateEnd.seconds = dropDate.getTime() / 1000;
        if (ticket.dateEnd.seconds >= ticket.dateStart.seconds) {
          // prepare message and notification
          message = `has changed date end ${formatDate(new Date(ticketCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}`;
          notification = `</p>has changed service desk ticket <a class="link" routerLink="services/${ticket.serviceRef.id}/ticket/${ticket.uid}">${ticket.name}</a> <code class="code">date end: ${formatDate(new Date(ticketCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code></p>`;
        } else {
          ticket.dateEnd.seconds = ticket.dateStart.seconds;
          ticket.dateStart.seconds = dropDate.getTime() / 1000;
          // prepare message and notification
          message = `has changed date start: ${formatDate(new Date(ticketCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}<br>and date end: ${formatDate(new Date(ticketCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}`;
          notification = `</p>has changed service desk ticket <a class="link" routerLink="services/${ticket.serviceRef.id}/ticket/${ticket.uid}">${ticket.name}</a> <code class="code">date start: ${formatDate(new Date(ticketCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code><code class="code">date end: ${formatDate(new Date(ticketCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code></p>`;
        }
      } else {    // if drag'n'drop the middle day of the ticket
        // check if we change date backward or forward
        const diffBusinessDays = this.calcBusinessDays(dragDate, dropDate);
        if (diffBusinessDays > 0) {
          // check and remove weekend days
          // @ts-ignore
          ticket.dateStart.seconds = this.addBusinessDays(ticket.dateStart.seconds * 1000, diffBusinessDays) / 1000;
          // @ts-ignore
          ticket.dateEnd.seconds = this.addBusinessDays(ticket.dateEnd.seconds * 1000, diffBusinessDays) / 1000;
        } else {
          // minus business days
          ticket.dateStart.seconds = this.minusBusinessDays(ticket.dateStart.seconds * 1000, diffBusinessDays) / 1000;
          ticket.dateEnd.seconds = this.minusBusinessDays(ticket.dateEnd.seconds * 1000, diffBusinessDays) / 1000;
        }
        // prepare message and notification
        message = `has changed date start: ${formatDate(new Date(ticketCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}<br>and date end: ${formatDate(new Date(ticketCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}`;
        notification = `</p>has changed service desk ticket <a class="link" routerLink="services/${ticket.serviceRef.id}/ticket/${ticket.uid}">${ticket.name}</a> <code class="code">date start: ${formatDate(new Date(ticketCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code><code class="code">date end: ${formatDate(new Date(ticketCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(new Date(ticket.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')}</code></p>`;
      }
    }

    show = this.calcShowInDaysMonthsWeeks(ticket.dateStart.seconds * 1000, ticket.dateEnd.seconds * 1000);

    this.tasksService.updateTask({
      dateStart: new Date(ticket.dateStart.seconds * 1000),
      dateEnd: new Date(ticket.dateEnd.seconds * 1000),
      showInDays: show.days,
      showInMonths: show.months,
      showInWeeks: show.weeks
    }, `services/${ticket.serviceRef.id}/tickets/${ticket.uid}`)
      .then(() => {
        // insert message
        this.insertMessageTicket(
          message,
          ticket
        );
        // calculate users which should be informed
        const informed = this.calcInformedTicket(ticket);
        for (const informUser of Object.keys(informed)) {
          this.saveNotificationTaskTicket(
            notification,
            informUser,
            ticket.uid,
            'serviceDeskTicket'
          );

        }
        // show snackbar
        this.snackBar.open('Ticket has been updated', '', environment.snackbarSuccess);
        // close progress bar
        this.progress.ref().complete();
      })
      .catch(err => {
        // show snackbar
        this.snackBar.open(err.message, '', environment.snackbarWarn);
        // close progress bar
        this.progress.ref().complete();
      });
  }

  // manually remove and add task - avoid blinking caused by delay for time needed to read data from firestore
  private removeAndAddTask(task: any, dragDate: any, dropDate: any): void {
    // find indexes to remove
    let removeTodoIndex = {
      day: -1,
      task: -1
    };
    let removeOrederedIndex = {
      day: -1,
      task: -1
    };
    for (let i = 0; i < this.days.length; i++) {
      if (this.days[i].date.getTime() === dragDate.getTime()) {
        for (let j = 0; j < this.days[i].tasksTodo.length; j++) {
          if (this.days[i].tasksTodo[j].uid === task.uid) {
            // save remove index
            removeTodoIndex = {
              day: i,
              task: j
            };
          }
        }
      }
      if (this.days[i].date.getTime() === dragDate.getTime()) {
        for (let j = 0; j < this.days[i].tasksOrdered.length; j++) {
          if (this.days[i].tasksOrdered[j].uid === task.uid) {
            // remove task
            removeOrederedIndex = {
              day: i,
              task: j
            };
          }
        }
      }
    }

    // manually add and remove tasks
    if (removeTodoIndex.day > -1) {
      // add task
      this.days.forEach(day => {
        if (day.date.getTime() === dropDate.getTime()) {
          day.tasksTodo.push(task);
        }
      });
      // remove task
      this.days[removeTodoIndex.day].tasksTodo.splice(removeTodoIndex.task, 1);
    }
    if (removeOrederedIndex.day > -1) {
      // add task
      this.days.forEach(day => {
        if (day.date.getTime() === dropDate.getTime()) {
          day.tasksOrdered.push(task);
        }
      });
      // remove task
      this.days[removeOrederedIndex.day].tasksOrdered.splice(removeOrederedIndex.task, 1);
    }
  }

  // manually remove and add ticket - avoid blinking caused by delay for time needed to read data from firestore
  private removeAndAddTicket(ticket: any, dragDate: any, dropDate: any): void {
    // find indexes to remove
    let removeIndex = {
      day: -1,
      ticket: -1
    };
    for (let i = 0; i < this.days.length; i++) {
      if (this.days[i].date.getTime() === dragDate.getTime()) {
        for (let j = 0; j < this.days[i].tickets.length; j++) {
          if (this.days[i].tickets[j].uid === ticket.uid) {
            // save remove index
            removeIndex = {
              day: i,
              ticket: j
            };
          }
        }
      }
    }
    // manually add and remove tickets
    if (removeIndex.day > -1) {
      // add task
      this.days.forEach(day => {
        if (day.date.getTime() === dropDate.getTime()) {
          day.tickets.push(ticket);
        }
      });
      // remove ticket
      this.days[removeIndex.day].tickets.splice(removeIndex.ticket, 1);
    }
  }

  private calcInformedTask(task: any): any {
    const informed: any = {};
    if (task.userAuthorRef.id !== this.user.uid) {
      informed[task.userAuthorRef.id] = true;
    }
    if (task.userTodoRef.id !== this.user.uid) {
      informed[task.userTodoRef.id] = true;
    }
    return informed;
  }

  private calcInformedTicket(ticket: any): any {
    const informed: any = {};
    if (ticket.userTodoRef.id !== this.user.uid) {
      informed[ticket.userTodoRef.id] = true;
    }
    return informed;
  }

  private addBusinessDays(timestamp: string, days: any): any {
    const date = new Date(timestamp);
    while (days > 0) {
      date.setDate(date.getDate() + 1);
      if (date.getDay() !== 6 && date.getDay() !== 0) {
        days--;
      }
    }
    return date.getTime();
  }

  private minusBusinessDays(timestamp: any, days: any): any {
    const date = new Date(timestamp);
    while (days < 0) {
      date.setDate(date.getDate() - 1);
      if (date.getDay() !== 6 && date.getDay() !== 0) {
        days++;
      }
    }
    return date.getTime();
  }

  private calcBusinessDays = (dDate1: any, dDate2: any) => {
    // tslint:disable-next-line:one-variable-per-declaration
    let iWeeks, iDateDiff, iAdjust = 0, abs = true;
    if (dDate2 < dDate1) {
      const temp = dDate1;
      dDate1 = dDate2;
      dDate2 = temp;
      abs = false;
    }
    let iWeekday1 = dDate1.getDay(); // day of week
    let iWeekday2 = dDate2.getDay();
    iWeekday1 = (iWeekday1 === 0) ? 7 : iWeekday1; // change Sunday from 0 to 7
    iWeekday2 = (iWeekday2 === 0) ? 7 : iWeekday2;
    if ((iWeekday1 > 5) && (iWeekday2 > 5)) {
      iAdjust = 1;
    } // adjustment if both days on weekend
    iWeekday1 = (iWeekday1 > 5) ? 5 : iWeekday1; // only count weekdays
    iWeekday2 = (iWeekday2 > 5) ? 5 : iWeekday2;

    // calculate difference in weeks (1000mS * 60sec * 60min * 24hrs * 7 days = 604800000)
    iWeeks = Math.floor((dDate2.getTime() - dDate1.getTime()) / 604800000);

    if (iWeekday1 <= iWeekday2) {
      iDateDiff = (iWeeks * 5) + (iWeekday2 - iWeekday1);
    } else {
      iDateDiff = ((iWeeks + 1) * 5) - (iWeekday1 - iWeekday2);
    }

    iDateDiff -= iAdjust; // take into account both days on weekend

    if (!abs) {
      return -Math.abs(iDateDiff);
    } else {
      return (iDateDiff);
    }
  }

  private calcShowInDaysMonthsWeeks(dateStartTimestamp: any, dateEndTimestamp: any): any {
    const dateStart = new Date(dateStartTimestamp);
    dateStart.setHours(0, 0, 0, 0);
    const dateEnd = new Date(dateEndTimestamp);
    dateEnd.setHours(0, 0, 0, 0);
    const show = {
      days: {},
      months: {},
      weeks: {}
    };
    let firstJanuary: Date;
    for (const incDate = new Date(dateStart);
         incDate <= new Date(dateEnd);
         incDate.setDate(incDate.getDate() + 1)) {
      // show in months
      // @ts-ignore
      show.months[incDate.getFullYear() + '' + ('0' + (incDate.getMonth() + 1)).slice(-2)] = true;

      // check weekend days
      if ((incDate.getDay() !== 6) && (incDate.getDay() !== 0)) {
        // show in days
        // @ts-ignore
        show.days[incDate.getFullYear() + '' + ('0' + (incDate.getMonth() + 1)).slice(-2) + '' +
        ('0' + incDate.getDate()).slice(-2)] = true;
      }

      // show in weeks
      firstJanuary = new Date(incDate.getFullYear(), 0, 1);
      // @ts-ignore
      show.weeks[incDate.getFullYear() + '' + Math.ceil((((incDate.getTime() - firstJanuary.getTime()) / 86400000) +
        firstJanuary.getDay() + 1) / 7)] = true;
    }
    return show;
  }

  private insertMessageTask(messageMessage: string, task: TaskType, type: string = 'auto'): any {
    let message: MessageType;
    message = {
      uid: this.afs.createId(),
      message: messageMessage,
      dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
      userRef: this.afs.doc(`users/${this.user.uid}`).ref,
      files: [],
      type
    };
    this.messagesService.insertMessage(message, `companies/${this.user.companyRef.id}/tasks/${task.uid}/messages/${message.uid}`)
      .catch(err => {
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  private insertMessageTicket(messageMessage: string, ticket: TicketType, type: string = 'auto'): void {
    let message: MessageType;
    message = {
      uid: this.afs.createId(),
      message: messageMessage,
      dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
      userRef: this.afs.doc(`users/${this.user.uid}`).ref,
      files: [],
      type
    };
    this.messagesService.insertMessage(message, `services/${ticket.serviceRef.id}/tickets/${ticket.uid}/messages/${message.uid}`)
      .catch(err => {
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  private saveNotificationTaskTicket(message: string, userId: string, elementUid: string, elementType: string): any {
    const notification: NotificationType = {
      uid: this.afs.createId(),
      message,
      dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
      dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
      userAuthorRef: this.afs.doc(`users/${this.user.uid}`).ref,
      userInformedRef: this.afs.doc(`users/${userId}`).ref,
      elementUid,
      elementType: 'projectTask',
      view: 'info',
      isUnread: true
    };
    const subscriptionNotification: any = {};

    subscriptionNotification[userId] = this.notificationsService
      .getNotificationsToMerge(`companies/${this.user.companyRef.id}/notifications/`, notification)
      .subscribe(notifications => {
        if (notifications.length) {
          this.notificationsService.updateNotification({
            message: notifications[0].message + message,
            dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            userInformedRef: this.afs.doc(`users/${userId}`).ref,
          }, `companies/${this.user.companyRef.id}/notifications/${notifications[0].uid}`).then();
        } else {
          this.notificationsService
            .insertNotification(notification, `companies/${this.user.companyRef.id}/notifications/${notification.uid}`)
            .then(() => {
            })
            .catch(err => {
              this.snackBar.open(err.message, '', environment.snackbarWarn);
            });
        }
        subscriptionNotification[userId].unsubscribe();
      });
  }

  onDateRemove(): void {
    this.task.dateEnd = '';
    this.task.dateStart = '';
  }

  autocompleteDisplay(project?: ProjectType): string | undefined {
    return project ? project.name : undefined;
  }

  autocompleteBlur(): void {
    if (typeof this.project === 'string') {
      this.projects.forEach((project: any) => {
        if (project.name === this.project) {
          this.project = project;
        }
      });
    }
  }

  autocompleteSelected(selected: any): void {
    if (typeof this.project === 'string') {
      this.projects.forEach((project: any) => {
        if (project === selected) {
          this.project = project;
        }
      });
    }
  }

  onSaveTask(form: NgForm): void {
    if (form.valid) {
      this.progress.ref().start();

      let show = {
        days: {} = {},
        months: {} = {},
        weeks: {} = {}
      };
      if (this.task.dateStart && this.task.dateEnd) {
        show = this.calcShowInDaysMonthsWeeks(new Date(this.task.dateStart).getTime(), new Date(this.task.dateEnd).getTime());
      }

      // insert task
      const task: TaskType = {
        uid: this.afs.createId(),
        userTodoRef: this.afs.doc(`users/${this.usersComponent.uid}`).ref,
        userAuthorRef: this.afs.doc(`users/${this.user.uid}`).ref,
        name: form.value.name,
        description: '',
        projectRef: this.afs.doc(`companies/${this.user.companyRef.id}/projects/${form.value.project.uid}`).ref,
        dateStart: this.task.dateStart,
        dateEnd: this.task.dateEnd,
        showInMonths: show.months,
        showInDays: show.days,
        showInWeeks: show.weeks,
        type: this.task.type,
        priority: 'normal',
        estimation: this.task.estimation,
        dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
        dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
        userUpdateRef: this.afs.doc(`users/${this.user.uid}`).ref,
        status: 'todo',
        isActive: true,
        files: []
      };
      this.tasksService.insertTask(task, `companies/${this.user.companyRef.id}/tasks/${task.uid}`)
        .then(() => {
          // insert message
          this.insertMessageTask(
            `has created the task`,
            task
          );

          // get project to show in dialog
          this.subscriptions.push(this.projectsService.getProject(this.user, task.projectRef.id).subscribe(project => {
            task.project = project;
          }));
          this.onOpenDialogTask(task);

          // inform users
          const informed = this.calcInformedTask(task);
          for (const informUser of Object.keys(informed)) {
            // insert notification
            this.saveNotificationTaskTicket(
              `<p>has created project task <a class="link" routerLink="projects/${form.value.project.uid}/task/${task.uid}">${task.name}</a></p>`,
              informUser,
              task.uid,
              'projectTask'
            );
            // send push notification
            this.subscriptions.push(this.usersService.getUserByRef(this.afs.doc(`users/${informUser}`).ref).subscribe((user: any) => {
              this.fcmService.sendMessage(user, {
                title: `New task has been created`,
                body: `${this.user.name} has created the task ${task.name}`,
                click_action: `${environment.url}/notifications`
              });
            }));
          }

          // update project
          this.updateProject(form.value.project, this.userCurrent);

          // show snackbar and complete progress
          this.snackBar.open('Task has been created', '', environment.snackbarSuccess);
          this.progress.ref().complete();
        })
        .catch(err => {
          this.snackBar.open(err.message, '', environment.snackbarWarn);
          this.progress.ref().complete();
        });
      form.resetForm({
        project: form.value.project,
        dateStart: form.value.dateStart,
        dateEnd: form.value.dateEnd,
        estimation: form.value.estimation,
        type: form.value.type
      });

    }
  }

  onOpenDialogTask(task: any): void {
    this.dialog.open(SharedTaskDialogComponent, {
      height: '100vh',
      autoFocus: false,
      data: {
        task,
        user: this.user
      }
    });
  }

  onOpenDialogTicket(ticket: any): void {
    this.dialog.open(SharedTicketDialogComponent, {
      height: '100vh',
      autoFocus: false,
      data: {
        ticketUid: ticket.uid,
        serviceUid: ticket.service.uid,
        user: this.user
      }
    });
  }

  dropTaskTicket(event: CdkDragDrop<string[]>): void {
    const element: TaskType | TicketType = event.item.data;
    const drag: any = event.previousContainer.data;
    const drop: any = event.container.data;
    if (drag.date !== drop.date) {
      if (event.item.data.hasOwnProperty('taskStart')) {
        this.onUpdateTask(element, drag.date, drop.date);
      } else {
        this.onUpdateTicket(element, drag.date, drop.date);
      }
    }

  }

  onEnterTask(task: any): void {
    this.enterTaskUid = task.uid;
  }

  onEnterTicket(ticket: any): void {
    this.enterTicketUid = ticket.uid;
  }

  onLeaveTask(): void {
    this.enterTaskUid = '';
  }

  onLeaveTicket(): void {
    this.enterTicketUid = '';
  }

  onShowTodo(): void {
    this.progress.ref().start();
    this.showTodo = !this.showTodo;
    if (!this.user.settings) {
      this.user.settings = {};
    }
    this.user.settings.calendar_showTodo = this.showTodo;
    this.usersService.updateUser({
      settings: this.user.settings
    }, this.user.uid)
      .then(() => {
        this.progress.ref().complete();
        this.snackBar.open('User settings has been updated', '', environment.snackbarSuccess);
      })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  onShowOrdered(): void {
    this.progress.ref().start();
    this.showOrdered = !this.showOrdered;
    if (!this.user.settings) {
      this.user.settings = {};
    }
    this.user.settings.calendar_showOrdered = this.showOrdered;
    this.usersService.updateUser({
      settings: this.user.settings
    }, this.user.uid)
      .then(() => {
        this.progress.ref().complete();
        this.snackBar.open('User settings has been updated', '', environment.snackbarSuccess);
      })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  filterWeekend = (d: Date): boolean => {
    let day;
    if (d) {
      day = d.getDay();
    }
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  dateStartChange(): void {
    if (!this.task.dateEnd) {
      this.task.dateEnd = this.task.dateStart;
    }
    if (this.task.dateEnd.getTime() < this.task.dateStart.getTime()) {
      this.task.dateEnd = this.task.dateStart;
    }
  }

  dateEndChange(): void {
    if (!this.task.dateStart) {
      this.task.dateStart = this.task.dateEnd;
    }
    if (this.task.dateEnd.getTime() < this.task.dateStart.getTime()) {
      this.task.dateStart = this.task.dateEnd;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.routeParamsSubscription.unsubscribe();
  }
}
