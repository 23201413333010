import {Component, OnInit} from '@angular/core';
import {UserType} from '../../../_types/user.type';
import {UsersService} from '../../../_services/users.service';
import {NgForm} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgProgress} from 'ngx-progressbar';

@Component({
    selector: 'app-profile-email',
    templateUrl: './profile-email.component.html',
    styleUrls: ['./profile-email.component.scss']
})
export class ProfileEmailComponent implements OnInit {

    user: UserType;
    email: string;

    constructor(private usersService: UsersService,
                private route: ActivatedRoute,
                private snackBar: MatSnackBar,
                private progress: NgProgress) {

    }

    ngOnInit() {
        // set user
        this.user = this.route.snapshot.parent.parent.data.user;

        // set email
        this.email = this.user.email;
    }

    onSave(form: NgForm) {
        if (form.valid) {
            if (this.email !== form.value.email) {
                this.progress.ref().start();
                this.usersService.updateEmail(form.value.email)
                    .then(() => {
                        this.snackBar.open('Recovery e-mail has been sent', '', environment.snackbarSuccess);
                        this.user.email = form.value.email;
                        this.onUpdate();
                    })
                    .catch(err => {
                        this.progress.ref().complete();
                        this.snackBar.open(err.message, '', environment.snackbarWarn);
                    });
            } else {
                this.snackBar.open('E-mail has not been changed', '', environment.snackbarWarn);
            }
        }
    }

    onUpdate() {
        this.usersService.setUser(this.user)
            .then(() => {
                this.progress.ref().complete();
                this.snackBar.open('Profile has been updated', '', environment.snackbarSuccess);
            })
            .catch(err => {
                this.progress.ref().complete();
                this.snackBar.open(err.message, '', environment.snackbarWarn);
            });
    }

}
