import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {TicketType} from '../../_types/ticket.type';
import {Subscription} from 'rxjs/internal/Subscription';
import {UsersService} from '../../_services/users.service';
import {UserType} from '../../_types/user.type';
import {MessageType} from '../../_types/message.type';
import {NgForm} from '@angular/forms';
import {UploaderOptions, UploadFile, UploadInput, UploadOutput} from 'ngx-uploader';
import {COMMA, ENTER, SEMICOLON, SPACE} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {ActivatedRoute, Router} from '@angular/router';
import {ServicesService} from '../../_services/services.service';
import {ProjectsService} from '../../_services/projects.service';
import {formatDate} from '@angular/common';
import {environment} from '../../../environments/environment';
import {TicketsService} from '../../_services/tickets.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AngularFirestore} from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import {FileType} from '../../_types/file.type';
import {MessagesService} from '../../_services/messages.service';
import {FcmService} from '../../_services/fcm.service';
import {NotificationType} from '../../_types/notification.type';
import {NotificationsService} from '../../_services/notifications.service';
import {NgProgress, NgProgressComponent} from 'ngx-progressbar';
import {UploadTaskSnapshot} from '@angular/fire/storage/interfaces';

@Component({
  selector: 'app-shared-ticket',
  templateUrl: './shared-ticket.component.html',
  styleUrls: ['./shared-ticket.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SharedTicketComponent implements OnInit, OnDestroy, OnChanges {

  @Input() ticketUid: string;
  @Input() serviceUid: string;
  @Input() user: UserType;
  @Input() view: string;

  ticket: TicketType;
  users: UserType[] = [];
  usersComplete = false;
  service: any;
  project: any;
  ticketCopy: TicketType;
  todoCopy: UserType;
  messageFiles: FileType[] = [];
  dateStartDatepicker: any;

  dateEndDatepicker: any;
  todo: any;
  author: UserType;
  showRemove = false;

  messages: MessageType[] = [];
  messagesComplete = false;

  hideAutoComments = false;

  userSupport: UserType = {
    uid: null,
    email: null,
    name: 'Support',
    companyRef: null,
    isEmailVerified: null,
    dateUpdate: null,
    userUpdateRef: null,
    isActive: null,
    isEmailNotification: null,
    roleRef: null,
    changelog: {
      page: null,
      bottomSheet: null
    }
  };
  userCustomer: UserType = {
    uid: null,
    email: null,
    name: 'Customer',
    companyRef: null,
    isEmailVerified: null,
    dateUpdate: null,
    userUpdateRef: null,
    isActive: null,
    isEmailNotification: null,
    roleRef: null,
    changelog: {
      page: null,
      bottomSheet: null
    }
  };

  ticketFiles: FileType[] = [];
  ticketFileRemove: FileType;
  ticketFileShowRemove: boolean[] = [];

  options: UploaderOptions;

  filesMessage: UploadFile[] = [];
  filesTicket: UploadFile[] = [];

  uploadInput: EventEmitter<UploadInput>;
  dragOver: boolean;

  expandImage = false;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON, SPACE];

  private subscriptions: Array<Subscription> = [];

  constructor(private usersService: UsersService,
              private servicesService: ServicesService,
              private projectsService: ProjectsService,
              private ticketsService: TicketsService,
              private messagesService: MessagesService,
              private notificationsService: NotificationsService,
              private progress: NgProgress,
              private afs: AngularFirestore,
              private snackBar: MatSnackBar,
              private fcm: FcmService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnChanges() {
    this.subscriptions.push(this.route.params.subscribe(params => {
      // get service
      this.getService();

      // get ticket
      this.getTicket();

      // get messages
      this.getMessages();
    }));
  }

  ngOnInit() {
  }

  private getUsers() {
    this.subscriptions.push(this.usersService.getUsers(this.service.companyRef).subscribe(users => {
      this.users = users;
      this.usersComplete = true;

      // match author and to-do
      this.matchAuthorTodoUser();

      // match messages to users
      this.matchMessagesToUsers();
    }));
  }

  private matchMessagesToUsers() {
    if (this.view !== 'service') {
      if (this.messagesComplete && this.usersComplete) {
        this.messages.forEach(message => {
          this.users.forEach(user => {
            if (message.userRef) {
              if (message.userRef.id === user.uid) {
                message.author = user;
              }
            } else {
              message.author = this.userCustomer;
            }
          });
        });
      }
    } else {
      if (this.messagesComplete) {
        this.messages.forEach(message => {
          if (message.userRef) {
            message.author = this.userSupport;
          } else {
            message.author = this.userCustomer;
          }
        });
      }
    }
  }

  private getService() {
    this.servicesService.getServiceOnce(this.serviceUid).then(service => {
      this.service = service.data();
      if (this.view !== 'service') {
        // get users
        this.getUsers();
      }
    });
  }

  private getTicket() {
    this.ticketsService.getTicket(`services/${this.serviceUid}/tickets/${this.ticketUid}`).subscribe(ticket => {
      this.ticket = ticket;

      // set dateStartDatepicker and dateEndDatepicker
      if (this.ticket.dateStart) {
        this.dateStartDatepicker = new Date(this.ticket.dateStart.seconds * 1000);
      }
      if (this.ticket.dateEnd) {
        this.dateEndDatepicker = new Date(this.ticket.dateEnd.seconds * 1000);
      }

      // copy ticket
      this.ticketCopy = Object.assign({}, this.ticket);
    });
  }

  private calculateShowIn(dateStart, dateEnd) {
    dateStart.setHours(0, 0, 0, 0);
    dateEnd.setHours(0, 0, 0, 0);
    const showIn = {
      months: {},
      weeks: {},
      days: {},
    };
    let firstJanuary;
    for (const incDate = dateStart; incDate <= dateEnd; incDate.setDate(incDate.getDate() + 1)) {
      // show in months
      showIn.months[incDate.getFullYear() + '' + ('0' + (incDate.getMonth() + 1)).slice(-2)] = true;

      // check weekend days
      if ((incDate.getDay() !== 6) && (incDate.getDay() !== 0)) {
        // show in days
        showIn.days[incDate.getFullYear() + '' + ('0' + (incDate.getMonth() + 1)).slice(-2) + '' + ('0' + incDate.getDate()).slice(-2)] = true;
      }

      // show in weeks
      firstJanuary = new Date(incDate.getFullYear(), 0, 1);
      showIn.weeks[incDate.getFullYear() + '' + Math.ceil((((incDate.getTime() - firstJanuary.getTime()) / 86400000) + firstJanuary.getDay() + 1) / 7)] = true;
    }
    return showIn;
  }

  private onUpdateField(field) {
    // start progress bar
    this.progress.ref().start();
    // save message
    this.saveMessage(field.messageMessage);
    // save notification and send push message
    const informedIds = this.calculateInformed();
    for (const id of Object.keys(informedIds)) {
      // save notification
      this.saveNotification({
        message: field.notificationMessage,
        informedId: id
      });
      // send push
      if (this.user) {
        this.sendPush({
          message: field.pushMessage,
          informedId: id
        });
      }
    }

    // update ticket
    this.ticketsService.updateTicket(field.update, `services/${this.ticket.serviceRef.id}/tickets/${this.ticket.uid}`)
      .then(() => {
        // update ticketCopy
        this.ticketCopy = {...this.ticket};
        // open snackbar
        this.snackBar.open('Ticket has been updated', '', environment.snackbarSuccess);
        // close progress bar
        this.progress.ref().complete();
      }).catch(err => {
        // show snackbar
        this.snackBar.open(err.message, '', environment.snackbarWarn);
        // close progress bar
        this.progress.ref().complete();
      }
    );
  }

  private saveMessage(messageMessage: string, type: string = 'auto') {
    let message: MessageType;

    let userRef;
    if (this.user) {
      userRef = this.afs.doc(`users/${this.user.uid}`).ref;
    } else {
      userRef = null;
    }

    message = {
      uid: this.afs.createId(),
      message: messageMessage,
      dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
      userRef: userRef,
      files: this.messageFiles,
      type: type
    };
    this.messagesService.insertMessage(message, `services/${this.service.uid}/tickets/${this.ticket.uid}/messages/${message.uid}`)
      .then(() => {
        this.messageFiles = [];
      })
      .catch(err => {
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  private getMessages() {
    this.subscriptions.push(this.messagesService
      .getMessages(`services/${this.serviceUid}/tickets/${this.ticketUid}/messages`)
      .subscribe(messages => {
        this.messages = messages;
        this.messagesComplete = true;

        // match messages to users
        this.matchMessagesToUsers();
      }));
  }

  private startUploadTicket(): void {
    let filesLength = this.filesTicket.length;
    this.ticketFiles = [];
    // start progress bar
    this.progress.ref().start();
    // show snackbar
    this.snackBar.open(`Uploading file(s)`, '', environment.snackbarInfo);
    for (const file of this.filesTicket) {
      // prepare file
      const fileNameArray = file.name.split('.');
      const name = this.afs.createId() + '.' + fileNameArray[fileNameArray.length - 1];
      const path = `/${this.user.companyRef.id}/services/${this.service.uid}/${name}`;

      // this.tasksService.uploadFile(file.nativeFile, path, file.name);
      this.ticketsService.uploadFile(file.nativeFile, path, file.name).then(snapshot => {
        // push file to message
        snapshot.ref.getDownloadURL().then(downloadURL => {
          // push file to array
          this.ticketFiles.push({
            name: file.name,
            url: downloadURL,
            type: file.type,
            size: file.size,
            isActive: true
          });
          filesLength--;
          if (filesLength === 0) {
            // close progress bar
            this.progress.ref().complete();
            this.onUpdate('filesAdd');
          }
        });
      });
    }
    // clear files
    this.filesTicket = [];
  }

  autocompleteDisplayTodo(user?: UserType): string | undefined {
    return user ? user.name : undefined;
  }

  matchAuthorTodoUser() {
    this.users.forEach(user => {
      if (user.uid === this.ticket.userTodoRef.id) {
        this.todo = user;
        this.todoCopy = Object.assign({}, this.todo);
      }

      if (this.ticket.userAuthorRef && user.uid === this.ticket.userAuthorRef.id) {
        this.author = user;
      }
    });
  }

  onUpdate(field: string, optionalParam?: string) {
    let messageMessage: string;
    let notificationMessage: string;
    let pushMessage: {};
    let userName = 'Anonymous';

    switch (field) {
      case 'dateEnd':
        if (this.dateEndDatepicker.getTime() !== (this.ticketCopy.dateEnd.seconds * 1000)) {
          messageMessage = `has changed date end: ${formatDate(new Date(this.ticketCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(this.dateEndDatepicker, 'yyyy-MM-dd', 'en-US')}`;
          notificationMessage = `<p>has changed service desk ticket <a class="link" routerLink="services/${this.ticket.serviceRef.id}/ticket/${this.ticket.uid}">${this.ticket.name}</a> <code class="code">date end: ${formatDate(new Date(this.ticketCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(this.dateEndDatepicker, 'yyyy-MM-dd', 'en-US')}</code></p>`;
          pushMessage = {
            title: `Change of service desk ticket date end`,
            body: `${this.user.name} has changed service desk ticket ${this.ticket.name} date end: ${formatDate(new Date(this.ticketCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(this.dateEndDatepicker, 'yyyy-MM-dd', 'en-US')}`,
            click_action: `${environment.url}/notifications`
          };
          // rewrite showInDays, showInMonth, showInWeeks
          const showIn = this.calculateShowIn(new Date(this.ticket.dateStart.seconds * 1000), new Date(this.dateEndDatepicker.getTime()));
          this.onUpdateField({
            update: {
              dateEnd: this.dateEndDatepicker,
              showInDays: showIn.days,
              showInMonths: showIn.months,
              showInWeeks: showIn.weeks,
            },
            messageMessage: messageMessage,
            notificationMessage: notificationMessage,
            pushMessage: pushMessage
          });
        }
        break;
      case 'dateStart':
        if (this.dateStartDatepicker.getTime() !== (this.ticketCopy.dateStart.seconds * 1000)) {
          messageMessage = `has changed date start: ${formatDate(new Date(this.ticketCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(this.dateStartDatepicker, 'yyyy-MM-dd', 'en-US')}`;
          notificationMessage = `<p>has changed service desk ticket <a class="link" routerLink="services/${this.ticket.serviceRef.id}/ticket/${this.ticket.uid}">${this.ticket.name}</a> <code class="code">date start: ${formatDate(new Date(this.ticketCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(this.dateStartDatepicker, 'yyyy-MM-dd', 'en-US')}</code></p>`;
          pushMessage = {
            title: `Change of service desk ticket date start`,
            body: `${this.user.name} has changed service desk ticket ${this.ticket.name} date start: ${formatDate(new Date(this.ticketCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(this.dateStartDatepicker, 'yyyy-MM-dd', 'en-US')}`,
            click_action: `${environment.url}/notifications`
          };
          // rewrite showInDays, showInMonth, showInWeeks
          const showIn = this.calculateShowIn(new Date(this.dateStartDatepicker.getTime()), new Date(this.ticket.dateEnd.seconds * 1000));
          this.onUpdateField({
            update: {
              dateStart: this.dateStartDatepicker,
              showInDays: showIn.days,
              showInMonths: showIn.months,
              showInWeeks: showIn.weeks
            },
            messageMessage: messageMessage,
            notificationMessage: notificationMessage,
            pushMessage: pushMessage
          });
        }
        break;
      case 'dateStartEnd':
        if ((this.dateStartDatepicker.getTime() !== (this.ticketCopy.dateStart.seconds * 1000)) && (this.dateEndDatepicker.getTime() !== (this.ticketCopy.dateEnd.seconds * 1000))) {
          messageMessage = `has changed date start: ${formatDate(new Date(this.ticketCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(this.dateStartDatepicker, 'yyyy-MM-dd', 'en-US')}, date end: ${formatDate(new Date(this.ticketCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(this.dateEndDatepicker, 'yyyy-MM-dd', 'en-US')}`;
          notificationMessage = `<p>has changed service desk ticket <a class="link" routerLink="services/${this.ticket.serviceRef.id}/ticket/${this.ticket.uid}">${this.ticket.name}</a> <code class="code">date start: ${formatDate(new Date(this.ticketCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(this.dateStartDatepicker, 'yyyy-MM-dd', 'en-US')}</code><code>date end: ${formatDate(new Date(this.ticketCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(this.dateEndDatepicker, 'yyyy-MM-dd', 'en-US')}</code></p>`;
          pushMessage = {
            title: `Change of service desk ticket dates`,
            body: `${this.user.name} has changed service desk ticket ${this.ticket.name} date start: ${formatDate(new Date(this.ticketCopy.dateStart.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(this.dateStartDatepicker, 'yyyy-MM-dd', 'en-US')}, date end: ${formatDate(new Date(this.ticketCopy.dateEnd.seconds * 1000), 'yyyy-MM-dd', 'en-US')} → ${formatDate(this.dateEndDatepicker, 'yyyy-MM-dd', 'en-US')}`,
            click_action: `${environment.url}/notifications`
          };
          // rewrite showInDays, showInMonth, showInWeeks
          const showIn = this.calculateShowIn(new Date(this.dateStartDatepicker.getTime()), new Date(this.dateEndDatepicker.getTime()));

          this.onUpdateField({
            update: {
              dateStart: this.dateStartDatepicker,
              dateEnd: this.dateEndDatepicker,
              showInDays: showIn.days,
              showInMonths: showIn.months,
              showInWeeks: showIn.weeks
            },
            messageMessage: messageMessage,
            notificationMessage: notificationMessage,
            pushMessage: pushMessage
          });
        }
        break;
      case 'estimation':
        if (this.ticketCopy.estimation !== this.ticket.estimation) {
          if (this.ticket.estimation > 999) {
            this.ticket.estimation = 999;
          }
          messageMessage = `has changed estimation: ${this.ticketCopy.estimation} → ${this.ticket.estimation}`;
          notificationMessage = `<p>has changed service desk ticket <a class="link" routerLink="services/${this.ticket.serviceRef.id}/ticket/${this.ticket.uid}">${this.ticket.name}</a> <code class="code">estimation: ${this.ticketCopy.estimation} → ${this.ticket.estimation}</code></p>`;
          pushMessage = {
            title: `Change of service desk ticket estimation`,
            body: `${this.user.name} has changed service desk ticket ${this.ticket.name} estimation: ${this.ticketCopy.estimation} → ${this.ticket.estimation}`,
            click_action: `${environment.url}/notifications`
          };
          this.onUpdateField({
            update: {
              estimation: this.ticket.estimation,
            },
            messageMessage: messageMessage,
            notificationMessage: notificationMessage,
            pushMessage: pushMessage
          });
        }
        break;
      case 'userTodoRef':
        this.subscriptions.push(this.usersService.getUserByRef(this.ticket.userTodoRef).subscribe(user => {
          this.todo = user;
          messageMessage = `has changed todo: ${this.todoCopy.name} → ${this.todo.name}`;
          notificationMessage = `<p>has changed service desk ticket <a class="link" routerLink="service/${this.ticket.serviceRef.id}/tickets/${this.ticket.uid}">${this.ticket.name}</a> <code class="code">todo: ${this.todoCopy.name} → ${this.todo.name}</code></p>`;
          pushMessage = {
            title: `Change of service desk ticket todo`,
            body: `${this.user.name} has changed service desk ticket ${this.ticket.name} todo: ${this.todoCopy.name} → ${this.todo.name}`,
            click_action: `${environment.url}/notifications`
          };
          // update field, insert message, insert notification
          this.onUpdateField({
            update: {
              userTodoRef: this.ticket.userTodoRef,
            },
            messageMessage: messageMessage,
            notificationMessage: notificationMessage,
            pushMessage: pushMessage
          });
          // update todoCopy
          this.todoCopy = Object.assign({}, this.todo);
        }));
        break;
      case 'notificationEmailAdd':
        messageMessage = `has added notification email: ${optionalParam}`;
        notificationMessage = `<p>has changed service desk ticket <a class="link" routerLink="services/${this.ticket.serviceRef.id}/ticket/${this.ticket.uid}">${this.ticket.name}</a> <code class="code">add notification email: ${optionalParam}</code></p>`;
        if (this.user) {
          userName = this.user.name;
        }
        pushMessage = {
          title: `Change of service desk ticket notification emails`,
          body: `${userName} has changed service desk ticket ${this.ticket.name} by adding notification email: ${optionalParam}`,
          click_action: `${environment.url}/notifications`
        };
        this.onUpdateField({
          update: {
            notificationEmails: this.ticket.notificationEmails,
          },
          messageMessage: messageMessage,
          notificationMessage: notificationMessage,
          pushMessage: pushMessage
        });
        break;
      case 'notificationEmailRemove':
        messageMessage = `has removed notification email: ${optionalParam}`;
        notificationMessage = `<p>has changed service desk ticket <a class="link" routerLink="services/${this.ticket.serviceRef.id}/ticket/${this.ticket.uid}">${this.ticket.name}</a> <code class="code">remove notification email: ${optionalParam}</code></p>`;
        if (this.user) {
          userName = this.user.name;
        }
        pushMessage = {
          title: `Change of service desk ticket notification emails`,
          body: `${userName} has changed service desk ticket ${this.ticket.name} by removing notification email: ${optionalParam}`,
          click_action: `${environment.url}/notifications`
        };
        this.onUpdateField({
          update: {
            notificationEmails: this.ticket.notificationEmails,
          },
          messageMessage: messageMessage,
          notificationMessage: notificationMessage,
          pushMessage: pushMessage
        });
        break;
      case 'status':
        // prepare message
        let tempStatusBefore = this.ticketCopy.status.split(/(?=[A-Z])/).join(' ').toLowerCase();
        tempStatusBefore = tempStatusBefore.charAt(0).toUpperCase() + tempStatusBefore.slice(1);
        let tempStatusAfter = this.ticket.status.split(/(?=[A-Z])/).join(' ').toLowerCase();
        tempStatusAfter = tempStatusAfter.charAt(0).toUpperCase() + tempStatusAfter.slice(1);
        messageMessage = `has changed status: ${tempStatusBefore} → ${tempStatusAfter}`;
        notificationMessage = `<p>has changed service desk ticket <a class="link" routerLink="services/${this.ticket.serviceRef.id}/ticket/${this.ticket.uid}">${this.ticket.name}</a> <code class="code">status: ${tempStatusBefore} → ${tempStatusAfter}</code></p>`;
        if (this.user) {
          userName = this.user.name;
        }
        pushMessage = {
          title: `Change of service desk ticket status`,
          body: `${userName} has changed project service desk ticket ${this.ticket.name} status: ${tempStatusBefore} → ${tempStatusAfter}`,
          click_action: `${environment.url}/notifications`
        };

        // update field, insert message, insert notification
        this.onUpdateField({
          update: {
            status: this.ticket.status,
          },
          messageMessage: messageMessage,
          notificationMessage: notificationMessage,
          pushMessage: pushMessage
        });
        break;
      case 'priority':
        // prepare message
        let tempPriorityBefore = this.ticketCopy.priority.split(/(?=[A-Z])/).join(' ').toLowerCase();
        tempPriorityBefore = tempPriorityBefore.charAt(0).toUpperCase() + tempPriorityBefore.slice(1);
        let tempPriorityAfter = this.ticket.priority.split(/(?=[A-Z])/).join(' ').toLowerCase();
        tempPriorityAfter = tempPriorityAfter.charAt(0).toUpperCase() + tempPriorityAfter.slice(1);
        messageMessage = `has changed status: ${tempPriorityBefore} → ${tempPriorityAfter}`;
        notificationMessage = `<p>has changed service desk ticket <a class="link" routerLink="services/${this.ticket.serviceRef.id}/ticket/${this.ticket.uid}">${this.ticket.name}</a> <code class="code">status: ${tempPriorityBefore} → ${tempPriorityAfter}</code></p>`;
        pushMessage = {
          title: `Change of service desk ticket priority`,
          body: `${this.user.name} has changed service desk ticket ${this.ticket.name} priority: ${tempPriorityBefore} → ${tempPriorityAfter}`,
          click_action: `${environment.url}/notifications`
        };

        // update field, insert message, insert notification
        this.onUpdateField({
          update: {
            priority: this.ticket.priority,
          },
          messageMessage: messageMessage,
          notificationMessage: notificationMessage,
          pushMessage: pushMessage
        });
        break;
      case 'name':
        if (this.ticketCopy.name !== this.ticket.name) {
          messageMessage = `has changed name: ${this.ticketCopy.name} → ${this.ticket.name}`;
          notificationMessage = `<p>has changed service desk ticket <a class="link" routerLink="services/${this.ticket.serviceRef.id}/ticket/${this.ticket.uid}">${this.ticket.name}</a> <code class="code">name: ${this.ticketCopy.name} → ${this.ticket.name}</code></p>`;
          pushMessage = {
            title: `Change of service desk ticket name`,
            body: `${this.user.name} has changed service desk ticket ${this.ticket.name} name: ${this.ticketCopy.name} → ${this.ticket.name}`,
            click_action: `${environment.url}/notifications`
          };
          // update field, insert message, insert notification
          this.onUpdateField({
            update: {
              name: this.ticket.name,
            },
            messageMessage: messageMessage,
            notificationMessage: notificationMessage,
            pushMessage: pushMessage
          });
        }
        break;
      case 'description':
        if (this.ticketCopy.description !== this.ticket.description) {
          messageMessage = `has changed description`;
          notificationMessage = `<p>has changed description in the service desk ticket <a class="link" routerLink="services/${this.ticket.serviceRef.id}/ticket/${this.ticket.uid}">${this.ticket.name}</a></p>`;
          pushMessage = {
            title: `Change of service desk ticket description`,
            body: `${this.user.name} has changed description in the service desk ticket ${this.ticket.name}`,
            click_action: `${environment.url}/notifications`
          };
          // update field, insert message, insert notification
          this.onUpdateField({
            update: {
              description: this.ticket.description,
            },
            messageMessage: messageMessage,
            notificationMessage: notificationMessage,
            pushMessage: pushMessage
          });
        }
        break;
      case 'isActiveFalse':
        messageMessage = `has removed ticket`;
        notificationMessage = `<p>has removed service desk ticket <a class="link" routerLink="services/${this.ticket.serviceRef.id}/ticket/${this.ticket.uid}">${this.ticket.name}</a></p>`;
        if (this.user) {
          userName = this.user.name;
        }
        pushMessage = {
          title: `Removed service desk ticket`,
          body: `${userName} has changed description in the service desk ticket ${this.ticket.name}`,
          click_action: `${environment.url}/notifications`
        };
        // update field, insert message, insert notification
        this.onUpdateField({
          update: {
            isActive: this.ticket.isActive,
          },
          messageMessage: messageMessage,
          notificationMessage: notificationMessage,
          pushMessage: pushMessage
        });
        break;
      case 'isActiveTrue':
        messageMessage = `has withdrawn the removal of the ticket`;
        notificationMessage = `<p>has withdrawn the removal of the service desk ticket <a class="link" routerLink="services/${this.ticket.serviceRef.id}/ticket/${this.ticket.uid}">${this.ticket.name}</a></p>`;
        if (this.user) {
          userName = this.user.name;
        }
        pushMessage = {
          title: `Withdrew the removal of the ticket`,
          body: `${userName} has withdrawn the removal of the service desk ticket ${this.ticket.name}`,
          click_action: `${environment.url}/notifications`
        };
        // update field, insert message, insert notification
        this.onUpdateField({
          update: {
            isActive: this.ticket.isActive,
          },
          messageMessage: messageMessage,
          notificationMessage: notificationMessage,
          pushMessage: pushMessage
        });
        break;
      case 'filesAdd':
        let filesAdded = '';
        this.ticketFiles.forEach(function (file, i) {
          if (i === 0) {
            filesAdded += `${file.name}`;
          } else {
            filesAdded += `, ${file.name}`;
          }
        });
        messageMessage = `has added file(s): ${filesAdded}`;
        notificationMessage = `<p>has changed service desk ticket <a class="link" routerLink="services/${this.ticket.serviceRef.id}/ticket/${this.ticket.uid}">${this.ticket.name}</a> <code class="code">files added: ${filesAdded}</code></p>`;
        pushMessage = {
          title: `Add files to the service desk ticket`,
          body: `${this.user.name} has changed service desk ticket ${this.ticket.name}, files added: ${filesAdded}`,
          click_action: `${environment.url}/notifications`
        };
        // update field, insert message, insert notification
        this.ticket.files = this.ticket.files.concat(this.ticketFiles);
        this.onUpdateField({
          update: {
            files: this.ticket.files,
          },
          messageMessage: messageMessage,
          notificationMessage: notificationMessage,
          pushMessage: pushMessage
        });
        break;
      case 'fileRemove':
        messageMessage = `has removed file: ${this.ticketFileRemove.name}`;
        notificationMessage = `<p>has changed service desk ticket <a class="link" routerLink="services/${this.ticket.serviceRef.id}/ticket/${this.ticket.uid}">${this.ticket.name}</a> <code class="code">removed file: ${this.ticketFileRemove.name}</code></p>`;
        pushMessage = {
          title: `Remove files to the service desk ticket`,
          body: `${this.user.name} has changed service desk ticket ${this.ticket.name}, removed file: ${this.ticketFileRemove.name}`,
          click_action: `${environment.url}/notifications`
        };
        // update field, insert message, insert notification
        // this.task.files = this.task.files.concat(this.taskFiles);
        this.onUpdateField({
          update: {
            files: this.ticket.files
          },
          messageMessage: messageMessage,
          notificationMessage: notificationMessage,
          pushMessage: pushMessage
        });
        break;
    }
  }

  onRemove() {
    this.ticket.isActive = false;
    this.onUpdate('isActiveFalse');
  }

  onUndoRemove() {
    this.ticket.isActive = true;
    this.onUpdate('isActiveTrue');
  }

  filterWeekend = (d: Date): boolean => {
    let day;
    if (d) {
      day = d.getDay();
    }
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  };

  onChangeDateStart() {
    if (this.dateStartDatepicker.getTime() <= (this.ticket.dateEnd.seconds * 1000)) {
      this.onUpdate('dateStart');
    } else {
      this.dateStartDatepicker = new Date(this.dateStartDatepicker.getTime());
      this.dateEndDatepicker = new Date(this.dateStartDatepicker.getTime());
      this.onUpdate('dateStartEnd');
    }
  }

  onChangeDateEnd() {
    if (this.dateEndDatepicker.getTime() >= (this.ticket.dateStart.seconds * 1000)) {
      this.onUpdate('dateEnd');
    } else {
      this.dateStartDatepicker = new Date(this.dateEndDatepicker.getTime());
      this.dateEndDatepicker = new Date(this.dateEndDatepicker.getTime());
      this.onUpdate('dateStartEnd');
    }
  }

  onAddEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // add email
    if ((value || '').trim() && re.test(String(value).toLowerCase())) {
      let duplicate = false;
      // check duplicates
      this.ticket.notificationEmails.forEach(email => {
        if (email === value.trim()) {
          duplicate = true;
        }
      });
      if (!duplicate) {
        this.ticket.notificationEmails.push(value.trim());
        this.onUpdate('notificationEmailAdd', value.trim());
      }
      input.value = '';
    }
  }

  onRemoveEmail(email) {
    const index = this.ticket.notificationEmails.indexOf(email);
    if (index >= 0) {
      this.ticket.notificationEmails.splice(index, 1);
      this.onUpdate('notificationEmailRemove', email);
    }
  }

  onChangeStatus(event) {
    this.ticket.status = event.value;
    this.onUpdate('status');
  }

  onChangePriority(event) {
    this.ticket.priority = event.value;
    this.onUpdate('priority');
  }

  onChangeDescription(event) {
    if ((event.range === null) && (event.oldRange !== null)) {
      this.onUpdate('description');
    }
  }

  onGoToService() {
    this.router.navigate([`/services/${this.ticket.serviceRef.id}/details`]).then();
  }

  onGoToTicket() {
    this.router.navigate([`/services/${this.ticket.serviceRef.id}/ticket/${this.ticket.uid}`]).then();
  }

  onCopyLink() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = `${environment.url}/services/${this.ticket.serviceRef.id}/ticket/${this.ticketUid}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackBar.open('Link to the ticket has been copied', '', environment.snackbarInfo);
  }

  onChangeUserSettings() {
    this.progress.ref().start();
    this.hideAutoComments = !this.hideAutoComments;
    if (!this.user.settings) {
      this.user.settings = {};
    }
    this.user.settings['tasks_hideAutoComments'] = this.hideAutoComments;
    this.usersService.updateUser({
      settings: this.user.settings
    }, this.user.uid)
      .then(() => {
        this.progress.ref().complete();
        this.snackBar.open('User settings has been updated', '', environment.snackbarSuccess);
      })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  onSend(form: NgForm) {
    const messageText = form.value.messageText;

    if (this.filesMessage.length) {
      let filesLength = this.filesMessage.length;
      for (const file of this.filesMessage) {
        // prepare file
        const fileNameArray = file.name.split('.');
        const name = this.afs.createId() + '.' + fileNameArray[fileNameArray.length - 1];
        const path = `/${this.user.companyRef.id}/services/${this.service.uid}/${name}`;
        const uploadTask = this.ticketsService.uploadFile(file.nativeFile, path, file.name);
        uploadTask.on('state_changed', (snapshot: UploadTaskSnapshot) => {
          file.progress.data.percentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 10000) / 100;
        }, (error) => {
          // Handle unsuccessful uploads
          this.snackBar.open(`Unsuccessful uploads`, '', environment.snackbarWarn);
        }, () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            // push file to array
            this.messageFiles.push({
              name: file.name,
              url: downloadURL,
              type: file.type,
              size: file.size,
              isActive: true
            });
            filesLength--;
            if (filesLength === 0) {
              this.saveMessage(messageText, 'user');
              this.messageFiles = [];
              this.filesMessage = [];
            }
          });
        });
      }
    } else {
      this.saveMessage(messageText, 'user');
    }
    form.resetForm();

    let informed = {};
    informed = this.calculateInformed();

    const notificationMessage = `<p>has commented service desk ticket <a class="link" routerLink="services/${this.service.uid}/ticket/${this.ticket.uid}">${this.ticket.name}</a> <code class="code">${messageText.replace(/<[^>]*>/g, '')}</code></p>`;
    for (const id of Object.keys(informed)) {
      // save notification
      this.saveNotification({
        message: notificationMessage,
        informedId: id
      });
    }
  }

  private saveNotification(messageInformed) {

    let authorRef = null;
    if (this.user) {
      authorRef = this.afs.doc(`users/${this.user.uid}`).ref;
    }

    const notification: NotificationType = {
      uid: this.afs.createId(),
      message: messageInformed.message,
      dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
      dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
      userAuthorRef: authorRef,
      userInformedRef: this.afs.doc(`users/${messageInformed.informedId}`).ref,
      elementUid: this.ticket.uid,
      elementType: 'serviceDeskTicket',
      view: 'info',
      isUnread: true,
      author: 'Service desk ' + this.service.name
    };

    this.servicesService.getServiceOnce(this.serviceUid).then(service => {
      this.service = service.data();
    });

    if (notification.userAuthorRef) {
      this.notificationsService.onceNotificationsToMerge(`companies/${this.user.companyRef.id}/notifications/`, notification).then(n => {
        const notifications = [];
        n.forEach(not => {
          notifications.push(not.data());
        });
        if (notifications.length) {
          this.notificationsService.updateNotification({
            message: notifications[0].message + messageInformed.message,
            dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            userInformedRef: this.afs.doc(`users/${messageInformed.informedId}`).ref,
          }, `companies/${this.user.companyRef.id}/notifications/${notifications[0].uid}`).then();
        } else {
          this.insertNotification(notification);
        }
      });
    } else {
      this.insertNotification(notification);
    }
  }

  private sendPush(messageInformed) {
    this.subscriptions.push(this.usersService.getUserByRef(this.afs.doc(`users/${messageInformed.informedId}`).ref).subscribe(user => {
      this.fcm.sendMessage(user, messageInformed.message);
    }));
  }

  private insertNotification(notification) {
    this.notificationsService
      .insertNotification(notification, `companies/${this.service.companyRef.id}/notifications/${notification.uid}`)
      .then(() => {
      })
      .catch(err => {
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  private calculateInformed() {
    const informed = {};
    if (!this.user) {
      informed[this.ticket.userTodoRef.id] = true;
    } else {
      if (this.ticketCopy.userTodoRef.id !== this.user.uid) {
        informed[this.ticketCopy.userTodoRef.id] = true;
      }
      if (this.ticket.userTodoRef.id !== this.user.uid) {
        informed[this.ticket.userTodoRef.id] = true;
      }
    }
    return informed;
  }

  onUploadTicket(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') { // when all files added in queue
      this.onUpdate('description');
      this.startUploadTicket();
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
      // https://github.com/bleenco/ngx-uploader/issues/330
      this.filesTicket.push(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      // update current data in files array for uploading file
      const index = this.filesTicket.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.filesTicket[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.filesTicket = this.filesTicket.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
  }

  onDeleteTicketFile(index) {
    this.ticketFileRemove = this.ticket.files[index];
    this.ticket.files[index].isActive = false;
    this.expandImage = false;
    this.onUpdate('fileRemove');
  }

  onUploadMessage(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') { // when all files added in queue
      // this.startUpload();
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
      this.filesMessage.push(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      // update current data in files array for uploading file
      const index = this.filesMessage.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.filesMessage[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.filesMessage = this.filesMessage.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
  }

  autocompleteSelectedTodo() {
    if (this.todo.uid !== this.todoCopy.uid) {
      this.ticket.userTodoRef = this.afs.doc(`users/${this.todo.uid}`).ref;
      this.onUpdate('userTodoRef');
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
