import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ServiceComponent} from './service.component';
import {ResolveUserService} from '../_services/resolve-user.service';
import {TicketComponent} from './ticket/ticket.component';


const routes: Routes = [
  {
    path: 'service',
    children: [
      {path: ':slug', component: ServiceComponent},
      {path: ':slug/:uidTicket', component: TicketComponent},
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CommonModule
  ],
  exports: [RouterModule],
  declarations: []
})
export class ServiceRoutingModule {
}

