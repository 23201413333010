import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {UserExternalType} from '../_types/userExternal.type';
import {UserType} from '../_types/user.type';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})
export class UsersExternalService {

    constructor(private afs: AngularFirestore) {
    }

    // check duplicates
    isExists(userExternal: UserExternalType, user: UserType): Observable<UserExternalType[]> {
        return this.afs.collection<UserExternalType>(`usersExternal`, ref => ref
            .where('isActive', '==', true)
            .where('companyRef', '==', user.companyRef)
            .where('email', '==', userExternal.email))
            .valueChanges();
    }

    // insert external user
    insertUserExternal(userExternal: UserExternalType): Promise<void> {
        return this.afs.doc<UserExternalType>(`usersExternal/${userExternal.uid}`).set(userExternal);
    }

    // get external users
    getUsersExternal(user: UserType): Observable<UserExternalType[]> {
        return this.afs.collection<UserExternalType>(`usersExternal`, ref => ref
            .where('isActive', '==', true)
            .where('companyRef', '==', user.companyRef)
            .orderBy('email'))
            .valueChanges();
    }

    // get external user by email
    getUserExternalByEmail(email: string): Observable<UserExternalType[]> {
        return this.afs.collection<UserExternalType>(`usersExternal`, ref => ref
            .where('isActive', '==', true)
            .where('email', '==', email))
            .valueChanges();
    }

    // update external user
    updateUserExternal(fields: {}, externalUserUid: string): Promise<void> {
        return this.afs.doc<UserExternalType>(`usersExternal/${externalUserUid}`).update(fields);
    }
}
