import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {PrivilegeType} from '../_types/privilege.type';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})
export class PrivilegesService {

    constructor(private afs: AngularFirestore) {
    }

    // get privileges
    getPrivileges(): Observable<PrivilegeType[]> {
        return this.afs.collection<PrivilegeType>(`privileges/`, ref => ref
            .orderBy('description'))
            .valueChanges();
    }

    // get privilege
    // getPrivilege(path) {
    //     return this.afs.doc<PrivilegeType>(path).valueChanges();
    // }
}
