<section class="page-section">
    <form (ngSubmit)="onSave(f)" #f="ngForm" autocomplete="off">
        <div class="row">
            <div class="col-4">
                <mat-card>
                    <mat-card-content>

                        <div class="col-header">E-mail</div>
                        <mat-form-field>
                            <input matInput
                                   type="email"
                                   name="email"
                                   #email="ngModel"
                                   ngModel
                                   required
                                   email
                                   placeholder="E-mail">
                            <mat-error *ngIf="email.hasError('required')">
                                E-mail is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>


                        <button type="submit"
                                mat-raised-button
                                color="primary"
                                [disabled]="f.form.invalid">Save
                        </button>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </form>
</section>
