import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {TaskType} from '../_types/task.type';
import * as firebase from 'firebase/app';
import {first, single, take} from 'rxjs/operators';
import get = Reflect.get;
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(private afs: AngularFirestore) {
  }

  // get task
  getTask(path: string): Observable<TaskType | undefined> {
    return this.afs.doc<TaskType>(path).valueChanges();

    // return this.afs.doc<TaskType>(path).valueChanges().pipe(map(task => {
    //     task.project$ = this.afs.doc(task.projectRef.path).valueChanges();
    //     return task;
    // }));
  }

  // get tasks to do by month
  getTasksTodoByMonth(path: string, yearMonth: string, userTodoRef: any): Observable<TaskType[]> {
    return this.afs.collection<TaskType>(path, ref => ref
      .where(`showInMonths.${yearMonth}`, '==', true)
      .where(`isActive`, '==', true)
      .where(`userTodoRef`, '==', userTodoRef)).valueChanges();

    // return this.afs.collection<TaskType>(path, ref => ref
    //     .where(`showInMonths.${yearMonth}`, '==', true)
    //     .where(`isActive`, '==', true)
    //     .where(`userTodoRef`, '==', userTodoRef)).valueChanges().pipe(map(tasks => {
    //     for (let task of tasks) {
    //         task.project$ = this.afs.doc(task.projectRef.path).valueChanges();
    //     }
    //     return tasks;
    // }));

  }

  // get tasks to do without date
  getTasksTodoNoDate(path: string, userTodoRef: any): Observable<TaskType[]> {
    return this.afs.collection<TaskType>(path, ref => ref
      .where(`dateStart`, '==', '')
      .where(`dateEnd`, '==', '')
      .where(`isActive`, '==', true)
      .where(`userTodoRef`, '==', userTodoRef)).valueChanges();
  }

  // get tasks by project with pagination
  getTasksByProject(path: string, projectRef: any, task = {}, startAfterAt = '', limit: number): any {
    if (Object.keys(task).length > 0) {
      if (startAfterAt === 'startAfter') {
        return this.afs.collection<TaskType>(path, ref => ref
          .where(`projectRef`, '==', projectRef)
          .where(`isActive`, '==', true)
          .orderBy('dateStart', 'desc')
          .startAfter(task)
          .limit(limit))
          .snapshotChanges();
      } else if (startAfterAt === 'startAt') {
        return this.afs.collection<TaskType>(path, ref => ref
          .where(`projectRef`, '==', projectRef)
          .where(`isActive`, '==', true)
          .orderBy('dateStart', 'desc')
          .startAt(task)
          .limit(limit))
          .snapshotChanges();
      }
    } else {
      return this.afs.collection<TaskType>(path, ref => ref
        .where(`projectRef`, '==', projectRef)
        .where(`isActive`, '==', true)
        .orderBy('dateStart', 'desc')
        .limit(limit))
        .snapshotChanges();
    }
  }

  // get tasks ordered by month
  getTasksOrderedByMonth(path: string, yearMonth: string, userAuthorRef: any): Observable<TaskType[]> {
    return this.afs.collection<TaskType>(path, ref => ref
      .where(`showInMonths.${yearMonth}`, '==', true)
      .where(`isActive`, '==', true)
      .where(`userAuthorRef`, '==', userAuthorRef)).valueChanges();
  }

  // get tasks ordered without date
  getTasksOrderedNoDate(path: string, userAuthorRef: any): Observable<TaskType[]> {
    return this.afs.collection<TaskType>(path, ref => ref
      .where(`dateStart`, '==', '')
      .where(`dateEnd`, '==', '')
      .where(`isActive`, '==', true)
      .where(`userAuthorRef`, '==', userAuthorRef)).valueChanges();
  }

  // get tasks to do any user
  getTasksTodoByWeekAnyUser(path: string, yearWeek: string): Observable<TaskType[]> {
    return this.afs.collection<TaskType>(path, ref => ref
      .where(`showInWeeks.${yearWeek}`, '==', true)
      .where(`isActive`, '==', true))
      .valueChanges();
  }

  // get tasks project by month
  getTasksProjectByMonth(path: string, yearMonth: string, projectRef: any): Observable<TaskType[]> {
    return this.afs.collection<TaskType>(path, ref => ref
      .where(`showInMonths.${yearMonth}`, '==', true)
      .where(`isActive`, '==', true)
      .where(`projectRef`, '==', projectRef)).valueChanges();
  }

  // get tasks project without date
  getTasksProjectNoDate(path: string, projectRef: any): Observable<TaskType[]> {
    return this.afs.collection<TaskType>(path, ref => ref
      .where(`dateStart`, '==', '')
      .where(`dateEnd`, '==', '')
      .where(`isActive`, '==', true)
      .where(`projectRef`, '==', projectRef)).valueChanges();
  }

  // get tasks project
  getTasksProject(path: string, projectRef: any): Observable<TaskType[]> {
    return this.afs.collection<TaskType>(path, ref => ref
      .where(`isActive`, '==', true)
      .where(`projectRef`, '==', projectRef)).valueChanges();
  }

  // once tasks project
  onceTasksProject(path: string, projectRef: any): any {
    const db = firebase.firestore();
    return db.collection(path).where(`isActive`, '==', true).where(`projectRef`, '==', projectRef).get();
  }

  // insert task
  insertTask(task: TaskType, path: string): Promise<void> {
    return this.afs.doc<TaskType>(path).set(task);
  }

  // update task
  updateTask(fields: {}, path: string): Promise<void> {
    return this.afs.doc<TaskType>(path).update(fields);
  }

  // upload file
  uploadFile(file: any, path: string, fileName: string): any {

    // check if file exists
    // return this.checkIfFileExists(path, fileName).then(name => {
    //     console.log(name);
    //     // put file to storage
    //
    //     const metadata = {
    //         contentDisposition: 'attachment; filename=' + name
    //     };
    //
    //     return firebase.storage().ref().child(path + name).put(file, metadata);
    // });

    const metadata = {
      contentDisposition: 'attachment; filename=' + fileName,
    };

    return firebase.storage().ref().child(path).put(file, metadata);
  }

  // private checkIfFileExists(path, fileName) {
  //     return firebase.storage().ref(path + fileName).getDownloadURL().then(() => {
  //
  //         const fileNameArray = fileName.split('.');
  //         const fileExtension = fileNameArray[fileNameArray.length - 1];
  //         fileName = fileNameArray.slice(0, -1).join('') + ' copy.' + fileExtension;
  //         // fileName = 'copy ' + fileName;
  //         return this.checkIfFileExists(path, fileName);
  //     }).catch(() => {
  //         return fileName;
  //     });
  //
  // }


}
