<div class="container">
  <div class="logo" *ngIf="service">
    <img src="{{ service.logo }}">
  </div>
  <div class="row" *ngIf="service">
    <div class="col help-text-align-center">
      <p>{{ service.description }}</p>
    </div>
  </div>
  <div class="row">
    <div [ngClass]="{'col-3': subcategories, 'col-12': !subcategories}">
      <mat-nav-list *ngIf="categories">
        <ng-container *ngFor="let cat of categories">
          <mat-list-item *ngIf="cat.isForUnlogged || (user && user.companyRef.id === service.companyRef.id)"
                         [ngClass]="{'active': category && (cat.uid === category.uid), 'wide': !subcategories}"
                         (click)="onSelectCategory(cat)">
            <div matLine class="category" [ngClass]="{'category-right': subcategories}">{{ cat.name }}</div>
          </mat-list-item>
        </ng-container>
      </mat-nav-list>
    </div>
    <div class="col-9" *ngIf="subcategories">
      <mat-accordion>
        <ng-container *ngFor="let subcategory of subcategories">
          <mat-expansion-panel *ngIf="subcategory.isForUnlogged || (user && user.companyRef.id === service.companyRef.id)" (opened)="onSelectSubcategory(subcategory)">
            <mat-expansion-panel-header class="panel-header">
              <mat-panel-title>
                {{ subcategory.name }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="panel-body">
              <div class="desc">{{ subcategory.description }}</div>
              <div class="form" *ngIf="subcategory.inputs">
                <form (ngSubmit)="onSave(f)" #f="ngForm" autocomplete="off">
                  <input type="hidden" name="_subcategoryUid" ngModel="{{ subcategory.uid }}">

                  <ng-container *ngIf="user">
                    <label class="label">Author</label>
                    <div class="user">
                      <div *ngIf="user.photoURL"
                           [ngStyle]="{'background-image': 'url(' + user.photoURL + ')'}"
                           class="user-avatar"></div>
                      <div *ngIf="!user.photoURL"
                           class="user-avatar">{{ user.name | avatar }}</div>
                      <div class="user-name">{{ user.name }} <small>{{ user._rolesName }}</small></div>
                    </div>
                  </ng-container>

                  <mat-form-field>
                    <mat-chip-list #chipList>
                      <mat-chip *ngFor="let email of emails"
                                [removable]="true"
                                (removed)="onRemoveEmail(email)">
                        {{email}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                      <input placeholder="Notification emails *"
                             autocomplete="none"
                             [matAutocomplete]="auto"
                             (input)="onChange($event.target)"
                             [matChipInputFor]="chipList"
                             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                             [matChipInputAddOnBlur]="onAddEmail"
                             (matChipInputTokenEnd)="onAddEmail($event)">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelected($event)">
                      <mat-option class="option-lsemail" *ngFor="let lsEmail of lsEmails; index as i" [value]="lsEmail">
                        {{lsEmail}} <span class="material-icons remove"
                        (click)="onRemoveLSEmail(i)">close</span>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>

                  <ng-container *ngFor="let input of subcategory.inputs">

                    <!-- text field -->
                    <mat-form-field *ngIf="input.type === 'text'">
                      <input matInput
                             type="text"
                             name="{{ input.name }}"
                             ngModel
                             required="{{ input.isRequired }}"
                             placeholder="{{ input.name }}">
                      <mat-hint *ngIf="input.description">{{ input.description }}</mat-hint>
                    </mat-form-field>
                    <!-- end text field -->

                    <!-- url field -->
                    <mat-form-field *ngIf="input.type === 'url'">
                      <input matInput
                             type="text"
                             name="{{ input.name }}"
                             ngModel
                             required="{{ input.isRequired }}"
                             pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                             placeholder="{{ input.name }}">
                      <mat-hint *ngIf="input.description">{{ input.description }}</mat-hint>
                    </mat-form-field>
                    <!-- end url field -->

                    <!-- number field -->
                    <mat-form-field *ngIf="input.type === 'number'">
                      <input matInput
                             type="number"
                             name="{{ input.name }}"
                             ngModel
                             required="{{ input.isRequired }}"
                             placeholder="{{ input.name }}">
                      <mat-hint *ngIf="input.description">{{ input.description }}</mat-hint>
                    </mat-form-field>
                    <!-- end number field -->

                    <!-- textarea field -->
                    <mat-form-field *ngIf="input.type === 'textarea'">
                    <textarea matInput
                              name="{{ input.name }}"
                              ngModel
                              required="{{ input.isRequired }}"
                              matTextareaAutosize
                              placeholder="{{ input.name }}"></textarea>
                      <mat-hint *ngIf="input.description">{{ input.description }}</mat-hint>
                    </mat-form-field>
                    <!-- end textarea field -->

                    <!-- select field -->
                    <mat-form-field *ngIf="input.type === 'select'">
                      <mat-label>{{ input.name }}</mat-label>
                      <mat-select required="{{ input.isRequired }}" name="{{ input.name }}" ngModel>
                        <mat-option *ngFor="let option of input.options" [value]="option">
                          {{ option }}
                        </mat-option>
                      </mat-select>
                      <mat-hint *ngIf="input.description">{{ input.description }}</mat-hint>
                    </mat-form-field>
                    <!-- end select field -->

                    <!-- file field -->
                    <ng-container *ngIf="input.type === 'file'">
                      <label class="label">{{ input.name }}</label>
                      <ul class="uploads" *ngIf="filesTicket.length">
                        <li class="upload" *ngFor="let file of filesTicket; index as i">{{file.name}} <span
                          *ngIf="file.progress.data.percentage">({{file.progress.data.percentage}}%)</span><span
                          *ngIf="!file.progress.data.percentage" class="material-icons remove"
                          (click)="onRemoveFile(i)">close</span></li>
                      </ul>
                      <div class="drop-container"
                           ngFileDrop
                           [options]="options"
                           (uploadOutput)="onUpload($event)"
                           [uploadInput]="uploadInput"
                           [ngClass]="{ 'is-drop-over': dragOver }">
                        <input type="file"
                               ngFileSelect
                               [options]="options"
                               (uploadOutput)="onUpload($event)"
                               [uploadInput]="uploadInput"
                               multiple>
                        <span>Select or drop file(s) here</span>
                      </div>
                      <div class="hint" *ngIf="input.description">{{ input.description }}</div>
                    </ng-container>
                    <!-- end file field -->

                  </ng-container>


                  <button type="submit"
                          mat-raised-button
                          color="primary"
                          [disabled]="f.form.invalid || !emails.length">Save
                  </button>
                </form>
              </div>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </div>
  </div>
  <div class="footer">
    Powered by <img src="assets/favicon/favicon-192x192.png"> Soteam
  </div>
</div>
