import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ChatType} from '../../../_types/chat.type';
import {Subscription} from 'rxjs';
import {ChatsService} from '../../../_services/chats.service';
import {UserType} from '../../../_types/user.type';
import {UsersService} from '../../../_services/users.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-widget-chats',
    templateUrl: './widget-chats.component.html',
    styleUrls: ['./widget-chats.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WidgetChatsComponent implements OnInit, OnDestroy {

    @Input() users: UserType[];

    user: UserType;
    chats: ChatType[];
    hiddenChatsVisibility = false;
    hiddenChats: {} = {};

    private subscriptions: Array<Subscription> = [];

    constructor(private chatsService: ChatsService,
                private usersService: UsersService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        // set user
        this.user = this.route.snapshot.parent.data.user;

        // get chats and messages
        this.getChatsAndMessages();

        // get hidden chats
        this.hiddenChats = JSON.parse(localStorage.getItem('dashboardWidgetLastChatMessages')) || {};
    }

    getChatsAndMessages() {
        // get chats
        this.subscriptions.push(this.chatsService.getChats(this.user).subscribe(chats => {
            this.chats = chats;
            this.chats.forEach(chat => {
                // check access to the chat
                chat.userRefs.forEach(userRef => {
                    if (userRef.id === this.user.uid) {
                        // get last messages
                        this.subscriptions.push(
                            this.chatsService
                                .getMessages(`companies/${this.user.companyRef.id}/chats/${chat.uid}/messages`, 3, 'desc')
                                .subscribe(messages => {
                            chat.lastMessages = messages.reverse();
                            this.matchChatMessagesToUsers();
                        }));
                    }
                });
            });
        }));
    }

    showChat(uid) {
        delete this.hiddenChats[uid];
        localStorage.setItem('dashboardWidgetLastChatMessages', JSON.stringify(this.hiddenChats));
    }

    hideChat(uid) {
        this.hiddenChats[uid] = true;
        localStorage.setItem('dashboardWidgetLastChatMessages', JSON.stringify(this.hiddenChats));
    }

    private matchChatMessagesToUsers() {
        for (let i = 0; i < this.chats.length; i++) {
            if (this.chats[i].lastMessages) {
                for (let j = 0; j < this.chats[i].lastMessages.length; j++) {
                    for (let k = 0; k < this.users.length; k++) {
                        if (this.users[k].uid === this.chats[i].lastMessages[j].userRef.id) {
                            this.chats[i].lastMessages[j].author = this.users[k];
                        }
                    }
                }
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
