import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {PostsService} from '../../../_services/posts.service';
import {BrandsService} from '../../../_services/brands.service';
import {BrandsComponent} from '../brands.component';
import {UserType} from '../../../_types/user.type';
import {PostType} from '../../../_types/post.type';
import {environment} from '../../../../environments/environment';
import {UsersService} from '../../../_services/users.service';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-brand-post',
  templateUrl: './brand-post.component.html',
  styleUrls: ['./brand-post.component.scss']
})
export class BrandPostComponent implements OnInit, OnDestroy, OnChanges {

  user: UserType;
  uidPost: string;
  post: PostType;
  users: UserType[];

  postEdit: PostType;

  hideAutoComments: boolean;

  private subscriptions: Array<Subscription> = [];
  private postSubscription: Subscription;

  constructor(private route: ActivatedRoute,
              private postsService: PostsService,
              private usersService: UsersService,
              private brandsService: BrandsService,
              private brandsComponent: BrandsComponent,
              private progress: NgProgress,
              private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    // set user
    this.user = this.route.snapshot.parent.parent.data.user;
    if (this.user.settings && this.user.settings.socialBrands_hideAutoComments) {
      this.hideAutoComments = this.user.settings.socialBrands_hideAutoComments;
    }

    this.subscriptions.push(this.route.params.subscribe(params => {
      this.uidPost = params.uidPost;

      // get post
      this.getPost();

      // get users
      this.getUsers();
    }));
  }

  ngOnChanges() {
  }

  onChangeUserSettings() {
    this.progress.ref().start();
    this.hideAutoComments = !this.hideAutoComments;
    if (!this.user.settings) {
      this.user.settings = {};
    }
    this.user.settings['socialBrands_hideAutoComments'] = this.hideAutoComments;
    this.usersService.updateUser({
      settings: this.user.settings
    }, this.user.uid)
      .then(() => {
        this.progress.ref().complete();
        this.snackBar.open('User settings has been updated', '', environment.snackbarSuccess);
        this.getPost();
      })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  private getPost() {
    if (this.postSubscription) {
      this.postSubscription.unsubscribe();
    }

    this.postSubscription = this.postsService.getPost(`companies/${this.user.companyRef.id}/posts/${this.uidPost}`)
      .subscribe(post => {
        this.post = post;
        this.getBrand();
      });
  }

  private getUsers() {
    this.subscriptions.push(this.usersService.getUsers(this.user.companyRef).subscribe(users => {
      this.users = users;
    }));
  }

  private getBrand() {
    this.subscriptions.push(this.brandsService.getBrand(this.user, this.post.brandRef.id).subscribe(brand => {
      this.brandsComponent.title = brand.name;
      this.post.brand = brand;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.postSubscription.unsubscribe();
  }

}
