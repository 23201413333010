import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UsersService} from '../../../_services/users.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ModulesComponent} from '../modules.component';
import {Subscription} from 'rxjs';
import {UserType} from '../../../_types/user.type';
import {ModuleType} from '../../../_types/module.type';
import {ModulesService} from '../../../_services/modules.service';
import * as firebase from 'firebase/app';
import {environment} from '../../../../environments/environment';
import {AccessToModule} from '../../../_types/accessToModule.type';
import {AccessToModulesService} from '../../../_services/access-to-modules.service';
import {PricesService} from '../../../_services/prices.service';
import {CompanyType} from '../../../_types/company.type';
import {CompaniesService} from '../../../_services/companies.service';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-module-users',
  templateUrl: './module-users.component.html',
  styleUrls: ['./module-users.component.scss']
})
export class ModuleUsersComponent implements OnInit, OnDestroy {

  userCurrent: UserType;
  module: ModuleType;
  moduleUid: string;
  moduleComplete = false;
  users: UserType[];
  usersComplete = false;
  numberOfUsers: number;
  numberOfUsersMax = 0;
  lastAccessToModule: AccessToModule;
  pricePLNForModule = 0;
  priceUSDForModule = 0;
  company: CompanyType;

  searchUser: string;

  date = new Date();
  month: number;
  year: number;
  monthChanges: AccessToModule[] = [];
  monthChangesComplete = false;
  prevChange: AccessToModule;
  prevChangeComplete = false;

  drawChart = false;

  monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  public chart = {
    type: 'bar',
    options: {
      aspectRatio: 4,
      responsive: true,
      legend: {
        display: false
      },
      tooltips: {
        titleFontFamily: 'Quicksand',
        bodyFontFamily: 'Quicksand',
        displayColors: false
      },
      scales: {
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Number of users',
            fontColor: '#34334b',
          },
          ticks: {
            fontFamily: 'Quicksand',
            fontColor: '#34334b',
            beginAtZero: true,
            userCallback: function (label, index, labels) {
              // when the floored value is the same as the value we have a whole number
              if (Math.floor(label) === label) {
                return label;
              }
            },
          },
          gridLines: {
            color: 'transparent',
          }
        }],
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: this.monthNames[this.date.getMonth()] + ' ' + this.date.getFullYear(),
            fontColor: '#34334b',
          },
          ticks: {
            fontFamily: 'Quicksand',
            fontColor: '#34334b',
          },
          barPercentage: .5,
          gridLines: {
            color: 'transparent',
          }
        }],
      }
    },
    labels: [],
    dataset: [{
      label: 'Number of users',
      data: [],
      backgroundColor: '#9327fe',
      hoverBackgroundColor: '#0da7ff'
    }, {
      label: 'Max. number of users',
      data: [],
      backgroundColor: 'transparent',
      borderColor: '#0da7ff',

      // Changes this dataset to become a line
      type: 'line'
    }]
  };

  private subscriptions: Array<Subscription> = [];
  private subscriptionsChanges: Array<Subscription> = [];

  constructor(private route: ActivatedRoute,
              private usersService: UsersService,
              private modulesService: ModulesService,
              private modulesComponent: ModulesComponent,
              private accessToModulesService: AccessToModulesService,
              private pricesService: PricesService,
              private companiesService: CompaniesService,
              private afs: AngularFirestore,
              private progress: NgProgress,
              private snackBar: MatSnackBar) {

    // set params
    this.subscriptions.push(this.route.params.subscribe(params => {
      this.modulesComponent.setNav(params.uid);
      this.moduleUid = params.uid;

      // set user
      this.userCurrent = this.route.snapshot.parent.parent.data.user;

      // get module
      this.getModule();

      // get users
      this.getUsers();

      // get max number of users in month
      this.getLastNumberOfUsers();

      // get changes
      this.getChanges();

      // get prices
      this.getPrices();

      // get currency / company info
      this.getCompany();
    }));
  }

  ngOnInit() {
  }

  private getCompany() {
    this.subscriptions.push(this.companiesService.getCompany(`companies/${this.userCurrent.companyRef.id}`).subscribe(company => {
      this.company = company;
    }));
  }

  private getPrices() {
    const dateTimestamp = new Date().getTime();
    this.subscriptions.push(this.pricesService.getPricesForModule(`companies/${this.userCurrent.companyRef.id}/prices`, this.afs.doc(`modules/${this.moduleUid}/`).ref).subscribe(prices => {
      if (prices.length) {
        prices[0].prices.forEach(price => {
          if ((price.dateStart.seconds * 1000 <= dateTimestamp) && (price.dateEnd.seconds * 1000 >= dateTimestamp)) {
            this.pricePLNForModule = price.valuePLN;
            this.priceUSDForModule = price.valueUSD;
          }
        });
      }
    }));
  }

  private getChanges() {
    // unsubscribe subscriptions
    this.subscriptionsChanges.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });

    // set month and year
    this.month = this.date.getMonth() + 1;
    this.year = this.date.getFullYear();

    // reset var
    this.monthChangesComplete = false;
    this.prevChangeComplete = false;

    // get month changes
    const firstDayOfTheMonth: Date = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    this.subscriptionsChanges.push(this.accessToModulesService.getAccessesForModuleDateLimitSign(
      `companies/${this.userCurrent.companyRef.id}/accessToModules`,
      this.afs.doc(`modules/${this.moduleUid}/`).ref,
      firstDayOfTheMonth,
      999,
      '>=').subscribe(changes => {
      this.monthChanges = changes;
      this.monthChangesComplete = true;
      this.setChart();
    }));

    // get prev change
    this.subscriptionsChanges.push(this.accessToModulesService.getAccessesForModuleDateLimitSign(
      `companies/${this.userCurrent.companyRef.id}/accessToModules`,
      this.afs.doc(`modules/${this.moduleUid}/`).ref,
      firstDayOfTheMonth,
      1,
      '<').subscribe(changes => {
      if (changes.length) {
        this.prevChange = changes[0];
        this.prevChangeComplete = true;
        this.drawChart = true;
        this.setChart();
      } else {
        this.drawChart = false;
      }
    }));
  }

  private setChart() {
    if (this.monthChangesComplete && this.prevChangeComplete) {

      // set variables
      let dateTimestamp;
      let monthChangeDate;
      const daysInTheMonth = new Date(this.date.getFullYear(), this.date.getMonth(), 0).getDate();

      // reset data
      this.chart.dataset[0].data = [];
      this.chart.dataset[1].data = [];
      this.chart.labels = [];

      // set calendar
      for (let i = 1; i <= daysInTheMonth; i++) {
        this.chart.labels.push(i);

        if (i <= this.date.getDate()) {
          // find date in changes
          this.monthChanges.forEach(monthChange => {
            dateTimestamp = new Date(this.date.getFullYear(), this.date.getMonth(), i).getTime();
            if (monthChange.dateCreate) {
              monthChangeDate = new Date(monthChange.dateCreate.seconds * 1000);
            } else {
              monthChangeDate = new Date();
            }
            monthChangeDate = new Date(monthChangeDate.getFullYear(), monthChangeDate.getMonth(), monthChangeDate.getDate()).getTime();
            if (dateTimestamp === monthChangeDate) {
              this.chart.dataset[0].data[i - 1] = monthChange.numberOfUsers;
              this.numberOfUsersMax = Math.max(monthChange.numberOfUsers, this.numberOfUsersMax);
              this.chart.dataset[1].data[i - 1] = this.numberOfUsersMax;
            }
          });

          // if didn't find data in changes
          if ((typeof this.chart.dataset[0].data[i - 1] === 'undefined') && (i === 1)) {  // copy data from the last change
            this.chart.dataset[0].data[i - 1] = this.prevChange.numberOfUsers;
            this.numberOfUsersMax = this.prevChange.numberOfUsers;
            this.chart.dataset[1].data[i - 1] = this.numberOfUsersMax;
          }
          if ((typeof this.chart.dataset[0].data[i - 1] === 'undefined') && (i !== 1)) { // copy data from prev element
            this.chart.dataset[0].data[i - 1] = this.chart.dataset[0].data[i - 2];
            this.numberOfUsersMax = Math.max(this.chart.dataset[0].data[i - 2], this.numberOfUsersMax);
            this.chart.dataset[1].data[i - 1] = this.numberOfUsersMax;
          }
        } else {
          this.chart.dataset[0].data[i - 1] = 0;
          this.chart.dataset[1].data[i - 1] = this.chart.dataset[1].data[i - 2];
        }
      }
    }
  }

  private getModule() {
    this.subscriptions.push(this.modulesService.getModule(this.modulesComponent.uid).subscribe(module => {
      this.module = module;
      this.modulesComponent.title = this.module.name;
      this.moduleComplete = true;
      this.matchUserAccessToModule();
    }));
  }

  private getUsers() {
    this.subscriptions.push(this.usersService.getUsersActive(this.userCurrent.companyRef).subscribe(users => {
      this.users = users;
      this.usersComplete = true;
      this.matchUserAccessToModule();
    }));
  }

  private matchUserAccessToModule() {
    this.numberOfUsers = 0;
    if (this.moduleComplete && this.usersComplete) {
      this.users.forEach(user => {
        user.moduleAccess = false;
        if (user._modules && user._modules[this.module.code]) {
          user.moduleAccess = true;
          this.numberOfUsers++;
        }
      });
    }
  }

  private getLastNumberOfUsers() {
    this.subscriptions.push(this.accessToModulesService.getLastAccessForModule(`companies/${this.userCurrent.companyRef.id}/accessToModules`, this.afs.doc(`modules/${this.moduleUid}/`).ref).subscribe(accesses => {
      this.lastAccessToModule = accesses[0];
    }));
  }

  onChangeAccess(user) {
    this.progress.ref().start();

    let messageTooltip: string;
    let messageDB: string;
    let accessMessage: AccessToModule;

    // define messages and count final number of users
    if (user._modules && user._modules[this.module.code]) { // remove access
      delete user._modules[this.module.code];
      this.numberOfUsers--;
      messageTooltip = 'Access to the module has been removed';
      messageDB = `has removed access to ${user.name}`;
    } else {  // add access
      if (!user._modules) {
        user._modules = {};
      }
      user._modules[this.module.code] = true;
      this.numberOfUsers++;
      messageTooltip = 'Access to the module has been added';
      messageDB = `has added access to ${user.name}`;
    }

    // define access message
    accessMessage = {
      uid: this.afs.createId(),
      dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
      message: messageDB,
      moduleRef: this.afs.doc(`modules/${this.module.uid}/`).ref,
      numberOfUsers: this.numberOfUsers,
      userRef: this.afs.doc(`users/${this.userCurrent.uid}`).ref,
    };

    this.usersService.updateUser({
      _modules: user._modules
    }, user.uid)
      .then(() => {
        this.accessToModulesService.insertAccess(accessMessage, `companies/${this.userCurrent.companyRef.id}/accessToModules/${accessMessage.uid}`)
          .then(() => {
            this.snackBar.open(messageTooltip, '', environment.snackbarSuccess);
            this.progress.ref().complete();
          })
          .catch(err => {
            this.snackBar.open(err.message, '', environment.snackbarWarn);
          });
      })
      .catch(err => {
        this.snackBar.open(err.message, '', environment.snackbarWarn);
        this.progress.ref().complete();
      });
    this.progress.ref().complete();
  }

  getPrevMonth() {
    this.date = new Date(this.date.setMonth(this.date.getMonth() - 1));
    this.getChanges();
  }

  getNextMonth() {
    this.date = new Date(this.date.setMonth(this.date.getMonth() + 1));
    this.getChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptionsChanges.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
