import {Component, EventEmitter, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ChatsComponent} from '../chats.component';
import {ActivatedRoute} from '@angular/router';
import {ChatType} from '../../../_types/chat.type';
import {ChatsService} from '../../../_services/chats.service';
import {UsersService} from '../../../_services/users.service';
import {MessageType} from '../../../_types/message.type';
import {FileType} from '../../../_types/file.type';
import {UploaderOptions, UploadFile, UploadInput, UploadOutput} from 'ngx-uploader';
import {UserType} from '../../../_types/user.type';
import {NgForm} from '@angular/forms';
import * as firebase from 'firebase/app';
import {environment} from '../../../../environments/environment';
import {NotificationType} from '../../../_types/notification.type';
import {NotificationsService} from '../../../_services/notifications.service';
import {FcmService} from '../../../_services/fcm.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-chat-messages',
    templateUrl: './chat-messages.component.html',
    styleUrls: ['./chat-messages.component.scss']
})
export class ChatMessagesComponent implements OnInit {

    chat: ChatType;
    user: UserType;
    users: UserType[] = [];
    messages: MessageType[] = [];
    messageText: string;
    messageFiles: FileType[] = [];

    options: UploaderOptions;
    files: UploadFile[] = [];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;

    private subscriptions: Array<Subscription> = [];

    constructor(private chatsComponent: ChatsComponent,
                private chatsService: ChatsService,
                private notificationsService: NotificationsService,
                private usersService: UsersService,
                private fcm: FcmService,
                private route: ActivatedRoute,
                private afs: AngularFirestore,
                private snackBar: MatSnackBar) {

        // set params
        this.subscriptions.push(this.route.params.subscribe(params => {
            this.chatsComponent.setNav(params.uid);

            // get user
            // this.subscriptions.push(this.usersService.user$.subscribe(user => {
            //
            //     if (user) {
            //         this.user = user;
            //
            //         //get chat
            //         this.getChat();
            //
            //         //get messages
            //         this.getMessages();
            //
            //         //get users
            //         this.getUsers();
            //     }
            // }))
        }));
    }

    ngOnInit() {
    }

    getChat() {
        this.subscriptions.push(this.chatsService.getChat(`companies/${this.user.companyRef.id}/chats/${this.chatsComponent.uid}`).subscribe(chat => {
            this.chat = chat;
            this.chatsComponent.title = this.chat.name;
        }));
    }

    getMessages() {
        this.chatsService.getMessages(`companies/${this.user.companyRef.id}/chats/${this.chatsComponent.uid}/messages`).subscribe(messages => {
            this.messages = messages;
            this.matchMessagesToUsers();
        });
    }

    getUsers() {
        this.usersService.getUsers(this.user.companyRef).subscribe(users => {
            this.users = users;
            this.matchMessagesToUsers();
        });
    }

    onSend(form: NgForm) {
        // save notification & send push message
        const informedIds = this.calculateInformed();
        for (const id in informedIds) {
            // save notification
            this.saveNotification({
                message: `<div class="message__one">has send the message ${form.value.messageText} on the channel <a class="link" routerLink="chats/${this.chat.uid}/messages">${this.chat.name}</a></div>`,
                informedId: id
            });

            // send push
            this.sendPush({
                message: {
                    title: `New channel message`,
                    body: `${this.user.name} has send the message ${form.value.messageText} on the channel ${this.chat.name}`,
                    click_action: `${environment.url}/notifications`
                },
                informedId: id
            });
        }

        let message: MessageType;
        message = {
            uid: this.afs.createId(),
            message: form.value.messageText,
            dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
            userRef: this.afs.doc(`users/${this.user.uid}`).ref,
            files: this.messageFiles,
            type: 'user'
        };
        this.chatsService.insertMessage(message, `companies/${this.user.companyRef.id}/chats/${this.chatsComponent.uid}/messages/${message.uid}`)
            .then(() => {
                this.messageFiles = [];
            })
            .catch(err => {
                this.snackBar.open(err.message, '', environment.snackbarWarn);
            });

        // reset form
        form.resetForm();
    }

    onDeleteFile(index) {
        this.messageFiles.splice(index, 1);
    }

    onUploadOutput(output: UploadOutput): void {

        if (output.type === 'allAddedToQueue') { // when all files added in queue
            this.startUpload();
        } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
            this.files.push(output.file);
        } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
            console.log('uploading');

            // update current data in files array for uploading file
            const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
            this.files[index] = output.file;
        } else if (output.type === 'removed') {
            console.log('removed');
            // remove file from array when removed
            this.files = this.files.filter((file: UploadFile) => file !== output.file);
        } else if (output.type === 'dragOver') {
            this.dragOver = true;
        } else if (output.type === 'dragOut') {
            this.dragOver = false;
        } else if (output.type === 'drop') {
            this.dragOver = false;
        }
    }

    startUpload(): void {
        for (const file of this.files) {
            // start progress bar
            // this.progress.ref().start();
            // show snackbar
            this.snackBar.open(`Uploading file(s)`, '', environment.snackbarInfo);
            // prepare file
            const fileNameArray = file.name.split('.');
            const name = this.afs.createId() + '.' + fileNameArray[fileNameArray.length - 1];
            const path = `/${this.user.companyRef.id}/chats/${this.chatsComponent.uid}/${name}`;
            this.chatsService.uploadFile(file.nativeFile, path).then(snapshot => {
                // push file to message
                snapshot.ref.getDownloadURL().then(downloadURL => {
                    // push file to array
                    this.messageFiles.push({
                        name: file.name,
                        url: downloadURL,
                        type: file.type,
                        size: file.size,
                        isActive: true
                    });
                    // close progress bar
                    // this.progress.ref().complete();
                });
            });
        }

        // clear files
        this.files = [];
    }

    cancelUpload(id: string): void {
        this.uploadInput.emit({type: 'cancel', id});
    }

    removeFile(id: string): void {
        this.uploadInput.emit({type: 'remove', id});
    }

    removeAllFiles(): void {
        this.uploadInput.emit({type: 'removeAll'});
    }

    private matchMessagesToUsers() {
        if (this.messages.length && this.users.length) {
            this.messages.forEach(message => {
                this.users.forEach(user => {
                    if (user.uid === message.userRef.id) {
                        message.author = user;
                    }
                });
            });
        }
    }

    private calculateInformed() {
        const informed = {};
        for (let i = 0; i < this.chat.userRefs.length; i++) {
            if (this.chat.userRefs[i].id !== this.user.uid) {
                informed[this.chat.userRefs[i].id] = true;
            }
        }
        return informed;
    }

    private saveNotification(messageInformed) {
        let notification: NotificationType = {
            uid: this.afs.createId(),
            message: messageInformed.message,
            dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
            dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            userAuthorRef: this.afs.doc(`users/${this.user.uid}`).ref,
            userInformedRef: this.afs.doc(`users/${messageInformed.informedId}`).ref,
            elementUid: this.chat.uid,
            elementType: 'channelMessage',
            view: 'info',
            isUnread: true
        };
        const subscriptionNotification = {};

        subscriptionNotification[messageInformed.informedId] = this.notificationsService.getNotificationsToMerge(`companies/${this.user.companyRef.id}/notifications/`, notification).subscribe(notifications => {
            if (notifications.length) {
                const messageAditional = notification.message;
                notification = notifications[0];
                notification.message += messageAditional;
                this.notificationsService.updateNotification({message: notification.message}, `companies/${this.user.companyRef.id}/notifications/${notification.uid}`)
                    .then(() => {
                    })
                    .catch(err => {
                        this.snackBar.open(err.message, '', environment.snackbarWarn);
                    });
            }
            else {
                this.notificationsService.insertNotification(notification, `companies/${this.user.companyRef.id}/notifications/${notification.uid}`)
                    .then(() => {
                    })
                    .catch(err => {
                        this.snackBar.open(err.message, '', environment.snackbarWarn);
                    });
            }
            subscriptionNotification[messageInformed.informedId].unsubscribe();
        });
    }

    private sendPush(messageInformed) {
        this.subscriptions.push(this.usersService.getUserByRef(this.afs.doc(`users/${messageInformed.informedId}`).ref).subscribe(user => {
            this.fcm.sendMessage(user, messageInformed.message);
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
