<section class="page-section">
    <form (ngSubmit)="onSave(f)" #f="ngForm" autocomplete="off">

        <div>
            <mat-form-field>
                <input matInput
                       type="text"
                       name="name"
                       #name="ngModel"
                       ngModel
                       required
                       placeholder="Name">
                <mat-error *ngIf="name.hasError('required')">
                    Name is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field>
                <input matInput
                       type="text"
                       name="shortDesc"
                       #shortDesc="ngModel"
                       ngModel
                       placeholder="Short description">
            </mat-form-field>
        </div>

        <button type="submit"
                mat-raised-button color="primary"
                [disabled]="f.form.invalid">Save
        </button>
    </form>
</section>