import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {UsersService} from '../../../_services/users.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {UserType} from '../../../_types/user.type';
import {PrivilegesService} from '../../../_services/privileges.service';
import {ChatsService} from '../../../_services/chats.service';
import {TasksService} from '../../../_services/tasks.service';
import {ProjectsService} from '../../../_services/projects.service';
import {ActivatedRoute} from '@angular/router';
import {RolesService} from '../../../_services/roles.service';
import {animate, animateChild, query, stagger, style, transition, trigger} from '@angular/animations';
import {environment} from '../../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgProgress} from 'ngx-progressbar';

@Component({
  selector: 'app-aside-sidebar',
  templateUrl: './aside-sidebar.component.html',
  styleUrls: ['./aside-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('list', [
      transition(':enter', [
        query('@items', stagger(50, animateChild()))
      ]),
    ]),
    trigger('items', [
      transition(':enter', [
        style({opacity: 0, transform: 'scale(0.5)'}),
        animate('.2s ease-out', style({opacity: 1, transform: 'scale(1)'}))
      ]),
      transition(':leave', [
        style({transform: 'scale(1)', opacity: 1, height: '*'}),
        animate('.2s ease-in', style({opacity: 0, transform: 'scale(0.5)'})),
      ]),
    ])
  ]
})
export class AsideSidebarComponent implements OnInit, OnDestroy {

  user: UserType;
  users: UserType[] = [];
  searchUser: string;

  private subscriptions: Array<Subscription> = [];

  constructor(private usersService: UsersService,
              private rolesService: RolesService,
              private tasksService: TasksService,
              private chatsService: ChatsService,
              private projectsService: ProjectsService,
              private privilegesService: PrivilegesService,
              private route: ActivatedRoute,
              private progress: NgProgress,
              private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    // set user
    this.user = this.route.snapshot.data.user;

    // get users
    if ((this.user) && (this.user._rolesName === 'admin' || this.user._privileges.canGetUser)) {
      this.getUsers();
    } else {
      this.users.push(this.user);
    }
  }

  onAddFavorite(userUid) {
    this.progress.ref().start();
    if (!this.user.settingsData) {
      this.user.settingsData = {};
    }
    if (!this.user.settingsData.users_favorites) {
      this.user.settingsData.users_favorites = {};
    }
    this.user.settingsData.users_favorites[userUid] = true;
    this.usersService.updateUser({
      settingsData: this.user.settingsData
    }, this.user.uid)
      .then(() => {
        this.progress.ref().complete();
        this.snackBar.open('User settings has been updated', '', environment.snackbarSuccess);
      })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  onRemoveFavorite(userUid) {
    delete this.user.settingsData.users_favorites[userUid];
    this.usersService.updateUser({
      settingsData: this.user.settingsData
    }, this.user.uid)
      .then(() => {
        this.progress.ref().complete();
        this.snackBar.open('User settings has been updated', '', environment.snackbarSuccess);
      })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  private getUsers() {
    if (this.user) {
      this.users = [];
      this.usersService.onceUsersActive(this.user.companyRef).then(users => {
        users.forEach(userSnapshot => {
          const user: UserType = {
            uid: userSnapshot.data().uid,
            email: userSnapshot.data().email,
            name: userSnapshot.data().name,
            companyRef: userSnapshot.data().companyRef,
            isEmailVerified: userSnapshot.data().isEmailVerified,
            dateUpdate: userSnapshot.data().dateUpdate,
            userUpdateRef: userSnapshot.data().userUpdateRef,
            isActive: userSnapshot.data().isActive,
            isEmailNotification: userSnapshot.data().isEmailNotification,
            roleRef: userSnapshot.data().roleRef,
            _rolesName: userSnapshot.data()._rolesName,
            photoURL: userSnapshot.data().photoURL,
            changelog: {
              page: '',
              bottomSheet: '',
            }
          };
          this.users.push(user);
        });
      });
      // this.subscriptions.push(this.usersService.getUsersActive(this.user.companyRef).subscribe(users => {
      //     this.users = users;
      // }));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
