<main>
    <div class="container">
        <div class="row">
            <div class="col">
                <header class="page-header">
                    <h1 *ngIf="user">{{ user.name }}</h1>
                    <nav mat-tab-nav-bar>
                        <a mat-tab-link
                           *ngFor="let link of navLinks"
                           [routerLink]="link.path"
                           routerLinkActive #rla="routerLinkActive"
                           [active]="rla.isActive">
                            {{link.label}}
                        </a>
                    </nav>
                </header>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</main>