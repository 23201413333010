import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../_services/users.service';
import {ServicesService} from '../../_services/services.service';
import {ServiceType} from '../../_types/service.type';
import {UserType} from '../../_types/user.type';
import {ProjectType} from '../../_types/project.type';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit, OnDestroy {

  uid: string | any;
  user: UserType;
  services: ServiceType[] | any;
  projects: ProjectType[] | any;
  servicesComplete = false;
  projectsComplete = false;
  searchService: string | any;
  navLinks: any[] | any;
  title: string | any;

  showFullPage = false;

  private subscriptions: Array<Subscription> = [];

  constructor(private router: Router,
              private usersService: UsersService,
              private route: ActivatedRoute,
              private servicesService: ServicesService) {

    // set menu and title
    this.subscriptions.push(this.router.events.subscribe(() => {
      this.showFullPage = false;
      switch (this.router.url) {
        case '/services/service-add': {
          this.title = 'Add service desk';
          break;
        }

        case '/services': {
          this.showFullPage = true;
          this.navLinks = [];
          this.title = '';
          break;
        }
      }
    }));

    // set user
    // @ts-ignore
    this.user = this.route.snapshot.parent.data.user;

    // get services
    this.getServices();
  }

  ngOnInit(): void {
  }

  private getServices(): void {
    this.subscriptions.push(this.servicesService.getServices(this.user).subscribe(services => {
      this.services = services;
      this.servicesComplete = true;
    }));
  }

  setNav(uid: string): void {
    this.uid = uid;

    this.navLinks = [];

    this.navLinks.push({
      path: uid + '/list',
      label: 'List'
    });

    // this.navLinks.push({
    //   path: uid + '/details',
    //   label: 'Details'
    // });

    if (this.user._rolesName === 'admin' || this.user._privileges.canGetUser) {
      this.navLinks.push({
        path: uid + '/team',
        label: 'Team'
      });
    }

    // this.navLinks.push({
    //   path: uid + '/followers',
    //   label: 'Followers'
    // });
    //
    // this.navLinks.push({
    //   path: uid + '/changes',
    //   label: 'Changes'
    // });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
