import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ProjectsComponent} from '../projects.component';
import {UserType} from '../../../_types/user.type';
import {ProjectType} from '../../../_types/project.type';
import {ProjectsService} from '../../../_services/projects.service';
import {UploaderOptions, UploadFile, UploadInput, UploadOutput} from 'ngx-uploader';
import {environment} from '../../../../environments/environment';
import {FileType} from '../../../_types/file.type';
import {AngularFirestore} from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import {MessageType} from '../../../_types/message.type';
import {MessagesService} from '../../../_services/messages.service';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-project-assets',
    templateUrl: './project-assets.component.html',
    styleUrls: ['./project-assets.component.scss']
})
export class ProjectAssetsComponent implements OnInit, OnDestroy {

    user: UserType;
    project: ProjectType;
    filesProject: FileType[] = [];
    showRemove: boolean[] = [];
    change: MessageType;

    options: UploaderOptions;
    files: UploadFile[] = [];
    dragOver: boolean;
    uploadInput: EventEmitter<UploadInput>;

    private subscriptions: Array<Subscription> = [];

    constructor(private route: ActivatedRoute,
                private projectsComponent: ProjectsComponent,
                private messagesService: MessagesService,
                private projectsService: ProjectsService,
                private progress: NgProgress,
                private snackBar: MatSnackBar,
                private afs: AngularFirestore) {
        // set params
        this.subscriptions.push(this.route.params.subscribe(params => {
            this.projectsComponent.setNav(params.uid);

            // set user
            this.user = this.route.snapshot.parent.parent.data.user;

            // get project
            this.subscriptions.push(this.projectsService.getProject(this.user, this.projectsComponent.uid).subscribe(project => {
                this.project = project;
                this.projectsComponent.title = this.project.name;
            }));

        }));
    }

    onUpload(output: UploadOutput): void {
        if (output.type === 'allAddedToQueue') { // when all files added in queue
            this.startUpload();
        } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
            this.files.push(output.file);
        } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
            console.log('uploading');
            // update current data in files array for uploading file
            const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
            this.files[index] = output.file;
        } else if (output.type === 'removed') {
            console.log('removed');
            // remove file from array when removed
            this.files = this.files.filter((file: UploadFile) => file !== output.file);
        } else if (output.type === 'dragOver') {
            this.dragOver = true;
        } else if (output.type === 'dragOut') {
            this.dragOver = false;
        } else if (output.type === 'drop') {
            this.dragOver = false;
        }
    }

    startUpload(): void {
        // check how many files are uploading
        let filesLength = this.files.length;
        // reset temporary project files
        this.filesProject = [];
        // start progress bar
        this.progress.ref().start();
        // show snackbar
        this.snackBar.open(`Uploading file(s)`, '', environment.snackbarInfo);
        // set change
        this.change = {
            uid: this.afs.createId(),
            message: '',
            dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
            userRef: this.afs.doc(`users/${this.user.uid}`).ref,
            files: [],
            type: 'auto'
        };
        for (const file of this.files) {
            // prepare file
            const fileNameArray = file.name.split('.');
            const name = this.afs.createId() + '.' + fileNameArray[fileNameArray.length - 1];
            const path = `/${this.user.companyRef.id}/projects/${this.project.uid}/${name}`;
            this.projectsService.uploadFile(file.nativeFile, path, file.name).then(snapshot => {
                // push file to message
                snapshot.ref.getDownloadURL().then(downloadURL => {
                    // push file to array
                    this.filesProject.push({
                        name: file.name,
                        url: downloadURL,
                        type: file.type,
                        size: file.size,
                        isActive: true
                    });
                    // set change message
                    if (this.change.message === '') {
                        this.change.message += `has added asset(s) ${file.name}`;
                    } else {
                        this.change.message += `, ${file.name}`;
                    }
                    filesLength--;
                    if (filesLength === 0) {
                        this.onUpdate('File(s) has been added.');
                    }

                });
            });
        }
        // clear files
        this.files = [];
    }

    onRemove(i) {
        this.progress.ref().start();
        this.project.files[i].isActive = false;
        // set change
        this.change = {
            uid: this.afs.createId(),
            message: `has removed asset ${this.project.files[i].name}`,
            dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
            userRef: this.afs.doc(`users/${this.user.uid}`).ref,
            files: [],
            type: 'auto'
        };
        this.onUpdate('File has been removed.');
    }

    onUpdate(message: string) {
        // update field
        this.project.files = this.project.files.concat(this.filesProject);
        // update task
        this.projectsService.updateProject({
            files: this.project.files,
            dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            userUpdateRef: this.afs.doc(`users/${this.user.uid}`).ref
        }, this.user, this.project.uid)
            .then(() => {
                this.messagesService.insertMessage(this.change, `companies/${this.user.companyRef.id}/projects/${this.project.uid}/changes/${this.change.uid}`)
                    .then(() => {
                        // show snackbar
                        this.snackBar.open(message, '', environment.snackbarSuccess);
                        // close progress bar
                        this.progress.ref().complete();
                    })
                    .catch(err => {
                        this.snackBar.open(err.message, '', environment.snackbarWarn);
                        // close progress bar
                        this.progress.ref().complete();
                    });
            }).catch(err => {
                // show snackbar
                this.snackBar.open(err.message, '', environment.snackbarWarn);
                // close progress bar
                this.progress.ref().complete();
            }
        );
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
