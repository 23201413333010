import {Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {UserType} from '../../_types/user.type';
import {Subscription} from 'rxjs';
import {PostType} from '../../_types/post.type';
import {environment} from '../../../environments/environment';
import {AngularFirestore} from '@angular/fire/firestore';
import {PostsService} from '../../_services/posts.service';
import {NgForm} from '@angular/forms';
import {MessageType} from '../../_types/message.type';
import {UploaderOptions, UploadFile, UploadInput, UploadOutput} from 'ngx-uploader';
import {BrandsService} from '../../_services/brands.service';
import {UsersService} from '../../_services/users.service';
import {NotificationType} from '../../_types/notification.type';
import * as firebase from 'firebase/app';
import {NotificationsService} from '../../_services/notifications.service';
import {BrandsComponent} from '../../core/brands/brands.component';
import {SharedPostDialogComponent} from '../shared-post-dialog/shared-post-dialog.component';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UploadTaskSnapshot} from '@angular/fire/storage/interfaces';

@Component({
  selector: 'app-shared-post',
  templateUrl: './shared-post.component.html',
  styleUrls: ['./shared-post.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SharedPostComponent implements OnInit, OnDestroy, OnChanges {

  @Input() post: PostType;
  @Input() user: UserType;
  @Input() users: UserType[];
  @Input() view: string;

  // users: UserType[];

  hideAutoComments: boolean;
  showRemove = false;
  showRemoveFile: boolean[] = [];
  expandFile: boolean[] = [];
  selectFileInfo = false;
  saveInProgress = false;

  platforms = [
    {
      value: 'facebook',
      viewValue: 'Facebook',
      types: [
        {value: 'carousel', viewValue: 'Carousel'},
        {value: 'collection', viewValue: 'Collection'},
        {value: 'eventResponses', viewValue: 'Event responses'},
        {value: 'group', viewValue: 'Group'},
        {value: 'image', viewValue: 'Image'},
        {value: 'instantExperience', viewValue: 'Instant Experience'},
        {value: 'leadGenerationAds', viewValue: 'Lead generation ads'},
        {value: 'pageLikes', viewValue: 'Page likes'},
        {value: 'postEngagement', viewValue: 'Post Engagement'},
        {value: 'offers', viewValue: 'Offers'},
        {value: 'video', viewValue: 'Video'}
      ]
    },
    {
      value: 'twitter',
      viewValue: 'Twitter',
      types: [
        {value: 'generalTweets', viewValue: 'General Tweets'},
        {value: 'mentions', viewValue: 'Mentions'},
        {value: 'replies', viewValue: 'Replies'},
      ]
    },
    {
      value: 'youTube',
      viewValue: 'YouTube',
      types: [
        {value: 'image', viewValue: 'Image'},
        {value: 'playlist', viewValue: 'Playlist'},
        {value: 'polls', viewValue: 'Polls'},
        {value: 'text', viewValue: 'Text'},
        {value: 'video', viewValue: 'Video'}
      ]
    },
    {
      value: 'instagram',
      viewValue: 'Instagram',
      types: [
        {value: 'carousel', viewValue: 'Carousel'},
        {value: 'igtv', viewValue: 'IGTV'},
        {value: 'mappedGuided', viewValue: 'Mapped – Guided'},
        {value: 'photo', viewValue: 'Photo'},
        {value: 'sponsored', viewValue: 'Sponsored'},
        {value: 'story', viewValue: 'Story'},
        {value: 'video', viewValue: 'Video'},
      ]
    },
    {
      value: 'linkedIn', viewValue: 'LinkedIn',
      types: [
        {value: 'conversation', viewValue: 'Conversation'},
        {value: 'dynamic', viewValue: 'Dynamic'},
        {value: 'job', viewValue: 'Job'},
        {value: 'message', viewValue: 'Message'},
        {value: 'singleImage', viewValue: 'Single Image'},
        {value: 'text', viewValue: 'Text'},
        {value: 'video', viewValue: 'Video'},
      ]
    }
  ];

  types = [];

  options: UploaderOptions;
  files: UploadFile[] = [];
  uploadInput: EventEmitter<UploadInput>;
  dragOver: boolean;

  parentComponent: any;

  private subscriptions: Array<Subscription> = [];

  constructor(private postsService: PostsService,
              public injector: Injector,
              private usersService: UsersService,
              private brandsService: BrandsService,
              private notificationsService: NotificationsService,
              private afs: AngularFirestore,
              private progress: NgProgress,
              private snackBar: MatSnackBar) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.view !== 'dialog') {
      this.parentComponent = this.injector.get(BrandsComponent);
    } else {
      this.parentComponent = this.injector.get(SharedPostDialogComponent);
    }

    this.hideAutoComments = false;
    if (this.user.settings && this.user.settings.socialBrands_hideAutoComments) {
      this.hideAutoComments = this.user.settings.socialBrands_hideAutoComments;
    }

    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });

    // clone post to check if user changed post or only click and unclick the field
    this.post.datepicker = new Date(this.post.date.seconds * 1000);

    this.platforms.forEach(platform => {
      if (platform.value === this.post.platform) {
        platform.types.forEach(type => {
          if (type.value === this.post.type) {
            this.post.typeValue = type.viewValue;
          }
        });
      }
    });

    // set types
    this.platforms.forEach(platform => {
      if (platform.value === this.post.platform) {
        this.types = platform.types;
      }
    });

    // define select file
    if (this.post.shouldSelectFile) {
      this.selectFileInfo = true;
      this.post.files.forEach(file => {
        if (file.isSelected) {
          this.selectFileInfo = false;
        }
      });
    }

    // define platformView and typeView
    if (this.post.platform) {
      this.platforms.forEach(platform => {
        if (this.post.platform === platform.value) {
          this.post.platformView = platform.viewValue;
          if (this.post.type) {
            platform.types.forEach(type => {
              if (this.post.type === type.value) {
                this.post.typeView = type.viewValue;
              }
            });
          }
        }
      });
    }

    // get notification
    this.getNotification();

    // get brand
    // this.getBrand();

    // get user
    // this.getUser();

    // get users
    // this.getUsers();
  }

  onEdit() {
    this.parentComponent.postEdit = Object.assign({}, this.post);
    this.parentComponent.postEdit.date = Object.assign({}, this.post.date);
    this.parentComponent.postEdit.files = this.post.files.map(a => Object.assign({}, a));

    this.platforms.forEach(platform => {
      if (platform.value === this.parentComponent.postEdit.platform) {
        this.types = platform.types;
      }
    });
  }

  onSelectPlatform(selected) {
    this.platforms.forEach(platform => {
      if (platform.value === selected) {
        this.types = platform.types;
      }
    });
  }

  onUpdateStatus(status) {
    if (this.post.status !== status) {
      const fields = {status: status};
      const changeMessage = 'has changed status: ' + this.post.status.charAt(0).toUpperCase() + this.post.status.slice(1)
        + ' → ' + status.charAt(0).toUpperCase() + status.slice(1);
      const notification = '<p>has changed <a class="link" routerLink="brands/'
        + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
        + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a><code class="code">status: '
        + this.post.status.charAt(0).toUpperCase() + this.post.status.slice(1)
        + ' → ' + status.charAt(0).toUpperCase() + status.slice(1) + '</code></p>';
      this.post.status = status;
      this.save(fields, changeMessage, notification);
    }
  }

  onDelete() {
    this.post.isActive = false;
    const fields = {isActive: this.post.isActive};
    const changeMessage = 'has removed the post';
    const notification = '<p>has removed <a class="link" routerLink="brands/'
      + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
      + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a></p>';
    this.save(fields, changeMessage, notification);
  }

  onUndoDelete() {
    this.post.isActive = true;
    const fields = {isActive: this.post.isActive};
    const changeMessage = 'has withdrew the removal of the post';
    const notification = '<p>has withdrew the removal of <a class="link" routerLink="brands/'
      + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
      + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a></p>';
    this.save(fields, changeMessage, notification);
  }

  onSave() {
    let changeMessage = '';
    let notification = '';
    let update = false;
    const fields = {};

    this.saveInProgress = true;

    // date
    if (this.post.datepicker.getTime() !== this.parentComponent.postEdit.datepicker.getTime()) {

      update = true;
      fields['date'] = this.parentComponent.postEdit.date;

      const dateBefore = new Date(this.post.date.seconds * 1000);
      changeMessage = 'has changed date: ' + dateBefore.getFullYear() + '-'
        + ('0' + (dateBefore.getMonth() + 1)).slice(-2) + '-' + ('0' + dateBefore.getDate()).slice(-2) + ' → '
        + this.post.datepicker.getFullYear() + '-' + ('0' + (this.post.datepicker.getMonth() + 1)).slice(-2) + '-'
        + ('0' + this.post.datepicker.getDate()).slice(-2);
      fields['date'] = this.parentComponent.postEdit.datepicker;

      const month = ('0' + (this.parentComponent.postEdit.datepicker.getMonth() + 1)).slice(-2);
      const year = this.parentComponent.postEdit.datepicker.getFullYear();
      fields['showInMonth'] = year + '' + month;
      notification = '<p>has changed <a class="link" routerLink="brands/'
        + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
        + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a><code class="code">date: '
        + dateBefore.getFullYear() + '-'
        + ('0' + (dateBefore.getMonth() + 1)).slice(-2) + '-' + ('0' + dateBefore.getDate()).slice(-2) + ' → '
        + this.parentComponent.postEdit.datepicker.getFullYear() + '-' + ('0' + (this.parentComponent.postEdit.datepicker.getMonth() + 1)).slice(-2) + '-'
        + ('0' + this.parentComponent.postEdit.datepicker.getDate()).slice(-2) + '</code></p>';
    }

    // time
    if (this.post.time !== this.parentComponent.postEdit.time) {
      if (changeMessage) {
        changeMessage += ', time: ';
      } else {
        changeMessage = 'has changed time: ';
      }
      update = true;
      fields['time'] = this.parentComponent.postEdit.time;
      changeMessage += this.post.time + ' → ' + this.parentComponent.postEdit.time;
      notification += '<p>has changed <a class="link" routerLink="brands/'
        + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
        + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a><code class="code">time: ' + this.post.time + ' → ' + this.parentComponent.postEdit.time + '</code></p>';
    }

    // platform
    if (this.post.platform !== this.parentComponent.postEdit.platform) {
      if (changeMessage) {
        changeMessage += ', platform: ';
      } else {
        changeMessage = 'has changed platform: ';
      }
      update = true;

      fields['platform'] = this.parentComponent.postEdit.platform;
      let platformBefore = '';
      let platformAfter = '';
      this.platforms.forEach(platform => {
        if (platform.value === this.post.platform) {
          platformBefore = platform.viewValue;
        }
        if (platform.value === this.parentComponent.postEdit.platform) {
          platformAfter = platform.viewValue;
        }
      });
      changeMessage += platformBefore + ' → ' + platformAfter;
      notification += '<p>has changed <a class="link" routerLink="brands/'
        + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
        + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a><code class="code">platform: ' + platformBefore + ' → ' + platformAfter + '</code></p>';
    }

    // type
    if (this.post.type !== this.parentComponent.postEdit.type) {
      if (changeMessage) {
        changeMessage += ', type: ';
      } else {
        changeMessage = 'has changed type: ';
      }
      update = true;

      fields['type'] = this.parentComponent.postEdit.type;
      let typeBefore = '';
      let typeAfter = '';
      this.types.forEach(type => {
        if (type.value === this.post.type) {
          typeBefore = type.viewValue;
        }
        if (type.value === this.parentComponent.postEdit.type) {
          typeAfter = type.viewValue;
        }
      });
      changeMessage += typeBefore + ' → ' + typeAfter;
      notification += '<p>has changed <a class="link" routerLink="brands/'
        + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
        + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a><code class="code">type: ' + typeBefore + ' → ' + typeAfter + '</code></p>';
    }

    // content
    if (this.post.content !== this.parentComponent.postEdit.content) {
      if (changeMessage) {
        changeMessage += ', content: ';
      } else {
        changeMessage = 'has changed content: ';
      }
      update = true;
      fields['content'] = this.parentComponent.postEdit.content;
      changeMessage += this.post.content + ' → ' + this.parentComponent.postEdit.content;
      notification += '<p>has changed <a class="link" routerLink="brands/'
        + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
        + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a><code class="code">content: ' + this.post.content + ' → ' + this.parentComponent.postEdit.content + '</code></p>';
    }

    // image copy
    if (this.post.imageCopy !== this.parentComponent.postEdit.imageCopy) {
      if (changeMessage) {
        changeMessage += ', image copy: ';
      } else {
        changeMessage = 'has changed image copy: ';
      }
      update = true;
      fields['imageCopy'] = this.parentComponent.postEdit.imageCopy;
      changeMessage += this.post.imageCopy + ' → ' + this.parentComponent.postEdit.imageCopy;
      notification += '<p>has changed <a class="link" routerLink="brands/'
        + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
        + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a><code class="code">image copy: ' + this.post.imageCopy + ' → ' + this.parentComponent.postEdit.imageCopy + '</code></p>';
    }

    // image comments
    if (this.post.imageComments !== this.parentComponent.postEdit.imageComments) {
      if (changeMessage) {
        changeMessage += ', image comments: ';
      } else {
        changeMessage = 'has changed image comments: ';
      }
      update = true;
      fields['imageComments'] = this.parentComponent.postEdit.imageComments;
      changeMessage += this.post.imageComments + ' → ' + this.parentComponent.postEdit.imageComments;
      notification += '<p>has changed <a class="link" routerLink="brands/'
        + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
        + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a><code class="code">image comments: ' + this.post.imageComments + ' → ' + this.parentComponent.postEdit.imageComments + '</code></p>';
    }

    // file should be selected
    if (this.post.shouldSelectFile !== this.parentComponent.postEdit.shouldSelectFile) {
      if (this.parentComponent.postEdit.shouldSelectFile) {
        if (changeMessage) {
          changeMessage += ', file should be selected';
        } else {
          changeMessage += 'has changed that a file should be selected';
        }
        notification += '<p>has changed that a file should be selected in the <a class="link" routerLink="brands/'
          + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
          + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a></p>';
      } else {
        if (changeMessage) {
          changeMessage = ', file should not be selected';
        } else {
          changeMessage += 'has changed that a file should not be selected';
        }
        notification += '<p>has changed that a file should not be selected in the <a class="link" routerLink="brands/'
          + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
          + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a></p>';
      }
      update = true;
      fields['shouldSelectFile'] = this.parentComponent.postEdit.shouldSelectFile;
    }

    // files remove - check if some files has been remove
    const removed = [];
    for (const file of this.post.files) {
      for (const fileEdit of this.parentComponent.postEdit.files) {
        if ((file.url === fileEdit.url) && (file.isActive !== fileEdit.isActive)) {
          removed.push(file.name);
        }
      }
    }
    if (removed.length) {
      if (changeMessage) {
        changeMessage += ', removed file(s): ' + removed.join(', ');
      } else {
        changeMessage = 'has removed file(s): ' + removed.join(', ');
      }
      update = true;
      this.parentComponent.postEdit.files = this.post.files.map(a => Object.assign({}, a));
      fields['files'] = this.parentComponent.postEdit.files;
      notification += '<p>has removed in '
        + '<a class="link" routerLink="brands/'
        + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
        + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a><code class="code">file(s): ' + removed.join(', ')
        + '</code></p>';
    }

    // files upload
    if (this.files.length) {
      update = false; // stop update and wait for uploading the files
      let filesLength = this.files.length;
      const postEditFiles = this.parentComponent.postEdit.files.map(a => Object.assign({}, a));
      const added = [];
      // this.snackBar.open(`Uploading file(s)`, '', environment.snackbarInfo);
      for (const file of this.files) {
        // prepare file
        const fileNameArray = file.name.split('.');
        const name = this.afs.createId() + '.' + fileNameArray[fileNameArray.length - 1];
        const path = `/${this.user.companyRef.id}/brands/${this.post.brand.uid}/${name}`;
        const uploadTask = this.postsService.uploadFile(file.nativeFile, path, file.name);

        uploadTask.on('state_changed', (snapshot: UploadTaskSnapshot) => {
          file.progress.data.percentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 10000) / 100;
        }, (error) => {
          // Handle unsuccessful uploads
          this.snackBar.open(`Unsuccessful uploads`, '', environment.snackbarWarn);
        }, () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            // push file to array
            added.push(file.name);
            postEditFiles.push({
              name: file.name,
              url: downloadURL,
              type: file.type,
              size: file.size,
              isActive: true
            });
            filesLength--;
            if (filesLength === 0) {
              if (changeMessage) {
                changeMessage += ', added file(s): ' + added.join(', ');
              } else {
                changeMessage = 'has added file(s): ' + added.join(', ');
              }
              notification += '<p>has added in '
                + '<a class="link" routerLink="brands/'
                + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
                + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a><code class="code">file(s): '
                + added.join(', ') + '</code></p>';
              fields['files'] = postEditFiles;
              this.files = [];
              this.save(fields, changeMessage, notification);
            }
          });
        });
      }
    }

    // update without files
    if (update) {
      this.save(fields, changeMessage, notification);
    }

    // close without any update
    if (!update && !this.files.length) {
      this.parentComponent.postEdit = null;
      this.saveInProgress = false;
    }
  }

  save(fields, changeMessage, notification) {
    this.progress.ref().start();

    this.post.messages.push({
      uid: this.afs.createId(),
      message: changeMessage,
      dateCreate: new Date,
      userRef: this.afs.doc(`users/${this.user.uid}`).ref,
      files: [],
      type: 'auto'
    });
    fields['messages'] = this.post.messages;

    this.postsService.updatePost(fields, this.user, this.post.uid)
      .then(() => {
        this.post.datepicker = new Date(this.post.date.seconds * 1000);
        // inform follower users
        if (this.post.brand.followerRefs) {
          for (const informUser of Object.keys(this.post.brand.followerRefs)) {
            if (this.post.status !== 'hide') { // if post does not have status hide
              if (informUser !== this.user.uid) {
                this.saveNotification(
                  notification,
                  informUser,
                  this.post.uid
                );
              }
            } else { // if post does have status hide
              // get user to check if user can see hidden post
              this.usersService.onceUserActive(this.afs.doc(`users/${informUser}`).ref).then(infU => {
                if ((infU.data().uid !== this.user.uid) && ((infU.data()._privileges.canSeeHiddenPost) || (infU.data()._rolesName === 'admin'))) {
                  this.saveNotification(
                    notification,
                    informUser,
                    this.post.uid
                  );
                }
              });
            }
          }
        }
        this.parentComponent.postEdit = null;
        this.saveInProgress = false;
        this.progress.ref().complete();
        this.snackBar.open('Post has been updated', '', environment.snackbarSuccess);
      })
      .catch(err => {
        this.parentComponent.postEdit = null;
        this.saveInProgress = false;
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  onSelectFile(post, fileIndex) {
    const fields = {};
    post.files.forEach(file => {
      file.isSelected = false;
    });
    post.files[fileIndex].isSelected = true;
    fields['files'] = [...post.files];
    this.save(fields, 'has selected file: ' + post.files[fileIndex].name, '<p>has selected in '
      + '<a class="link" routerLink="brands/'
      + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
      + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a><code class="code">file: ' + post.files[fileIndex].name
      + '</code></p>');
  }

  onUnselectFile(post, fileIndex) {
    const fields = {};
    post.files[fileIndex].isSelected = false;
    fields['files'] = [...post.files];
    this.save(fields, 'has unselected file: ' + post.files[fileIndex].name, '<p>has unselected in '
      + '<a class="link" routerLink="brands/'
      + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
      + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a><code class="code">file: ' + post.files[fileIndex].name
      + '</code></p>');
  }

  onCopyLink() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = `${environment.url}/brands/${this.post.brand.uid}/post/${this.post.uid}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackBar.open('Link to the post has been copied', '', environment.snackbarInfo);
  }

  onSend(form: NgForm) {
    let message: MessageType;
    const notification = '<p>has commented in brand <a class="link" routerLink="brands/' + this.post.brandRef.id + '">'
      + this.post.brand.name + '</a> <a class="link" routerLink="brands/' + this.post.brandRef.id + '/post/' + this.post.uid
      + '">post</a><code class="code">' + form.value.messageText.replace(/<[^>]*>/g, '') + '</code></p>';
    message = {
      uid: this.afs.createId(),
      message: form.value.messageText,
      dateCreate: new Date(),
      userRef: this.afs.doc(`users/${this.user.uid}`).ref,
      files: [],
      type: 'user'
    };
    this.post.messages.push(message);
    // update post
    this.postsService.updatePost({messages: this.post.messages}, this.user, this.post.uid)
      .then(() => {
        this.snackBar.open('Message has been added', '', environment.snackbarSuccess);
        // inform follower users
        if (this.post.brand.followerRefs) {
          for (const informUser of Object.keys(this.post.brand.followerRefs)) {
            if (this.post.status !== 'hide') { // if post does not have status hide
              if (informUser !== this.user.uid) {
                this.saveNotification(
                  notification,
                  informUser,
                  this.post.uid
                );
              }
            } else { // if post does have status hide
              // get user to check if user can see hidden post
              this.usersService.onceUserActive(this.afs.doc(`users/${informUser}`).ref).then(infU => {
                if ((infU.data().uid !== this.user.uid) && ((infU.data()._privileges.canSeeHiddenPost) || (infU.data()._rolesName === 'admin'))) {
                  this.saveNotification(
                    notification,
                    informUser,
                    this.post.uid
                  );
                }
              });
            }
          }
        }
        this.progress.ref().complete();
      })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
    // reset form
    form.resetForm();
  }

  onUpload(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') { // when all files added in queue
      // this.startUpload();
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
      this.files.push(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      console.log('uploading');
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
      console.log(output.file);
    } else if (output.type === 'removed') {
      console.log('removed');
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
  }

  private getNotification() {
    // console.log('asd');
    // this.subscriptions.push(this.notificationsService.getNotificationsByElementUid(`companies/${this.user.companyRef.id}/notifications/`, this.user, [this.post.uid]).subscribe(notification => {
    //   console.log(notification);
    // }));
  }

  private getBrand() {
    // console.log('asd');
    // get brand once
    // this.brandsService.getBrandOnce(this.user, this.post.brandRef.id).then(brand => {
    //   console.log()
    //   this.post.brand = brand.data();
    // });

    this.subscriptions.push(this.brandsService.getBrand(this.user, this.post.brandRef.id).subscribe(brand => {
      this.post.brand = brand;
    }));
  }

  private saveNotification(message: string, userId: string, postUid: string) {
    const notification: NotificationType = {
      uid: this.afs.createId(),
      message: message,
      dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
      dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
      userAuthorRef: this.afs.doc(`users/${this.user.uid}`).ref,
      userInformedRef: this.afs.doc(`users/${userId}`).ref,
      elementUid: postUid,
      elementType: 'brandPost',
      view: 'info',
      isUnread: true
    };
    const subscriptionNotification = {};

    this.notificationsService.onceNotificationsToMerge(`companies/${this.user.companyRef.id}/notifications/`, notification)
      .then(notifications => {
        let exist = false;

        // merge notifcations
        notifications.forEach(notificationSnapshot => {
          exist = true;
          const not = notificationSnapshot.data();
          this.notificationsService.updateNotification({
            message: not.message + message,
            dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            userInformedRef: this.afs.doc(`users/${userId}`).ref,
          }, `companies/${this.user.companyRef.id}/notifications/${not.uid}`).then();
        });

        // insert new notification
        if (!exist) {
          this.notificationsService
            .insertNotification(notification, `companies/${this.user.companyRef.id}/notifications/${notification.uid}`)
            .then(() => {
            })
            .catch(err => {
              this.snackBar.open(err.message, '', environment.snackbarWarn);
            });
        }
      });
  }

  ngOnDestroy() {
    console.log('destroy');
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  // private getUsers() {
  //   this.subscriptions.push(this.usersService.getUsers(this.user.companyRef).subscribe(users => {
  //     this.users = users;
  //   }));
  // }

  // private getUser() {
  //   this.usersService.getUser().subscribe(user => {
  //     this.hideAutoComments = false;
  //     if (this.user.settings && this.user.settings.socialBrands_hideAutoComments) {
  //       this.hideAutoComments = user.settings.socialBrands_hideAutoComments;
  //     }
  //   });
  // }

  // onUpdateTime() {
  //   if ((/^([0-1]\d|20|21|22|23):[0-5]\d$/.test(this.post.time)) || (this.post.time === '')) {
  //     this.onUpdate('time');
  //   } else {
  //     this.post.time = this.postClone.time;
  //   }
  // }

  // startUpload(): void {
  // check how many files are uploading
  // let filesLength = this.files.length;
  // const names = [];
  // start progress bar
  // this.progress.ref().start();
  // show snackbar
  // this.snackBar.open(`Uploading file(s)`, '', environment.snackbarInfo);
  // for (const file of this.files) {
  //   // prepare file
  //   const fileNameArray = file.name.split('.');
  //   const name = this.afs.createId() + '.' + fileNameArray[fileNameArray.length - 1];
  //   const path = `/${this.user.companyRef.id}/brands/${this.post.brand.uid}/${name}`;
  //   const uploadTask = this.postsService.uploadFile(file.nativeFile, path, file.name);
  //
  //   uploadTask.on('state_changed', function (snapshot: UploadTaskSnapshot) {
  //     console.log(snapshot);
  //     file.progress.data.percentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 10000) / 100;
  //   }, function (error) {
  //     // Handle unsuccessful uploads
  //     this.snackBar.open(`Unsuccessful uploads`, '', environment.snackbarWarn);
  //   }, function () {
  //     // Handle successful uploads on complete
  //     // For instance, get the download URL: https://firebasestorage.googleapis.com/...
  //     uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
  //       console.log('File available at', downloadURL);
  //       // push file to array
  //       names.push(file.name);
  //       this.post.files.push({
  //         name: file.name,
  //         url: downloadURL,
  //         type: file.type,
  //         size: file.size,
  //         isActive: true
  //       });
  //       filesLength--;
  //       if (filesLength === 0) {
  //
  //
  //         this.onUpdate('files', 'has added file(s): ' + names.join(', '), '<p>has added in '
  //           + '<a class="link" routerLink="brands/'
  //           + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
  //           + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a><code class="code">file(s): '
  //           + names.join(', ') + '</code></p>');
  //       }
  //     });
  //   });

  // this.postsService.uploadFile(file.nativeFile, path, file.name).then(snapshot => {
  //   // push file to message
  //   snapshot.ref.getDownloadURL().then(downloadURL => {
  //     // push file to array
  //     names.push(file.name);
  //     this.post.files.push({
  //       name: file.name,
  //       url: downloadURL,
  //       type: file.type,
  //       size: file.size,
  //       isActive: true
  //     });
  //     filesLength--;
  //     if (filesLength === 0) {
  //       this.onUpdate('files', 'has added file(s): ' + names.join(', '), '<p>has added in '
  //         + '<a class="link" routerLink="brands/'
  //         + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
  //         + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a><code class="code">file(s): '
  //         + names.join(', ') + '</code></p>');
  //     }
  //   });
  // });
  // }
  // clear files
  // this.files = [];
  // }

  // onUpdate(field, fileMessage = '', fileNotification = '') {
  //
  //   // find clone of the changing post to show changes
  //   let isChanged = false;
  //
  //   // check if simple field has been changed
  //   if (this.post[field] !== this.postClone[field]) {
  //     isChanged = true;
  //     this.progress.ref().start();
  //   }
  //
  //   // if nothing changed
  //   if (!isChanged) {
  //     return;
  //   }
  //
  //   // define update field
  //   const fields = {};
  //   fields[field] = this.post[field];
  //
  //   // define change message
  //   let changeMessage = 'has changed ' + field + ': ' + this.postClone[field] + ' → ' + this.post[field];
  //
  //   // define notification message
  //   let notification = '<p>has changed <a class="link" routerLink="brands/'
  //     + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
  //     + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a><code class="code">' + field +
  //     ': ' + this.postClone[field] + ' → ' + this.post[field] + '</code></p>';
  //   switch (field) {
  //     case 'isActive':
  //       if (!this.post.isActive) {
  //         changeMessage = 'has removed the post';
  //         notification = '<p>has removed <a class="link" routerLink="brands/'
  //           + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
  //           + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a></p>';
  //       } else {
  //         changeMessage = 'has withdrew the removal of the post';
  //         notification = '<p>has withdrew the removal of <a class="link" routerLink="brands/'
  //           + this.post.brandRef.id + '">' + this.post.brand.name + '</a> <a class="link" routerLink="brands/'
  //           + this.post.brandRef.id + '/post/' + this.post.uid + '">post</a></p>';
  //       }
  //       break;
  //     case 'files':
  //       changeMessage = fileMessage;
  //       notification = fileNotification;
  //       break;
  //     case 'fileIsSelected':
  //       changeMessage = fileMessage;
  //       notification = fileNotification;
  //       break;
  //   }
  //
  //   // add to update message field
  //   this.post.messages.push({
  //     uid: this.afs.createId(),
  //     message: changeMessage,
  //     dateCreate: new Date,
  //     userRef: this.afs.doc(`users/${this.user.uid}`).ref,
  //     files: [],
  //     type: 'auto'
  //   });
  //   fields['messages'] = this.post.messages;
  //
  //   // update post
  //   this.postsService.updatePost(fields, this.user, this.post.uid)
  //     .then(() => {
  //       this.progress.ref().complete();
  //       this.snackBar.open('Post has been updated', '', environment.snackbarSuccess);
  //       this.post.datepicker = new Date(this.post.date.seconds * 1000);
  //       // clone post
  //       this.postClone = Object.assign({}, this.post);
  //       this.postClone.date = Object.assign({}, this.post.date);
  //       this.postClone.files = Object.assign({}, this.post.files);
  //       // inform follower users
  //       if (this.post.brand.followerRefs) {
  //         for (const informUser of Object.keys(this.post.brand.followerRefs)) {
  //           if (this.post.status !== 'hide') { // if post does not have status hide
  //             if (informUser !== this.user.uid) {
  //               this.saveNotification(
  //                 notification,
  //                 informUser,
  //                 this.post.uid
  //               );
  //             }
  //           } else { // if post does have status hide
  //             // get user to check if user can see hidden post
  //             this.usersService.onceUserActive(this.afs.doc(`users/${informUser}`).ref).then(infU => {
  //               if ((infU.data().uid !== this.user.uid) && ((infU.data()._privileges.canSeeHiddenPost) || (infU.data()._rolesName === 'admin'))) {
  //                 this.saveNotification(
  //                   notification,
  //                   informUser,
  //                   this.post.uid
  //                 );
  //               }
  //             });
  //           }
  //         }
  //       }
  //     })
  //     .catch(err => {
  //       this.progress.ref().complete();
  //       this.snackBar.open(err.message, '', environment.snackbarWarn);
  //     });
  // }

}
