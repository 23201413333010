import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {Observable} from 'rxjs/internal/Observable';
import {RoleType} from '../../_types/role.type';
import {RolesService} from '../../_services/roles.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserType} from '../../_types/user.type';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit, OnDestroy {

  uid: string | any;
  title: string | any;
  navLinks: any[] | any;
  searchRole: string | any;
  user: UserType;

  roles$: Observable<RoleType[]>;

  showFullPage = false;

  private subscriptions: Array<Subscription> = [];

  constructor(private rolesService: RolesService,
              private route: ActivatedRoute,
              private router: Router) {

    // set menu and title
    this.subscriptions.push(router.events.subscribe((val) => {
      this.showFullPage = false;
      switch (this.router.url) {
        case '/roles/role-add': {
          this.title = 'Add role';
          break;
        }

        case '/roles': {
          this.title = '';
          this.navLinks = [];
          this.showFullPage = true;
          break;
        }

        default: {
        }

      }
    }));

    // set user
    // @ts-ignore
    this.user = this.route.snapshot.parent.data.user;

    // get roles
    this.roles$ = rolesService.getRoles(this.user);

  }

  setNav(uid: any): void {
    this.uid = uid;
    this.navLinks = [
      {
        path: uid + '/privileges',
        label: 'Privileges'
      },
      {
        path: uid + '/details',
        label: 'Details'
      }
    ];
  }

  removeNav(): void {
    this.navLinks = [];
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
