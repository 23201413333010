import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {ClientsService} from '../../_services/clients.service';
import {Observable} from 'rxjs/internal/Observable';
import {ClientType} from '../../_types/client.type';
import {ActivatedRoute, Router} from '@angular/router';
import {UserType} from '../../_types/user.type';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit, OnDestroy {

  uid: string;
  title: string;
  clients$: Observable<ClientType[]>;
  searchClient: string;
  navLinks: { path: string, label: string }[] = [];
  user: UserType;

  showFullPage = false;

  private subscriptions: Array<Subscription> = [];

  constructor(private clientsService: ClientsService,
              private route: ActivatedRoute,
              private router: Router) {

    // set menu and title
    this.subscriptions.push(router.events.subscribe(() => {
      this.showFullPage = false;
      switch (this.router.url) {
        case '/clients/client-add': {
          this.title = 'Add client';
          break;
        }

        case '/clients': {
          this.title = '';
          this.navLinks = [];
          this.showFullPage = true;
          break;
        }

      }
    }));

    // set user
    this.user = this.route.snapshot.parent.data.user;

    // get clients
    this.clients$ = clientsService.getClients(this.user);

  }

  setNav(uid) {
    this.uid = uid;
    this.navLinks = [
      {
        path: uid + '/details',
        label: 'Details'
      }
    ];
  }

  removeNav() {
    this.navLinks = [];
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
