<section class="page-section">
    <form (ngSubmit)="onSave(f)" #f="ngForm" autocomplete="off">
        <div class="row">
            <div class="col-4">
                <mat-card>
                    <mat-card-content>
                        <div class="col-header">Details</div>
                        <mat-form-field>
                            <input matInput
                                   type="text"
                                   name="name"
                                   #name="ngModel"
                                   ngModel
                                   required
                                   placeholder="Name">
                            <mat-error *ngIf="name.hasError('required')">
                                Name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <input matInput
                                   type="text"
                                   placeholder="Client"
                                   name="client"
                                   [(ngModel)]="client"
                                   appForbiddenType="string"
                                   (blur)="autocompleteBlur()"
                                   required
                                   [matAutocomplete]="autoClients">
                            <mat-autocomplete
                                    autoActiveFirstOption
                                    #autoClients="matAutocomplete"
                                    [displayWith]="autocompleteDisplay"
                                    (optionSelected)="autocompleteSelected($event.option.value)">
                                <mat-option *ngFor="let client of clients | filter:client:['name']" [value]="client">
                                    {{client.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error>
                                Client is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <input matInput
                                   type="text"
                                   name="shortDesc"
                                   #shortDesc="ngModel"
                                   ngModel
                                   placeholder="Short description">
                        </mat-form-field>

                        <mat-form-field>
                            <textarea matInput
                                      name="notes"
                                      #notes="ngModel"
                                      ngModel
                                      matTextareaAutosize
                                      placeholder="Notes"></textarea>
                        </mat-form-field>

                        <button type="submit"
                                mat-raised-button
                                color="primary"
                                [disabled]="f.form.invalid">Save
                        </button>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </form>
</section>
