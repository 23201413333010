import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {CompanyType} from '../_types/company.type';
import {Observable} from 'rxjs/internal/Observable';
import {UserType} from '../_types/user.type';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  companiesCollection: AngularFirestoreCollection<CompanyType>;
  companies: Observable<CompanyType[]>;

  constructor(private afs: AngularFirestore) {
    this.companiesCollection = afs.collection<CompanyType>('companies');
    this.companies = this.companiesCollection.valueChanges();
  }

  getCompany(path: string): any {
    return this.afs.doc<CompanyType>(path).valueChanges();
  }

  // insertCompany(company: CompanyType) {
  //   const companyRef: AngularFirestoreDocument<any> = this.afs.doc(`companies/${company.uid}`);
  //   return companyRef.set(company);
  // }

  updateCompany(fields: {}, user: UserType): any {
    return this.afs.doc<CompanyType>(`companies/${user.companyRef.id}`).update(fields);
  }
}
