<section class="page-section">
  <div class="page-section-header">
    <mat-form-field>
      <input matInput placeholder="Search" type="search" [(ngModel)]="searchUser">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>

  <ng-container *ngFor="let user of users | filter:searchUser:['name','_rolesName']">
    <mat-card class="mat-card--user">
      <a routerLink="/users/{{ user.uid }}" class="mat-card-link">
        <mat-card-header>
          <div mat-card-avatar
               *ngIf="user.photoURL"
               [ngStyle]="{'background-image': 'url(' + user.photoURL + ')'}"></div>
          <div mat-card-avatar
               *ngIf="!user.photoURL">{{ user.name | avatar }}
          </div>
          <mat-card-title>{{ user.name }}<br>
            <small>{{ user._rolesName }}</small>
          </mat-card-title>
        </mat-card-header>
      </a>
    </mat-card>
  </ng-container>
</section>

