import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserType} from '../../../_types/user.type';
import {BrandType} from '../../../_types/brand.type';
import {RoleType} from '../../../_types/role.type';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {UsersService} from '../../../_services/users.service';
import {RolesService} from '../../../_services/roles.service';
import {BrandsComponent} from '../brands.component';
import {MessagesService} from '../../../_services/messages.service';
import {BrandsService} from '../../../_services/brands.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {environment} from '../../../../environments/environment';
import * as firebase from 'firebase/app';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-brand-followers',
    templateUrl: './brand-followers.component.html',
    styleUrls: ['./brand-followers.component.scss']
})
export class BrandFollowersComponent implements OnInit, OnDestroy {

    userCurrent: UserType;
    brand: BrandType;
    users: UserType[] = [];
    roles: RoleType[] = [];

    usersComplete = false;
    brandComplete = false;

    searchUser: string;

    private subscriptions: Array<Subscription> = [];

    constructor(private route: ActivatedRoute,
                private usersService: UsersService,
                private rolesService: RolesService,
                private brandsComponent: BrandsComponent,
                private messagesService: MessagesService,
                private brandsService: BrandsService,
                private afs: AngularFirestore,
                private progress: NgProgress,
                private snackBar: MatSnackBar) {

        // set params
        this.subscriptions.push(this.route.params.subscribe(params => {
            this.brandsComponent.setNav(params.uid);

            // set user
            this.userCurrent = this.route.snapshot.parent.parent.data.user;

            // get brand
            this.getBrand();

            // get users
            this.getUsers();

        }));
    }

    ngOnInit() {
    }

    private getBrand() {
        this.subscriptions.push(this.brandsService.getBrand(this.userCurrent, this.brandsComponent.uid).subscribe(brand => {
            this.brand = brand;
            this.brandsComponent.title = this.brand.name;
            this.brandComplete = true;
            this.setFollowers();
        }));
    }

    private getUsers() {
        this.subscriptions.push(this.usersService.getUsersActive(this.userCurrent.companyRef).subscribe(users => {
            this.users = users;
            this.usersComplete = true;
            this.setFollowers();
        }));
    }

    private setFollowers() {
        if (this.brandComplete && this.usersComplete) {
            this.users.forEach(user => {
                user.isFollower = false;
                this.userCurrent.isFollower = false;
                if (this.brand.followerRefs) {
                  for (const followerUid of Object.keys(this.brand.followerRefs)) {
                    if (user.uid === followerUid) {
                      user.isFollower = true;
                    }
                    if (this.userCurrent.uid === followerUid) {
                      this.userCurrent.isFollower = true;
                    }
                  }
                }
            });
        }
    }


    onUnfollow() {
        this.progress.ref().start();

        // define change
        const change = {
            uid: this.afs.createId(),
            message: `unfollow changes`,
            dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
            userRef: this.afs.doc(`users/${this.userCurrent.uid}`).ref,
            files: [],
            type: 'auto'
        };

        // delete follower from object
        delete this.brand.followerRefs[this.userCurrent.uid];

        // update database and insert change message
        this.brandsService.updateBrand({followerRefs: this.brand.followerRefs}, this.userCurrent, this.brand.uid)
            .then(() => {
                this.messagesService.insertMessage(change, `companies/${this.userCurrent.companyRef.id}/brands/${this.brand.uid}/changes/${change.uid}`)
                    .then(() => {
                        this.snackBar.open('Follower has benn removed', '', environment.snackbarSuccess);
                        this.progress.ref().complete();
                    })
                    .catch(err => {
                        this.snackBar.open(err.message, '', environment.snackbarWarn);
                        this.progress.ref().complete();
                    });
            })
            .catch(err => {
                this.snackBar.open(err.message, '', environment.snackbarWarn);
            });
    }

    onFollow() {
        this.progress.ref().start();

        // define change
        const change = {
            uid: this.afs.createId(),
            message: `follow changes`,
            dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
            userRef: this.afs.doc(`users/${this.userCurrent.uid}`).ref,
            files: [],
            type: 'auto'
        };

        // add object to followers
        if (!this.brand.followerRefs) {
            this.brand.followerRefs = {};
        }
        this.brand.followerRefs[this.userCurrent.uid] = this.afs.doc(`users/${this.userCurrent.uid}`).ref;

        // update database and insert change message
        this.brandsService.updateBrand({followerRefs: this.brand.followerRefs}, this.userCurrent, this.brand.uid)
            .then(() => {
                this.messagesService.insertMessage(change, `companies/${this.userCurrent.companyRef.id}/brands/${this.brand.uid}/changes/${change.uid}`)
                    .then(() => {
                        this.snackBar.open('Follower has benn added', '', environment.snackbarSuccess);
                        this.progress.ref().complete();
                    })
                    .catch(err => {
                        this.snackBar.open(err.message, '', environment.snackbarWarn);
                        this.progress.ref().complete();
                    });
            })
            .catch(err => {
                this.snackBar.open(err.message, '', environment.snackbarWarn);
                this.progress.ref().complete();
            });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
