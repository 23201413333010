import {Injectable} from '@angular/core';
import {ChatType} from '../_types/chat.type';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {MessageType} from '../_types/message.type';

import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import * as firebase from 'firebase/app';
import {ClientType} from '../_types/client.type';
import {UserType} from '../_types/user.type';
import OrderByDirection = firebase.firestore.OrderByDirection;

@Injectable({
    providedIn: 'root'
})
export class ChatsService {

    constructor(private afAuth: AngularFireAuth,
                private afs: AngularFirestore, ) {
    }

    // get chats
    getChats(user: UserType): Observable<ChatType[]> {
        return this.afs.collection<ChatType>(`companies/${user.companyRef.id}/chats`, ref => ref
            .where('isActive', '==', true)
            .orderBy('name'))
            .valueChanges()
            .pipe(map(chats => {
                // check access to chat
                for (const chat of chats) {
                    chat.access = this.checkAccessToChat(chat, (this.afAuth).currentUser);
                }
                return chats;
            }));
    }

    // check access to chat
    checkAccessToChat(chat: ChatType, user: any): boolean {
        let access = false;
        if (chat) {
            for (const userRef of chat.userRefs) {
                if (userRef.id === user.uid) {
                    access = true;
                }
            }
        }
        return access;
    }

    // get chat
    getChat(path: string): Observable<ChatType | undefined> {
        return this.afs.doc<ChatType>(path).valueChanges();
    }

    // is exists - check duplicates
    isExists(path: string, name: any): Observable<ChatType[]> {
        return this.afs.collection<ChatType>(path, ref => ref
            .where('isActive', '==', true)
            .where('name', '==', name))
            .valueChanges();
    }

    // get chat messages
    getMessages(path: string, limit: number = 100, order: OrderByDirection = 'asc'): Observable<MessageType[]> {
        return this.afs.collection<MessageType>(path, ref => ref
            .orderBy('dateCreate', order)
            .limit(limit))
            .valueChanges();
    }

    // uppload file
    uploadFile(file: any, path: string): any {
        const storageRef = firebase.storage().ref();
        return storageRef.child(path).put(file);
    }

    // insert message
    insertMessage(message: MessageType, path: string): Promise<void> {
        return this.afs.doc<MessageType>(path).set(message);
    }

    // insert chat
    insertChat(chat: ChatType, path: string): Promise<void> {
        return this.afs.doc<ChatType>(path).set(chat);
    }

    // delete chat
    deleteChat(path: string): Promise<void> {
        return this.afs.doc<ChatType>(path).delete();
    }
}
