import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChatsComponent} from '../chats.component';
import {NgForm} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import * as firebase from 'firebase/app';
import {ChatsService} from '../../../_services/chats.service';
import {UserType} from '../../../_types/user.type';
import {Subscription} from 'rxjs';
import {ChatType} from '../../../_types/chat.type';
import {UsersService} from '../../../_services/users.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-chat-add',
    templateUrl: './chat-add.component.html',
    styleUrls: ['./chat-add.component.scss']
})
export class ChatAddComponent implements OnInit, OnDestroy {

    user: UserType;

    private subscriptions: Array<Subscription> = [];

    constructor(private chatsComponent: ChatsComponent,
                private usersService: UsersService,
                private chatsServices: ChatsService,
                private afs: AngularFirestore,
                private progress: NgProgress,
                private snackBar: MatSnackBar) {

        // get user
        // this.subscriptions.push(this.usersService.user$.subscribe(user => {
        //     if (user) {
        //         this.user = user;
        //     }
        // }))

        // set title
        this.chatsComponent.title = 'Add chat';
    }

    ngOnInit() {
    }

    onSave(form: NgForm) {
            this.progress.ref().start();

            // check duplicate
            this.subscriptions.push(this.chatsServices.isExists(`companies/${this.user.companyRef.id}/chats/`, form.value.name).subscribe(chats => {
                if (chats.length) {
                    this.progress.ref().complete();
                    this.snackBar.open('Chat already exists', '', environment.snackbarWarn);
                } else {
                    // insert chat
                    const chat: ChatType = {
                        uid: this.afs.createId(),
                        name: form.value.name,
                        shortDesc: form.value.shortDesc,
                        userRefs: [this.afs.doc(`users/${this.user.uid}`).ref],
                        dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
                        userUpdateRef: this.afs.doc(`users/${this.user.uid}`).ref,
                        isActive: true
                    };

                    this.chatsServices.insertChat(chat, `companies/${this.user.companyRef.id}/chats/${chat.uid}`)
                        .then(() => {
                            this.progress.ref().complete();
                            this.snackBar.open('Chat has been added', '', environment.snackbarSuccess);
                        }).catch(err => {
                        this.progress.ref().complete();
                        this.snackBar.open(err.message, '', environment.snackbarWarn);
                    });
                    form.resetForm();
                }
                this.subscriptions[this.subscriptions.length - 1].unsubscribe();
            }));

    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
