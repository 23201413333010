<ng-container *ngIf="user && users.length">
  <mat-form-field>
    <input matInput placeholder="Search" type="search" autocomplete="off" [(ngModel)]="searchUser">
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>

  <mat-nav-list>
    <!-- first show current user -->
    <div class="mat-list-items">
      <a mat-list-item
         *ngIf="user && (user._rolesName === 'admin' || user._privileges.canGetUser)"
         class="me"
         routerLinkActive="active"
         routerLink="/users/{{ user.uid }}">
        <div matListAvatar
             *ngIf="user.photoURL"
             [ngStyle]="{'background-image': 'url(' + user.photoURL + ')'}"></div>
        <div matListAvatar
             *ngIf="!user.photoURL">
          {{ user.name | avatar }}
        </div>
        <h3 matLine>
          {{user.name}}<br>
          <small>{{user._rolesName}}</small>
        </h3>
      </a>

      <a mat-list-item
         *ngIf="user && (user._rolesName !== 'admin' && !user._privileges.canGetUser)"
         class="me"
         routerLinkActive="active"
         routerLink="/profile/settings">
        <div matListAvatar
             *ngIf="user.photoURL"
             [ngStyle]="{'background-image': 'url(' + user.photoURL + ')'}"></div>
        <div matListAvatar
             *ngIf="!user.photoURL">
          {{ user.name | avatar }}
        </div>
        <h3 matLine>
          {{user.name}}<br>
          <small>{{user._rolesName}}</small>
        </h3>
      </a>
    </div>


    <div @list>

      <!-- favorite users -->
      <div *ngFor="let u of users | filter:searchUser:['name','_rolesName']" class="mat-list-items" @items>
        <mat-list-item
          *ngIf="user && (u.uid !== user.uid) && user.settingsData && user.settingsData.users_favorites && user.settingsData.users_favorites[u.uid]"
          mat-list-item
          class="mat-list-item--favorite"
          routerLinkActive="active">
          <a class="mat-list-item-link" routerLink="/users/{{ u.uid }}"></a>
          <div class="favorite">
              <span
                *ngIf="!user.settingsData || !user.settingsData.users_favorites || !user.settingsData.users_favorites[u.uid]"
                class="favorite-outline material-icons"
                (click)="onAddFavorite(u.uid)">star_outline</span>
            <span
              *ngIf="user.settingsData && user.settingsData.users_favorites && user.settingsData.users_favorites[u.uid]"
              class="favorite-star material-icons"
              (click)="onRemoveFavorite(u.uid)">star</span>
          </div>
          <div matListAvatar
               *ngIf="u.photoURL"
               [ngStyle]="{'background-image': 'url(' + u.photoURL + ')'}"></div>
          <div matListAvatar
               *ngIf="!u.photoURL">
            {{ u.name | avatar }}
          </div>
          <h3 matLine>
            {{u.name}}<br>
            <small>{{u._rolesName}}</small>
          </h3>
        </mat-list-item>
      </div>
      <!-- /favorite users -->

      <!-- rest users -->
      <div *ngFor="let u of users | filter:searchUser:['name','_rolesName']" class="mat-list-items" @items>
        <mat-list-item
          *ngIf="user && (u.uid !== user.uid) && (!user.settingsData || !user.settingsData.users_favorites || !user.settingsData.users_favorites[u.uid])"
          mat-list-item
          routerLinkActive="active">
          <a class="mat-list-item-link" routerLink="/users/{{ u.uid }}"></a>
          <div class="favorite">
              <span
                *ngIf="!user.settingsData || !user.settingsData.users_favorites || !user.settingsData.users_favorites[u.uid]"
                class="favorite-outline material-icons"
                (click)="onAddFavorite(u.uid)">star_outline</span>
            <span
              *ngIf="user.settingsData && user.settingsData.users_favorites && user.settingsData.users_favorites[u.uid]"
              class="favorite-star material-icons"
              (click)="onRemoveFavorite(u.uid)">star</span>
          </div>
          <div matListAvatar
               *ngIf="u.photoURL"
               [ngStyle]="{'background-image': 'url(' + u.photoURL + ')'}"></div>
          <div matListAvatar
               *ngIf="!u.photoURL">
            {{ u.name | avatar }}
          </div>
          <h3 matLine>
            {{u.name}}<br>
            <small>{{u._rolesName}}</small>
          </h3>
        </mat-list-item>
      </div>
      <!-- /rest users -->

    </div>
    <div *ngIf="users.length < 2 && user && user._privileges.canGetUser" class="list-info"><i class="material-icons">info</i>
      <p><small>New users should register using e-mail in the company domain</small></p></div>
  </mat-nav-list>


</ng-container>
<ng-template *ngIf="!users.length">
  <div class="spinner">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</ng-template>

<div class="container-btm" *ngIf="user && (user._privileges.canCreateUser || user._rolesName === 'admin'); else btnShowUsers">
  <a routerLink="/users/user-invite" mat-stroked-button color="primary">Invite external<br>user</a>
</div>

<ng-template #btnShowUsers>
  <div class="container-btm">
    <a routerLink="/users/user-list" mat-stroked-button color="primary">Show users</a>
  </div>
</ng-template>

