<section class="page-section">
  <form (ngSubmit)="onSave()" #f="ngForm" autocomplete="off" *ngIf="project">
    <mat-card>
      <mat-card-content *ngIf="user._privileges.canUpdateProject || user._rolesName === 'admin'; else info">

        <div class="row" *ngIf="project.isActive === false">
          <div class="col">
            <div class="section--undoRemove">
              This project has been archived.
              <button mat-stroked-button class="mat-white" (click)="onUndoRemove()" type="button">Undo archive</button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="col-header">Details</div>
            <mat-form-field>
              <input matInput
                     type="text"
                     name="name"
                     #name="ngModel"
                     [(ngModel)]="project.name"
                     required
                     placeholder="Name">
              <mat-error *ngIf="name.hasError('required')">
                Name is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <input matInput
                     type="text"
                     name="client"
                     (blur)="autocompleteBlur()"
                     [(ngModel)]="client"
                     appForbiddenType="string"
                     required
                     placeholder="Client"
                     [matAutocomplete]="autoClients">
              <mat-autocomplete
                autoActiveFirstOption
                #autoClients="matAutocomplete"
                [displayWith]="autocompleteDisplay"
                (optionSelected)="autocompleteSelected($event.option.value)">
                <mat-option *ngFor="let client of clients | filter:client:['name']" [value]="client">
                  {{client.name}}
                </mat-option>
              </mat-autocomplete>
              <mat-error>
                Client is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <input matInput
                     type="text"
                     name="shortDesc"
                     #shortDesc="ngModel"
                     [(ngModel)]="project.shortDesc"
                     placeholder="Short description">
            </mat-form-field>

            <mat-form-field>
                            <textarea matInput
                                      name="notes"
                                      #notes="ngModel"
                                      [(ngModel)]="project.notes"
                                      matTextareaAutosize
                                      placeholder="Notes"></textarea>
            </mat-form-field>
          </div>

          <div class="col-3" *ngIf="roles">
            <div class="col-header">Estimations</div>
            <mat-form-field *ngFor="let role of roles">
              <input matInput
                     type="number"
                     name="estimation{{[role.uid]}}"
                     #estimation{{[role.uid]}}="ngModel"
                     [(ngModel)]="project.estimations[role.uid]"
                     min="0"
                     placeholder="{{ role.name }} estimation (h)">
            </mat-form-field>
          </div>

          <div class="col-3">
            <div class="col-header">Deadline</div>
            <mat-form-field>
              <input name="deadline"
                     matInput
                     [matDatepicker]="picker"
                     placeholder="Choose a date"
                     [(ngModel)]="project.deadline"
                     #deadline="ngModel"
                     readonly>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-3">
            <div class="col-header">Groups</div>
            <mat-chip-list *ngIf="project.groups">
              <mat-chip *ngFor="let group of project.groups">{{ group.name }}<mat-icon matChipRemove (click)="onRemoveGroup(group.name)">cancel</mat-icon></mat-chip>
            </mat-chip-list>
          </div>
        </div>

        <button type="submit"
                mat-raised-button color="primary"
                [disabled]="f.form.invalid">Save
        </button>
        <span *ngIf="project.isActive">
                    <a (click)="showRemove = !showRemove" class="link link--btn" *ngIf="!showRemove">Archive</a>
                    <span *ngIf="showRemove" class="link--btn">Are you sure? <a (click)="showRemove = false"
                                                                                class="link">No</a>&nbsp;<a
                      (click)="onRemove()" class="link">Yes</a></span>
                </span>
      </mat-card-content>

      <ng-template #info>
        <mat-card-content>
          <div class="row">
            <div class="col-4">
              <div class="col-header">Details</div>
              <mat-form-field>
                <input matInput
                       type="text"
                       name="name"
                       #name="ngModel"
                       [(ngModel)]="project.name"
                       readonly
                       placeholder="Name">
              </mat-form-field>

              <mat-form-field>
                <input matInput
                       type="text"
                       name="client"
                       [(ngModel)]="client"
                       readonly
                       placeholder="Client"
                       [matAutocomplete]="autoClients">
                <mat-autocomplete autoActiveFirstOption #autoClients="matAutocomplete"
                                  [displayWith]="autocompleteDisplay">
                  <mat-option *ngFor="let client of clients | filter:client:['name']" [value]="client">
                    {{client.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field>
                <input matInput
                       type="text"
                       name="shortDesc"
                       readonly
                       [(ngModel)]="project.shortDesc"
                       placeholder="Short description">
              </mat-form-field>

              <mat-form-field>
                            <textarea matInput
                                      name="notes"
                                      readonly
                                      [(ngModel)]="project.notes"
                                      matTextareaAutosize
                                      placeholder="Notes"></textarea>
              </mat-form-field>
            </div>

            <div class="col-4" *ngIf="roles">
              <div class="col-header">Estimations</div>
              <mat-form-field *ngFor="let role of roles">
                <input matInput
                       type="number"
                       name="estimation{{[role.uid]}}"
                       readonly
                       [(ngModel)]="project.estimations[role.uid]"
                       placeholder="{{ role.name }} estimation (h)">
              </mat-form-field>
            </div>

            <div class="col-4">
              <div class="col-header">Deadline</div>
              <mat-form-field>
                <input name="deadline"
                       matInput
                       [matDatepicker]="picker"
                       placeholder="Date"
                       [(ngModel)]="project.deadline"
                       #deadline="ngModel"
                       readonly>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </ng-template>
    </mat-card>
  </form>
</section>
