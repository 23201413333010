<section class="page-section">
    <div class="page-section-header">
        <mat-form-field>
            <input matInput placeholder="Search" type="search" [(ngModel)]="searchUser">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
    </div>
    <ng-container *ngFor="let user of users | filter:searchUser:['name','_rolesName']">
        <mat-card class="mat-card--user" *ngIf="user.isActive">
            <a routerLink="/users/{{ user.uid }}" class="mat-card-link">
                <mat-card-header>
                    <div mat-card-avatar
                         *ngIf="user.photoURL"
                         [ngStyle]="{'background-image': 'url(' + user.photoURL + ')'}"></div>
                    <div mat-card-avatar
                         *ngIf="!user.photoURL">{{ user.name | avatar }}
                    </div>
                    <mat-card-title>{{ user.name }}<br>
                        <small>{{ user._rolesName }}</small>
                    </mat-card-title>
                </mat-card-header>
            </a>
            <mat-card-actions
                    *ngIf="userCurrent._privileges.canUpdateProject || (userCurrent._rolesName === 'admin'); else info">
                <mat-slide-toggle [checked]="user.projectAccess" (change)="onChangeAccess(user.uid)">Access
                </mat-slide-toggle>
            </mat-card-actions>
            <ng-template #info>
                <mat-slide-toggle *ngIf="user.projectAccess" [checked]="user.projectAccess" disabled>Access
                </mat-slide-toggle>
                <mat-slide-toggle *ngIf="!user.projectAccess" [checked]="user.projectAccess" disabled>Block
                </mat-slide-toggle>
            </ng-template>
        </mat-card>
    </ng-container>
</section>

