import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../_services/users.service';
import {ClientType} from '../../_types/client.type';
import {ProjectType} from '../../_types/project.type';
import {ProjectsService} from '../../_services/projects.service';
import {UserType} from '../../_types/user.type';
import {ClientsService} from '../../_services/clients.service';
import {environment} from '../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgProgress} from 'ngx-progressbar';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy {

  user: UserType;
  uid: string | any;
  title: string | any;
  projects: ProjectType[] | any;
  projectsComplete = false;
  clients: ClientType[] | any;
  clientsComplete = false;
  searchProject: string | any;
  navLinks: any[] | any;

  showFullPage = false;

  showArchive = false;

  private subscriptions: Array<Subscription> = [];
  private subscriptionProjects: Subscription | any;
  private subscriptionProjectsArchival: Subscription | any;

  constructor(private router: Router,
              private usersService: UsersService,
              private route: ActivatedRoute,
              private projectsService: ProjectsService,
              private clientsService: ClientsService,
              private progress: NgProgress,
              private snackBar: MatSnackBar) {

    // set menu and title
    this.subscriptions.push(this.router.events.subscribe(() => {
      this.showFullPage = false;
      switch (this.router.url) {
        case '/projects/project-add': {
          this.title = 'Add project';
          break;
        }

        case '/projects': {
          this.title = '';
          this.navLinks = [];
          this.showFullPage = true;
          break;
        }
      }
    }));

    // set user
    // @ts-ignore
    this.user = this.route.snapshot.parent.data.user;

    // get projects
    this.getProjects();

    // get clients
    this.getClients();

  }

  ngOnInit(): void {
  }

  private getProjects(): void {
    if (this.subscriptionProjectsArchival) {
      this.subscriptionProjectsArchival.unsubscribe();
    }
    this.subscriptionProjects = this.projectsService.getProjects(this.user).subscribe(projects => {
      this.projects = projects;
      this.projectsComplete = true;
      this.matchProjectsToClients();
    });
  }

  private getProjectsArchival(): void {
    if (this.subscriptionProjects) {
      this.subscriptionProjects.unsubscribe();
    }
    this.subscriptionProjectsArchival = this.projectsService.getProjectsNotActive(this.user).subscribe(projects => {
      this.projects = projects;
      this.projectsComplete = true;
      this.matchProjectsToClients();
    });
  }

  private getClients(): void {
    this.subscriptions.push(this.clientsService.getClients(this.user).subscribe(clients => {
      this.clients = clients;
      this.clientsComplete = true;
      this.matchProjectsToClients();
    }));
  }

  private matchProjectsToClients(): void {
    if (this.projectsComplete && this.clientsComplete) {
      this.projects.forEach((project: any) => {
        // calculate estimations total
        if (!project.estimationsTotal) {
          project.estimationsTotal = 0;
        }

        if (project.estimations) {
          for (const est of Object.keys(project.estimations)) {
            project.estimationsTotal += project.estimations[est];
          }
        }

        // calculate estimations real total
        if (!project.estimationsRealTotal) {
          project.estimationsRealTotal = 0;
        }
        if (project.estimationsReal) {
          for (const est of Object.keys(project.estimationsReal)) {
            for (const taskEst of Object.keys(project.estimationsReal[est])) {
              project.estimationsRealTotal += project.estimationsReal[est][taskEst];
            }
          }
        }

        // calculate deadline info
        if (project.deadline) {
          // const today = new Date();
          project.deadlineInfo = new Date(project.deadline.seconds * 1000);
        }

        // match client to project
        this.clients.forEach((client: any) => {
          if (client.uid === project.clientRef.id) {
            project.client = client;
            project.clientName = client.name;
          }
        });
      });
    }
  }

  onShowArchive(): void {
    this.showArchive = !this.showArchive;
    if (this.showArchive) {
      this.getProjectsArchival();
    } else {
      this.getProjects();
    }
  }

  onAddFavorite(projectId: any): void {
    this.progress.ref().start();
    if (!this.user.settingsData) {
      this.user.settingsData = {};
    }
    if (!this.user.settingsData.projects_favorites) {
      this.user.settingsData.projects_favorites = {};
    }
    this.user.settingsData.projects_favorites[projectId] = true;
    this.usersService.updateUser({
      settingsData: this.user.settingsData
    }, this.user.uid)
      .then(() => {
        this.progress.ref().complete();
        this.snackBar.open('User settings has been updated', '', environment.snackbarSuccess);
      })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  onRemoveFavorite(projectId: any): void {
    // @ts-ignore
    delete this.user.settingsData.projects_favorites[projectId];
    this.usersService.updateUser({
      settingsData: this.user.settingsData
    }, this.user.uid)
      .then(() => {
        this.progress.ref().complete();
        this.snackBar.open('User settings has been updated', '', environment.snackbarSuccess);
      })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  setNav(uid: string): void {
    this.uid = uid;
    this.navLinks = [
      {
        path: uid + '/details',
        label: 'Details'
      },
      {
        path: uid + '/schedule',
        label: 'Schedule'
      },
      {
        path: uid + '/team',
        label: 'Team'
      },
      {
        path: uid + '/wiki',
        label: 'Wiki'
      },
      {
        path: uid + '/assets',
        label: 'Assets'
      },
      {
        path: uid + '/changes',
        label: 'Changes'
      },
      {
        path: uid + '/stats',
        label: 'Stats'
      }

    ];
  }

  removeNav(): void {
    this.navLinks = [];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    if (this.subscriptionProjects) {
      this.subscriptionProjects.unsubscribe();
    }
    if (this.subscriptionProjectsArchival) {
      this.subscriptionProjectsArchival.unsubscribe();
    }
  }

}
