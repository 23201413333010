import {Component, OnDestroy, OnInit} from '@angular/core';
import {CompanyType} from '../../../_types/company.type';
import {Subscription} from 'rxjs/internal/Subscription';
import {UsersService} from '../../../_services/users.service';
import {CompaniesService} from '../../../_services/companies.service';
import {NgForm} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {UserType} from '../../../_types/user.type';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgProgress} from 'ngx-progressbar';


@Component({
  selector: 'app-settings-details',
  templateUrl: './settings-details.component.html',
  styleUrls: ['./settings-details.component.scss']
})
export class SettingsDetailsComponent implements OnInit, OnDestroy {

  company: CompanyType | any;
  user: UserType;

  private subscriptions: Array<Subscription> = [];

  constructor(private usersService: UsersService,
              private companiesService: CompaniesService,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar,
              private progress: NgProgress) {

    // set user
    // @ts-ignore
    this.user = this.route.snapshot.parent.parent.data.user;

    // get company
    this.subscriptions.push(this.companiesService.getCompany(this.user.companyRef.path).subscribe((company: any) => {
      this.company = company;
    }));

  }

  ngOnInit(): void {
  }

  onSave(form: NgForm): void {
    if (form.valid) {
      this.progress.ref().start();
      this.companiesService.updateCompany({
        name: form.value.name,
        tin: form.value.tin,
        streetNumber: form.value.streetNumber,
        zipCode: form.value.zipCode,
        city: form.value.city,
        currency: form.value.currency
      }, this.user)
        .then(() => {
          this.progress.ref().complete();
          this.snackBar.open('Company has been updated', '', environment.snackbarSuccess);
        })
        .catch((err: any) => {
          this.progress.ref().complete();
          this.snackBar.open(err.message, '', environment.snackbarWarn);
        });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
