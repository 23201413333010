import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {RoleType} from '../../../_types/role.type';
import {RolesService} from '../../../_services/roles.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {environment} from '../../../../environments/environment';
import * as firebase from 'firebase/app';
import {RolesComponent} from '../roles.component';
import {UserType} from '../../../_types/user.type';
import {ActivatedRoute} from '@angular/router';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
    selector: 'app-role-add',
    templateUrl: './role-add.component.html',
    styleUrls: ['./role-add.component.scss']
})
export class RoleAddComponent implements OnInit {

    user: UserType | any;


    constructor(private rolesService: RolesService,
                private rolesComponent: RolesComponent,
                private afs: AngularFirestore,
                private route: ActivatedRoute,
                private progress: NgProgress,
                private snackBar: MatSnackBar) {

        this.rolesComponent.removeNav();

    }

    ngOnInit(): void {
        // set user
        // @ts-ignore
      this.user = this.route.snapshot.parent.parent.data.user;
    }

    onSave(form: NgForm): any {
        this.progress.ref().start();
        const name = form.value.name.trim();

        if ((name.toLowerCase() === 'default') || (name.toLowerCase() === 'admin')) {
            this.progress.ref().complete();
            this.snackBar.open('Please, choose other name', '', environment.snackbarWarn);
            return null;
        }

        form.resetForm();

        const role: RoleType = {
            uid: this.afs.createId(),
            name: name,
            dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            userUpdateRef: this.afs.doc(`users/${this.user.uid}`).ref,
            isActive: true,
            privilegeRefs: []
        };

        this.rolesService.insertRole(this.user, role)
            .then(() => {
                this.progress.ref().complete();
                this.snackBar.open('Role has been added', '', environment.snackbarSuccess);
            })
            .catch(err => {
                this.progress.ref().complete();
                this.snackBar.open(err.message, '', environment.snackbarWarn);
            });
    }

}
