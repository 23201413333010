import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {RolesComponent} from '../roles.component';
import {ActivatedRoute, Router} from '@angular/router';
import {RoleType} from '../../../_types/role.type';
import {NgForm} from '@angular/forms';
import {RolesService} from '../../../_services/roles.service';
import {UserType} from '../../../_types/user.type';
import {environment} from '../../../../environments/environment';
import * as firebase from 'firebase/app';
import {AngularFirestore} from '@angular/fire/firestore';
import {UsersService} from '../../../_services/users.service';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-role-details',
    templateUrl: './role-details.component.html',
    styleUrls: ['./role-details.component.scss']
})
export class RoleDetailsComponent implements OnInit, OnDestroy {

    role: RoleType | any;
    user: UserType | any;
    showRemove: false | any;
    batchLimit = 500;

    private subscriptions: Array<Subscription> = [];

    constructor(private rolesComponent: RolesComponent,
                private route: ActivatedRoute,
                private rolesService: RolesService,
                private usersService: UsersService,
                private afs: AngularFirestore,
                private router: Router,
                private progress: NgProgress,
                private snackBar: MatSnackBar) {

        // get params
        this.subscriptions.push(this.route.params.subscribe(params => {
            this.rolesComponent.setNav(params.uid);

            // set user
            // @ts-ignore
            this.user = this.route.snapshot.parent.parent.data.user;

            // get role
            this.subscriptions.push(this.rolesService.getRole(this.user, this.rolesComponent.uid).subscribe(role => {
                this.role = role;
                this.rolesComponent.title = this.role.name;
            }));
        }));
    }

    ngOnInit(): void {
    }

    onSave(form: NgForm): any {
        if (form.valid) {
            this.progress.ref().start();
            this.role.name = form.value.name;

            if ((this.role.name === 'default') || (this.role.name === 'admin')) {
                this.progress.ref().complete();
                this.snackBar.open('Please, choose other name', '', environment.snackbarWarn);
                return null;
            }

            this.rolesService.updateRole({
                dateUpdate: this.role.dateUpdate,
                userUpdateRef: this.role.userUpdateRef,
                name: this.role.name
            }, this.user, this.role.uid)
                .then(() => {
                    // update users with updated role
                    let batch = this.afs.firestore.batch();
                    let batchSubscription: Subscription;
                    batchSubscription = this.usersService.getFilteredUsers(this.user, {
                        name: 'roleRef',
                        val: this.afs.doc(`companies/${this.user.companyRef.id}/roles/${this.role.uid}`).ref
                    }).subscribe(users => {
                        for (let i = 0; i < users.length; i++) {
                            batch.update(this.afs.doc(`users/${users[i].uid}`).ref, {
                                _rolesName: this.role.name
                            });
                            if ((i === users.length - 1) || (i % this.batchLimit === 0)) {
                                batch.commit().then(() => {
                                });
                                batch = this.afs.firestore.batch();
                            }
                        }
                        batchSubscription.unsubscribe();
                        this.progress.ref().complete();
                        this.snackBar.open('Role has been updated', '', environment.snackbarSuccess);
                    });
                }).catch(err => {
                    this.progress.ref().complete();
                    this.snackBar.open(err.message, '', environment.snackbarSuccess);
                });
        }
    }

    onRemove(): void {
        this.progress.ref().start();
        this.role.isActive = false;
        this.role.dateUpdate = firebase.firestore.FieldValue.serverTimestamp();
        this.role.userUpdateRef = this.afs.doc(`users/${this.user.uid}`).ref;
        this.rolesService.insertRole(this.user, this.role).then();
        this.router.navigate(['/roles'])
            .then(() => {
                this.progress.ref().complete();
                this.snackBar.open('Role has been removed', '', environment.snackbarSuccess);
            })
            .catch(err => {
                this.progress.ref().complete();
                this.snackBar.open(err.message, '', environment.snackbarWarn);
            });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
