import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClientType} from '../../../_types/client.type';
import {RoleType} from '../../../_types/role.type';
import {UserType} from '../../../_types/user.type';
import {Subscription} from 'rxjs';
import {ClientsService} from '../../../_services/clients.service';
import {MessagesService} from '../../../_services/messages.service';
import {RolesService} from '../../../_services/roles.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {NgForm} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {MessageType} from '../../../_types/message.type';
import * as firebase from 'firebase/app';
import {BrandsService} from '../../../_services/brands.service';
import {BrandsComponent} from '../brands.component';
import {BrandType} from '../../../_types/brand.type';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-brand-add',
  templateUrl: './brand-add.component.html',
  styleUrls: ['./brand-add.component.scss']
})
export class BrandAddComponent implements OnInit, OnDestroy {

  clients: ClientType[];
  client: any;
  roles: RoleType[];
  user: UserType;

  private subscriptions: Array<Subscription> = [];
  private subscriptionIsExists: Subscription;

  constructor(private clientsService: ClientsService,
              private brandsService: BrandsService,
              private messagesService: MessagesService,
              private brandsComponent: BrandsComponent,
              private rolesService: RolesService,
              private route: ActivatedRoute,
              private afs: AngularFirestore,
              private progress: NgProgress,
              private snackBar: MatSnackBar,
              private router: Router) {


    // set user
    this.user = this.route.snapshot.parent.parent.data.user;

    // get clients
    this.getClients();

    // set title
    this.brandsComponent.title = 'Add social brand';

  }

  ngOnInit() {
  }

  private getClients() {
    this.subscriptions.push(this.clientsService.getClients(this.user).subscribe(clients => {
      this.clients = clients;
    }));
  }

  autocompleteDisplay(client?: ClientType): string | undefined {
    return client ? client.name : undefined;
  }

  autocompleteBlur() {
    if (typeof this.client === 'string') {
      this.clients.forEach(client => {
        if (client.name === this.client) {
          this.client = client;
        }
      });
    }
  }

  autocompleteSelected(selected) {
    if (typeof this.client === 'string') {
      this.clients.forEach(client => {
        if (client === selected) {
          this.client = client;
        }
      });
    }
  }

  onSave(form: NgForm) {

    this.progress.ref().start();

    // insert brand
    const brand: BrandType = {
      uid: this.afs.createId(),
      clientRef: this.afs.doc(`companies/${this.user.companyRef.id}/clients/${form.value.client.uid}`).ref,
      name: form.value.name,
      shortDesc: form.value.shortDesc,
      notes: form.value.notes,
      userRefs: {
        [this.user.uid]: this.afs.doc(`users/${this.user.uid}`).ref
      },
      isActive: true,
      imageURL: ''
    };

    // check duplicate
    this.subscriptionIsExists = this.brandsService.isExists(brand, this.user).subscribe(brands => {
      if (brands.length) {
        this.progress.ref().complete();
        this.snackBar.open('Social brand exists', '', environment.snackbarWarn);
      } else {
        // insert brand
        this.subscriptionIsExists.unsubscribe();
        this.brandsService.insertBrand(brand, this.user)
            .then(() => {
              // insert changes
              const change: MessageType = {
                uid: this.afs.createId(),
                message: 'has created the social brand',
                dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
                userRef: this.afs.doc(`users/${this.user.uid}`).ref,
                files: [],
                type: 'auto'
              };
              this.messagesService.insertMessage(change, `companies/${this.user.companyRef.id}/brands/${brand.uid}/changes/${change.uid}`)
                  .then(() => {
                    this.progress.ref().complete();
                    this.snackBar.open('Social brand has been added', '', environment.snackbarSuccess);
                    this.router.navigate([`/brands/${brand.uid}/team`]).then();
                  })
                  .catch(err => {
                    this.snackBar.open(err.message, '', environment.snackbarWarn);
                  });
            })
            .catch(err => {
              this.progress.ref().complete();
              this.snackBar.open(err.message, '', environment.snackbarWarn);
            });
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
