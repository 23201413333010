import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {RoleType} from '../_types/role.type';
import {UserType} from '../_types/user.type';
import {ProjectType} from '../_types/project.type';
import * as firebase from 'firebase';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  // batchLimit: 500;

  constructor(private afs: AngularFirestore) {
  }

  getRole(user: UserType, roleUid: string): Observable<RoleType | undefined> {
    return this.afs.doc<RoleType>(`companies/${user.companyRef.id}/roles/${roleUid}`)
      .valueChanges();
  }

  // get roles
  getRoles(user: UserType): Observable<RoleType[]> {
    return this.afs.collection<RoleType>(`companies/${user.companyRef.id}/roles`, ref => ref
      .where('isActive', '==', true)
      .orderBy('name'))
      .valueChanges();
  }

  getProjectOnce(user: UserType, projectUid: string): any {
    const db = firebase.firestore();
    return db.doc(`companies/${user.companyRef.id}/projects/${projectUid}`).get();
  }

  // get roles once
  getRolesOnce(user: UserType): any {
    const db = firebase.firestore();
    return db.collection(`companies/${user.companyRef.id}/roles`)
      .where('isActive', '==', true)
      .orderBy('name')
      .get();
  }

  // insert role
  insertRole(user: UserType, role: RoleType): Promise<void> {
    return this.afs.doc<RoleType>(`companies/${user.companyRef.id}/roles/${role.uid}`).set(role);
  }

  // update role
  updateRole(fields: {}, user: UserType, roleUid: string): Promise<void> {
    // update "_privileges" fields in "users" documents
    // let batch = this.afs.firestore.batch();
    // let batchSubscription: Subscription;
    // batchSubscription = this.usersService.getFilteredUsers(user,
    //     {
    //         name: 'roleRef',
    //         val: this.afs.doc(`companies/${user.companyRef.id}/roles/${roleUid}`).ref
    //     }).subscribe(
    //     users => {
    //         // update all users
    //         for (let i = 0; i < users.length; i++) {
    //             batch.update(this.afs.doc(`users/${users[i].uid}`).ref, {
    //                 _privileges['']: client.name
    //             })
    //             if ((i === users.length - 1) || (i % this.batchLimit === 0)) {
    //                 batch.commit().then(() => {});
    //                 batch = this.afs.firestore.batch();
    //             }
    //         }
    //         batchSubscription.unsubscribe();
    //     });
    //

    return this.afs.doc<ProjectType>(`companies/${user.companyRef.id}/roles/${roleUid}`).update(fields);

  }
}
