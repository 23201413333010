import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClientsService} from '../../../_services/clients.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {UserType} from '../../../_types/user.type';
import {ClientType} from '../../../_types/client.type';
import {ProjectsComponent} from '../projects.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectType} from '../../../_types/project.type';
import {ProjectsService} from '../../../_services/projects.service';
import {environment} from '../../../../environments/environment';
import * as firebase from 'firebase/app';
import {AngularFirestore} from '@angular/fire/firestore';
import {RoleType} from '../../../_types/role.type';
import {RolesService} from '../../../_services/roles.service';
import {MessageType} from '../../../_types/message.type';
import {MessagesService} from '../../../_services/messages.service';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit, OnDestroy {

  clients: ClientType[] = [];
  roles: RoleType[];
  user: UserType;
  project: ProjectType;
  projectClone: ProjectType;
  client: any;
  clientClone: any;
  showRemove = false;
  showArchive = false;

  private subscriptions: Array<Subscription> = [];

  constructor(private clientsService: ClientsService,
              private messagesService: MessagesService,
              private projectsComponent: ProjectsComponent,
              private projectsService: ProjectsService,
              private rolesService: RolesService,
              private route: ActivatedRoute,
              private router: Router,
              private afs: AngularFirestore,
              private progress: NgProgress,
              private snackBar: MatSnackBar) {

    // set params
    this.subscriptions.push(this.route.params.subscribe(params => {
      this.projectsComponent.setNav(params.uid);

      // set user
      this.user = this.route.snapshot.parent.parent.data.user;

      // get clients
      this.getClients();

      // get project
      this.getProject();

      // get roles
      this.getRoles();
    }));

  }

  private getClients() {
    this.subscriptions.push(this.clientsService.getClients(this.user).subscribe(clients => {
      this.clients = clients;
      this.matchClientsToProject();
    }));
  }

  private getProject() {
    this.subscriptions.push(this.projectsService.getProject(this.user, this.projectsComponent.uid).subscribe(project => {
      this.project = project;
      if (this.project.deadline) {
        this.project.deadline = new Date(this.project.deadline.seconds * 1000);
      }
      this.projectClone = Object.assign({}, this.project);
      if (this.project.groups) {
        this.projectClone.groups = this.project.groups.map(a => ({...a}));
      }
      this.projectsComponent.title = this.project.name;
      this.matchClientsToProject();
    }));
  }

  private getRoles() {
    if (this.user._privileges.canGetRole || (this.user._rolesName === 'admin')) {
      this.subscriptions.push(this.rolesService.getRoles(this.user).subscribe(roles => {
        this.roles = roles;
        this.setProjectEstimations();
      }));
    }
  }

  private matchClientsToProject() {
    if (this.clients.length && this.project) {
      for (const client of this.clients) {
        if (client.uid === this.project.clientRef.id) {
          this.client = client;
          this.clientClone = this.client;
        }
      }
    }
  }

  private setProjectEstimations() {
    for (const role of this.roles) {
      if (!this.project.estimations[role.uid]) {
        this.project.estimations[role.uid] = 0;
      }
    }
    this.projectClone.estimations = Object.assign({}, this.project.estimations);
  }

  private dateFormat(date: Date) {
    if (date) {
      const mm = date.getMonth() + 1;
      const dd = date.getDate();

      return [date.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd
      ].join('-');
    } else {
      return '';
    }
  }

  ngOnInit() {
  }

  autocompleteDisplay(client?: ClientType): string | undefined {
    return client ? client.name : undefined;
  }

  autocompleteBlur() {
    if (typeof this.client === 'string') {
      this.clients.forEach(client => {
        if (client.name === this.client) {
          this.client = client;
        }
      });
    }
  }

  autocompleteSelected(selected) {
    if (typeof this.client === 'string') {
      this.clients.forEach(client => {
        if (client === selected) {
          this.client = client;
        }
      });
    }
  }

  onSave() {
    this.progress.ref().start();

    // check differences
    let message = '';
    if (this.projectClone.name !== this.project.name) {
      message += `has changed name: ${this.projectClone.name} → ${this.project.name}`;
    }
    if (this.clientClone.uid !== this.client.uid) {
      if (message) {
        message += '<br>';
      }
      message += `has changed client: ${this.clientClone.name} → ${this.client.name}`;
    }
    if (this.projectClone.shortDesc !== this.project.shortDesc) {
      if (message) {
        message += '<br>';
      }
      message += `has changed short description: ${this.projectClone.shortDesc} → ${this.project.shortDesc}`;
    }
    if (this.projectClone.notes !== this.project.notes) {
      if (message) {
        message += '<br>';
      }
      message += `has changed notes: ${this.projectClone.notes} → ${this.project.notes}`;
    }
    for (const field of Object.keys(this.project.estimations)) {
      if (this.projectClone.estimations[field] !== this.project.estimations[field]) {
        if (message) {
          message += '<br>';
        }
        let roleName = '';
        for (const role of this.roles) {
          if (field === role.uid) {
            roleName = role.name;
          }
        }
        message += `has changed ${roleName} estimation: ${this.projectClone.estimations[field]} → ${this.project.estimations[field]}`;
      }
    }
    if (this.projectClone.deadline !== this.project.deadline) {
      if (message) {
        message += '<br>';
      }
      message += `has changed deadline: ${this.dateFormat(this.projectClone.deadline)} → ${this.dateFormat(this.project.deadline)}`;
    }
    if (this.projectClone.groups) {
      let messageGroup = '';
      this.projectClone.groups.forEach(groupClone => {
        let exists = false;
        this.project.groups.forEach(group => {
          if (groupClone.name === group.name) {
            exists = true;
          }
        });

        if (!exists) {
          if (!messageGroup) {
            messageGroup = 'has removed group(s): ' + groupClone.name;
          } else {
            messageGroup += ', ' + groupClone.name;
          }
        }
      });
      if (message) {
        message += '<br>' + messageGroup;
      } else {
        message = messageGroup;
      }
    } else {
      this.project.groups = [];
    }

    // update project
    this.projectsService.updateProject({
      name: this.project.name,
      shortDesc: this.project.shortDesc,
      clientRef: this.afs.doc(`companies/${this.user.companyRef.id}/clients/${this.client.uid}`).ref,
      notes: this.project.notes,
      estimations: this.project.estimations,
      deadline: this.project.deadline,
      groups: this.project.groups
    }, this.user, this.project.uid)
      .then(() => {
        // insert changes
        const change: MessageType = {
          uid: this.afs.createId(),
          message: message,
          dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
          userRef: this.afs.doc(`users/${this.user.uid}`).ref,
          files: [],
          type: 'auto'
        };
        this.messagesService.insertMessage(change, `companies/${this.user.companyRef.id}/projects/${this.project.uid}/changes/${change.uid}`)
          .then(() => {
            this.snackBar.open('Project has been updated', '', environment.snackbarSuccess);
            this.progress.ref().complete();
            // update clones
            this.projectClone = Object.assign({}, this.project);
            this.projectClone.estimations = Object.assign({}, this.project.estimations);
            this.clientClone = Object.assign({}, this.client);
          })
          .catch(err => {
            this.snackBar.open(err.message, '', environment.snackbarWarn);
          });
      }).catch(err => {
      this.snackBar.open(err.message, '', environment.snackbarWarn);
      this.progress.ref().complete();
    });
  }

  onRemoveGroup(groupName: string) {
    for (let i = this.project.groups.length - 1; i >= 0; i--) {
      if (this.project.groups[i].name === groupName) {
        this.project.groups.splice(i, 1);
      }
    }
  }

  onRemove() {
    this.progress.ref().start();
    this.projectsService.updateProject({
      isActive: false
    }, this.user, this.project.uid).then(() => {
      // insert changes
      const change: MessageType = {
        uid: this.afs.createId(),
        message: 'has removed project',
        dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
        userRef: this.afs.doc(`users/${this.user.uid}`).ref,
        files: [],
        type: 'auto'
      };
      this.messagesService.insertMessage(change, `companies/${this.user.companyRef.id}/projects/${this.project.uid}/changes/${change.uid}`)
        .then(() => {
          this.progress.ref().complete();
          this.snackBar.open('Project has been removed', '', environment.snackbarSuccess);
        })
        .catch(err => {
          this.snackBar.open(err.message, '', environment.snackbarWarn);
        });
    })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  onUndoRemove() {
    this.progress.ref().start();
    this.projectsService.updateProject({
      isActive: true
    }, this.user, this.project.uid).then(() => {
      // insert changes
      const change: MessageType = {
        uid: this.afs.createId(),
        message: 'has withdrawn the removal of the project',
        dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
        userRef: this.afs.doc(`users/${this.user.uid}`).ref,
        files: [],
        type: 'auto'
      };
      this.messagesService.insertMessage(change, `companies/${this.user.companyRef.id}/projects/${this.project.uid}/changes/${change.uid}`)
        .then(() => {
          this.progress.ref().complete();
          this.snackBar.open('Withdrawn the removal of the project', '', environment.snackbarSuccess);
        })
        .catch(err => {
          this.snackBar.open(err.message, '', environment.snackbarWarn);
        });
    })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });

  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
