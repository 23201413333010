<section class="page-section page-section--project-assets" *ngIf="project">
    <div class="drop-container"
         ngFileDrop
         [options]="options"
         (uploadOutput)="onUpload($event)"
         [uploadInput]="uploadInput"
         [ngClass]="{ 'is-drop-over': dragOver }">
        <input type="file"
               ngFileSelect
               [options]="options"
               (uploadOutput)="onUpload($event)"
               [uploadInput]="uploadInput"
               multiple>
        <span>Select or drop file(s) here</span>
    </div>

    <mat-accordion *ngIf="project.files">
        <ng-container *ngFor="let file of project.files; let i = index">
            <mat-expansion-panel *ngIf="file.isActive">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ file.name }}
                    </mat-panel-title>
                    <mat-panel-description>
                        {{ file.size | fileSize }} ({{ file.type }})
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="mat-expansion-panel-body">
                    <div class="row">
                        <div class="col">
                            <a class="link" download href="{{ file.url }}">Download</a>
                        </div>
                        <div class="col help-text-align-right">
                            <a (click)="showRemove[i] = !showRemove[i]" class="link" *ngIf="!showRemove[i]">Delete</a>
                            <span *ngIf="showRemove[i]">Are you sure? <a (click)="showRemove[i] = false"
                                                                         class="link">No</a>&nbsp;<a
                                    (click)="onRemove(i)" class="link">Yes</a></span>
                        </div>
                    </div>
                    <div class="expansion-panel__image"
                         *ngIf="file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/gif'">
                        <a target="_blank" href="{{ file.url }}"><img src="{{ file.url }}"></a>
                    </div>
                </div>

            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
</section>

