import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ModuleType} from '../_types/module.type';
import {UserType} from '../_types/user.type';
import {MessageType} from '../_types/message.type';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ModulesService {

  constructor(private afs: AngularFirestore) { }

  // get module
  getModule(moduleUid: string): Observable<ModuleType | undefined> {
    return this.afs.doc<ModuleType>(`modules/${moduleUid}`).valueChanges();
  }

  // get modules
  getModules(): Observable<ModuleType[]> {
    return this.afs.collection<ModuleType>(`modules`, ref => ref
        .orderBy('name'))
        .valueChanges();
  }

  // get module access changes
  getModuleAccessChanges(user: UserType, moduleUid: string): Observable<MessageType[]> {
    return this.afs.collection<MessageType>(`companies/${user.companyRef.id}/modulesAccesses/${moduleUid}/changes`, ref => ref
        .orderBy('dateCreate'))
        .valueChanges();
  }
}
