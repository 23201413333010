import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersComponent} from '../users.component';
import {UserType} from '../../../_types/user.type';
import {UsersService} from '../../../_services/users.service';
import {RoleType} from '../../../_types/role.type';
import {RolesService} from '../../../_services/roles.service';
import {AngularFirestore} from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import {environment} from '../../../../environments/environment';
import {NgForm} from '@angular/forms';
import {PrivilegeType} from '../../../_types/privilege.type';
import {PrivilegesService} from '../../../_services/privileges.service';
import {ModulesService} from '../../../_services/modules.service';
import {ModuleType} from '../../../_types/module.type';
import {AccessToModulesService} from '../../../_services/access-to-modules.service';
import {AccessToModule} from '../../../_types/accessToModule.type';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgProgress} from 'ngx-progressbar';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit, OnDestroy {

  userCurrent: UserType | any;
  user: UserType | undefined;
  roles: RoleType[] | undefined;
  showRemove = false;
  privileges: PrivilegeType[] | any;
  modules: ModuleType[] | undefined;
  modulesComplete = false;
  users: UserType[] | undefined;
  usersComplete = false;
  accessMessages: AccessToModule[] = [];

  private subscriptions: Array<Subscription> = [];
  private subscriptionUserCurrent: Subscription | undefined;

  constructor(private usersComponent: UsersComponent,
              private rolesService: RolesService,
              private privilegesService: PrivilegesService,
              private accessToModule: AccessToModulesService,
              private modulesService: ModulesService,
              private usersService: UsersService,
              private afs: AngularFirestore,
              private router: Router,
              private route: ActivatedRoute,
              private progress: NgProgress,
              private snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    // get params
    this.subscriptions.push(this.route.params.subscribe(params => {

      // set user
      // @ts-ignore
      this.user = this.route.snapshot.parent.parent.data.user;

      // get current user
      this.subscriptionUserCurrent = this.usersService.getUserByRef(this.afs.doc(`users/${params.uid}`).ref).subscribe(userCurrent => {
        this.userCurrent = userCurrent;
        this.usersComponent.setNavInternal(params.uid);
        this.usersComponent.title = this.userCurrent?.name;

        // get roles
        this.getRoles();

        // get privileges
        this.getPrivileges();
      });
    }));
  }

  private getRoles(): void {
    if (this.user) {
      this.subscriptions.push(this.rolesService.getRoles(this.user).subscribe(roles => {
        this.roles = roles;
        this.matchRoleToCurrentUser();
      }));
    }
  }

  private getPrivileges(): void {
    this.subscriptions.push(this.privilegesService.getPrivileges().subscribe(privileges => {
      this.privileges = privileges;
    }));
  }

  private matchRoleToCurrentUser(): void {
    this.roles?.forEach(role => {
      if (role.uid === this.userCurrent?.roleRef.id) {
        this.userCurrent.role = role;
      }
    });
  }

  private getModules(): void {
    this.subscriptions.push(this.modulesService.getModules().subscribe(modules => {
      this.modules = modules;
      this.modulesComplete = true;
      this.recountAccessToModules();
    }));
  }

  private getUsers(): void {
    this.subscriptions.push(this.usersService.getUsersActive(this.user?.companyRef).subscribe(users => {
      this.users = users;
      this.usersComplete = true;
      this.recountAccessToModules();
    }));
  }

  private updateUser(): void {
    // unsubscribe user
    this.subscriptionUserCurrent?.unsubscribe();

    // update user
    this.usersService.updateUser({
      isActive: false,
      _modules: {},
      dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
      userUpdateRef: this.afs.doc(`users/${this.user?.uid}`).ref
    }, this.userCurrent?.uid)
      .then(() => {
        // get all users
        this.getUsers();
      })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  private recountAccessToModules(): void {
    let numberOfUsers: number;
    if (this.modulesComplete && this.usersComplete) {
      for (const moduleCode of Object.keys(this.userCurrent?._modules)) {
        this.modules?.forEach(module => {
          if (module.code === moduleCode) {
            numberOfUsers = 0;

            // recount users
            this.users?.forEach(user => {
              if (user._modules[module.code]) {
                numberOfUsers++;
              }
            });

            // set access message
            this.accessMessages.push({
              uid: this.afs.createId(),
              dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
              message: `has deleted user ${this.userCurrent.name}`,
              moduleRef: this.afs.doc(`modules/${module.uid}/`).ref,
              numberOfUsers,
              userRef: this.afs.doc(`users/${this.user?.uid}`).ref,
            });
          }
        });
      }

      this.setAccessMessages();
    }
  }

  private setAccessMessages(): void {
    const batch = this.afs.firestore.batch();
    this.accessMessages.forEach(accessMessage => {
      batch.set(this.afs.doc(`companies/${this.userCurrent.companyRef.id}/accessToModules/${accessMessage.uid}`).ref, accessMessage);
    });
    batch.commit()
      .then(() => {
        this.router.navigate(['/dashboard'])
          .then(() => {
            this.progress.ref().complete();
            this.snackBar.open('User has been removed', '', environment.snackbarSuccess);
          })
          .catch(err => {
            this.progress.ref().complete();
            this.snackBar.open(err.message, '', environment.snackbarWarn);
          });
      })
      .catch((err: any) => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  onSave(form: NgForm): void {
    this.progress.ref().start();
    this.userCurrent.name = form.value.name;
    this.userCurrent.dateUpdate = firebase.firestore.FieldValue.serverTimestamp();
    this.userCurrent.userUpdateRef = this.afs.doc(`users/${this.user?.uid}`).ref;

    // match privileges to chosen role
    // tslint:disable-next-line:variable-name
    const _privileges: any = {};
    if (this.roles) {
      for (const role of this.roles) {
        if (this.userCurrent.role.uid === role.uid) {
          for (const privilegeRef of role.privilegeRefs) {
            for (const privilege of this.privileges) {
              if (privilegeRef.id === privilege.uid) {
                _privileges[privilege.code] = true;
              }
            }
          }
        }
      }
    }

    // update user
    this.usersService.updateUser({
      name: this.userCurrent.name,
      dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
      userUpdateRef: this.afs.doc(`users/${this.user?.uid}`).ref,
      roleRef: this.afs.doc(`companies/${this.user?.companyRef.id}/roles/${this.userCurrent.role.uid}`).ref,
      _rolesName: this.userCurrent.role.name,
      _privileges
    }, this.userCurrent.uid)
      .then(() => {
        this.progress.ref().complete();
        this.snackBar.open('User has been updated', '', environment.snackbarSuccess);
      })
      .catch(err => {
        this.progress.ref().complete();
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  onRemove(): void {
    this.progress.ref().start();

    // update user
    // then get all active users
    this.updateUser();

    // get all modules
    // then set accessMessages
    // then redirect
    this.getModules();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptionUserCurrent?.unsubscribe();
  }

}
