<section class="page-section">
    <div class="row">
        <div class="col-4">
            <mat-card>
                <mat-card-content *ngIf="user._rolesName === 'admin' || user._privileges.canUpdateRole; else info">
                    <!-- role is NOT default -->
                    <ng-container *ngIf="role && role.name != 'default'">
                        <form (ngSubmit)="onSave(f)" #f="ngForm" autocomplete="off">

                            <div>
                                <mat-form-field>
                                    <input matInput
                                           type="text"
                                           name="name"
                                           #name="ngModel"
                                           [(ngModel)]="role.name"
                                           required
                                           placeholder="Name">
                                    <mat-error *ngIf="name.hasError('required')">
                                        Name is <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <button type="submit"
                                    mat-raised-button color="primary"
                                    [disabled]="f.form.invalid">Save
                            </button>
                            <a (click)="showRemove = !showRemove" class="link link--btn" *ngIf="!showRemove">Delete</a>
                            <span *ngIf="showRemove" class="link--btn">Are you sure? <a (click)="showRemove = false" class="link">No</a>&nbsp;<a (click)="onRemove()" class="link">Yes</a></span>
                        </form>
                    </ng-container>
                    <!-- /role is NOT default -->

                    <!-- role is default -->
                    <ng-container *ngIf="role && role.name == 'default'">
                        <form #f="ngForm" autocomplete="off">
                            <div>
                                <mat-form-field>
                                    <input matInput
                                           disabled
                                           type="text"
                                           name="name"
                                           #name="ngModel"
                                           [(ngModel)]="role.name"
                                           required
                                           placeholder="Name">
                                </mat-form-field>
                            </div>
                        </form>
                    </ng-container>
                    <!-- /role is default -->
                </mat-card-content>

                <ng-template #info>
                    <mat-card-content *ngIf="role">
                        <mat-form-field>
                            <input matInput
                                   type="text"
                                   name="name"
                                   #name="ngModel"
                                   [(ngModel)]="role.name"
                                   readonly
                                   placeholder="Name">
                        </mat-form-field>
                    </mat-card-content>
                </ng-template>
            </mat-card>
        </div>
    </div>
</section>
