<section class="page-section">
    <form (ngSubmit)="onSave(f)" #f="ngForm">
        <div class="row">
            <div class="col-4">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field *ngIf="user">
                            <input matInput
                                   type="text"
                                   name="name"
                                   [(ngModel)]="user.name"
                                   #name="ngModel"
                                   required
                                   placeholder="Name">
                            <mat-error *ngIf="name.hasError('required')">
                                Name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-slide-toggle [checked]="user.isEmailNotification"
                                          [(ngModel)]="user.isEmailNotification"
                                          name="isEmailNotification"
                                          #name="ngModel"
                                          class="help-margin-bottom-20px">E-mail notifications</mat-slide-toggle>

                        <div class="help-margin-bottom-20px" *ngIf="user">
                            <div *ngIf="user.photoURL" class="picture"
                                 [ngStyle]="{'background-image': 'url(' + user.photoURL + ')'}">
                                <i class="material-icons" (click)="onDeletePhoto()">clear</i>
                            </div>
                            <div class="picture" *ngIf="!user.photoURL">{{ user.name | avatar}}</div>
                        </div>

                        <div class="help-margin-bottom-20px">
                            <button mat-stroked-button
                                    class="btn-file"
                                    color="primary"
                                    type="button">Choose file<input id="picture"
                                                                    type="file"
                                                                    class="form-control"
                                                                    name="picture"
                                                                    (change)="chooseFile($event)"/>
                            </button>
                            <span *ngIf="selectedFiles">{{ selectedFiles[0].name }}</span>
                        </div>

                        <button type="submit"
                                mat-raised-button color="primary"
                                [disabled]="f.form.invalid">Save
                        </button>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </form>
</section>
