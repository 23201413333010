<section class="page-section page-section--settings-domains">
    <mat-list *ngIf="company" role="list">
        <mat-list-item *ngFor="let domain of company.domains" role="listitem">
            <h3 mat-line> @{{ domain }} </h3>
            <!--<button mat-icon-button (click)="onRemove(domain)">-->
                <!--<mat-icon>delete</mat-icon>-->
            <!--</button>-->
        </mat-list-item>

        <!--<mat-list-item>-->
            <!--<h3 mat-line>-->
                <!--@<input matInput-->
                       <!--type="text"-->
                       <!--name="domain"-->
                       <!--[(ngModel)]="domain"-->
                       <!--required-->
                       <!--(keyup.enter)="onAdd()"-->
                       <!--placeholder="new domain name">-->
            <!--</h3>-->

            <!--<button mat-icon-button (click)="onAdd()">-->
                <!--<mat-icon>add</mat-icon>-->
            <!--</button>-->
        <!--</mat-list-item>-->

    </mat-list>

</section>
