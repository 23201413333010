<section class="aside-subnav" [ngClass]="{'aside-subnav--full-page': showFullPage}">
  <ng-container *ngIf="modules; else loading">
    <ng-container *ngIf="modules.length">
      <div class="search">
        <mat-form-field>
          <input matInput placeholder="Search" type="search" [(ngModel)]="searchModule">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>

      <mat-nav-list>
        <div *ngFor="let module of modules | filter:searchModule:['name','description']">
          <a mat-list-item
             routerLinkActive="active"
             routerLink="/modules/{{ module.uid }}">
            <div matLine>
              {{ module.name }}
            </div>
            <div matLine class="info">
                            <span *ngIf="module._usersNumber !== 'undefined'"
                                  matTooltip="Number of users with access to module"
                                  matTooltipPosition="above"><mat-icon>supervisor_account</mat-icon><small>{{ module._usersNumber }}</small></span>
            </div>
          </a>
        </div>
      </mat-nav-list>

    </ng-container>
  </ng-container>

  <ng-template #loading>
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </ng-template>
</section>

<main class="help-margin-left-180px">
  <div class="container">
    <div class="row">
      <div class="col">
        <header class="page-header">
          <h1 [innerHTML]="title" *ngIf="title"></h1>
          <nav mat-tab-nav-bar>
            <a mat-tab-link
               *ngFor="let link of navLinks"
               [routerLink]="link.path"
               routerLinkActive #rla="routerLinkActive"
               [active]="rla.isActive">
              {{link.label}}
            </a>
          </nav>
        </header>

        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</main>
