import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModuleType} from '../../../_types/module.type';
import {PriceType} from '../../../_types/price.type';
import {ModulesService} from '../../../_services/modules.service';
import {PricesService} from '../../../_services/prices.service';
import {Subscription} from 'rxjs';
import {UserType} from '../../../_types/user.type';
import {ActivatedRoute} from '@angular/router';
import {CompaniesService} from '../../../_services/companies.service';
import {CompanyType} from '../../../_types/company.type';

@Component({
  selector: 'app-settings-prices',
  templateUrl: './settings-prices.component.html',
  styleUrls: ['./settings-prices.component.scss']
})
export class SettingsPricesComponent implements OnInit, OnDestroy {

  user: UserType;
  modules: ModuleType[] | any;
  modulesComplete = false;
  prices: PriceType[] | any;
  pricesComplete = false;
  company: CompanyType | any;

  private subscriptions: Array<Subscription> = [];

  constructor(private modulesService: ModulesService,
              private pricesService: PricesService,
              private companiesService: CompaniesService,
              private route: ActivatedRoute) {

    // set user
    // @ts-ignore
    this.user = this.route.snapshot.parent.parent.data.user;

    // get module
    this.getModules();

    // get prices
    this.getPrices();

    // get company
    this.getCompany();
  }

  private getCompany(): void {
    this.subscriptions.push(this.companiesService.getCompany(this.user.companyRef.path).subscribe((company: any) => {
      this.company = company;
    }));
  }

  private getModules(): void {
    this.subscriptions.push(this.modulesService.getModules().subscribe(modules => {
      this.modules = modules;
      this.modulesComplete = true;
      this.matchModuleToPrices();
    }));
  }

  private getPrices(): void {
    const dateNow = new Date();
    this.subscriptions.push(this.pricesService.getPrices(this.user).subscribe(prices => {
      this.prices = prices;
      this.prices.forEach((price: any) => {
        price.prices.forEach((p: any) => {
          p.isNow = false;
          if ((p.dateStart.seconds * 1000) <= dateNow.getTime() && ((p.dateEnd.seconds * 1000) >= dateNow.getTime())) {
            p.isNow = true;
          }
        });
      });
      this.pricesComplete = true;
      this.matchModuleToPrices();
    }));
  }

  private matchModuleToPrices(): void {
    if (this.modulesComplete && this.pricesComplete) {
      this.modules.forEach((module: any) => {
        this.prices.forEach((price: any) => {
          if (module.uid === price.moduleRef.id) {
            module.price = price;
          }
        });
      });
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
