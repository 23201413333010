import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserType} from '../../../_types/user.type';
import {UserExternalType} from '../../../_types/userExternal.type';
import {Subscription} from 'rxjs';
import {UsersExternalService} from '../../../_services/users-external.service';
import {UsersService} from '../../../_services/users.service';
import {UsersComponent} from '../users.component';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-user-invitations',
    templateUrl: './user-invitations.component.html',
    styleUrls: ['./user-invitations.component.scss']
})
export class UserInvitationsComponent implements OnInit, OnDestroy {

    user: UserType | undefined;
    usersExternal: UserExternalType[] = [];
    usersComplete = false;

    showRemove: boolean[] = [];

    private subscriptions: Array<Subscription> = [];

    constructor(private usersExternalService: UsersExternalService,
                private usersService: UsersService,
                private usersComponent: UsersComponent,
                private route: ActivatedRoute,
                private progress: NgProgress,
                private snackBar: MatSnackBar) {

        // set nav
        this.usersComponent.setNavExternal();
    }

    ngOnInit(): void {
        // set title
        this.usersComponent.title = 'Users';


        // set user
        // @ts-ignore
        this.user = this.route.snapshot.parent.parent.data.user;

        // get external users
        this.getExternalUsers();
    }

    private getExternalUsers(): void {
      if (this.user) {
        this.subscriptions.push(this.usersExternalService.getUsersExternal(this.user).subscribe(usersExternal => {
          this.usersExternal = usersExternal;
          for (let i = 0; i < this.usersExternal.length; i++) {
            this.showRemove[i] = false;
          }
        }));
      }
    }

    onRemove(externalUser: any): void {
        this.progress.ref().start();
        this.usersExternalService.updateUserExternal({
            isActive: false
        }, externalUser.uid)
            .then(() => {
                this.snackBar.open('External user has been removed', '', environment.snackbarSuccess);
                this.progress.ref().complete();
            }).catch(err => {
            this.snackBar.open(err.message, '', environment.snackbarWarn);
            this.progress.ref().complete();
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
