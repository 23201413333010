import {Component, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnDestroy {

  title = 'app';

  private subscriptions: Array<Subscription> = [];

  constructor(private translate: TranslateService,
              private router: Router) {

    // set translation
    translate.setDefaultLang('en');
    translate.use('en');

    // get user
    // this.usersService.user$.subscribe(user => {
    //     this.user = user;
    //
    //     //get projects
    //     this.projectsService.getProjects(user).subscribe(projects => {
    //         this.projects = projects;
    //     })
    //
    //     //get users
    //
    // })

    // update current URL
    // this.subscriptions.push(this.usersService.user$.subscribe(user => {
    //     if (user) {
    //         router.events.forEach((event) => {
    //             if(event instanceof NavigationStart) {
    //                 this.usersService.updateUrl(event.url, `users/${user.uid}`)
    //             }
    //
    //             // NavigationEnd
    //             // NavigationCancel
    //             // NavigationError
    //             // RoutesRecognized
    //         });
    //
    //     }
    // }))
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }


}
