<ng-container *ngIf="user">

  <a routerLink="/" class="logo"><img src="../../assets/soteam.png" alt="Soteam"></a>

  <div class="profile">
    <button mat-button [matMenuTriggerFor]="menu">
      <div *ngIf="user.photoURL"
           class="profile__picture"
           [ngStyle]="{'background-image': 'url(' + user.photoURL + ')'}">
      </div>
      <div *ngIf="!user.photoURL"
           class="profile__picture">
        {{ user.name | avatar }}
      </div>
      <div class="profile__nameType">
        <div class="profile__name">{{ user.name }}</div>
        <div class="profile__role">{{ user._rolesName }}</div>
      </div>
    </button>
  </div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="onSettings()">Profile settings</button>
    <button mat-menu-item (click)="onLogOut()">Log Out</button>
  </mat-menu>

  <mat-nav-list>
    <a mat-list-item
       routerLink="/notifications"
       routerLinkActive="active">
      <mat-icon>notification_important</mat-icon>
      <span *ngIf="notificationsUnreadedCount" matBadge="{{ notificationsUnreadedCount }}"
            matBadgeOverlap="false">Notifications</span>
      <span *ngIf="!notificationsUnreadedCount">Notifications</span>
    </a>
    <a mat-list-item
       routerLink="/dashboard"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: true}">
      <mat-icon>dashboard</mat-icon>
      Dashboard</a>
    <a mat-list-item
       routerLink="/clients"
       *ngIf="user._privileges.canGetClient || user._rolesName === 'admin'"
       routerLinkActive="active">
      <mat-icon>business</mat-icon>
      Clients</a>
    <a mat-list-item
       routerLink="/projects"
       *ngIf="(user._privileges.canGetProject || user._rolesName === 'admin') && user._modules && user._modules.projects"
       routerLinkActive="active">
      <mat-icon>folder</mat-icon>
      Projects</a>
    <a mat-list-item
       routerLink="/brands"
       *ngIf="(user._privileges.canGetBrand || user._rolesName === 'admin') && user._modules && user._modules.brands"
       routerLinkActive="active">
      <mat-icon>share</mat-icon>
      Social media</a>
    <!--        <a mat-list-item-->
    <!--           routerLink="/feedback"-->
    <!--           *ngIf="(user._privileges.canGetFeedback || user._rolesName === 'admin') && user._modules && user._modules.feedback"-->
    <!--           routerLinkActive="active">-->
    <!--            <mat-icon>reply_all</mat-icon>-->
    <!--            Feedback</a>-->
    <a mat-list-item
       routerLink="/services"
       *ngIf="(user._privileges.canGetService || user._rolesName === 'admin') && user._modules && user._modules.services"
       routerLinkActive="active">
      <mat-icon>support</mat-icon>
      Service desk</a>
    <a mat-list-item
       routerLink="/settings"
       *ngIf="user._privileges.canUpdateCompany || user._rolesName === 'admin'"
       routerLinkActive="active">
      <mat-icon>settings</mat-icon>
      Settings and invoices</a>
    <a mat-list-item
       routerLink="/roles"
       *ngIf="(user._privileges.canUpdateRole && user._privileges.canGetRole) || user._rolesName === 'admin'"
       routerLinkActive="active">
      <mat-icon>security</mat-icon>
      Roles and privileges</a>
    <a mat-list-item
       routerLink="/modules"
       *ngIf="(user._privileges.canGetModule) || user._rolesName === 'admin'"
       routerLinkActive="active">
      <mat-icon>view_module</mat-icon>
      Modules and costs</a>
  </mat-nav-list>

  <section class="bottom">
    <div>
      <small><a routerLink="/changelog" class="link" *ngIf="log"><span
        *ngIf="!user.changelog || (user.changelog.page !== log.version)" matBadge="•" matBadgeSize="small"
        matBadgeOverlap="false">What's new?</span><span *ngIf="user.changelog && (user.changelog.page === log.version)">What's new?</span></a></small>
    </div>
    <div>
      <small><a href="assets/pdf/rules.pdf" target="_blank" class="link">Rules and regulations</a></small>
    </div>
    <div>
      <small><a href="assets/pdf/privacy.pdf" target="_blank" class="link">Privacy policy</a></small>
    </div>
    <div>
      <small>Copyright &copy; 2020, v. <span *ngIf="log">{{ log.version }}</span></small>
    </div>
  </section>
</ng-container>
