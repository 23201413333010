<section class="aside-subnav" [ngClass]="{'aside-subnav--full-page': showFullPage}">
  <ng-container *ngIf="projects; else loading">
    <ng-container *ngIf="projects.length">
      <div class="search">
        <mat-form-field>
          <input matInput placeholder="Search" type="search" [(ngModel)]="searchProject">
          <mat-icon matSuffix>search</mat-icon>
          <mat-hint *ngIf="!showArchive">in active projects</mat-hint>
          <mat-hint *ngIf="showArchive">in archival projects</mat-hint>
        </mat-form-field>
        <mat-slide-toggle (change)="onShowArchive()" [checked]="showArchive">Show archive</mat-slide-toggle>
      </div>

      <!-- projects -->
      <mat-nav-list
        [ngClass]="{'mat-nav-list--button': user._rolesName === 'admin' || user._privileges.canCreateProject}">
        <!-- favorite -->
        <div *ngFor="let project of projects | filter:searchProject:['name','shortDesc', 'clientName']">
          <mat-list-item
            class="mat-list-item--favorite"
            *ngIf="(project.access || user._rolesName === 'admin') && user.settingsData && user.settingsData.projects_favorites && user.settingsData.projects_favorites[project.uid]"
            routerLinkActive="active">
            <a class="mat-list-item-link" routerLink="/projects/{{ project.uid }}"></a>
            <div class="favorite">
              <span
                *ngIf="!user.settingsData || !user.settingsData.projects_favorites || !user.settingsData.projects_favorites[project.uid]"
                class="favorite-outline material-icons"
                (click)="onAddFavorite(project.uid)">star_outline</span>
              <span
                *ngIf="user.settingsData && user.settingsData.projects_favorites && user.settingsData.projects_favorites[project.uid]"
                class="favorite-star material-icons"
                (click)="onRemoveFavorite(project.uid)">star</span>
            </div>
            <div matLine
                 matTooltip="{{ project.name }}"
                 matTooltipPosition="above">
              {{ project.name }}
            </div>
            <div matLine *ngIf="project.shortDesc">
              <small>{{project.shortDesc}}</small>
            </div>
            <div matLine *ngIf="project.client">
              <small>{{project.client.name}}</small>
            </div>
          </mat-list-item>
        </div>
        <!-- /favorites -->

        <!-- rest projects -->
        <div *ngFor="let project of projects | filter:searchProject:['name','shortDesc', 'clientName']">
          <!-- user has access or user is admin -->
          <mat-list-item
            *ngIf="(project.access || user._rolesName === 'admin') && (!user.settingsData || !user.settingsData.projects_favorites || !user.settingsData.projects_favorites[project.uid])"
            routerLinkActive="active">
            <a class="mat-list-item-link" routerLink="/projects/{{ project.uid }}"></a>
            <div class="favorite">
              <span
                *ngIf="!user.settingsData || !user.settingsData.projects_favorites || !user.settingsData.projects_favorites[project.uid]"
                class="favorite-outline material-icons"
                (click)="onAddFavorite(project.uid)">star_outline</span>
              <span
                *ngIf="user.settingsData && user.settingsData.projects_favorites && user.settingsData.projects_favorites[project.uid]"
                class="favorite-star material-icons"
                (click)="onRemoveFavorite(project.uid)">star</span>
            </div>
            <div matLine
                 matTooltip="{{ project.name }}"
                 matTooltipPosition="above">
              {{ project.name }}
            </div>
            <div matLine *ngIf="project.shortDesc">
              <small>{{project.shortDesc}}</small>
            </div>
            <div matLine *ngIf="project.client">
              <small>{{project.client.name}}</small>
            </div>
          </mat-list-item>
          <!-- /user has access -->

          <!-- user has NOT access but user can create project - user can find project and decide if should create the new one -->
          <div *ngIf="user._privileges.canCreateProject">
            <mat-list-item class="disabled" *ngIf="!project.access" disableRipple>
              <div matLine>
                {{ project.name }}
              </div>
              <div matLine *ngIf="project.shortDesc">
                <small>{{project.shortDesc}}</small>
              </div>
              <div matLine *ngIf="project.client">
                <small>{{project.client.name}}</small>
              </div>
            </mat-list-item>
          </div>
          <!-- /user has NOT access -->

        </div>
      </mat-nav-list>
      <!-- /rest projects -->

    </ng-container>
  </ng-container>

  <ng-template #loading>
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </ng-template>

  <div class="container-btm"
       *ngIf="user._rolesName === 'admin' || user._privileges.canCreateProject">
    <a routerLink="/projects/project-add" mat-stroked-button color="primary">Add project</a>
  </div>
</section>

<main class="help-margin-left-180px">
  <div class="container">
    <header class="page-header"
            *ngIf="!showFullPage">
      <h1 [innerHTML]="title" *ngIf="title"></h1>
      <nav mat-tab-nav-bar>
        <a mat-tab-link
           *ngFor="let link of navLinks"
           [routerLink]="link.path"
           routerLinkActive #rla="routerLinkActive"
           [active]="rla.isActive">
          {{link.label}}
        </a>
      </nav>
    </header>

    <!-- project content -->
    <router-outlet></router-outlet>

    <!--    <header class="page-header"-->
    <!--            *ngIf="showFullPage">-->
    <!--      <h1>Projects</h1>-->
    <!--    </header>-->

    <!--    <ul>-->
    <!--      <li *ngFor="let project of projects | filter:searchProject:['name','shortDesc', 'clientName']">-->
    <!--        <a routerLink="/projects/{{ project.uid }}">{{ project.name }}</a>-->
    <!--      </li>-->
    <!--    </ul>-->

  </div>
</main>
