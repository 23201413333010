import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserType} from '../../_types/user.type';
import {ClientType} from '../../_types/client.type';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../_services/users.service';
import {ClientsService} from '../../_services/clients.service';
import {BrandType} from '../../_types/brand.type';
import {BrandsService} from '../../_services/brands.service';
import {PostType} from '../../_types/post.type';

@Component({
  selector: 'app-social',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit, OnDestroy {

  user: UserType;
  uid: string;
  title: string;
  brands: BrandType[];
  brandsComplete = false;
  clients: ClientType[];
  clientsComplete = false;
  searchBrand: string;
  navLinks: any[];
  postEdit: PostType;

  showFullPage = false;

  private subscriptions: Array<Subscription> = [];

  constructor(private router: Router,
              private usersService: UsersService,
              private route: ActivatedRoute,
              private brandsService: BrandsService,
              private clientsService: ClientsService) {

    // set menu and title
    this.subscriptions.push(this.router.events.subscribe(() => {
      this.showFullPage = false;
      switch (this.router.url) {
        case '/brands/brand-add': {
          this.title = 'Add social brand';
          break;
        }

        case '/brands': {
          this.title = '';
          this.navLinks = [];
          this.showFullPage = true;
          break;
        }
      }
    }));

    // set user
    this.user = this.route.snapshot.parent.data.user;

    // get brands
    this.getBrands();

    // get clients
    if (this.user._privileges.canGetClient || this.user._rolesName === 'admin') {
      this.getClients();
    }

  }

  ngOnInit() {

  }

  private getBrands() {
    this.subscriptions.push(this.brandsService.getBrands(this.user).subscribe(brands => {
      this.brands = brands;
      this.brandsComplete = true;
      this.matchBrandsToClients();
    }));
  }

  private getClients() {
    this.subscriptions.push(this.clientsService.getClients(this.user).subscribe(clients => {
      this.clients = clients;
      this.clientsComplete = true;
      this.matchBrandsToClients();
    }));
  }

  private matchBrandsToClients() {
    if (this.brandsComplete && this.clientsComplete) {
      this.brands.forEach(brand => {
        // match client to brand
        this.clients.forEach(client => {
          if (client.uid === brand.clientRef.id) {
            brand.client = client;
          }
        });
      });
    }
  }

  setNav(uid) {
    this.uid = uid;

    this.navLinks = [];

    this.navLinks.push({
      path: uid + '/calendar',
      label: 'Calendar'
    });

    this.navLinks.push({
      path: uid + '/details',
      label: 'Details'
    });

    if (this.user._rolesName === 'admin' || this.user._privileges.canGetUser) {
      this.navLinks.push({
        path: uid + '/team',
        label: 'Team'
      });
    }

    this.navLinks.push({
      path: uid + '/followers',
      label: 'Followers'
    });

    this.navLinks.push({
      path: uid + '/changes',
      label: 'Changes'
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
