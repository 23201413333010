<div class="row" *ngIf="project && !project.userRefs[userInp.uid]">
  <div class="col">
    <div class="section--permissionDenied">
      You don't have permission to this task
    </div>
  </div>
</div>

<ng-container *ngIf="project && project.userRefs[userInp.uid]">
  <form #f="ngForm" autocomplete="off" *ngIf="task">

    <div class="row" *ngIf="!task.isActive">
      <div class="col">
        <div class="section--undoRemove">
          This task has been removed.
          <button mat-stroked-button class="mat-white" (click)="onUndoRemove()">Undo remove</button>
        </div>
      </div>
    </div>

    <div class="row">
      <!--date start-->
      <div class="col-2">
        <mat-form-field class="help-width-100p">
          <input name="dateStart"
                 matInput
                 [matDatepickerFilter]="filterWeekend"
                 [matDatepicker]="pickerStart"
                 (dateChange)="onChangeDateStart()"
                 placeholder="Start date"
                 [(ngModel)]="dateStartDatepicker"
                 #dateStart="ngModel"
                 readonly>
          <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
          <mat-datepicker #pickerStart></mat-datepicker>
        </mat-form-field>
        <span class="date-remove" (click)="onDateRemove()" *ngIf="dateStartDatepicker"><i
          class="material-icons">close</i></span>
      </div>

      <!--date end-->
      <div class="col-2">
        <mat-form-field class="help-width-100p">
          <input name="dateEnd"
                 matInput
                 [matDatepickerFilter]="filterWeekend"
                 [matDatepicker]="pickerEnd"
                 (dateChange)="onChangeDateEnd()"
                 placeholder="End date"
                 [(ngModel)]="dateEndDatepicker"
                 #dateEnd="ngModel"
                 readonly>
          <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
          <mat-datepicker #pickerEnd></mat-datepicker>
        </mat-form-field>
        <span class="date-remove" (click)="onDateRemove()" *ngIf="dateEndDatepicker"><i
          class="material-icons">close</i></span>
      </div>

      <!--estimation-->
      <div class="col-1">
        <mat-form-field class="help-width-100p">
          <input matInput
                 type="number"
                 name="estimation"
                 min="0.5"
                 step="0.5"
                 [(ngModel)]="task.estimation"
                 (blur)="onUpdate('estimation')"
                 (keyup.enter)="onUpdate('estimation')"
                 #estimation="ngModel"
                 required
                 placeholder="Estimation">
          <mat-error *ngIf="estimation.hasError('required')">
            Estimation is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <!--author-->
      <div class="col-2">
        <mat-form-field class="help-width-100p">
          <mat-label>Author</mat-label>
          <div matPrefix *ngIf="author">
            <div *ngIf="author.photoURL"
                 class="prefix-user"
                 [ngStyle]="{'background-image': 'url(' + author.photoURL + ')'}">
            </div>
            <div *ngIf="!author.photoURL"
                 class="prefix-user prefix-user--signs">
              {{ author.name | avatar }}
            </div>
          </div>
          <input matInput
                 name="author"
                 type="text"
                 [(ngModel)]="author"
                 required
                 placeholder="Author"
                 [matAutocomplete]="autoAuthor">
          <mat-autocomplete autoActiveFirstOption
                            #autoAuthor="matAutocomplete"
                            (optionSelected)="autocompleteSelectedAuthor()"
                            [displayWith]="autocompleteDisplayAuthor">
            <mat-option *ngFor="let author of users | filter:author:['name']" [value]="author">
              {{author.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="!author">
            Author is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <!--to do-->
      <div class="col-2">
        <mat-form-field class="help-width-100p">
          <mat-label>Todo</mat-label>
          <div matPrefix *ngIf="todo">
            <div *ngIf="todo.photoURL"
                 class="prefix-user"
                 [ngStyle]="{'background-image': 'url(' + todo.photoURL + ')'}">
            </div>
            <div *ngIf="!todo.photoURL"
                 class="prefix-user prefix-user--signs">
              {{ todo.name | avatar }}
            </div>
          </div>
          <input matInput
                 name="todo"
                 type="text"
                 [(ngModel)]="todo"
                 required
                 placeholder="Todo"
                 [matAutocomplete]="autoTodo">
          <mat-autocomplete autoActiveFirstOption
                            #autoTodo="matAutocomplete"
                            (optionSelected)="autocompleteSelectedTodo()"
                            [displayWith]="autocompleteDisplayTodo">
            <mat-option *ngFor="let todo of users | filter:todo:['name']"
                        [value]="todo">
              {{todo.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="!todo">
            Todo is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-2">
        <mat-form-field class="help-width-100p" *ngIf="project">
          <mat-label>Task group</mat-label>

          <input matInput
                 name="projectGroup"
                 type="text"
                 [(ngModel)]="group"
                 placeholder="Task group"
                 (keyup.enter)="autocompleteSelectedGroup()"
                 (blur)="autocompleteSelectedGroup()"
                 [matAutocomplete]="autoGroup">
          <mat-autocomplete #autoGroup="matAutocomplete"
                            (optionSelected)="autocompleteSelectedGroup()">
            <mat-option *ngFor="let group of project.groups | filter:group:['name']"
                        [value]="group.name"
                        matTooltip="{{ group.name }}"
                        matTooltipPosition="above">
              {{group.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <!--delete-->
      <div *ngIf="task.isActive" class="link--delete">
        <a (click)="showRemove = !showRemove" class="link" *ngIf="!showRemove">Delete</a>
        <span *ngIf="showRemove">Are you sure? <a (click)="showRemove = false" class="link">No</a>&nbsp;<button
          mat-button
          (click)="onRemove()" color="primary" class="link">Yes</button></span>
      </div>
    </div>

    <div class="row">
      <div class="col" *ngIf="project">
        <div class="section--urls">
          <!--project and task URL-->
          <ng-container *ngIf="viewInp === 'page'">
            <button mat-flat-button
                    matTooltip="Go to project"
                    matTooltipPosition="above"
                    type="button"
                    (click)="onGoToProject()">{{ project.name }}
              <ng-container *ngIf="project.shortDesc">({{ project.shortDesc }})</ng-container>
            </button>
            <button mat-flat-button
                    matTooltip="Go to task"
                    matTooltipPosition="above"
                    type="button"
                    (click)="onGoToTask()">{{ task.name }}</button>
          </ng-container>
          <ng-container *ngIf="viewInp === 'dialog'">
            <button mat-flat-button
                    matTooltip="Go to project"
                    matTooltipPosition="above"
                    type="button"
                    (click)="onGoToProject()"
                    [mat-dialog-close]>{{ project.name }}
              <ng-container *ngIf="project.shortDesc">({{ project.shortDesc }})</ng-container>
            </button>
            <button mat-flat-button
                    matTooltip="Go to task"
                    matTooltipPosition="above"
                    type="button"
                    (click)="onGoToTask()"
                    [mat-dialog-close]>{{ task.name }}</button>
          </ng-container>
          <button mat-button
                  matTooltip="Copy link to task"
                  type="button"
                  (click)="onCopyLink()"
                  matTooltipPosition="above"><i class="material-icons">link</i></button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="section--status-type-priority">
          <!-- status -->
          <mat-button-toggle-group #taskStatus="matButtonToggleGroup"
                                   (change)="onChangeStatus($event)"
                                   matTooltip="Status"
                                   matTooltipPosition="above"
                                   value="{{ task.status }}">
            <mat-button-toggle value="todo" class="mat-button-toggle--todo">Todo</mat-button-toggle>
            <mat-button-toggle value="inProgress" class="mat-button-toggle--inprogress">In progress
            </mat-button-toggle>
            <mat-button-toggle value="toCheck" class="mat-button-toggle--tocheck">To check
            </mat-button-toggle>
            <mat-button-toggle value="done" class="mat-button-toggle--done">Done</mat-button-toggle>
          </mat-button-toggle-group>
          <!--type-->
          <mat-button-toggle-group #taskType="matButtonToggleGroup"
                                   (change)="onChangeType($event)"
                                   matTooltip="Type"
                                   matTooltipPosition="above"
                                   value="{{ task.type }}">
            <mat-button-toggle value="feature" class="mat-button-toggle--feature"><span>Feature</span>
            </mat-button-toggle>
            <mat-button-toggle value="bug" class="mat-button-toggle--bug"><span>Bug</span>
            </mat-button-toggle>
            <mat-button-toggle value="check" class="mat-button-toggle--check"><span>Check</span>
            </mat-button-toggle>
          </mat-button-toggle-group>
          <!--priority-->
          <mat-button-toggle-group #taskPriority="matButtonToggleGroup"
                                   (change)="onChangePriority($event)"
                                   matTooltip="Priority"
                                   matTooltipPosition="above"
                                   value="{{ task.priority }}">
            <mat-button-toggle value="lowest" class="mat-button-toggle--lowest">Lowest</mat-button-toggle>
            <mat-button-toggle value="low" class="mat-button-toggle--low">Low</mat-button-toggle>
            <mat-button-toggle value="normal" class="mat-button-toggle--normal">Normal</mat-button-toggle>
            <mat-button-toggle value="high" class="mat-button-toggle--high">High</mat-button-toggle>
            <mat-button-toggle value="highest" class="mat-button-toggle--highest">Highest
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="section--name">
          <!--name-->
          <mat-form-field *ngIf="task.userAuthorRef.id && (task.userAuthorRef.id === userInp.uid)">
          <textarea matInput
                    name="name"
                    #name="ngModel"
                    [(ngModel)]="task.name"
                    (keyup.enter)="onUpdate('name'); false"
                    (keydown.enter)="false"
                    (blur)="onUpdate('name')"
                    matTextareaAutosize
                    required
                    placeholder="Name"></textarea>
          </mat-form-field>
          <h2 *ngIf="task.userAuthorRef.id && (task.userAuthorRef.id !== userInp.uid)">{{ task.name }}</h2>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="section--description">
          <!--description-->
          <ng-container *ngIf="task.userAuthorRef.id && (task.userAuthorRef.id === userInp.uid); else info">
            <quill-editor
              name="description"
              #description="ngModel"
              [(ngModel)]="task.description"
              (onSelectionChanged)="onChangeDescription($event)"
              placeholder="Write a description..."
              required>
              <div quill-editor-toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" [title]="'Bold'"></button>
                            <button class="ql-italic" [title]="'Italic'"></button>
                            <button class="ql-underline" [title]="'Underline'"></button>
                            <button class="ql-strike" [title]="'Strike'"></button>
                        </span>
                <span class="ql-formats">
                            <button class="ql-list" value="ordered" [title]="'List ordered'"></button>
                            <button class="ql-list" value="bullet" [title]="'List bullet'"></button>
                        </span>
                <span class="ql-formats">
                            <button class="ql-link" [title]="'Link'"></button>
                            <button class="ql-blockquote" [title]="'Blockquote'"></button>
                            <button class="ql-code-block" [title]="'Code-block'"></button>
                            <button class="ql-clean" [title]="'Clean format'"></button>
                        </span>
              </div>
            </quill-editor>
          </ng-container>
          <ng-template #info>
            <div [outerHTML]="task.description | urlify"></div>
          </ng-template>
        </div>
      </div>
    </div>
  </form>

  <div class="row">
    <div class="col">
      <div class="section--files">
        <!--assets-->
        <div class="drop-container"
             ngFileDrop
             *ngIf="author && author.uid === userInp.uid"
             [options]="options"
             (uploadOutput)="onUploadDescription($event)"
             (click)="onUpdate('description')"
             [uploadInput]="uploadInput"
             [ngClass]="{ 'is-drop-over': dragOver }">
          <input type="file"
                 ngFileSelect
                 [options]="options"
                 (uploadOutput)="onUploadDescription($event)"
                 [uploadInput]="uploadInput"
                 multiple>
          <span>Select or drop file(s) here</span>
        </div>

        <mat-accordion *ngIf="task && task.files">
          <ng-container *ngFor="let file of task.files; let i = index">
            <mat-expansion-panel *ngIf="file.isActive">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ file.name }}
                </mat-panel-title>
                <mat-panel-description>
                  {{ file.size | fileSize }} ({{ file.type }})
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="mat-expansion-panel-wrapper"
                   [ngClass]="{ 'mat-expansion-panel-wrapper__expand': expandImage }">
                <div class="options">
                  <a class="link" download href="{{ file.url }}">Download</a>
                  <div class="options--right">
                    <a (click)="taskFileShowRemove[i] = !taskFileShowRemove[i]" class="link"
                       *ngIf="!taskFileShowRemove[i]">Delete</a>
                    <span *ngIf="taskFileShowRemove[i]">Are you sure? <a
                      (click)="taskFileShowRemove[i] = false" class="link">No</a>&nbsp;<a
                      (click)="onDeleteTaskFile(i)" class="link">Yes</a></span>
                    <span *ngIf="expandImage"
                          (click)="expandImage = !expandImage"
                          class="link-close"><span class="material-icons">clear</span></span>
                  </div>
                </div>
                <div class="expansion-panel__image"
                     *ngIf="file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/gif'">
                  <img src="{{ file.url }}" (click)="expandImage = !expandImage">
                </div>
              </div>

            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="section--comments-header">
        <h2>Comments ({{ messages.length }})</h2>
        <mat-slide-toggle [checked]="hideAutoComments"
                          (change)="onChangeUserSettings()">Hide auto comments
        </mat-slide-toggle>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-list class="mat-list-messages">
        <mat-list-item class="mat-list-item--write-message" *ngIf="userInp">
          <div matListAvatar
               *ngIf="userInp.photoURL"
               [ngStyle]="{'background-image': 'url(' + userInp.photoURL + ')'}"></div>
          <div matListAvatar
               *ngIf="!userInp.photoURL">
            {{ userInp.name | avatar }}
          </div>
          <div matLine>
            <div class="mat-line--name">{{userInp.name}}</div>
            <div>
              <form #f="ngForm"
                    (ngSubmit)="onSend(f)"
                    (keydown.meta.enter)="onSend(f)"
                    (keydown.control.enter)="onSend(f)">
                <quill-editor
                  name="messageText"
                  #message="ngModel"
                  ngModel
                  placeholder="Write a message..."
                  required>
                  <div quill-editor-toolbar>
                                        <span class="ql-formats">
                                            <button class="ql-bold" [title]="'Bold'"></button>
                                            <button class="ql-italic" [title]="'Italic'"></button>
                                            <button class="ql-underline" [title]="'Underline'"></button>
                                            <button class="ql-strike" [title]="'Strike'"></button>
                                        </span>
                    <span class="ql-formats">
                                            <button class="ql-list" value="ordered" [title]="'List ordered'"></button>
                                            <button class="ql-list" value="bullet" [title]="'List bullet'"></button>
                                        </span>
                    <span class="ql-formats">
                                        <button class="ql-link" [title]="'Link'"></button>
                                        <button class="ql-blockquote" [title]="'Blockquote'"></button>
                                        <button class="ql-code-block" [title]="'Code-block'"></button>
                                        <button class="ql-clean" [title]="'Clean format'"></button>
                                    </span>
                  </div>
                </quill-editor>


                <div class="drop-container"
                     ngFileDrop
                     [options]="options"
                     (uploadOutput)="onUploadMessage($event)"
                     [uploadInput]="uploadInput"
                     [ngClass]="{ 'is-drop-over': dragOver }">
                  <input type="file"
                         ngFileSelect
                         [options]="options"
                         (uploadOutput)="onUploadMessage($event)"
                         [uploadInput]="uploadInput"
                         multiple>
                  <span>Select or drop file(s) here</span>
                </div>

                <ul *ngIf="messageFiles" class="list-files">
                  <li *ngFor="let file of messageFiles; let i = index">
                    <div class="name"><a href="{{ file.url }}" class="link" target="_blank">{{file.name}}</a></div>
                    <span class="info">{{ file.size | fileSize }} ({{ file.type }})</span>
                    <i class="material-icons" (click)="onDeleteMessageFile(i)">clear</i>
                  </li>
                </ul>

                <button type="submit"
                        class="submit"
                        mat-raised-button color="primary"
                        [disabled]="f.form.invalid">Send
                </button>
                <small class="cmdCtrlEnter">or press CMD/CTRL + Enter</small>
              </form>
            </div>
          </div>
        </mat-list-item>

        <ng-container *ngFor="let message of messages">
          <app-shared-message *ngIf="message && !(hideAutoComments && message.type == 'auto')"
                              [message]="message"></app-shared-message>
        </ng-container>

      </mat-list>
    </div>
  </div>
</ng-container>
