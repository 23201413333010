<section class="page-section">
    <form (ngSubmit)="onSave(f)" #f="ngForm">
        <div class="row">
            <div class="col-4">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field *ngIf="user">
                            <input matInput
                                   type="email"
                                   name="email"
                                   #email="ngModel"
                                   [(ngModel)]="user.email"
                                   email
                                   required
                                   placeholder="E-mail">
                            <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
                                Please enter a valid email address
                            </mat-error>
                            <mat-error *ngIf="email.hasError('required')">
                                Email is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>

                        <button type="submit"
                                mat-raised-button color="primary"
                                [disabled]="f.form.invalid">Save
                        </button>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </form>
</section>