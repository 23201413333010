<main>
    <div class="container">
        <div class="row">
            <div class="col">

                <header class="page-header">
                    <h1>What's new?</h1>
                </header>
                <section class="page-section">
                    <mat-list *ngIf="logs.length" role="list">
                        <mat-list-item *ngFor="let log of logs">
                            <div class="dateTime">
                                {{ log.datetime.toDate() | date: 'yyyy-MM-dd'}}<br>
                                <small>v. {{ log.version }}</small>
                            </div>
                            <div matLine>
                                <div class="title" [innerHTML]="log.title | safeHtml"></div>
                                <div class="description" [innerHTML]="log.description | safeHtml"></div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </section>

            </div>
        </div>
    </div>
</main>
