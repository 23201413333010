import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {AuthService} from '../../_services/auth.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {UsersService} from '../../_services/users.service';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    constructor(private authService: AuthService,
                private usersService: UsersService,
                private router: Router,
                private progress: NgProgress,
                private snackBar: MatSnackBar) {
    }

    ngOnInit(): void {
    }

    onLogin(form: NgForm): void {
        if (form.valid) {
            this.progress.ref().start();
            this.authService.login(form.value)
                .then(user => {
                    if (!user.user.emailVerified) {
                        this.snackBar.open('E-mail has not been verified', '', environment.snackbarWarn);
                        this.progress.ref().complete();
                        this.authService.logout().then();
                    } else {
                        // update user isEmailVerified
                        this.usersService.updateUser({
                            isEmailVerified: true
                        }, user.user.uid).then();

                        // get user and check if it's active
                        this.usersService.getUser().subscribe((user2: { isActive: any; }) => {
                            if (!user2.isActive) {
                                this.progress.ref().complete();
                                this.snackBar.open('Your account has been removed', '', environment.snackbarWarn);
                            } else {
                                // redirect to dashboard
                                this.progress.ref().complete();
                                this.router.navigate(['/dashboard']).then();
                            }
                        });
                    }
                })
                .catch(err => {
                    this.progress.ref().complete();
                    this.snackBar.open(err.message, '', environment.snackbarWarn);
                });
        }
    }

}
