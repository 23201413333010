<main>
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="logo"><img src="../../../assets/soteam.png" alt="Soteam"></h1>

                <mat-card>
                    <mat-card-header>
                        <mat-card-title>Password forgot</mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                        <form name="form" (ngSubmit)="onSend(f)" #f="ngForm" class="text-left">

                            <mat-form-field class="help-width-100p">
                                <input matInput
                                       type="email"
                                       name="email"
                                       #email="ngModel"
                                       email
                                       ngModel
                                       required
                                       placeholder="E-mail">
                                <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
                                    Please enter a valid e-mail address
                                </mat-error>
                                <mat-error *ngIf="email.hasError('required')">
                                    E-mail is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>

                            <button type="submit"
                                    mat-raised-button color="primary"
                                    [disabled]="f.form.invalid">Send
                            </button>
                            <a routerLink="/login" class="link link--btn">Back to log in</a>
                        </form>
                    </mat-card-content>
                </mat-card>
                <p>Don't have an account? <a routerLink="/register" class="btn-sign-up" mat-stroked-button color="primary">Sign Up</a></p>
                <p><a href="https://so.team" class="link" target="_blank">About Soteam</a></p>
            </div>
        </div>
    </div>
</main>
