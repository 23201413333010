<section class="page-section">
  <form (ngSubmit)="onSave(f)" #f="ngForm" autocomplete="off" *ngIf="brand">
    <div class="row">
      <div class="col-4">
        <mat-card>
          <mat-card-content *ngIf="user._privileges.canUpdateBrand || user._rolesName === 'admin'; else info">
            <div class="section--undoRemove" *ngIf="!brand.isActive">
              This social brand has been removed.
              <button mat-stroked-button class="mat-white" (click)="onUndoRemove()" type="button">Undo
                remove
              </button>
            </div>

            <div class="col-header">Details</div>
            <mat-form-field>
              <input matInput
                     type="text"
                     name="name"
                     #name="ngModel"
                     [(ngModel)]="brand.name"
                     required
                     placeholder="Name">
              <mat-error *ngIf="name.hasError('required')">
                Name is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <input matInput
                     type="text"
                     name="client"
                     (blur)="autocompleteBlur()"
                     [(ngModel)]="client"
                     appForbiddenType="string"
                     required
                     placeholder="Client"
                     [matAutocomplete]="autoClients">
              <mat-autocomplete
                autoActiveFirstOption
                #autoClients="matAutocomplete"
                [displayWith]="autocompleteDisplay"
                (optionSelected)="autocompleteSelected($event.option.value)">
                <mat-option *ngFor="let client of clients | filter:client:['name']" [value]="client">
                  {{client.name}}
                </mat-option>
              </mat-autocomplete>
              <mat-error>
                Client is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <input matInput
                     type="text"
                     name="shortDesc"
                     #shortDesc="ngModel"
                     [(ngModel)]="brand.shortDesc"
                     placeholder="Short description">
            </mat-form-field>

            <mat-form-field>
                            <textarea matInput
                                      name="notes"
                                      #notes="ngModel"
                                      [(ngModel)]="brand.notes"
                                      matTextareaAutosize
                                      placeholder="Notes"></textarea>
            </mat-form-field>

            <div class="help-margin-bottom-20px">
              <div *ngIf="brand.imageURL" class="picture"
                   [ngStyle]="{'background-image': 'url(' + brand.imageURL + ')'}">
                <i class="material-icons" (click)="onDeleteImage()">clear</i>
              </div>
              <div class="picture" *ngIf="!brand.imageURL">{{ brand.name | avatar}}</div>
            </div>

            <div class="help-margin-bottom-20px">
              <button mat-stroked-button
                      class="btn-file"
                      color="primary"
                      type="button">Choose file<input id="picture"
                                                      type="file"
                                                      class="form-control"
                                                      name="picture"
                                                      (change)="chooseFile($event)"/>
              </button>
              <span *ngIf="selectedFiles">{{ selectedFiles[0].name }}</span>
            </div>

            <button type="submit"
                    mat-raised-button color="primary"
                    [disabled]="f.form.invalid">Save
            </button>
            <span *ngIf="brand.isActive">
                            <a (click)="showRemove = !showRemove" class="link link--btn" *ngIf="!showRemove">Delete</a>
                            <span *ngIf="showRemove" class="link--btn">Are you sure? <a (click)="showRemove = false"
                                                                                        class="link">No</a>&nbsp;<a
                              (click)="onRemove()" class="link">Yes</a></span>
                        </span>

          </mat-card-content>

          <ng-template #info>
            <div class="col-header">Details</div>
            <mat-form-field class="mat-form-field--readonly">
              <input matInput
                     type="text"
                     name="name"
                     #name="ngModel"
                     [(ngModel)]="brand.name"
                     readonly
                     placeholder="Name">
            </mat-form-field>

            <mat-form-field *ngIf="user._privileges.canGetClient"
                            class="mat-form-field--readonly">
              <input matInput
                     type="text"
                     name="client"
                     [(ngModel)]="client.name"
                     readonly
                     placeholder="Client">
            </mat-form-field>

            <mat-form-field class="mat-form-field--readonly">
              <input matInput
                     type="text"
                     name="shortDesc"
                     readonly
                     [(ngModel)]="brand.shortDesc"
                     placeholder="Short description">
            </mat-form-field>

            <mat-form-field class="mat-form-field--readonly">
                <textarea matInput
                          name="notes"
                          readonly
                          [(ngModel)]="brand.notes"
                          matTextareaAutosize
                          placeholder="Notes"></textarea>
            </mat-form-field>
          </ng-template>

        </mat-card>
      </div>
    </div>


  </form>
</section>
