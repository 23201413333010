import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {NgForm} from '@angular/forms';
import {ClientType} from '../../../_types/client.type';
import {ClientsService} from '../../../_services/clients.service';
import {environment} from '../../../../environments/environment';
import * as firebase from 'firebase/app';
import {UserType} from '../../../_types/user.type';
import {ClientsComponent} from '../clients.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-client-details',
    templateUrl: './client-details.component.html',
    styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit, OnDestroy {

    client: ClientType;
    user: UserType;
    showRemove = false;

    private subscriptions: Array<Subscription> = [];


    constructor(private clientsService: ClientsService,
                private clientsComponent: ClientsComponent,
                private afs: AngularFirestore,
                private route: ActivatedRoute,
                private router: Router,
                private progress: NgProgress,
                private snackBar: MatSnackBar) {


        this.subscriptions.push(this.route.params.subscribe(params => {
            this.clientsComponent.setNav(params.uid);

            // set user
            this.user = this.route.snapshot.parent.parent.data.user;

            // get client
            this.subscriptions.push(this.clientsService.getClient(this.user, this.clientsComponent.uid).subscribe(client => {
                this.client = client;
                this.clientsComponent.title = this.client.name;
            }));
        }));

    }

    ngOnInit() {
    }

    onSave(form: NgForm) {
        if (form.valid) {
            this.progress.ref().start();
            this.client.name = form.value.name;
            this.client.dateUpdate = firebase.firestore.FieldValue.serverTimestamp();
            this.client.userUpdateRef = this.afs.doc(`users/${this.user.uid}`).ref;
            this.clientsService.updateClient(this.client, this.user)
                .then(() => {
                    this.progress.ref().complete();
                    this.snackBar.open('Client has been updated', '', environment.snackbarSuccess);
                })
                .catch(err => {
                    this.progress.ref().complete();
                    this.snackBar.open(err.message, '', environment.snackbarWarn);
                });
        }
    }

    onRemove() {
        this.progress.ref().start();
        const fields = {
            isActive: false,
            dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            userUpdateRef: this.afs.doc(`users/${this.user.uid}`).ref
        };
        this.clientsService.removeClient(this.client, this.user, fields)
            .then(() => {
                this.router.navigate(['/clients'])
                    .then(() => {
                        this.clientsComponent.removeNav();
                        this.progress.ref().complete();
                        this.snackBar.open('Client has been removed', '', environment.snackbarSuccess);
                    })
                    .catch(err => {
                        this.progress.ref().complete();
                        this.snackBar.open(err.message, '', environment.snackbarWarn);
                    });
            })
            .catch(err => {
                this.progress.ref().complete();
                this.snackBar.open(err.message, '', environment.snackbarWarn);
            });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
