<section class="page-section page-section--settings-invoices">
  <mat-accordion *ngIf="invoices.length && company">
    <ng-container *ngFor="let invoice of invoices">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title [ngClass]="{'paid': invoice.isPaid}">
            {{ invoice.name }}
          </mat-panel-title>
          <mat-panel-description *ngIf="company.currency === 'PLN'">
            Total: <strong>{{ invoice.totalNet | number: '0.2' }} PLN (net) / {{ invoice.totalGross | number: '0.2' }} PLN (gross)</strong> |
            Deadline: <strong>{{ invoice.deadline.toDate() | date: 'yyyy-MM-dd' }}</strong> | Status: <strong class="payment payment__paid" *ngIf="invoice.isPaid">paid</strong><strong class="payment payment__unpaid" *ngIf="!invoice.isPaid">unpaid</strong>
          </mat-panel-description>
          <mat-panel-description *ngIf="company.currency === 'USD'">
            Total: <strong>{{ invoice.totalNet | number: '0.2' }} USD (net) / {{ invoice.totalGross | number: '0.2' }} USD (gross)</strong> |
            Deadline: <strong>{{ invoice.deadline.toDate() | date: 'yyyy-MM-dd' }}</strong> | Status: <strong class="payment payment__paid" *ngIf="invoice.isPaid">paid</strong><strong class="payment payment__unpaid" *ngIf="!invoice.isPaid">unpaid</strong>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="mat-expansion-panel-body">
          <a class="link" target="_blank" href="{{ invoice.url }}">Open the invoice in a new window</a>
        </div>

      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>

  <p *ngIf="!invoices.length">You don't have any invoices.</p>
</section>
