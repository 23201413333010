import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {UsersService} from './users.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(public afAuth: AngularFireAuth,
                public usersService: UsersService) {
    }


    async register(user: { email: string; password: string; }): Promise<any>  {
      return (this.afAuth).createUserWithEmailAndPassword(user.email, user.password);
    }

    async login(user: { email: any; password: any; }): Promise<any>  {
      return (this.afAuth).signInWithEmailAndPassword(user.email, user.password);
    }

    async sendEmailVerification(): Promise<any>  {
      // @ts-ignore
      return (await this.afAuth.currentUser).sendEmailVerification();
    }

    async logout(): Promise<void>  {
      return (this.afAuth).signOut();
    }

}
