import {Component, OnDestroy, OnInit} from '@angular/core';
import {CompanyType} from '../../../_types/company.type';
import {Subscription} from 'rxjs/internal/Subscription';
import {UsersService} from '../../../_services/users.service';
import {CompaniesService} from '../../../_services/companies.service';
import {environment} from '../../../../environments/environment';
import {UserType} from '../../../_types/user.type';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgProgress} from 'ngx-progressbar';

@Component({
    selector: 'app-settings-domains',
    templateUrl: './settings-domains.component.html',
    styleUrls: ['./settings-domains.component.scss']
})
export class SettingsDomainsComponent implements OnInit, OnDestroy {

    company: CompanyType | any;
    domain = '';
    user: UserType;

    private subscriptions: Array<Subscription> = [];

    constructor(private usersService: UsersService,
                private companiesService: CompaniesService,
                private route: ActivatedRoute,
                private snackBar: MatSnackBar,
                private progress: NgProgress) {

        // set user
        // @ts-ignore
        this.user = this.route.snapshot.parent.parent.data.user;

        // get company
        this.subscriptions.push(this.companiesService.getCompany(this.user.companyRef.path).subscribe((company: any) => {
            this.company = company;
        }));

    }

    ngOnInit(): void {
    }

    // onRemove(domain) {
    //     this.progress.ref().start();
    //     const index = this.company.domains.indexOf(domain);
    //     if (index > -1) {
    //         this.company.domains.splice(index, 1);
    //     }
    //     this.companiesService.insertCompany(this.company)
    //         .then(() => {
    //             this.progress.ref().complete();
    //             this.snackBar.open('Domain has been removed', '', environment.snackbarSuccess);
    //         })
    //         .catch(err => {
    //             this.progress.ref().complete();
    //             this.snackBar.open(err.message, '', environment.snackbarWarn);
    //         });
    // }

    // onAdd() {
    //     if (this.domain != '') {
    //         this.progress.ref().start();
    //         this.company.domains.push(this.domain);
    //         this.domain = '';
    //         this.companiesService.insertCompany(this.company)
    //             .then(() => {
    //                 this.progress.ref().complete();
    //                 this.snackBar.open('Domain has been added.', '', environment.snackbarSuccess);
    //             })
    //             .catch(err => {
    //                 this.progress.ref().complete();
    //                 this.snackBar.open(err.message, '', environment.snackbarWarn);
    //             })
    //     }
    //
    // }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
