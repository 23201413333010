<mat-card class="mat-card--dashboard">
  <mat-card-header>
    <mat-card-title>Weekly progress</mat-card-title>
    <mat-card-subtitle>Check users tasks</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="mat-card-settings">
      <div>
        <a mat-stroked-button (click)="prevWeek()">Prev week</a>
        <span class="dates">{{ monday | date: 'yyyy-MM-dd' }} - {{ friday | date: 'yyyy-MM-dd' }}</span>
        <a mat-stroked-button (click)="nextWeek()">Next week</a>
        <mat-form-field>
          <input matInput placeholder="Search" type="search" [(ngModel)]="searchTask">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div>
        <mat-slide-toggle [checked]="userCurrent.settings.dashboard_weeklyProgress_showHiddenUsers"
                          (change)="onChangeUserSettings('showHiddenUsers')">Show hidden users
        </mat-slide-toggle>

        <mat-slide-toggle [checked]="userCurrent.settings.dashboard_weeklyProgress_hideTodoTasks"
                          (change)="onChangeUserSettings('hideTodoTasks')">Hide todo tasks
        </mat-slide-toggle>

        <mat-slide-toggle [checked]="userCurrent.settings.dashboard_weeklyProgress_hideAuthorTasks"
                          (change)="onChangeUserSettings('hideAuthorTasks')">Hide ordered tasks
        </mat-slide-toggle>
      </div>
    </div>

    <div class="row row--header">
      <div class="col-2"></div>
      <ng-container *ngFor="let header of headerWeek">
        <div class="col-2"
             [ngClass]="{'col--today': header.today}">
          <div>{{ header.name }}</div>
          <div class="date">
            <small>{{ header.date }}</small>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-container *ngFor="let user of users">
      <div class="row row--user"
           *ngIf="user.isActive && (!userCurrent.settingsData.dashboard_hiddenUsers || !userCurrent.settingsData.dashboard_hiddenUsers[user.uid] || userCurrent.settings.dashboard_weeklyProgress_showHiddenUsers)"
           [ngClass]="{'hidden': userCurrent.settingsData.dashboard_hiddenUsers && userCurrent.settingsData.dashboard_hiddenUsers[user.uid]}">
        <div class="col-2 col--profile">
          <a routerLink="/users/{{ user.uid }}">
            <div class="profile__avatar"
                 *ngIf="user.photoURL"
                 [ngStyle]="{'background-image': 'url(' + user.photoURL + ')'}"></div>
            <div class="profile__avatar"
                 *ngIf="!user.photoURL">
              {{ user.name | avatar }}
            </div>
            <div class="profile__name">
              <div>{{ user.name }}</div>
              <small>{{ user._rolesName }}</small>
            </div>
          </a>
          <div class="profile__ico">
            <button
              *ngIf="!userCurrent.settingsData.dashboard_hiddenUsers || !userCurrent.settingsData.dashboard_hiddenUsers[user.uid]"
              matTooltip="Hide user"
              matTooltipPosition="above"
              mat-icon-button
              (click)="hideUser(user.uid)">
              <i class="material-icons">visibility_off</i>
            </button>
            <button
              *ngIf="userCurrent.settingsData.dashboard_hiddenUsers && userCurrent.settingsData.dashboard_hiddenUsers[user.uid]"
              matTooltip="Show user"
              matTooltipPosition="above"
              mat-icon-button
              (click)="showUser(user.uid)">
              <i class="material-icons">visibility</i>
            </button>
          </div>
        </div>
        <ng-container *ngFor="let header of headerWeek">
          <ng-container *ngIf="user.tasks && user.tasks[header.date]">
            <div class="col-2 col--tasks"
                 [ngClass]="{'col--today': user.tasks[header.date].today}">
              <p class="title"
                 *ngIf="!userCurrent.settings.dashboard_weeklyProgress_hideTodoTasks">
                <small *ngIf="user.tasks[header.date]['todoHours']">
                  Todo ({{ user.tasks[header.date]['todoHours'] }}h)
                </small>
              </p>
              <ul class="tasks"
                  *ngIf="user.tasks[header.date]['todo'] && !userCurrent.settings.dashboard_weeklyProgress_hideTodoTasks">
                <li *ngFor="let task of user.tasks[header.date]['todo'] | filter:searchTask:['name', 'projectName']">
                  <ng-container *ngIf="task.project">
                    <div *ngIf="task.project.access"
                         routerLinkActive="active"
                         matTooltip="({{ task.priority }}) {{ task.name }} - {{ task.project.name }}"
                         matTooltipPosition="above"
                         class="task"
                         (mouseenter)="onEnterTask(task)"
                         (mouseleave)="onLeaveTask()"
                         (click)="onOpenDialog(task)"
                         [ngClass]="{'task--start': task.taskStart, 'task--end': task.taskEnd, 'task--enter': (task.uid == enterTaskUid), 'task--lowest': (task.priority == 'lowest'), 'task--low': (task.priority == 'low'), 'task--normal': (task.priority == 'normal'), 'task--high': (task.priority == 'high'), 'task--highest': (task.priority == 'highest'),'task--done': (task.status == 'done'), 'task--inprogress': (task.status == 'inProgress'), 'task--tocheck': (task.status == 'toCheck'), 'task--feature': (task.type == 'feature'), 'task--bug': (task.type == 'bug'), 'task--check': (task.type == 'check')}"
                         [ngStyle]="{'height.px': task.estimationDay * 20, 'line-height.px': task.estimationDay * 20}">
                      <ng-container *ngIf="task.estimationDay > 0.5">
                        <span>{{ task.name }}</span>
                      </ng-container>
                    </div>
                    <div *ngIf="!task.project.access"
                         matTooltip="({{ task.priority }}) {{ task.name }} - {{ task.project.name }}"
                         matTooltipPosition="above"
                         class="task task--denied"
                         [ngStyle]="{'height.px': task.estimationDay * 20, 'line-height.px': task.estimationDay * 20}">
                      <ng-container *ngIf="task.estimationDay > 0.5">
                        <span>{{ task.name }}</span>
                      </ng-container>
                    </div>
                  </ng-container>
                </li>
              </ul>
              <p class="title"
                 *ngIf="!userCurrent.settings.dashboard_weeklyProgress_hideAuthorTasks">
                <small *ngIf="user.tasks[header.date]['orderedHours']">
                  Ordered ({{ user.tasks[header.date]['orderedHours'] }}h)
                </small>
              </p>
              <ul class="tasks"
                  *ngIf="user.tasks[header.date]['ordered'] && !userCurrent.settings.dashboard_weeklyProgress_hideAuthorTasks">
                <li
                  *ngFor="let task of user.tasks[header.date]['ordered'] | filter:searchTask:['name', 'projectName']">
                  <ng-container *ngIf="task.project">
                    <div *ngIf="task.project.access"
                         routerLinkActive="active"
                         matTooltip="({{ task.priority }}) {{ task.name }} - {{ task.project.name }}"
                         matTooltipPosition="above"
                         class="task"
                         (click)="onOpenDialog(task)"
                         (mouseenter)="onEnterTask(task)"
                         (mouseleave)="onLeaveTask()"
                         [ngClass]="{'task--start': task.taskStart, 'task--end': task.taskEnd, 'task--enter': (task.uid == enterTaskUid), 'task--lowest': (task.priority == 'lowest'), 'task--low': (task.priority == 'low'), 'task--normal': (task.priority == 'normal'), 'task--high': (task.priority == 'high'), 'task--highest': (task.priority == 'highest'),'task--done': (task.status == 'done'), 'task--inprogress': (task.status == 'inProgress'), 'task--tocheck': (task.status == 'toCheck'), 'task--feature': (task.type == 'feature'), 'task--bug': (task.type == 'bug'), 'task--check': (task.type == 'check')}"
                         [ngStyle]="{'height.px': task.estimationDay * 20, 'line-height.px': task.estimationDay * 20}">
                      <ng-container *ngIf="task.estimationDay > 0.5">
                        <span>{{ task.name }}</span>
                      </ng-container>
                    </div>
                    <div *ngIf="!task.project.access"
                         matTooltip="({{ task.priority }}) {{ task.name }} - {{ task.project.name }}"
                         matTooltipPosition="above"
                         class="task task--denied"
                         [ngStyle]="{'height.px': task.estimationDay * 20, 'line-height.px': task.estimationDay * 20}">
                      <ng-container *ngIf="task.estimationDay > 0.5">
                        <span>{{ task.name }}</span>
                      </ng-container>
                    </div>
                  </ng-container>
                </li>
              </ul>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
