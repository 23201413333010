<section class="page-section page-section--role-privileges">
    <mat-accordion>
        <mat-expansion-panel *ngFor="let section of privilegeSections" open="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h4>{{ section.name }}</h4>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-selection-list>

                <ng-container *ngFor="let privilege of section.privileges">
                    <mat-list-option checkboxPosition="before"
                                     class="checkbox"
                                     *ngIf="user._rolesName === 'admin' || user._privileges.canUpdateRole; else info"
                                     [selected]="privilege.access"
                                     (click)="privilege.access = !privilege.access; onChange(privilege.uid)">
                        <div matLine>
                            <div class="role-desc">{{ privilege.description }}</div>
                        </div>
                    </mat-list-option>

                    <ng-template #info>
                        <mat-list-option checkboxPosition="before"
                                         [selected]="privilege.access"
                                         disabled="">
                            <div matLine>
                                <div class="role-desc">{{ privilege.description }}</div>
                            </div>
                        </mat-list-option>
                    </ng-template>
                </ng-container>

            </mat-selection-list>
        </mat-expansion-panel>
    </mat-accordion>
</section>
