import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ClientType} from '../../../_types/client.type';
import {AngularFirestore} from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import {ClientsService} from '../../../_services/clients.service';
import {environment} from '../../../../environments/environment';
import {ClientsComponent} from '../clients.component';
import {UserType} from '../../../_types/user.type';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-client-add',
    templateUrl: './client-add.component.html',
    styleUrls: ['./client-add.component.scss']
})
export class ClientAddComponent implements OnInit {

    user: UserType;

    private subscriptionIsExists: Subscription;

    constructor(private clientsComponent: ClientsComponent,
                private clientsService: ClientsService,
                private afs: AngularFirestore,
                private route: ActivatedRoute,
                private progress: NgProgress,
                private snackBar: MatSnackBar) {

        this.clientsComponent.removeNav();

    }

    ngOnInit() {
    }

    onSave(form: NgForm) {

        this.progress.ref().start();
        const name = form.value.name;

        // set user
        this.user = this.route.snapshot.parent.parent.data.user;

        const client: ClientType = {
            uid: this.afs.createId(),
            name: name,
            dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            userUpdateRef: this.afs.doc(`users/${this.user.uid}`).ref,
            isActive: true
        };

        // check duplicates
        this.subscriptionIsExists = this.clientsService.isExists(client, this.user).subscribe(clients => {
            if (clients.length) {
                this.progress.ref().complete();
                this.snackBar.open('Client exists', '', environment.snackbarWarn);
            } else {
                // insert client
                this.subscriptionIsExists.unsubscribe();
                this.clientsService.insertClient(client, this.user)
                    .then(() => {
                        this.progress.ref().complete();
                        this.snackBar.open('Client has been added', '', environment.snackbarSuccess);
                        form.resetForm();
                    }).catch(err => {
                    this.progress.ref().complete();
                    this.snackBar.open(err.message, '', environment.snackbarWarn);
                });
            }
        });
    }
}
