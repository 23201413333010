import { Injectable } from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {TicketType} from '../_types/ticket.type';
import {ServiceType} from '../_types/service.type';
import * as firebase from 'firebase/app';
import {TaskType} from '../_types/task.type';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  constructor(private afs: AngularFirestore) {
  }

  // get ticket
  getTicket(path: string): Observable<TicketType | undefined> {
    return this.afs.doc<TicketType>(path).valueChanges();
  }

  // get ticket once
  getTicketOnce(serviceUid: string, ticketUid: string): any {
    const db = firebase.firestore();
    return db.doc(`services/${serviceUid}/tickets/${ticketUid}`).get();
  }

  // get tickets by month
  getTicketsByMonth(path: string, yearMonth: string, userTodoRef: any): Observable<TicketType[]> {
    return this.afs.collection<TicketType>(path, ref => ref
      .where(`showInMonths.${yearMonth}`, '==', true)
      .where(`isActive`, '==', true)
      .where(`userTodoRef`, '==', userTodoRef)).valueChanges();
  }

  // get tickets by service and month
  getTicketsByServiceMonth(service: ServiceType, yearMonth: string): Observable<TicketType[]> {
    return this.afs.collection<TicketType>(`services/${service.uid}/tickets`, ref => ref
      .where(`showInMonths.${yearMonth}`, '==', true)
      .where(`isActive`, '==', true))
      .valueChanges();
  }

  // insert ticket
  insertTicket(ticket: TicketType, path: string): Promise<void> {
    return this.afs.doc<TicketType>(path).set(ticket);
  }

  // update ticket
  updateTicket(fields: {}, path: string): Promise<void> {
    return this.afs.doc<TicketType>(path).update(fields);
  }

  // upload file
  uploadFile(file: any, path: string, fileName: string): any {
    const metadata = {
      contentDisposition: 'attachment; filename=' + fileName,
    };
    return firebase.storage().ref().child(path).put(file, metadata);
  }
}
