import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Observable, Subscription} from "rxjs";
import {ChatType} from "../../_types/chat.type";
import {UsersService} from "../../_services/users.service";
import {ChatsService} from "../../_services/chats.service";

@Component({
    selector: 'app-chats',
    templateUrl: './chats.component.html',
    styleUrls: ['./chats.component.scss']
})
export class ChatsComponent implements OnInit {

    uid: string;
    title: string = '';
    chats$: Observable<ChatType[]>;
    searchChat: string;
    navLinks: any[];

    private subscriptions: Array<Subscription> = [];

    constructor(private router: Router,
                private usersService: UsersService,
                private chatsService: ChatsService,) {
    }

    ngOnInit() {
        //set menu and title
        this.subscriptions.push(this.router.events.subscribe((val) => {
            switch (this.router.url) {
                case '/chats/chat-add': {
                    this.title = 'Add chat';
                    break;
                }
                default: {
                    this.title = '';
                }
            }
        }))

        //get chats
        // this.subscriptions.push(this.usersService.user$.subscribe(user => {
        //     if (user) {
        //         this.chats$ = this.chatsService.getChats(user);
        //     }
        // }))
    }

    setNav(uid) {
        this.uid = uid;
        this.navLinks = [
            {
                path: uid + '/messages',
                label: 'Messages'
            },
            {
                path: uid + '/details',
                label: 'Details'
            },
            {
                path: uid + '/team',
                label: 'Team'
            }
        ]
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
