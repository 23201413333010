import {Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs/internal/Subscription';
import {ServiceType} from '../_types/service.type';
import {ServiceCategoryType} from '../_types/serviceCategory.type';
import {ServiceSubcategoryType} from '../_types/serviceSubcategory.type';
import {FormControl, NgForm} from '@angular/forms';
import {COMMA, ENTER, SEMICOLON, SPACE} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {ServicesService} from '../_services/services.service';
import * as firebase from 'firebase/app';
import {environment} from '../../environments/environment';
import {TicketType} from '../_types/ticket.type';
import {AngularFirestore} from '@angular/fire/firestore';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TicketsService} from '../_services/tickets.service';
import {MessageType} from '../_types/message.type';
import {MessagesService} from '../_services/messages.service';
import {NotificationType} from '../_types/notification.type';
import {NotificationsService} from '../_services/notifications.service';
import {UsersService} from '../_services/users.service';
import {FcmService} from '../_services/fcm.service';
import {UploaderOptions, UploadFile, UploadInput, UploadOutput} from 'ngx-uploader';
import {FileType} from '../_types/file.type';
import {UserType} from '../_types/user.type';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {NgProgress} from 'ngx-progressbar';
import {UploadTaskSnapshot} from '@angular/fire/storage/interfaces';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit, OnDestroy {

  private subscriptions: Array<Subscription> = [];

  service: ServiceType | undefined;
  category: ServiceCategoryType | undefined;
  categories: ServiceCategoryType[] = [];
  subcategories: ServiceSubcategoryType[] | undefined;
  emails: string[] = [];
  user: UserType | undefined;
  lsEmails: any;
  lsEmailsCopy: any;

  emailCtrl = new FormControl();

  ticketFiles: FileType[] = [];
  filesTicket: UploadFile[] = [];
  options: UploaderOptions | undefined;
  uploadInput: EventEmitter<UploadInput> | undefined;
  dragOver: boolean | undefined;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON, SPACE];
  @ViewChild('auto') matAutocomplete: MatAutocomplete | undefined;

  constructor(private serviceService: ServicesService,
              private ticketsService: TicketsService,
              private messagesService: MessagesService,
              private notificationsService: NotificationsService,
              private usersService: UsersService,
              private router: Router,
              private fcmService: FcmService,
              private route: ActivatedRoute,
              private afs: AngularFirestore,
              private progress: NgProgress,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    // set params
    this.subscriptions.push(this.route.params.subscribe(params => {
      // get service
      this.getService(params.slug);

      // get user
      this.getUser();
    }));
  }

  private getLsEmails(): void {
    this.lsEmails = JSON.parse(localStorage.getItem('serviceDeskEmails') as string);
    if (this.lsEmails) {
      this.emails.forEach(email => {
        for (let i = 0; i < this.lsEmails.length; i++) {
          if (email === this.lsEmails[i]) {
            this.lsEmails.splice(i, 1);
          }
        }
      });
    }
  }

  private getUser(): void {
    this.subscriptions.push(this.usersService.getUser().subscribe((user: any) => {
      this.user = user;
      // @ts-ignore
      this.emails.push(this.user.email);
      this.getLsEmails();
    }));
  }

  private getService(slug: string): void {
    this.subscriptions.push(this.serviceService.getService(slug).subscribe(services => {
      this.service = services[0];

      // get categories and subcategories
      this.getCategoriesSubcategories();
    }));
  }

  private getCategoriesSubcategories(): void {
    // @ts-ignore
    this.serviceService.getCategoriesOnce(this.service).then(categories => {
      categories.forEach((categorySnapshot: any) => {
        const category = categorySnapshot.data() as ServiceCategoryType;
        category.subcategories = [];
        category.isForUnlogged = false;

        // get subcategories
        // @ts-ignore
        this.serviceService.getSubcategoriesOnce(this.service, category).then(subcategories => {
          subcategories.forEach((subcategorySnapshot: any) => {
            const subcategory = subcategorySnapshot.data() as ServiceSubcategoryType;
            if (subcategory.inputs) {
              subcategory.inputs = subcategory.inputs.sort(this.compare);
            }

            // push categories
            // @ts-ignore
            category.subcategories.push(subcategory);

            // check if subcategory is for unlogged users
            if (subcategory.isForUnlogged) {
              category.isForUnlogged = true;
            }
          });
        });

        // push categories
        this.categories.push(category);
      });
    });
  }

  private saveTicket(ticket: TicketType): void  {
    // @ts-ignore
    this.ticketsService.insertTicket(ticket, `services/${this.service.uid}/tickets/${ticket.uid}`)
      .then(() => {
        // insert message
        this.insertMessage(
          `has created the ticket`,
          ticket.uid
        );

        // inform users
        // insert notification
        // @ts-ignore
        this.saveNotification(`<p>has created service desk ticket <a class="link" routerLink="services/${this.service.uid}/ticket/${ticket.uid}">${ticket.name}</a></p>`,
          ticket.userTodoRef.id,
          ticket.uid
        );
        // send push notification
        this.subscriptions.push(this.usersService.getUserByRef(ticket.userTodoRef).subscribe((user: any) => {
          this.fcmService.sendMessage(user, {
            title: `New ticket has been created`,
            body: `Ticket ${ticket.name} has been created`,
            click_action: `${environment.url}/notifications`
          });
        }));

        // show snackbar and complete progress
        this.snackBar.open('Ticket has been created', '', environment.snackbarSuccess);
        this.progress.ref().complete();

        // redirect to ticket
        // @ts-ignore
        this.router.navigate([`/service/${this.service.slug}/${ticket.uid}`]).then();
      })
      .catch(err => {
        this.snackBar.open(err.message, '', environment.snackbarWarn);
        this.progress.ref().complete();
      });
  }

  private addEmail(emailVal: string): void  {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // add email
    if ((emailVal || '').trim() && re.test(String(emailVal).toLowerCase())) {

      let duplicateEmail = false;
      let duplicateLsEmail = false;

      // check duplicate emails
      this.emails.forEach(email => {
        if (email === emailVal.trim()) {
          duplicateEmail = true;
        }
      });

      // check duplicate local storage emails
      if (this.lsEmails) {
        this.lsEmails.forEach((lsEmail: string) => {
          if (lsEmail === emailVal.trim()) {
            duplicateLsEmail = true;
          }
        });
      }

      if (!duplicateEmail) {
        this.emails.push(emailVal.trim());
      }

      if (!duplicateLsEmail) {
        // @ts-ignore
        this.lsEmails = JSON.parse(localStorage.getItem('serviceDeskEmails')) || [];
        this.lsEmails.push(emailVal.trim());
        localStorage.setItem('serviceDeskEmails', JSON.stringify(this.lsEmails));
      }

      this.getLsEmails();
    }
  }

  private calcShowInDaysMonthsWeeks(dateStartTimestamp: any, dateEndTimestamp: any): any {
    const dateStart = new Date(dateStartTimestamp);
    dateStart.setHours(0, 0, 0, 0);
    const dateEnd = new Date(dateEndTimestamp);
    dateEnd.setHours(0, 0, 0, 0);
    const show = {
      days: {},
      months: {},
      weeks: {}
    };
    let firstJanuary: Date;
    for (const incDate = new Date(dateStart);
      incDate <= new Date(dateEnd);
      incDate.setDate(incDate.getDate() + 1)) {
      // show in months
      // @ts-ignore
      show.months[incDate.getFullYear() + '' + ('0' + (incDate.getMonth() + 1)).slice(-2)] = true;

      // check weekend days
      if ((incDate.getDay() !== 6) && (incDate.getDay() !== 0)) {
        // show in days
        // @ts-ignore
        show.days[incDate.getFullYear() + '' + ('0' + (incDate.getMonth() + 1)).slice(-2) + '' +
        '' + ('0' + incDate.getDate()).slice(-2)] = true;
      }

      // show in weeks
      firstJanuary = new Date(incDate.getFullYear(), 0, 1);
      // @ts-ignore
      show.weeks[incDate.getFullYear() + '' + Math.ceil((((incDate.getTime() - firstJanuary.getTime()) / 86400000) +
        firstJanuary.getDay() + 1) / 7)] = true;
    }
    return show;
  }

  private saveNotification(message: string, userId: string, ticketUid: string): void  {
    const notification: NotificationType = {
      uid: this.afs.createId(),
      message,
      dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
      dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
      userAuthorRef: null,
      userInformedRef: this.afs.doc(`users/${userId}`).ref,
      elementUid: ticketUid,
      elementType: 'serviceDeskTicket',
      view: 'info',
      isUnread: true,
      author: 'Service desk ' + (this.service?.name)
    };

    this.notificationsService
      .insertNotification(notification, `companies/${this.service?.companyRef.id}/notifications/${notification.uid}`)
      .then(() => {
      })
      .catch(err => {
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  private insertMessage(messageMessage: string, ticketUid: string, type: string = 'auto'): void  {
    let message: MessageType;
    message = {
      uid: this.afs.createId(),
      message: messageMessage,
      dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
      userRef: null,
      files: [],
      type
    };
    this.messagesService.insertMessage(message, `services/${this.service?.uid}/tickets/${ticketUid}/messages/${message.uid}`)
      .catch(err => {
        this.snackBar.open(err.message, '', environment.snackbarWarn);
      });
  }

  private filterAutocomplete(value: string): void {
    if (this.lsEmails) {
      const filterValue = value.toLowerCase();
      this.lsEmailsCopy = [...this.lsEmails];
      this.lsEmails = this.lsEmailsCopy.filter((email: any) => email.toLowerCase().indexOf(filterValue) === 0);
    }
  }

  onChange(target: any): void  {
    this.filterAutocomplete(target.value);
  }

  onRemoveEmail(email: string): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
      this.getLsEmails();
    }
  }

  onRemoveLSEmail(i: any): void  {
    // @ts-ignore
    const lsEmails = JSON.parse(localStorage.getItem('serviceDeskEmails'));
    lsEmails.splice(i, 1);
    localStorage.setItem('serviceDeskEmails', JSON.stringify(lsEmails));
    this.getLsEmails();
  }

  onAddEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    this.addEmail(value);
    input.value = '';
  }

  onSelected(event: MatAutocompleteSelectedEvent): void  {
    this.addEmail(event.option.value);
  }

  compare(a: { order: any; }, b: { order: any; }): any  {
    // Use toUpperCase() to ignore character casing
    const orderA = a.order;
    const orderB = b.order;

    let comparison = 0;
    if (orderA > orderB) {
      comparison = 1;
    } else if (orderA < orderB) {
      comparison = -1;
    }
    return comparison;
  }

  onSelectCategory(category: ServiceCategoryType | undefined): void  {
    this.category = category;
    this.subcategories = category?.subcategories;
  }

  onSelectSubcategory(subcategory: { notificationEmails: ConcatArray<string>; }): void  {
    // reset emails
    this.emails = [];

    // push user email
    if (this.user) {
      this.emails.push(this.user.email);
    }

    // push subcategory predefined emails
    if (subcategory.notificationEmails) {
      this.emails = this.emails.concat(subcategory.notificationEmails);
    }

    // get local storage emails to select
    this.getLsEmails();
  }

  onSave(form: NgForm): void  {
    if (form.valid) {
      this.progress.ref().start();

      let show = {
        days: {} = {},
        months: {} = {},
        weeks: {} = {}
      };

      // prepare description
      let desc = '';
      for (const [key, value] of Object.entries(form.value)) {
        let v: string = JSON.stringify(value);
        if (v[0] === '"') {
          v = v.slice(1);
        }
        if (v[v.length - 1] === '"') {
          v = v.slice(0, -1);
        }
        if (key[0] !== '_') {
          if (desc) {
            desc += '<br><br>';
          }
          desc += `<b>${key}:</b><br>${v.replace(/(?:\\r\\n|\\r|\\n)/g, '<br>')}`;
        }
      }

      // prepare uid
      const uid = this.afs.createId();

      // prepare date start
      const start = new Date();
      if ((start.getDay() === 0) || (start.getDay() === 6)) {
        start.setDate(start.getDate() + ((7 - start.getDay()) % 7 + 1) % 7);
      }

      // prepare show
      show = this.calcShowInDaysMonthsWeeks(start.getTime(), start.getTime());

      // prepare data to ticket
      let ticketUserTodoRef: any = '';
      let ticketName = '';
      let ticketUserSla = 0;
      let ticketEstimation = 1;
      // @ts-ignore
      this.subcategories.forEach(subcategory => {
        if (subcategory.uid === form.value._subcategoryUid) {
          ticketUserTodoRef = subcategory.userTodoRef;
          ticketName = subcategory.name;
          subcategory.inputs.forEach((input: any) => {
            if (input.isAddedToName) {
              ticketName += ` - ${form.value[input.name]}`;
            }
          });
          ticketUserSla = subcategory.sla;
          ticketEstimation = subcategory.estimation;
        }
      });

      // prepare user author
      let userAuthorRef = null;
      if (this.user) {
        userAuthorRef = this.afs.doc(`users/${this.user.uid}`).ref;
      }

      // prepare ticket
      const ticket: TicketType = {
        uid,
        userTodoRef: ticketUserTodoRef,
        userAuthorRef,
        name: ticketName,
        sla: ticketUserSla,
        description: desc,
        serviceRef: this.afs.doc(`services/${this.service?.uid}`).ref,
        dateStart: start,
        dateEnd: start,
        showInMonths: show.months,
        showInDays: show.days,
        showInWeeks: show.weeks,
        priority: 'normal',
        estimation: ticketEstimation,
        dateCreate: firebase.firestore.FieldValue.serverTimestamp(),
        status: 'waitingForSupport',
        isActive: true,
        files: [],
        notificationEmails: this.emails
      };

      // upload files
      if (this.filesTicket.length) {
        let filesLength = this.filesTicket.length;
        for (const file of this.filesTicket) {
          // prepare file
          const fileNameArray = file.name.split('.');
          const name = this.afs.createId() + '.' + fileNameArray[fileNameArray.length - 1];
          const path = `/${this.service?.companyRef.id}/services/${this.service?.uid}/${name}`;
          const uploadTask = this.ticketsService.uploadFile(file.nativeFile, path, file.name);
          uploadTask.on('state_changed', (snapshot: UploadTaskSnapshot) => {
            // @ts-ignore
            file.progress.data.percentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 10000) / 100;
          }, (error: any) => {
            // Handle unsuccessful uploads
            this.snackBar.open(`Unsuccessful uploads`, '', environment.snackbarWarn);
          }, () => {
            // Handle successful uploads on complete
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL: any) => {
              // push file to array
              this.ticketFiles.push({
                name: file.name,
                url: downloadURL,
                type: file.type,
                size: file.size,
                isActive: true
              });
              filesLength--;
              if (filesLength === 0) {
                ticket.files = this.ticketFiles;
                this.saveTicket(ticket);
                this.ticketFiles = [];
                this.filesTicket = [];
              }
            });
          });
        }
      } else {
        this.saveTicket(ticket);
      }
    }
  }

  onRemoveFile(i: number): void  {
    this.filesTicket.splice(i, 1);
  }

  onUpload(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') { // when all files added in queue
      // this.startUpload();
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
      this.filesTicket.push(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      // update current data in files array for uploading file
      const index = this.filesTicket.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.filesTicket[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.filesTicket = this.filesTicket.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
  }

  ngOnDestroy(): void  {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
