<section class="page-section page-section--user-invitations">
    <ng-container *ngIf="usersExternal">
        <mat-expansion-panel *ngFor="let userExternal of usersExternal; let i = index">

            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ userExternal.email }}
                </mat-panel-title>
                <mat-panel-description *ngIf="userExternal && userExternal.dateAdd">
                    invited by {{ userExternal.invitingName }}
                    at {{ userExternal.dateAdd.toDate() | date: 'yyyy-MM-dd HH:mm' }}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="panel-body">
                status: {{ userExternal.status }}
            </div>
            <mat-action-row>
                <a (click)="showRemove[i] = !showRemove[i]" class="link" *ngIf="!showRemove[i]">Delete</a><span
                    *ngIf="showRemove[i]">Are you sure? <a (click)="showRemove[i] = false" class="link">No</a>&nbsp;<button
                    mat-button (click)="onRemove(userExternal)" color="primary" class="link">Yes</button></span>
            </mat-action-row>

        </mat-expansion-panel>
    </ng-container>
    <div *ngIf="!usersExternal.length">You didn't send any invitations</div>
</section>
