import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {ActivatedRoute} from '@angular/router';
import {TicketsService} from '../../../_services/tickets.service';
import {ServicesComponent} from '../services.component';
import {UserType} from '../../../_types/user.type';
import {ServicesService} from '../../../_services/services.service';

@Component({
  selector: 'app-service-ticket',
  templateUrl: './service-ticket.component.html',
  styleUrls: ['./service-ticket.component.scss']
})
export class ServiceTicketComponent implements OnInit, OnDestroy {

  ticketUid: string | any;
  user: UserType | any;
  service: any;

  private subscriptions: Array<Subscription> = [];

  constructor(private route: ActivatedRoute,
              private ticketsService: TicketsService,
              private servicesService: ServicesService,
              private servicesComponent: ServicesComponent) {
  }

  ngOnInit(): void {
    // set user
    // @ts-ignore
    this.user = this.route.snapshot.parent.parent.data.user;
    this.subscriptions.push(this.route.params.subscribe(params => {
      this.ticketUid = params.uidTicket;
      // get service
      this.servicesService.getServiceOnce(params.uid).then((service: any) => {
        this.service = service.data();
        this.servicesComponent.title = this.service.name;
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
