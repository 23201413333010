import {Component, OnInit} from '@angular/core';
import {UserType} from '../../_types/user.type';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  today = new Date();

  navLinks: any[] | undefined;
  uid: string | undefined;
  title: string | undefined;
  user: UserType;


  constructor(private route: ActivatedRoute) {
    // set user
    this.user = this.route?.snapshot?.parent?.data.user;
  }

  ngOnInit(): void {
  }

  setNavInternal(uid: string | undefined): void {
    this.uid = uid;
    this.navLinks = [];

    // calendar link
    if (this.user._modules && this.user._modules.projects) {
      this.navLinks.push({
        path: uid + '/calendar',
        label: 'Calendar',
        exact: false
      });
    }

    // details link
    if (this.user._rolesName === 'admin' || this.user._privileges.canUpdateUser) {
      this.navLinks.push({
        path: uid + '/details',
        label: 'Details',
        exact: true
      });
    }

  }

  setNavExternal(): void {
    this.navLinks = [];

    // list link
    if (this.user._rolesName === 'admin' || this.user._privileges.canCreateUser) {
      // invite link
      this.navLinks.push({
        path: 'user-invite',
        label: 'Invite',
        exact: true
      });

      // invitations link
      this.navLinks.push({
        path: 'user-invitations',
        label: 'Invitations',
        exact: true
      });
    }

    this.navLinks.push({
      path: 'user-list',
      label: 'List',
      exact: true
    });

  }

}
