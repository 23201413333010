<!-- undo remove -->
<ng-container *ngIf="ticket">
  <div class="row" *ngIf="!ticket.isActive">
    <div class="col">
      <div class="section--undoRemove">
        This ticket has been removed.
        <button mat-stroked-button class="mat-white" (click)="onUndoRemove()">Undo remove</button>
      </div>
    </div>
  </div>

  <!--delete-->
  <div *ngIf="ticket.isActive" class="link--delete">
    <a (click)="showRemove = !showRemove" class="link" *ngIf="!showRemove">Delete</a>
    <span *ngIf="showRemove">Are you sure? <span (click)="showRemove = false" class="link">No</span>&nbsp;<span
      (click)="onRemove()" color="primary" class="link">Yes</span></span>
  </div>

  <div class="row">

    <!-- date create / SLA -->
    <div class="col-2">
      <mat-form-field class="mat-form-field--readonly">
        <input matInput
               value="{{ ticket.dateCreate.toDate() | date: 'yyyy-MM-dd H:mm' }} / {{ ticket.sla }}h"
               placeholder="Create date / SLA"
               readonly>
      </mat-form-field>
    </div>

    <!--author-->
    <div class="col-2" *ngIf="(view !== 'service') && author">
      <mat-form-field class="help-width-100p mat-form-field--readonly">
        <mat-label>Author</mat-label>
        <div matPrefix>
          <div *ngIf="author.photoURL"
               class="prefix-user"
               [ngStyle]="{'background-image': 'url(' + author.photoURL + ')'}">
          </div>
          <div *ngIf="!author.photoURL"
               class="prefix-user prefix-user--signs">
            {{ author.name | avatar }}
          </div>
        </div>
        <input matInput
               name="author"
               type="text"
               value="{{ author.name }}"
               required
               readonly
               placeholder="Author">
      </mat-form-field>
    </div>

    <!--date start-->
    <div class="col-2" *ngIf="view !== 'service'">
      <mat-form-field class="help-width-100p">
        <input name="dateStart"
               matInput
               [matDatepickerFilter]="filterWeekend"
               [matDatepicker]="pickerStart"
               (dateChange)="onChangeDateStart()"
               placeholder="Start date"
               [(ngModel)]="dateStartDatepicker"
               readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
        <mat-datepicker #pickerStart></mat-datepicker>
      </mat-form-field>
    </div>

    <!--date end-->
    <div class="col-2" *ngIf="view !== 'service'">
      <mat-form-field class="help-width-100p">
        <input name="dateEnd"
               matInput
               [matDatepickerFilter]="filterWeekend"
               [matDatepicker]="pickerEnd"
               (dateChange)="onChangeDateEnd()"
               placeholder="End date"
               [(ngModel)]="dateEndDatepicker"
               #dateEnd="ngModel"
               readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
        <mat-datepicker #pickerEnd></mat-datepicker>
      </mat-form-field>
    </div>

    <!--estimation-->
    <div class="col-2" *ngIf="view !== 'service'">
      <mat-form-field class="help-width-100p">
        <input matInput
               type="number"
               name="estimation"
               min="0.5"
               step="0.5"
               [(ngModel)]="ticket.estimation"
               (blur)="onUpdate('estimation')"
               (keyup.enter)="onUpdate('estimation')"
               #estimation="ngModel"
               required
               placeholder="Estimation">
        <mat-error *ngIf="estimation.hasError('required')">
          Estimation is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <!--to do-->
    <div class="col-2" *ngIf="view !== 'service'">
      <mat-form-field class="help-width-100p">
        <mat-label>Todo</mat-label>
        <div matPrefix *ngIf="todo">
          <div *ngIf="todo.photoURL"
               class="prefix-user"
               [ngStyle]="{'background-image': 'url(' + todo.photoURL + ')'}">
          </div>
          <div *ngIf="!todo.photoURL"
               class="prefix-user prefix-user--signs">
            {{ todo.name | avatar }}
          </div>
        </div>
        <input matInput
               name="todo"
               type="text"
               [(ngModel)]="todo"
               required
               placeholder="Todo"
               [matAutocomplete]="autoTodo">
        <mat-autocomplete autoActiveFirstOption
                          #autoTodo="matAutocomplete"
                          (optionSelected)="autocompleteSelectedTodo()"
                          [displayWith]="autocompleteDisplayTodo">
          <ng-container *ngFor="let todo of users | filter:todo:['name']">
            <mat-option [value]="todo" *ngIf="todo.isActive">
              {{todo.name}}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
        <mat-error *ngIf="!todo">
          Todo is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

  </div>

  <div class="row">
    <div class="col">
      <mat-form-field>
        <mat-chip-list #chipList>
          <mat-chip *ngFor="let email of ticket.notificationEmails"
                    [removable]="true"
                    (removed)="onRemoveEmail(email)">
            {{email}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input placeholder="Notification emails"
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="onAddEmail"
                 (matChipInputTokenEnd)="onAddEmail($event)">
        </mat-chip-list>
      </mat-form-field>
    </div>
  </div>

  <div class="row" *ngIf="view !== 'service'">
    <div class="col" *ngIf="ticket">
      <div class="section--urls">
        <!--service and ticket URL-->
        <ng-container *ngIf="view === 'page'">
          <button *ngIf="service"
                  mat-flat-button
                  matTooltip="Go to service desk"
                  matTooltipPosition="above"
                  type="button"
                  (click)="onGoToService()">{{ service.name }}
          </button>
          <button mat-flat-button
                  matTooltip="Go to ticket"
                  matTooltipPosition="above"
                  type="button"
                  (click)="onGoToTicket()">{{ ticket.name }}
          </button>
        </ng-container>
        <ng-container *ngIf="view === 'dialog'">
          <button *ngIf="service"
                  mat-flat-button
                  matTooltip="Go to service desk"
                  matTooltipPosition="above"
                  type="button"
                  (click)="onGoToService()"
                  [mat-dialog-close]>{{ service.name }}
          </button>
          <button *ngIf="project"
                  mat-flat-button
                  matTooltip="Go to project"
                  matTooltipPosition="above"
                  type="button"
                  (click)="onGoToProject()">{{ project.name }}
          </button>
          <button mat-flat-button
                  matTooltip="Go to ticket"
                  matTooltipPosition="above"
                  type="button"
                  (click)="onGoToTicket()"
                  [mat-dialog-close]>{{ ticket.name }}
          </button>
        </ng-container>
        <button mat-button
                matTooltip="Copy link to ticket"
                type="button"
                (click)="onCopyLink()"
                matTooltipPosition="above"><i class="material-icons">link</i></button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="section--status-type-priority">

        <!-- status -->
        <mat-button-toggle-group *ngIf="view !== 'service'"
                                 #ticketStatus="matButtonToggleGroup"
                                 (change)="onChangeStatus($event)"
                                 matTooltip="Status"
                                 matTooltipPosition="above"
                                 value="{{ ticket.status }}">
          <mat-button-toggle value="waitingForCustomer" class="mat-button-toggle--waiting-for-customer">Waiting for
            customer
          </mat-button-toggle>
          <mat-button-toggle value="waitingForSupport" class="mat-button-toggle--waiting-for-support">Waiting for
            support
          </mat-button-toggle>
          <mat-button-toggle value="inProgress" class="mat-button-toggle--inprogress">In progress
          </mat-button-toggle>
          <mat-button-toggle value="resolved" class="mat-button-toggle--resolved">Resolved</mat-button-toggle>
        </mat-button-toggle-group>

        <!-- status -->
        <mat-button-toggle-group *ngIf="view == 'service'"
                                 #ticketStatus="matButtonToggleGroup"
                                 (change)="onChangeStatus($event)"
                                 matTooltip="Status"
                                 matTooltipPosition="above"
                                 value="{{ ticket.status }}">
          <mat-button-toggle value="waitingForCustomer" class="mat-button-toggle--waiting-for-customer">Waiting for
            customer
          </mat-button-toggle>
          <mat-button-toggle value="waitingForSupport" class="mat-button-toggle--waiting-for-support">Waiting for
            support
          </mat-button-toggle>
          <mat-button-toggle disabled value="inProgress" class="mat-button-toggle--inprogress">In progress
          </mat-button-toggle>
          <mat-button-toggle disabled value="resolved" class="mat-button-toggle--resolved">Resolved</mat-button-toggle>
        </mat-button-toggle-group>

        <!--priority-->
        <mat-button-toggle-group *ngIf="view !== 'service'"
                                 #ticketPriority="matButtonToggleGroup"
                                 (change)="onChangePriority($event)"
                                 matTooltip="Priority"
                                 matTooltipPosition="above"
                                 value="{{ ticket.priority }}">
          <mat-button-toggle value="lowest" class="mat-button-toggle--lowest">Lowest</mat-button-toggle>
          <mat-button-toggle value="low" class="mat-button-toggle--low">Low</mat-button-toggle>
          <mat-button-toggle value="normal" class="mat-button-toggle--normal">Normal</mat-button-toggle>
          <mat-button-toggle value="high" class="mat-button-toggle--high">High</mat-button-toggle>
          <mat-button-toggle value="highest" class="mat-button-toggle--highest">Highest
          </mat-button-toggle>
        </mat-button-toggle-group>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="section--name">
        <!--name-->
        <mat-form-field *ngIf="view !== 'service'">
          <textarea matInput
                    name="name"
                    #name="ngModel"
                    [(ngModel)]="ticket.name"
                    (keyup.enter)="onUpdate('name'); false"
                    (keydown.enter)="false"
                    (blur)="onUpdate('name')"
                    matTextareaAutosize
                    required
                    placeholder="Name"></textarea>
        </mat-form-field>
        <h2 *ngIf="view == 'service'">{{ ticket.name }}</h2>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="section--description">
        <!--description-->
        <ng-container *ngIf="view !== 'service'; else info">
          <quill-editor
            name="description"
            #description="ngModel"
            [(ngModel)]="ticket.description"
            (onSelectionChanged)="onChangeDescription($event)"
            placeholder="Write a description..."
            required>
            <div quill-editor-toolbar>
            <span class="ql-formats">
                <button class="ql-bold" [title]="'Bold'"></button>
                <button class="ql-italic" [title]="'Italic'"></button>
                <button class="ql-underline" [title]="'Underline'"></button>
                <button class="ql-strike" [title]="'Strike'"></button>
            </span>
              <span class="ql-formats">
                <button class="ql-list" value="ordered" [title]="'List ordered'"></button>
                <button class="ql-list" value="bullet" [title]="'List bullet'"></button>
            </span>
              <span class="ql-formats">
                <button class="ql-link" [title]="'Link'"></button>
                <button class="ql-blockquote" [title]="'Blockquote'"></button>
                <button class="ql-code-block" [title]="'Code-block'"></button>
                <button class="ql-clean" [title]="'Clean format'"></button>
            </span>
            </div>
          </quill-editor>
        </ng-container>
        <ng-template #info>
          <div [outerHTML]="ticket.description | urlify"></div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="section--files">
        <!--assets-->
        <div *ngIf="view !== 'service'"
             class="drop-container"
             ngFileDrop
             [options]="options"
             (uploadOutput)="onUploadTicket($event)"
             (click)="onUpdate('description')"
             [uploadInput]="uploadInput"
             [ngClass]="{ 'is-drop-over': dragOver }">
          <input type="file"
                 ngFileSelect
                 [options]="options"
                 (uploadOutput)="onUploadTicket($event)"
                 [uploadInput]="uploadInput"
                 multiple>
          <span>Select or drop file(s) here</span>
        </div>

        <mat-accordion *ngIf="ticket && ticket.files">
          <ng-container *ngFor="let file of ticket.files; let i = index">
            <mat-expansion-panel *ngIf="file.isActive">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ file.name }}
                </mat-panel-title>
                <mat-panel-description>
                  {{ file.size | fileSize }} ({{ file.type }})
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="mat-expansion-panel-wrapper"
                   [ngClass]="{ 'mat-expansion-panel-wrapper__expand': expandImage }">
                <div class="options">
                  <a class="link" download href="{{ file.url }}">Download</a>
                  <div class="options--right">
                    <a (click)="ticketFileShowRemove[i] = !ticketFileShowRemove[i]" class="link"
                       *ngIf="!ticketFileShowRemove[i] && (view !== 'service')">Delete</a>
                    <span *ngIf="ticketFileShowRemove[i]">Are you sure? <a
                      (click)="ticketFileShowRemove[i] = false" class="link">No</a>&nbsp;<a
                      (click)="onDeleteTicketFile(i)" class="link">Yes</a></span>
                    <span *ngIf="expandImage"
                          (click)="expandImage = !expandImage"
                          class="link-close"><span class="material-icons">clear</span></span>
                  </div>
                </div>
                <div class="expansion-panel__image"
                     *ngIf="file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/gif'">
                  <img src="{{ file.url }}" (click)="expandImage = !expandImage">
                </div>
              </div>

            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="section--comments-header">
        <h2>Comments ({{ messages.length }})</h2>
        <mat-slide-toggle *ngIf="view !== 'service'"
                          [checked]="hideAutoComments"
                          (change)="onChangeUserSettings()">Hide auto comments
        </mat-slide-toggle>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">

      <mat-list class="mat-list-messages">
        <mat-list-item class="mat-list-item--write-message">
          <div matListAvatar
               *ngIf="user && user.photoURL"
               [ngStyle]="{'background-image': 'url(' + user.photoURL + ')'}"></div>
          <div matListAvatar
               *ngIf="user && !user.photoURL">
            {{ user.name | avatar }}
          </div>
          <div matListAvatar *ngIf="!user">C</div>
          <div matLine>
            <div class="mat-line--name" *ngIf="user">{{user.name}}</div>
            <div class="mat-line--name" *ngIf="!user">Customer</div>
            <div>
              <form #f="ngForm"
                    (ngSubmit)="onSend(f)"
                    (keydown.meta.enter)="onSend(f)"
                    (keydown.control.enter)="onSend(f)">
                <quill-editor
                  name="messageText"
                  #message="ngModel"
                  ngModel
                  placeholder="Write a message..."
                  required>
                  <div quill-editor-toolbar>
                    <span class="ql-formats">
                        <button class="ql-bold" [title]="'Bold'"></button>
                        <button class="ql-italic" [title]="'Italic'"></button>
                        <button class="ql-underline" [title]="'Underline'"></button>
                        <button class="ql-strike" [title]="'Strike'"></button>
                    </span>
                    <span class="ql-formats">
                        <button class="ql-list" value="ordered" [title]="'List ordered'"></button>
                        <button class="ql-list" value="bullet" [title]="'List bullet'"></button>
                    </span>
                    <span class="ql-formats">
                    <button class="ql-link" [title]="'Link'"></button>
                    <button class="ql-blockquote" [title]="'Blockquote'"></button>
                    <button class="ql-code-block" [title]="'Code-block'"></button>
                    <button class="ql-clean" [title]="'Clean format'"></button>
                </span>
                  </div>
                </quill-editor>

                <ul class="uploads" *ngIf="filesMessage.length">
                  <li class="upload" *ngFor="let file of filesMessage">{{file.name}} <span
                    *ngIf="file.progress.data.percentage">({{file.progress.data.percentage}}%)</span></li>
                </ul>

                <div class="drop-container"
                     ngFileDrop
                     [options]="options"
                     (uploadOutput)="onUploadMessage($event)"
                     [uploadInput]="uploadInput"
                     [ngClass]="{ 'is-drop-over': dragOver }">
                  <input type="file"
                         ngFileSelect
                         [options]="options"
                         (uploadOutput)="onUploadMessage($event)"
                         [uploadInput]="uploadInput"
                         multiple>
                  <span>Select or drop file(s) here</span>
                </div>

                <button type="submit"
                        class="submit"
                        mat-raised-button color="primary"
                        [disabled]="f.form.invalid">Send
                </button>
                <small class="cmdCtrlEnter">or press CMD/CTRL + Enter</small>
              </form>
            </div>
          </div>
        </mat-list-item>

        <ng-container *ngFor="let message of messages">
          <app-shared-message
            *ngIf="user && !(hideAutoComments && message.type == 'auto') || (!user && (message.type != 'auto'))"
            [message]="message"></app-shared-message>
        </ng-container>
      </mat-list>
    </div>
  </div>
</ng-container>
