import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {RolesComponent} from '../roles.component';
import {RolesService} from '../../../_services/roles.service';
import {PrivilegesService} from '../../../_services/privileges.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {ActivatedRoute} from '@angular/router';
import {PrivilegeType} from '../../../_types/privilege.type';
import {RoleType} from '../../../_types/role.type';
import {UserType} from '../../../_types/user.type';
import {AngularFirestore} from '@angular/fire/firestore';
import {environment} from '../../../../environments/environment';
import {UsersService} from '../../../_services/users.service';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-role-privileges',
    templateUrl: './role-privileges.component.html',
    styleUrls: ['./role-privileges.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RolePrivilegesComponent implements OnInit, OnDestroy {

    privilegeSections: [{
        name: string;
        privileges: PrivilegeType[];
    }] | any;
    privileges: PrivilegeType[] | any;
    privilegesComplete = false;

    role: RoleType | any;
    roleComplete = false;
    user: UserType | any;

    batchLimit = 500;

    private subscriptions: Array<Subscription> = [];

    constructor(private rolesComponent: RolesComponent,
                private rolesService: RolesService,
                private privilegesService: PrivilegesService,
                private usersService: UsersService,
                private route: ActivatedRoute,
                private afs: AngularFirestore,
                private progress: NgProgress,
                private snackBar: MatSnackBar) {

        // get role uid
        this.subscriptions.push(route.params.subscribe(params => {

            // set nav
            this.rolesComponent.setNav(params.uid);

            // set user
            // @ts-ignore
          this.user = this.route.snapshot.parent.parent.data.user;

            // get role
            this.getRole();

            // get privileges
            this.getPrivileges();
        }));
    }

    private getRole(): void {
        this.subscriptions.push(this.rolesService.getRole(this.user, this.rolesComponent.uid).subscribe(role => {
            this.role = role;
            this.rolesComponent.title = this.role.name;
            this.roleComplete = true;
        }));
    }

    private getPrivileges(): void {
        this.subscriptions.push(this.privilegesService.getPrivileges().subscribe(privileges => {
            delete this.privilegeSections;
            this.privileges = privileges;
            this.privilegesComplete = true;
            this.matchRoleToPrivileges();
        }));
    }

    private matchRoleToPrivileges(): void {
        if (this.roleComplete && this.privilegesComplete) {
            for (const privilege of this.privileges) {
                // check access
                privilege.access = false;
                for (const privilegeRef of this.role.privilegeRefs) {
                    if (privilege.uid === privilegeRef.id) {
                        privilege.access = true;
                    }
                }
                // add do section
                if (this.privilegeSections) {
                    let sectionExists = false;
                    for (const section of this.privilegeSections) {
                        if (section.name === privilege.section) {
                            section.privileges.push(privilege);
                            sectionExists = true;
                        }
                    }
                    if (!sectionExists) {
                        this.privilegeSections.push({
                            name: privilege.section,
                            privileges: [privilege]
                        });
                    }
                } else {
                    this.privilegeSections = [{
                        name: privilege.section,
                        privileges: [privilege]
                    }];
                }
            }
        }
    }

    ngOnInit(): void {
    }

    onChange(uid: any): void {
        this.progress.ref().start();
        let message = '';
      // tslint:disable-next-line:variable-name
        const _privilegesUser: any = {};

        // get and set privileges
        for (const privilege of this.privileges) {
            if (privilege.uid === uid) {
                if (!privilege.access) {
                    // remove privilege
                    this.role.privilegeRefs.forEach((privilegeRef: any, index: any) => {
                        if (privilege.uid === privilegeRef.id) {
                            this.role.privilegeRefs.splice(index, 1);
                        }
                    });
                    message = 'Privilege has been removed.';
                } else {
                    // add privilege
                    this.role.privilegeRefs.push(this.afs.doc(`privileges/${uid}`).ref);
                    message = 'Privilege has been added.';
                }
            }
            if (privilege.access) {
                _privilegesUser[privilege.code] = true;
            }
        }

        // update role
        this.role.dateUpdate = new Date();
        this.role.userUpdateRef = this.afs.doc(`users/${this.user.uid}`).ref;
        this.rolesService.updateRole({
            dateUpdate: this.role.dateUpdate,
            userUpdateRef: this.role.userUpdateRef,
            privilegeRefs: this.role.privilegeRefs
        }, this.user, this.role.uid)
            .then(() => {
                // update users with updated role
                let batch = this.afs.firestore.batch();
                let batchSubscription: Subscription;
                batchSubscription = this.usersService.getFilteredUsers(this.user, {
                    name: 'roleRef',
                    val: this.afs.doc(`companies/${this.user.companyRef.id}/roles/${this.role.uid}`).ref
                }).subscribe(users => {
                    for (let i = 0; i < users.length; i++) {
                        batch.update(this.afs.doc(`users/${users[i].uid}`).ref, {
                            _privileges: _privilegesUser
                        });
                        if ((i === users.length - 1) || (i % this.batchLimit === 0)) {
                            batch.commit().then(() => {
                            });
                            batch = this.afs.firestore.batch();
                        }
                    }
                    batchSubscription.unsubscribe();
                    this.progress.ref().complete();
                    this.snackBar.open(message, '', environment.snackbarSuccess);
                });
            }).catch(err => {
                this.progress.ref().complete();
                this.snackBar.open(err.message, '', environment.snackbarSuccess);
            });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
