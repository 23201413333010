<div class="row">
    <div class="col-md">
        <mat-list class="mat-list-messages" *ngIf="messages">
            <mat-list-item *ngFor="let message of messages">
                <ng-container *ngIf="message.author">
                    <div matListAvatar
                         *ngIf="message.author.photoURL"
                         [ngStyle]="{'background-image': 'url(' + message.author.photoURL + ')'}"></div>
                    <img matListAvatar
                         *ngIf="!message.author.photoURL"
                         class="profile__icon"
                         src="../../assets/default_profile_400x400.png">
                    <div matLine>
                        <div>{{message.author.name}}</div>
                        <div [outerHTML]="message.message"></div>
                        <div *ngIf="message.dateCreate">{{ message.dateCreate.toDate() | date: 'yyyy-MM-dd HH:mm' }}
                        </div>
                        <ul *ngIf="message.files">
                            <li *ngFor="let file of message.files">
                                <a href="{{file.url}}" target="_blank">{{file.name}}/{{file.type}}/{{file.size}}</a>
                            </li>
                        </ul>
                    </div>
                </ng-container>
            </mat-list-item>
            <mat-list-item *ngIf="user">
                <div matListAvatar
                     *ngIf="user.photoURL"
                     [ngStyle]="{'background-image': 'url(' + user.photoURL + ')'}"></div>
                <img matListAvatar
                     *ngIf="!user.photoURL"
                     class="profile__icon"
                     src="../../assets/default_profile_400x400.png">
                <div matLine>
                    <div>{{user.name}}</div>
                    <div>
                        <form (ngSubmit)="onSend(f)" #f="ngForm">
                            <quill-editor
                                    name="messageText"
                                    #message="ngModel"
                                    ngModel
                                    required>
                                <div quill-editor-toolbar>
                                        <span class="ql-formats">
                                            <button class="ql-bold" [title]="'Bold'"></button>
                                            <button class="ql-italic" [title]="'Italic'"></button>
                                            <button class="ql-underline" [title]="'Underline'"></button>
                                            <button class="ql-strike" [title]="'Strike'"></button>
                                        </span>
                                    <span class="ql-formats">
                                            <button class="ql-list" value="ordered" [title]="'List ordered'"></button>
                                            <button class="ql-list" value="bullet" [title]="'List bullet'"></button>
                                        </span>
                                    <span class="ql-formats">
                                            <button class="ql-blockquote" [title]="'Blockquote'"></button>
                                            <button class="ql-code-block" [title]="'Code-block'"></button>
                                        <button class="ql-clean" [title]="'Clean format'"></button>
                                        </span>
                                </div>
                            </quill-editor>


                            <div class="drop-container" ngFileDrop [options]="options"
                                 (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput"
                                 [ngClass]="{ 'is-drop-over': dragOver }">
                                <h1>Drag &amp; Drop</h1>
                                <input type="file" ngFileSelect [options]="options"
                                       (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>
                                or choose file(s)
                            </div>

                            <ul *ngIf="messageFiles">
                                <li *ngFor="let file of messageFiles; let i = index">
                                    {{file.name}} <i class="material-icons" (click)="onDeleteFile(i)">clear</i>
                                </li>
                            </ul>

                            <button type="submit"
                                    mat-raised-button color="primary"
                                    [disabled]="f.form.invalid">Send
                            </button>
                        </form>
                    </div>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</div>
