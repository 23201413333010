<main>
    <div class="container">
        <div class="row">
            <div class="col">

                <header class="page-header">
                    <h1>Dashboard</h1>
                </header>

                <section class="page-section">
                    <!-- Weekly progress -->
                    <div class="row row-weeklyProgress" *ngIf="user && user._modules && user._modules.projects">
                        <div class="col">
                            <app-widget-progress *ngIf="users" [users]="users"></app-widget-progress>
                        </div>
                    </div>

                    <!-- Brands -->
                    <div class="row row-weeklyProgress" *ngIf="user && user._modules && user._modules.brands">
                        <div class="col">
                            <app-widget-brands></app-widget-brands>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    </div>
</main>
