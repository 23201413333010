import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../_services/auth.service';
import {Router} from '@angular/router';
import {UsersService} from '../../_services/users.service';
import {CompaniesService} from '../../_services/companies.service';
import {NgForm} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {UserType} from '../../_types/user.type';
import * as firebase from 'firebase/app';
import {UsersExternalService} from '../../_services/users-external.service';
import {UserExternalType} from '../../_types/userExternal.type';
import {ChangelogType} from '../../_types/changelog.type';
import {SharedBottomsheetVersionComponent} from '../../_shared/shared-bottomsheet-version/shared-bottomsheet-version.component';
import {Subscription} from 'rxjs';
import {ChangelogService} from '../../_services/changelog.service';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  show = 'register';
  userExternal: UserExternalType;
  user: { email: string; uid: string; } = {email: '', uid: ''};
  userData: UserType;
  log: ChangelogType;
  trialDateEnd: Date;

  private subscriptionChangelog: Subscription;

  constructor(public authService: AuthService,
              private router: Router,
              private usersService: UsersService,
              private companiesService: CompaniesService,
              private usersExternalService: UsersExternalService,
              private changelogService: ChangelogService,
              private snackBar: MatSnackBar,
              private progress: NgProgress) {

    // get last changelog
    this.getChangelogLast();

    this.trialDateEnd = new Date;
    this.trialDateEnd = new Date(this.trialDateEnd.setMonth(this.trialDateEnd.getMonth() + 2)); // add 2 months
    this.trialDateEnd = new Date(this.trialDateEnd.getFullYear(), this.trialDateEnd.getMonth(), 0); // set last day of the previous month

  }

  ngOnInit() {
  }

  private getChangelogLast() {
    this.subscriptionChangelog = this.changelogService.getChangelogLast().subscribe(logs => {
      this.log = logs[0];
    });
  }


  onRegister(form: NgForm) {
    this.progress.ref().start();
    this.authService.register(form.value)
      .then(user => {
        this.user.email = user.user.email.toLowerCase();
        this.user.uid = user.user.uid;

        // prepare user name
        let userName = this.user.email.split('@')[0].replace(/\./g, ' ');
        const splitUserName = userName.toLowerCase().split(' ');
        for (let i = 0; i < splitUserName.length; i++) {
          splitUserName[i] = splitUserName[i].charAt(0).toUpperCase() + splitUserName[i].substring(1);
        }
        userName = splitUserName.join(' ');

        // set default user data
        this.userData = {
          uid: this.user.uid,
          email: this.user.email,
          name: userName,
          isEmailVerified: false,
          photoURL: '',
          dateUpdate: firebase.firestore.FieldValue.serverTimestamp(),
          userUpdateRef: this.user.uid,
          isActive: true,
          isEmailNotification: false,
          roleRef: null,
          companyRef: null,
          changelog: {
            bottomSheet: this.log.version,
            page: this.log.version
          }
        };

        // get external users and set company
        this.usersExternalService.getUserExternalByEmail(this.user.email).subscribe(userExternal => {
          if (userExternal.length) {
            // show invitation
            this.show = 'invitation';
            this.userExternal = userExternal[0];
            this.progress.ref().complete();
          } else {
            this.sendEmailVerification(form);
          }
        });
      })
      .catch(err => {
        this.snackBar.open(err.message, '', environment.snackbarWarn);
        this.progress.ref().complete();
      });
  }

  private sendEmailVerification(form) {
    // send email verification
    this.authService.sendEmailVerification()
      .then(() => {
        this.usersService.setUser(this.userData).then(() => {
          this.snackBar.open('Verification message has been sent', '', environment.snackbarSuccess);
          this.progress.ref().complete();
          // reset form
          form.resetForm();
          // update view
          this.show = 'complete';
        });
      })
      .catch(err => {
        this.snackBar.open(err.message, '', environment.snackbarWarn);
        this.progress.ref().complete();
      });
  }

  onChoose(form: NgForm) {
    if (form.value.invitation) {
      // set invitation as accepted
      this.usersExternalService.updateUserExternal({
        status: 'accepted'
      }, this.userExternal.uid)
        .then(() => {
          // update companyRef in user
          this.userData.companyRef = this.userExternal.companyRef;
          // continue register
          this.sendEmailVerification(form);
        })
        .catch(err => {
          this.snackBar.open(err.message, '', environment.snackbarWarn);
          this.progress.ref().complete();
        });
    } else {
      // set invitation as rejected
      this.usersExternalService.updateUserExternal({
        status: 'rejected'
      }, this.userExternal.uid)
        .then(() => {
          // continue register
          this.sendEmailVerification(form);
        })
        .catch(err => {
          this.snackBar.open(err.message, '', environment.snackbarWarn);
          this.progress.ref().complete();
        });
    }
  }

  ngOnDestroy() {
    this.subscriptionChangelog.unsubscribe();
  }
}



