import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AuthService} from '../_services/auth.service';
import {UsersService} from '../_services/users.service';
import {AngularFireAuth} from '@angular/fire/auth';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private auth: AuthService,
                private usersService: UsersService,
                private router: Router,
                private afAuth: AngularFireAuth) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        return this.afAuth.authState.pipe(
            map(auth => !!auth),
            tap(loggedIn => {
                    if (!loggedIn) {
                        this.router.navigate(['/login']);
                    }
                }
            ));
    }
}
