import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'urlify'
})
export class UrlifyPipe implements PipeTransform {

    transform(text: string): string | null {
        if (text) {
            return text;
            // const urlRegex = /(\b(https?|):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
            // return text.replace(urlRegex, function(url) {
            //     return '<a href="' + url + '" target="_blank" class="link">' + url + '</a>';
            // });
        }
        return null;
    }

}
