<section class="page-section">
  <div class="page-section-header">
    <mat-form-field>
      <input matInput
             placeholder="Search"
             type="search"
             [(ngModel)]="searchUser">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <ng-container *ngFor="let user of users | filter:searchUser:['name','_rolesName']">
    <mat-card class="mat-card--user"
              *ngIf="userCurrent._rolesName === 'admin' || userCurrent._privileges.canGetUser || userCurrent.uid === user.uid">
      <a routerLink="/users/{{ user.uid }}" class="mat-card-link">
        <mat-card-header>
          <div mat-card-avatar
               *ngIf="user.photoURL"
               [ngStyle]="{'background-image': 'url(' + user.photoURL + ')'}"></div>
          <div mat-card-avatar
               *ngIf="!user.photoURL">{{ user.name | avatar }}
          </div>
          <mat-card-title>{{ user.name }}<br>
            <small>{{ user._rolesName }}</small>
          </mat-card-title>
        </mat-card-header>
      </a>

      <mat-card-actions *ngIf="userCurrent.uid === user.uid && user.isFollower">
        <button mat-stroked-button color="warn" (click)="onUnfollow()">Unfollow</button>
      </mat-card-actions>
      <mat-card-actions *ngIf="userCurrent.uid === user.uid && !user.isFollower">
        <button mat-stroked-button color="success" (click)="onFollow()">Follow</button>
      </mat-card-actions>

      <mat-card-actions *ngIf="userCurrent.uid !== user.uid && user.isFollower">
        <button mat-stroked-button color="success"  disabled>Is follow</button>
      </mat-card-actions>
      <mat-card-actions *ngIf="userCurrent.uid !== user.uid && !user.isFollower">
        <button mat-stroked-button color="warn" disabled>Is not follow</button>
      </mat-card-actions>

    </mat-card>
  </ng-container>
</section>

