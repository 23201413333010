import {Injectable} from '@angular/core';
import {ClientType} from '../_types/client.type';
import {AngularFirestore} from '@angular/fire/firestore';
import {ProjectsService} from './projects.service';
import {UserType} from '../_types/user.type';
import {TaskType} from '../_types/task.type';
import {ProjectType} from '../_types/project.type';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})
export class ClientsService {

    // batchLimit: number = 500;

    constructor(private afs: AngularFirestore) {
    }

    // get client
    getClient(user: UserType, clientUid: string): Observable<ClientType | undefined> {
        return this.afs.doc<ClientType>(`companies/${user.companyRef.id}/clients/${clientUid}`)
            .valueChanges();
    }

    // get clients
    getClients(user: UserType): Observable<ClientType[]> {
        return this.afs.collection<ClientType>(`companies/${user.companyRef.id}/clients`, ref => ref
            .where('isActive', '==', true)
            .orderBy('name'))
            .valueChanges();
    }

    // check duplicates
    isExists(client: ClientType, user: UserType): Observable<ClientType[]> {
        return this.afs.collection<ClientType>(`companies/${user.companyRef.id}/clients`, ref => ref
            .where('isActive', '==', true)
            .where('name', '==', client.name))
            .valueChanges();
    }

    // insert client
    insertClient(client: ClientType, user: UserType): Promise<void> {
        return this.afs.doc<ClientType>(`companies/${user.companyRef.id}/clients/${client.uid}`)
            .set(client);
    }

    // update client
    updateClient(client: ClientType, user: UserType): Promise<void> {
        // update "_clients_*" fields in "projects" documents
        // let batch = this.afs.firestore.batch();
        // let batchSubscription: Subscription;
        // batchSubscription = this.projectsService.getFileteredProjects(user,
        //     {
        //         name: 'clientRef',
        //         val: this.afs.doc(`companies/${user.companyRef.id}/clients/${client.uid}`).ref
        //     }).subscribe(
        //     projects => {
        //         // update all projects
        //         for (let i = 0; i < projects.length; i++) {
        //             batch.update(this.afs.doc(`companies/${user.companyRef.id}/projects/${projects[i].uid}`).ref, {
        //                 _clients_name: client.name
        //             })
        //             if ((i === projects.length - 1) || (i % this.batchLimit === 0)) {
        //                 batch.commit().then(() => {
        //                 });
        //                 batch = this.afs.firestore.batch()
        //             }
        //         }
        //         batchSubscription.unsubscribe();
        //     });

        return this.afs.doc<ClientType>(`companies/${user.companyRef.id}/clients/${client.uid}`)
            .update(client);

    }

    // remove client
    removeClient(client: ClientType, user: UserType, fields: {}): Promise<void> {
        return this.afs.doc<TaskType>(`companies/${user.companyRef.id}/clients/${client.uid}`)
            .update(fields);
    }
}
