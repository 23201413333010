import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {NotificationsService} from '../../_services/notifications.service';
import {NotificationType} from '../../_types/notification.type';
import {Subscription} from 'rxjs/internal/Subscription';
import {UsersService} from '../../_services/users.service';
import * as firebase from 'firebase/app';
import {environment} from '../../../environments/environment';
import {UserType} from '../../_types/user.type';
import {ActivatedRoute, Router} from '@angular/router';
import {RoleType} from '../../_types/role.type';
import {RolesService} from '../../_services/roles.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {NgProgress} from 'ngx-progressbar';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationsComponent implements OnInit, OnDestroy {

  notifications: NotificationType[] = [];
  notificationsComplete = false;
  users: UserType[] = [];
  usersComplete = false;
  roles: RoleType[] = [];
  user: UserType;

  showDelete = false;
  showRead = false;
  isUnreadExists = false;
  batchLimit = 500;

  private subscriptions: Array<Subscription> = [];
  private subscriptionReadAll: Subscription;
  private subscriptionDeleteAll: Subscription;

  constructor(private notificationsService: NotificationsService,
              private rolesService: RolesService,
              private usersService: UsersService,
              private progress: NgProgress,
              private router: Router,
              private snackBar: MatSnackBar,
              private route: ActivatedRoute,
              private afs: AngularFirestore) {
  }

  ngOnInit() {
    // set user
    this.user = this.route.snapshot.parent.data.user;

    // get notifications
    this.getNotifications();

    // get users
    this.getUsers();
  }

  private getNotifications() {
    this.subscriptions.push(this.notificationsService.getNotifications(this.user).subscribe(notifications => {
      this.isUnreadExists = false;
      this.notifications = notifications;
      this.notifications.forEach(notification => {
        if (notification.isUnread === true) {
          this.isUnreadExists = true;
        }
      });
      this.notificationsComplete = true;
      this.matchNotificationsToUsers();
    }));
  }

  private getUsers() {
    this.subscriptions.push(this.usersService.getUsers(this.user.companyRef).subscribe(users => {
      this.users = users;
      this.usersComplete = true;
      this.matchNotificationsToUsers();
    }));
  }

  private matchNotificationsToUsers() {
    if (this.notificationsComplete && this.usersComplete) {
      this.notifications.forEach(notification => {
        if (notification.userAuthorRef) {
          this.users.forEach(user => {
            if (user.uid === notification.userAuthorRef.id) {
              notification.author = user;
            }
          });
        }
      });
    }
  }

  onRemove(notification) {
    // start progress bar
    this.progress.ref().start();
    // delete notification
    this.notificationsService.deleteNotification(`companies/${this.user.companyRef.id}/notifications/${notification.uid}`)
      .then(() => {
        // show snackbar
        this.snackBar.open('Notification has been removed', '', environment.snackbarSuccess);
        // close progress bar
        this.progress.ref().complete();
      })
      .catch(err => {
        // show snackbar
        this.snackBar.open(err.message, '', environment.snackbarWarn);
        // close progress bar
        this.progress.ref().complete();
      });
  }

  onRemoveAll() {
    // start progress bar
    this.progress.ref().start();
    // delete notifications
    let batch = this.afs.firestore.batch();
    // get all notifications
    this.subscriptionDeleteAll = this.notificationsService.getNotificationsAll(this.user).subscribe(notificationsAll => {
      this.subscriptionDeleteAll.unsubscribe();
      for (let i = 0; i < notificationsAll.length; i++) {
        batch.delete(this.afs.doc(`companies/${this.user.companyRef.id}/notifications/${this.notifications[i].uid}`).ref);
        if ((i === this.notifications.length - 1) || (i % this.batchLimit === 0)) {
          batch.commit().then(() => {
          });
          batch = this.afs.firestore.batch();
        }
      }
      this.progress.ref().complete();
      this.snackBar.open('All notifications has been removed', '', environment.snackbarSuccess);
      this.showDelete = false;
    });
  }

  onRead(notification) {
    // start progress bar
    this.progress.ref().start();
    // update notification
    this.notificationsService.updateNotification({
      isUnread: false,
      dateUpdate: firebase.firestore.FieldValue.serverTimestamp()
    }, `companies/${this.user.companyRef.id}/notifications/${notification.uid}`)
      .then(() => {
        // show snackbar
        this.snackBar.open('Notification has been set as read', '', environment.snackbarSuccess);
        // close progress bar
        this.progress.ref().complete();
      })
      .catch(err => {
        // show snackbar
        this.snackBar.open(err.message, '', environment.snackbarWarn);
        // close progress bar
        this.progress.ref().complete();
      });
  }

  onReadAll() {
    // start progress bar
    this.progress.ref().start();
    // update notification
    let batch = this.afs.firestore.batch();
    // get all notifications
    this.subscriptionReadAll = this.notificationsService.getNotificationsAll(this.user).subscribe(notificationsAll => {
      for (let i = 0; i < notificationsAll.length; i++) {
        batch.update(this.afs.doc(`companies/${this.user.companyRef.id}/notifications/${this.notifications[i].uid}`).ref, {
          isUnread: false
        });
        if ((i === this.notifications.length - 1) || (i % this.batchLimit === 0)) {
          batch.commit().then(() => {
          });
          batch = this.afs.firestore.batch();
        }
      }
      this.progress.ref().complete();
      this.snackBar.open('All notifications has been set as read', '', environment.snackbarSuccess);
      this.showRead = false;
      this.subscriptionReadAll.unsubscribe();
    });
  }

  onUnread(notification) {
    // start progress bar
    this.progress.ref().start();
    // update notification
    this.notificationsService.updateNotification({
      isUnread: true,
      dateUpdate: firebase.firestore.FieldValue.serverTimestamp()
    }, `companies/${this.user.companyRef.id}/notifications/${notification.uid}`)
      .then(() => {
        // show snackbar
        this.snackBar.open('Notification has been set as unread', '', environment.snackbarSuccess);
        // close progress bar
        this.progress.ref().complete();
      })
      .catch(err => {
        // show snackbar
        this.snackBar.open(err.message, '', environment.snackbarWarn);
        // close progress bar
        this.progress.ref().complete();
      });
  }

  onLink($event) {
    if ($event.target.tagName.toLowerCase() === 'a') {
      const link = $event.target.getAttribute('routerLink');
      this.router.navigate([link]);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
